/* @import-normalize; */

/* index.css or App.css */
@import "modern-normalize";

:root {
  --bg_gray: #151517;
  /* --bg_light_theme: #ffffff; */
  --bg_light_theme: #f1ffff;
  --body-dark-font: #ffffff;
  /* --body-dark-font: #fafafa; */
  --body-light-font: #444444;
}

.rent-detail-table {
  /* min-height: 400px; */
}

.rent-details-table-wrapper.unset-responsive .table-responsive {
  overflow: initial !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  color: var(--body-dark-font);
  letter-spacing: 0.5px;
}

body,
.front-end-wrapper {
  min-height: 100vh;
  min-width: 100%;
  overflow: auto;
}

.pac-container {
  z-index: 9999;
}

.pac-container * {
  color: #000000;
}

.llll {
  background-color: #000000 !important;
}

/* Configuration style starts */
/* Toastify style starts */
/* Toastify style ends */

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-box-shadow: none;
  background: #000000 !important;
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-text-fill-color: #ffffff !important;
  color: #ffffff !important;
  caret-color: #ffffff !important;
}

a {
  text-decoration: none;
  color: #37bdb0;
}
a:hover {
  color: #ffffff;
  /* color: var(--bg_gray); */
}

/* Scrollbar style starts */
/* width */
::-webkit-scrollbar {
  width: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  background-color: #151517;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #424243;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
  cursor: pointer;
}
/* Scrollbar style ends */
/* Configuration style ends */

/* ************* Global styles starts ************* */
.transparent-text {
  color: transparent !important;
}
/* LIGHT COLOR THEMES - Start */

.notification-item-detail-modal-main-wrapper
  .bg_lightTheme
  .modal-body-custom
  .detail-col.left {
  color: #888888 !important;
}

.notification-item-detail-modal-main-wrapper
  .bg_lightTheme
  .modal-body-custom
  .detail-col {
  color: var(--body-light-font) !important;
}

.notification-item-detail-modal-main-wrapper
  .bg_lightTheme
  .modal-body-custom
  .notification-txt {
  color: var(--body-light-font) !important;
}

.light_theme {
  * {
    color: var(--body-light-font);

    themeLink {
      text-decoration: none;
      color: var(--body-light-font) !important;
    }
    themeLink:hover {
      color: var(--bg_gray) !important;
    }
  }

  background-color: var(--bg_light_theme);

  .team-detail-modal-wrapper .modal-content {
    background-color: var(--body-dark-font) !important;
  }

  .team-detail-modal-wrapper .custom-modal-body {
    background: var(--body-dark-font) !important;
  }

  .tenant-moveout-detail-wrapper .display-move-out .font_white {
    color: #1d1d1e;
  }

  .blur-blue {
    position: absolute;
    width: 100px;
    height: 125px;
    background: #1dc5b4 0% 0% no-repeat padding-box;
    opacity: 0.75;
    filter: blur(50px);
  }

  .blur-cherry {
    position: absolute;
    width: 78px;
    height: 78px;
    background: #88103d 0% 0% no-repeat padding-box;
    opacity: 0.75;
    filter: blur(50px);
  }

  .bgShade {
    display: block;
    position: absolute;
    top: 50px;
    right: 0;
    z-index: -1;
    /* border: 2px solid red; */

    /* img{
      width: 150px;
      height: 175px;
    } */

    /* opacity: 0.5; */
  }

  .font_yellow {
    color: #ffb900;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0;
    -webkit-box-shadow: none;
    background: var(--body-dark-font) !important;
    transition: background-color 5000s ease-in-out 0s !important;
    -webkit-text-fill-color: var(--body-light-font) !important;
    color: var(--body-light-font) !important;
    caret-color: var(--body-light-font) !important;
  }

  hr {
    color: var(--body-light-font);
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: var(--body-dark-font);
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: var(--bg_light_theme);
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
    cursor: pointer;
  }

  /* FORM INPUT VALUES */

  /* INPUT TEXT */
  .form-input-wrapper input {
    background-color: #eefcfc /* //var(--bg_light_theme) */;
    color: var(--body-light-font);
  }

  /* INPUT TEXTAREA */
  .form-textarea-wrapper textarea {
    background-color: #eefcfc;
    /* var(--bg_light_theme); */
    color: var(--body-light-font);
  }

  /* CUSTOME MULTI CHECKBOX */
  .checkbox-multi-select-dropdown-wrapper .drop-menu {
    background-color: #eefcfc;
    /* //var(--bg_light_theme); */
    color: var(--body-light-font);
    border: 2px solid var(--body-dark-font);
  }

  /* INPUT DATE PICKER */
  .custom-date-input-main-wrapper-v2 .react-date-picker__wrapper {
    background-color: #eefcfc;
    /* //var(--bg_light_theme); */

    input {
      color: var(--body-light-font);
    }
  }

  .custom-date-input-main-wrapper-v2 input {
    color: var(--body-light-font) !important;
  }

  .custom-date-input-main-wrapper-v2 .react-date-picker__inputGroup__divider {
    color: var(--body-light-font) !important;
  }

  .custom-check-input-component-wrapper
    .variant-2
    input:checked
    ~ .checkmark::after {
    border-left: 3.5px solid #37bdb0;
    border-bottom: 3.5px solid #37bdb0;
  }

  /* INPUT SELECT */
  .form-input-wrapper select {
    background-color: #eefcfc;
    /* var(--bg_light_theme); */
    color: var(--body-light-font);
  }

  /* INPUT SELECT */
  .time-select-container .select-wrap {
    background-color: #eefcfc;
    /* var(--bg_light_theme); */
    color: var(--body-light-font);
  }

  /* INPUT FILE */
  .custom-file-input-wrapper .filename {
    background-color: #eefcfc;
    /* var(--bg_light_theme); */
    color: var(--body-light-font);
  }

  .custom-file-input-wrapper .filechosen label {
    color: var(--body-light-font);
  }

  /* INPUT TELEPHONE */
  .react-tel-input .form-control {
    background-color: #eefcfc !important;
    /* var(--bg_light_theme) !important; */
    color: var(--body-light-font) !important;
  }

  /* .react-tel-input .form-control */

  /* TEXT EDITOR */
  .text-main-wrapper {
    background-color: #eefcfc !important;
    /* var(--bg_light_theme); */
    color: var(--body-light-font);
  }

  .text-main-wrapper .sun-editor-editable {
    background-color: #eefcfc !important;
    /* var(--bg_light_theme); */
    color: var(--body-light-font);
  }

  /* ADD NEW CARD */
  .add-new-card-wrapper-main .input-wrapper input {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
  }

  /* .custom-date-input-main-wrapper-v2  */

  /* SEARCH BAR INPUT */
  .search-form {
    input {
      background-color: var(--body-dark-font);
      color: var(--body-light-font);
      /* color: ; */
      &::placeholder {
        color: var(--body-light-font);
      }
    }
  }

  /* LOADER */
  .loader-component-wrapper {
    background-color: var(--bg_light_theme);
  }

  /* INFO TILES */
  .overview-item-component-wrapper {
    background-color: #37bdb0;
    /* background-color: var(--body-dark-font); */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    color: var(--body-light-font);
  }

  .overview-item-component-wrapper .box .main-label .label {
    /* color: var(--body-light-font); */
    color: whitesmoke;
  }

  .overview-item-component-wrapper .box .main-label .result {
    color: whitesmoke;
  }

  .overview-item-component-wrapper .box .other-detail-info .label-data {
    color: black;
  }

  /* Community SLIDER */
  .community-item-component-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .add-community-wrapper-main .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .add-property-under-community-wrapper-main .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* Property SLIDER */
  .property-item-component-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .progress-steps-component-wrapper-v1 .progress-steps .line {
    background-color: var(--body-light-font);
  }

  .progress-steps-component-wrapper-v1 .progress-steps .step .step-num {
    background-color: var(--body-dark-font);
  }

  .add-property-wrapper-main .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .add-management-type-wrapper-main .form-group {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .add-management-type-wrapper-main .transparent {
    color: transparent;
  }

  .edit-property-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
  }

  .edit-community-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
  }

  .community-image-stripe-component-wrapper,
  .property-image-stripe-component-wrapper {
    background-color: var(--bg_light_theme);
  }

  .add-management-type-wrapper-main .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* .purchaseLicenseWrapper{
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  } */

  .purchaseLicenseWrapper .summary {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .purchaseLicenseWrapper {
    .counter-input-component-wrapper button {
      background-color: var(--body-dark-font);
      border: 3px solid #37bdb0;
    }
  }
  .purchaseLicenseWrapper .load-summary {
    background-color: #1dc5b4 !important;
    color: var(--body-light-font);
  }

  .assign-license-module-wrapper .warning-wrapper .warning-wrapper-text {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .transaction-status-wrapper-main {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* SWIPER DOT */

  .custom-slider-wrapper-main
    .custom-dot-list-style
    .react-multi-carousel-dot.react-multi-carousel-dot--active
    button {
    /* background-color: #37bdb0; */
    background-color: #88103d;
    border-color: #88103d;
  }

  .custom-slider-wrapper-main
    .custom-dot-list-style
    .react-multi-carousel-dot
    button {
    /* background-color: var(--body-light-font); */
    background-color: #37bdb0;
  }

  /* FAQS PAGE */
  .faqs-page-wrapper .accordion {
    background-color: var(--body-dark-font);
  }

  .faqs-page-wrapper .accordion .item {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  /* Help & Support Page */
  .helpAndSupport-page-wrapper .main-wrapper .main_contacts .btn p span {
    color: white;
  }

  .helpAndSupport-page-wrapper .main-wrapper .main_contacts .contacts p {
    color: white;
  }

  /* BREADCRUMB */

  .custom-bread-crumb > li > a {
    color: #88103d;
  }

  .custom-bread-crumb li:last-of-type .link-color {
    color: var(--body-light-font);
  }

  /* PAGINATION */
  .pagination-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* Global Table style starts */
  .custom-table-wrapper {
    /* background-color: #262626; */
    background-color: var(--body-dark-font);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* color: var(--body-dark-font); */
  }
  .custom-table-wrapper .custom-table {
  }
  .custom-table-wrapper .custom-table thead {
    /* background-color: #151517; */
    background-color: var(--bg_light_theme);
  }
  .custom-table-wrapper .custom-table thead tr {
  }
  .custom-table-wrapper .custom-table thead tr th {
    color: var(--body-light-font);
    /* color: white; */
    /* padding-top: 15px;
    padding-bottom: 15px;
    white-space: nowrap;*/
    font-weight: 500;
  }

  .custom-table-wrapper .custom-table tbody tr td {
    /* padding-bottom: 15px; */
    /* color: var(--body-light-font); */
  }

  /* RADIO BUTTONS */
  .custom-radio-component-wrapper .radio-button.active .circle {
    border: 2px solid #ffb900;
  }

  .custom-radio-component-wrapper .label {
    color: var(--body-light-font);
  }

  .custom-radio-component-wrapper .radio-button.active .circle .inner-dot {
    background-color: #ffb900;
  }

  /* Global Table style ends */

  /* TAB BUTTON */
  .tab-button-component-wrapper .sub-active {
    color: var(--body-light-font);
  }

  /* BREADCRUMB */
  /* .custom-bread-crumb li:last-of-type .link-color{
    color: var(--body-light-font);
  } */

  /* SETTINGS PAGE */
  .settings-page-wrapper .settings-content-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .settings-page-wrapper .links .link-wrapper {
    background-color: var(--bg_light_theme);

    a {
      color: var(--body-light-font);
    }
  }

  /* UPDATE EMAIL */
  .change-email-wrapper .center {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* UPDATE MOBILE */
  .change-mobile-wrapper .center {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .react-tel-input .country-list {
    background-color: var(--body-dark-font) !important;
    color: var(--body-light-font) !important;
  }

  .react-tel-input .country-list > li:hover {
    background-color: var(--bg_light_theme) !important;
    color: var(--body-light-font) !important;
  }

  .react-tel-input .country-list .highlight,
  .react-tel-input .country-list .preferred,
  .react-tel-input .country-list .active {
    background-color: var(--bg_light_theme) !important;
    color: var(--body-light-font) !important;
  }

  /* PROFILE MODULE */
  .profile-page-wrapper .detail-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .profile-page-wrapper .other-detail {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .edit-profile-page-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  }

  .edit-profile-page-wrapper .upload-icon {
    background-color: var(--bg_light_theme);
  }

  /* PROPERTY SETTING MODULE */
  /* .property-setting-wrapper .setting-wrapper{
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  } */

  /* .property-setting-wrapper .tab_list .tab{
    background-color: var(--body-dark-font);
  } */

  /* ADVERTISMENT */
  .advertisment-detail-wrapper .ad-chart-details {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .advertisment-detail-wrapper .advertisement-component{
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .add-advertisment-module-wrapper .add-new-form .form-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .edit-advertisement-module-wrapper .add-new-form .form-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* UNIT MODULE */
  .unit-detail-page-wrapper .detail-box {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .unit-detail-page-wrapper .detail-box .unit-detail-box .unitAge-title {
    background-color: var(--bg_light_theme);
  }

  .unit-detail-page-wrapper .access-check {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* ADD UNIT */
  .add-unit-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* EDIT UNIT */
  .edit-unit-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .add-unit-module-wrapper .warning-wrapper-text {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* INVITE UNIT */
  .invite-tenant-component-wrapper .form-wrapper .invitation-form {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .invite-tenant-component-wrapper .installment-list-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .search-tenant-component-wrapper {
    .fleid {
      .form-input-wrapper input {
        background-color: var(--body-dark-font);
        color: var(--body-light-font);
      }

      .react-tel-input .form-control {
        background-color: var(--body-dark-font) !important;
        color: var(--body-light-font) !important;
      }
    }
  }

  .search-tenant-component-wrapper {
    .field {
      .react-tel-input .form-control {
        background-color: var(--body-dark-font) !important;
        color: var(--body-light-font) !important;
      }
    }
  }

  .search-tenant-component-wrapper .search-result {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: var(--body-light-font);
  }
  /* ASSIGN LICENSE */
  .assign-license-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .counter-input-component-wrapper button {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  /* MANANGE TEAMS  */

  .manage-teams-page-wrapper .missing-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* ADD OWNER */
  .add-owner-wrapper-main .form-wrapper,
  .add-observer-wrapper-main .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* ADD OWNER SUBMEMBER */
  .add-role-under-owner-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* PMC - MODULE */
  /* ADD PMC */
  .add-PMC-wrapper-main .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .pm-under-pmc-table-module-wrapper .not-added-yet-employee h3 {
    background-color: var(--bg_light_theme);
  }

  .owner-table-module-wrapper .company_wrapper {
    border: 3px solid #37bdb0;
  }

  .observer-table-module-wrapper .company_wrapper {
    border: 3px solid #37bdb0;
  }

  .pmc-table-module-wrapper .company_wrapper {
    border: 3px solid #37bdb0;
  }

  .pmc-table-module-wrapper .bottom-arrow,
  .observer-table-module-wrapper .bottom-arrow,
  .owner-table-module-wrapper .bottom-arrow {
    border: 5px solid #37bdb0;
  }

  .pmc-table-module-wrapper .wrapper .company_wrapper_outer .accordion-button,
  .observer-table-module-wrapper .wrapper .company_wrapper_outer .accordion-button,
  .owner-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-button {
    background-color: var(--bg_light_theme);
  }

  .owner-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-button:not(.collapsed) {
    background-color: var(--bg_light_theme);
  }

  .observer-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-button:not(.collapsed) {
    background-color: var(--bg_light_theme);
  }

  .owner-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-button:not(.collapsed) {
    background-color: var(--bg_light_theme);
  }

  .observer-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-button:not(.collapsed) {
    background-color: var(--bg_light_theme);
  }

  .pmc-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-button:not(.collapsed) {
    background-color: var(--bg_light_theme);
  }

  .pmc-table-module-wrapper .wrapper .company_wrapper_outer .accordion-item,
  .owner-table-module-wrapper .wrapper .company_wrapper_outer .accordion-item,
  .observer-table-module-wrapper .wrapper .company_wrapper_outer .accordion-item {
    /* border: solid #37bdb0; */
    border: none;
  }

  .pmc-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-item:last-of-type
    .accordion-collapse {
    border: solid #37bdb0;
  }

  .owner-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-item:last-of-type
    .accordion-collapse {
    border: solid #37bdb0;
  }

  .observer-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-item:last-of-type
    .accordion-collapse {
    border: solid #37bdb0;
  }

  .owner-table-module-wrapper .bottom-arrow:after {
    border-top: 30px solid #37bdb0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
  }

  .observer-table-module-wrapper .bottom-arrow:after {
    border-top: 30px solid #37bdb0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
  }

  .pmc-table-module-wrapper .bottom-arrow:after {
    border-top: 30px solid #37bdb0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
  }

  .owner-table-module-wrapper .inner-wrapper-1 {
    border: 3px solid #37bdb0;
  }

  .observer-table-module-wrapper .inner-wrapper-1 {
    border: 3px solid #37bdb0;
  }

  .pmc-table-module-wrapper .inner-wrapper-1 {
    border: 3px solid #37bdb0;
  }

  /* ADD PM */
  .add-pm-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* ADD ACCOUNTANT */
  .add-acc-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .acc-table-under-pmc-module-wrapper .not-added-yet-employee h3 {
    background-color: var(--bg_light_theme);
  }

  .pmc-table-module-wrapper,
  .owner-table-module-wrapper, 
  .observer-table-module-wrapper {
    .wrapper {
      background-color: var(--body-dark-font);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      padding: 0;
    }
  }

  .pmc-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-button::after {
    right: 75px;
    top: 60px;
  }

  .owner-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-button::after {
    right: 75px;
    top: 60px;
  }

  .observer-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-button::after {
    right: 75px;
    top: 60px;
  }

  /* FMC - MODULE */
  .fmc-table-module-wrapper .company_wrapper {
    border: 3px solid #37bdb0;
  }

  .fmc-table-module-wrapper .bottom-arrow {
    border: 5px solid #37bdb0;
  }

  .fmc-table-module-wrapper .bottom-arrow:after {
    border-top: 30px solid #37bdb0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
  }

  .fmc-table-module-wrapper .inner-wrapper-1 {
    border: 3px solid #37bdb0;
  }

  .fmc-table-module-wrapper .wrapper .company_wrapper_outer .accordion-button {
    background-color: var(--bg_light_theme);
  }

  .fmc-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-button:not(.collapsed) {
    background-color: var(--bg_light_theme);
  }

  .fmc-table-module-wrapper .wrapper .company_wrapper_outer .accordion-item {
    /* border: solid #37bdb0; */
    border: none;
  }

  .fmc-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-item:last-of-type
    .accordion-collapse {
    border: solid #37bdb0;
  }

  /* ADD FMC */
  .add-FMC-wrapper-main .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .fm-table-under-fmc-module-wrapper .not-added-yet-employee h3 {
    background-color: var(--bg_light_theme);
  }

  /* ADD FM */
  .add-fm-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .mt-table-under-fmc-module-wrapper .not-added-yet-employee h3 {
    background-color: var(--bg_light_theme);
  }

  .acc-table-under-fmc-module-wrapper .not-added-yet-employee h3 {
    background-color: var(--bg_light_theme);
  }

  /* SEARCH ACCOUNT - START */
  .search-account-wrapper-main {
    .fleid {
      .form-input-wrapper input {
        background-color: var(--body-dark-font);
        color: var(--body-light-font);
      }

      .react-tel-input .form-control {
        background-color: var(--body-dark-font) !important;
        color: var(--body-light-font) !important;
      }
    }
  }

  .search-account-wrapper-main {
    .field {
      .react-tel-input .form-control {
        background-color: var(--body-dark-font) !important;
        color: var(--body-light-font) !important;
      }
    }
  }

  .search-account-wrapper-main .search-result {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* SEARCH ACCOUNT - END */

  /* ADD MT */
  .add-mt-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .fmc-table-module-wrapper {
    .wrapper {
      background-color: var(--body-dark-font);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      padding: 0;
    }
  }

  .fmc-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-button::after {
    right: 75px;
    top: 60px;
  }

  /* SMC */

  .smc-table-module-wrapper .company_wrapper {
    border: 3px solid #37bdb0;
  }

  .smc-table-module-wrapper .bottom-arrow {
    border: 5px solid #37bdb0;
  }

  .smc-table-module-wrapper .bottom-arrow:after {
    border-top: 30px solid #37bdb0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
  }

  .smc-table-module-wrapper .inner-wrapper-1 {
    border: 3px solid #37bdb0;
  }

  .smc-table-module-wrapper .wrapper .company_wrapper_outer .accordion-button {
    background-color: var(--bg_light_theme);
  }

  .smc-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-button:not(.collapsed) {
    background-color: var(--bg_light_theme);
  }

  .smc-table-module-wrapper .wrapper .company_wrapper_outer .accordion-item {
    /* border: solid #37bdb0; */
    border: none;
  }

  .smc-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-item:last-of-type
    .accordion-collapse {
    border: solid #37bdb0;
  }

  /* ADD SMC */
  .add-SMC-wrapper-main .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .sm-under-smc-table-module-wrapper .not-added-yet-employee h3 {
    background-color: var(--bg_light_theme);
  }

  /* ADD SM */
  .add-sm-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .so-under-smc-table-module-wrapper .not-added-yet-employee h3 {
    background-color: var(--bg_light_theme);
  }

  /* ADD SO */
  .add-so-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .smc-table-module-wrapper {
    .wrapper {
      background-color: var(--body-dark-font);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      padding: 0;
    }
  }

  .smc-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-button::after {
    right: 75px;
    top: 60px;
  }

  /* 3PC */
  .tpc-table-module-wrapper .company_wrapper {
    border: 3px solid #37bdb0;
  }

  .tpc-table-module-wrapper .bottom-arrow {
    border: 5px solid #37bdb0;
  }

  .tpc-table-module-wrapper .bottom-arrow:after {
    border-top: 30px solid #37bdb0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
  }

  .tpc-table-module-wrapper .inner-wrapper-1 {
    border: 3px solid #37bdb0;
  }

  .tpc-table-module-wrapper .wrapper .company_wrapper_outer .accordion-button {
    background-color: var(--bg_light_theme);
  }

  .tpc-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-button:not(.collapsed) {
    background-color: var(--bg_light_theme);
  }

  .tpc-table-module-wrapper .wrapper .company_wrapper_outer .accordion-item {
    /* border: solid #37bdb0; */
    border: none;
  }

  .tpc-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-item:last-of-type
    .accordion-collapse {
    border: solid #37bdb0;
  }

  /* ADD 3PC */
  .add-TPC-wrapper-main .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .tpm-table-under-tpc-module-wrapper .not-added-yet-employee h3 {
    background-color: var(--bg_light_theme);
  }

  /* ADD 3PM */
  .add-tpm-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .tpt-table-under-tpc-module-wrapper .not-added-yet-employee h3 {
    background-color: var(--bg_light_theme);
  }

  /* ADD 3PT */
  .add-tpt-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .tpc-table-module-wrapper {
    .wrapper {
      background-color: var(--body-dark-font);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      padding: 0;
    }
  }

  .tpc-table-module-wrapper
    .wrapper
    .company_wrapper_outer
    .accordion-button::after {
    right: 75px;
    top: 60px;
  }

  /* TEAM VIEW MODALS */
  .team-detail-modal-wrapper .custom-modal-body {
    background-color: var(--body-dark-font);
  }

  /* MANAGE TENANTS */
  .tenant-detail-page-wrapper .detail-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .tenant-detail-page-wrapper .unit-detail-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .tenant-detail-table-module-wrapper .wrapper {
    background-color: var(--body-dark-font);
  }

  .tenant-detail-table-module-wrapper .wrapper .accordion-item {
    border: solid #37bdb0;
  }

  .tenant-detail-table-module-wrapper
    .wrapper
    .accordion-item:last-of-type
    .accordion-collapse {
    /* border: solid #37bdb0; */
    border: none;
  }

  .tenant-detail-table-module-wrapper .wrapper .accordion-button {
    background-color: var(--bg_light_theme);
  }

  .tenant-detail-table-module-wrapper .onboarding-wrapper {
    background-color: var(--body-dark-font);
  }

  .tenant-detail-table-module-wrapper .onboarding-wrapper .accordion-item {
    border: solid #37bdb0;
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .accordion-item:last-of-type
    .accordion-collapse {
    /* border: solid #37bdb0; */
    border: none;
  }

  .tenant-detail-table-module-wrapper .onboarding-wrapper .accordion-button {
    background-color: var(--bg_light_theme);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .idVerify-wrapper
    .custom-table-wrapper {
    background-color: var(--body-dark-font);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .idVerify-wrapper
    .custom-table-wrapper
    .custom-table
    thead {
    background-color: var(--bg_light_theme);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .vehicle-wrapper
    .custom-table-wrapper {
    background-color: var(--body-dark-font);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .vehicle-wrapper
    .custom-table-wrapper
    .custom-table
    thead {
    background-color: var(--bg_light_theme);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .rent-wrapper
    .custom-table-wrapper {
    background-color: var(--body-dark-font);
  }

  .tenant-onboard-module-wrapper .cancel-application-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
  }

  .unit-slider-tenant-home .cancel-application-slider-item-wrapper {
    background-color: #f1ffff;
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .rent-wrapper
    .custom-table-wrapper
    .custom-table
    thead {
    background-color: var(--bg_light_theme);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .pet-wrapper
    .custom-table-wrapper {
    background-color: var(--body-dark-font);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .pet-wrapper
    .custom-table-wrapper
    .custom-table
    thead {
    background-color: var(--bg_light_theme);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .utility-wrapper
    .custom-table-wrapper {
    background-color: var(--body-dark-font);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .utility-wrapper
    .custom-table-wrapper
    .custom-table
    thead {
    background-color: var(--bg_light_theme);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .application-wrapper
    .custom-table-wrapper {
    background-color: var(--body-dark-font);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .application-wrapper
    .custom-table-wrapper
    .custom-table
    thead {
    background-color: var(--bg_light_theme);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .rent-detail-wrapper
    .custom-table-wrapper {
    background-color: var(--body-dark-font);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .rent-detail-wrapper
    .custom-table-wrapper
    .custom-table
    thead {
    background-color: var(--bg_light_theme);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .rent-detail-wrapper
    .installment-summ-wrapper {
    background-color: var(--bg_light_theme);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .initial-agreement-wrapper
    .custom-table-wrapper {
    background-color: var(--body-dark-font);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .initial-agreement-wrapper
    .custom-table-wrapper
    .custom-table
    thead {
    background-color: var(--bg_light_theme);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .keys-wrapper
    .custom-table-wrapper {
    background-color: var(--body-dark-font);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .keys-wrapper
    .custom-table-wrapper
    .custom-table
    thead {
    background-color: var(--bg_light_theme);
  }

  .details-wrapper .basic-wrapper .detail-box,
  .details-wrapper .commercial-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .details-wrapper .basic-wrapper .detail-box .heading,
  .details-wrapper .commercial-wrapper .detail-box .heading {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .contract-renewal-detail-wrapper .causes_wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .contract-renewal-detail-wrapper .household-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .contract-renewal-detail-wrapper .household-wrapper .detail-box .header {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .contract-renewal-detail-wrapper .initAgreementTemplate {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .contract-renewal-detail-wrapper .revision .message-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .initial-agreement-form-wrapper .revision-message {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .initial-agreement-form-wrapper .installment-plan-detail-wrapper {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .initial-agreement-form-wrapper
    .installment-plan-detail-wrapper
    .custom-table-wrapper
    .custom-table
    thead {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .accountant-moveout-clearance-wrapper
    .custom-table-wrapper
    .install:nth-child(even) {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .accountant-moveout-clearance-wrapper .causes_wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* Tenant Side Contract Renewal */
  .tenant-contract-renewal-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .tenant-contract-renewal-wrapper .move-out-on-rejecting-renewal {
    color: var(--body-light-font);
  }

  .transitioned-tenant-accountant-view .basic-wrapper .detail-box,
  .transitioned-tenant-accountant-view .commercial-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .transitioned-tenant-accountant-view .basic-wrapper .detail-box .heading,
  .transitioned-tenant-accountant-view
    .commercial-wrapper
    .detail-box
    .heading {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .installment-plan-detail-modal-wrapper .modal-body-custom .detail {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .transitioned-tenant-accountant-view
    .rent-detail-wrapper
    .custom-table-wrapper
    .install:nth-child(even) {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .new-tenant-review-module-wrapper
    .details-wrapper
    .property-wrapper
    .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .new-tenant-review-module-wrapper .details-wrapper .rejection_invite-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .new-tenant-review-module-wrapper .details-wrapper .txt-inv-cancelled {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* .new-tenant-review-module-wrapper .details-wrapper .installment-summ-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  } */

  .contract-wrapper .renew-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: 1px solid #37bdb0 !important;
  }

  .initial-agreement-form-wrapper {
    .doc-view-control-wrapper {
      background-color: var(--body-dark-font);
      color: var(--body-light-font) !important;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }

  .tenant-contract-renewal-wrapper
    .initial-agreement-form-wrapper-outer
    .doc-modal-view-wrapper,
  .tenant-contract-renewal-wrapper
    .final-agreement-form-wrapper-outer
    .doc-modal-view-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font) !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .tenant-renewal-consent .card-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
  }

  .tenant-move-out-request-wrapper .report-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .new-tenant-review-module-wrapper
    .details-wrapper
    .rent-installment-wrapper
    .box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .new-tenant-review-module-wrapper
    .details-wrapper
    .household-wrapper
    .detail-box
    .header {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .new-tenant-review-module-wrapper .details-wrapper .initAgreementTemplate {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .initial-agreement-form-wrapper .deposit-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .new-tenant-review-module-wrapper .details-wrapper .basic-wrapper .detail-box,
  .new-tenant-review-module-wrapper
    .details-wrapper
    .commercial-wrapper
    .detail-box {
    background-color: var(--body-dark-font) !important;
    color: var(--body-light-font) !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .heading {
      background-color: var(--bg_light_theme) !important;
      color: var(--body-light-font) !important;
    }
  }

  .tenant-onboard-module-wrapper
    .initial-agreement-form-wrapper-outer
    .doc-modal-view-wrapper,
  .tenant-onboard-module-wrapper
    .final-agreement-form-wrapper-outer
    .doc-modal-view-wrapper {
    background-color: var(--body-dark-font) !important;
    color: var(--body-light-font) !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .initial-agreement-form-wrapper .unit-detail-installment-plan {
    background-color: var(--body-dark-font) !important;
    color: var(--body-light-font) !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* .installment-summ-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
  } */

  .new-tenant-review-module-wrapper
    .details-wrapper
    .household-wrapper
    .detail-box {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .onboarding-wrapper .household-wrapper .detail-box {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .household-wrapper
    .detail-box
    .header {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .tenant-detail-table-module-wrapper
    .onboarding-wrapper
    .rent-detail-wrapper
    .custom-table-wrapper
    .install:nth-child(even) {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .new-tenant-review-module-wrapper
    .details-wrapper
    .rent-detail-wrapper
    .custom-table-wrapper
    .install:nth-child(even) {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  /* MOVE OUT */
  .move-out-pm-review-wrapper .move-out-report-wrapper .moveOut-detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .move-out-pm-review-wrapper
    .move-out-report-wrapper
    .moveOut-timeline-list-wrapper
    .log-wrapper:first-child
    .time-line {
    background-color: #37bdb0;
    color: var(--body-light-font);
  }

  .move-out-pm-review-wrapper
    .move-out-report-wrapper
    .moveOut-timeline-list-wrapper
    .log-wrapper
    .time-line {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .move-out-pm-review-wrapper
    .move-out-report-wrapper
    .moveOut-timeline-list-wrapper
    .dot {
    background-color: #c0bebe;
  }

  .move-out-pm-review-wrapper
    .move-out-report-wrapper
    .moveOut-timeline-list-wrapper
    .log-wrapper
    .time-line
    .detail-wrapper
    .doc-list {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .contract-fee-payment-module-wrapper .amt-list {
    background-color: var(--body-dark-font);
    color: var(--body-light-font) !important;
  }

  /* RENEWALS */
  .contract-renewal-detail-wrapper {
    .card {
      background-color: var(--body-dark-font);
      color: var(--body-light-font);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }

  .contract-renewal-detail-wrapper .card-heading {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .contract-renewal-detail-wrapper .card-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
  }

  .contract-renewal-detail-wrapper
    .rent-detail-wrapper
    .install:nth-child(even) {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  /* MANAGE MAINTENANCE */
  .normal-maintenance-detail-wrapper .detail-card {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .normal-maintenance-detail-wrapper
    .timeline-list-wrapper
    .log-wrapper:first-child
    .time-line {
    background-color: #37bdb0;
    color: var(--body-light-font);
  }

  .normal-maintenance-detail-wrapper .timeline-list-wrapper .dot {
    background-color: #c0bebe;
  }

  .normal-maintenance-detail-wrapper
    .timeline-list-wrapper
    .log-wrapper
    .time-line {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .normal-maintenance-detail-wrapper
    .timeline-list-wrapper
    .quotation-log
    .part-list,
  .normal-maintenance-detail-wrapper
    .timeline-list-wrapper
    .spare-part-log
    .part-list {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  /* MOVE IN */
  .maintenance-detail-component-wrapper
    .timeline-list-wrapper
    .log-wrapper:first-child
    .time-line {
    background-color: #37bdb0;
  }

  .maintenance-detail-component-wrapper
    .timeline-list-wrapper
    .log-wrapper
    .time-line {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* MOVE OUT */

  .move-out-maintenance-detail-component-wrapper .detail-card {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .maintenance-detail-component-wrapper .detail-card {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .move-out-maintenance-detail-component-wrapper
    .timeline-list-wrapper
    .log-wrapper:first-child
    .time-line {
    background-color: #37bdb0;
  }

  .maintenance-detail-component-wrapper
    .timeline-list-wrapper
    .log-wrapper:first-child
    .time-line {
    background-color: #37bdb0;
  }

  .move-out-maintenance-detail-component-wrapper
    .timeline-list-wrapper
    .log-wrapper
    .time-line {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .maintenance-detail-component-wrapper
    .timeline-list-wrapper
    .log-wrapper
    .time-line {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .move-out-maintenance-detail-component-wrapper .timeline-list-wrapper .dot {
    background-color: #c0bebe;
  }

  .maintenance-detail-component-wrapper .timeline-list-wrapper .dot {
    background-color: #c0bebe;
  }

  .maintenance-detail-component-wrapper
    .timeline-list-wrapper
    .quotation-log
    .part-list,
  .maintenance-detail-component-wrapper
    .timeline-list-wrapper
    .task-log
    .part-list {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .move-out-maintenance-detail-component-wrapper
    .uric-p1-log
    .uirc-detail
    .uirc-data {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .maintenance-detail-component-wrapper .uric-p1-log .uirc-detail .uirc-data {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  /* ROUTINE REQUESTS */
  .add-task-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .add-task-module-wrapper .daily-time .time-input {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .add-task-module-wrapper .weekly-day {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  }

  /* MANAGE INTERNAL MAINTENANCE */
  .internal-maintenance-detail-wrapper .detail-card {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .internal-maintenance-detail-wrapper .timeline-list-wrapper .dot {
    background-color: #c0bebe;
  }

  .internal-maintenance-detail-wrapper
    .timeline-list-wrapper
    .log-wrapper:first-child
    .time-line {
    background-color: #37bdb0;
    color: var(--body-light-font);
  }

  .internal-maintenance-detail-wrapper
    .timeline-list-wrapper
    .log-wrapper
    .time-line {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .internal-maintenance-detail-wrapper
    .timeline-list-wrapper
    .quotation-log
    .part-list,
  .internal-maintenance-detail-wrapper
    .timeline-list-wrapper
    .spare-part-log
    .part-list {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  /* ADD INTERNAL MAINT */
  .add-tenant-maintenance-module-wrapper .add-new-form .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* ROUTINE REQUEST MODULE */
  .security-complaint-report-component-wrapper
    .timeline-list-wrapper
    .log-wrapper:first-child
    .time-line {
    background-color: #37bdb0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .security-complaint-report-component-wrapper
    .timeline-list-wrapper
    .log-wrapper
    .time-line {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .security-complaint-report-component-wrapper .timeline-list-wrapper .dot {
    background-color: #c0bebe;
  }

  /* INVOICING */
  .invoicing-report-component-wrapper .detail-card {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .payment-data .trans-table {
      background-color: var(--bg_light_theme);
    }
  }

  .invoicing-report-component-wrapper
    .parties-detail-card
    .payment-data
    .pay-data {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  /* ADD TENANT INVOICE */
  .create-invoice-wrapper
    .add-tenant-invoice-module-wrapper
    .add-new-form
    .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .property-detail-card {
    background-color: var(--body-dark-font) !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .payment-data .trans-table {
      background-color: var(--bg_light_theme);
    }
  }

  /* ADD PROPERTY INVOICE */
  .create-invoice-wrapper
    .add-property-invoice-module-wrapper
    .add-new-form
    .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* ADD PARTY INVOICE */
  .create-invoice-wrapper
    .add-party-invoice-module-wrapper
    .add-new-form
    .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .create-invoice-wrapper .add-party-invoice-module-wrapper .options-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .get-cheque-detail-modal-wrapper .cheque-details-flex {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font) !important;
  }

  .create-invoice-wrapper
    .add-party-invoice-module-wrapper
    .add-new-form
    .spareParts-wrapper
    .sparePart-details {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .parties-detail-card {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .spareParts-wrapper {
      background-color: var(--bg_light_theme);
    }

    .payment-data .trans-table {
      background-color: var(--bg_light_theme);
    }
  }

  .invoicing-report-component-wrapper .detail-card .payment-data .pay-data {
    background-color: var(--bg_light_theme);
  }

  .invoicing-report-component-wrapper
    .property-detail-card
    .payment-data
    .pay-data {
    background-color: var(--bg_light_theme);
  }

  /* VIOLATION MODULE */
  .violation-report-component-wrapper .detail-card {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* ADD VIOLATION */
  .add-voilation-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* ANNOUCENMENT MODULE */
  .annoucement-detail-wrapper .detail-card {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .add-annoucement-module-wrapper .add-new-form .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* LOST & FOUND MODULE */
  .found-details-wrapper .report-wrapper .detail-box {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* ADD FOUND REPORT */
  .add-found-module-wrapper .add-new-form .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .lost-details-wrapper .report-wrapper .detail-box {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .claimed-details-wrapper .report-wrapper .detail-box {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .custom-datetime-input-component-wrapper-v2 .react-datetime-picker__wrapper {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .react-datetime-picker__inputGroup__amPm option {
    background-color: var(--bg_light_theme);
  }

  /* SECURITY MODULE */
  .security-complaint-report-component-wrapper .detail-card {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .add-scf-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .security-incident-wrapper .report-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .security-complaint-report-component-wrapper
    .security-report-timeline-list-wrapper
    .log-wrapper
    .detail-box-timeLine {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .security-complaint-report-component-wrapper
    .security-report-timeline-list-wrapper
    .log-wrapper
    .active {
    background-color: #1dc5b4 !important;
    color: var(--body-light-font);
  }

  .security-incident-wrapper .report-wrapper .detail-box-timeLine {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* .security-incident-wrapper
    .report-wrapper
    .detail-box-timeLine:first-of-type {
    background-color: #37bdb0;
    color: var(--body-light-font);
  } */

  .wrapper-security-detail .report-wrapper .detail-box-timeLine {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* .security-report-timeline-list-wrapper .log-wrapper .security-report-timeline-list-wrapper:first-child */
  /* .security-complaint-report-component-wrapper
    .security-report-timeline-list-wrapper
    .log-wrapper:first-child {
    background-color: #37bdb0 !important;
    color: var(--body-light-font);
  } */

  .wrapper-security-detail
    .report-wrapper
    .detail-box
    .security-unit-detail-box
    .last-update {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  /* VISITOR ACCESS PASS */
  .visitor-pass-wrapper .report-wrapper .detail-box {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .contractor-pass-wrapper .report-wrapper .detail-box {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .guest-pass-wrapper .report-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* LOG BOOK MODULE */
  .visitor-log-book-wrapper .visitor-report-wrapper .visitor-detail-box {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .visitor-sign-out-wrapper .report-wrapper .detail-box {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* .signout-form .form-wrapper {
      background-color: var(--body-dark-font) !important;
    } */
  }

  /* ADD LOG BOOK */
  .add-visitor-module-wrapper .add-new-form .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .visitor-sign-out-wrapper
    .report-wrapper
    .detail-form-box
    .signout-form
    .form-wrapper {
    background-color: var(--body-dark-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* PROPERTY SETTING MODULE */
  .property-setting-wrapper .setting-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* BREAD CRUMBS */

  .custom-bread-crumb li > a {
    color: #88103d !important;
  }

  .custom-bread-crumb li:last-of-type .link-color {
    color: #37bdb0 !important;
  }

  /* RATING MODULE */
  .rate-wrapper .icon {
    /* fill: #00cd3e !important; */
    fill: #37bdb0 !important;
  }

  /* DASHBOARD MODULES */
  /* OCCUPANCY DASH */
  .occupation-dashboard-wrapper .report-chart-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .searchbar-component-wrapper .dash-search input {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
  }

  .occupation-dashboard-wrapper .search-box .date div {
    background-color: var(--body-dark-font);
  }

  /* CONTRACTS DASH */

  .contract-dashboard-wrapper .report-chart-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* .searchbar-component-wrapper .dash-search input{
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
  } */

  .contract-dashboard-wrapper .search-box .date div {
    background-color: var(--body-dark-font);
  }

  /* FINANCIAL DASH */
  .financial-dashboard-wrapper .report-chart-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .financial-dashboard-wrapper .search-box .date div {
    background-color: var(--body-dark-font);
  }

  /* TENANT MAINTENANCE DASH */
  .maintenance-in-dashboard-wrapper .report-chart-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .maintenance-in-dashboard-wrapper .search-box .date div {
    background-color: var(--body-dark-font);
  }

  /* INTERNAL MAINTENANCE DASH */
  .maintenance-out-dashboard-wrapper .report-chart-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .maintenance-out-dashboard-wrapper .search-box .date div {
    background-color: var(--body-dark-font);
  }

  /* SECURITY DASH */
  .security-dashboard-wrapper .report-chart-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .security-dashboard-wrapper .search-box .date div {
    background-color: var(--body-dark-font);
  }

  /* SPARE PARTS DASH */
  .spare-parts-dashboard-wrapper .report-chart-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .spare-parts-dashboard-wrapper .search-box .date div {
    background-color: var(--body-dark-font);
  }

  /* ROUTINE REQUESTS DASH */
  .routine-dashboard-wrapper .report-chart-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .routine-dashboard-wrapper .search-box .date div {
    background-color: var(--body-dark-font);
  }

  /* SHARED FACILITY DASH */
  .shared-facility-dashboard-wrapper .report-chart-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .shared-facility-dashboard-wrapper .search-box .date div {
    background-color: var(--body-dark-font);
  }

  /* RATING DASH */
  .ratings-dashboard-wrapper .report-chart-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .ratings-dashboard-wrapper .search-box .date div {
    background-color: var(--body-dark-font);
  }

  /* TEAMS DASH */
  .teams-dashboard-wrapper .report-chart-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .teams-dashboard-wrapper .search-box .date div {
    background-color: var(--body-dark-font);
  }

  /* VIOLATIONS DASH */
  .violations-dashboard-wrapper .report-chart-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .violations-dashboard-wrapper .search-box .date div {
    background-color: var(--body-dark-font);
  }

  /* MOVE IN DASH */
  .move-dashboard-wrapper .report-chart-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .move-dashboard-wrapper .search-box .date div {
    background-color: var(--body-dark-font);
  }

  /* MOVE OUT DASH */
  .move-out-dashboard-wrapper .report-chart-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .move-out-dashboard-wrapper .search-box .date div {
    background-color: var(--body-dark-font);
  }

  /* MANAGE CARDS */
  .manage-cards-page-wrapper .card-list-wrapper-main {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* MAANGE PAYMENT GATEWAY */
  .payment-setting-page-wrapper .inner-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .payment-setting-page-wrapper .edit-btn {
    color: var(--body-light-font);
  }

  .add-payment-setting-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  }

  /* MANAGE PASSWORD */
  .change-password-module-wrapper-specific .form-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* MANAGE THEME */
  .theme-setting-page-wrapper .inner-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* MANAGE ACCOUNT SUSPENTION */
  .account-setting-page-wrapper .inner-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* NOTIFICATIONS */
  .notification-list-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .notification-list-wrapper .not-seen {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
    border: 2px solid #262626;
  }

  .notification-list-wrapper .item {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  /* MANAGE LICENCES */
  .license-overview-detail-component-wrapper .box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .plan {
      background-color: var(--bg_light_theme);
    }
  }

  .invoice-list-component-wrapper .invoice-list {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .invoice-item-component-wrapper {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .receipt-component-wrapper .receipt-body {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* .license-overview-detail-component-wrapper .box  */

  .community-license-slider-item-component-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .community-property-slider-license-module-wrapper .community-name-button {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .property-license-slider-item-component-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* TENANT SIDE LIGHT THEME */

  /* ONBOARDING */
  .progress-steps-component-wrapper-v2 .progress-steps .step .step-num {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
  }

  .progress-steps-component-wrapper-v2 .progress-steps .step .step-num,
  .progress-steps-component-wrapper-v3 .progress-steps .step .step-num {
    background-color: var(--bg_light_theme);
  }

  .progress-steps-component-wrapper-v2 .progress-steps .line,
  .progress-steps-component-wrapper-v3 .progress-steps .line {
    background-color: var(--body-light-font);
  }

  .progress-steps-component-wrapper-v2 .progress-steps .active {
    background-color: #88103d;
    color: #ffffff;
  }

  .progress-steps-component-wrapper-v2 .progress-steps .line {
    background-color: var(--body-light-font);
  }

  .progress-steps-component-wrapper-v4 .progress-steps .line {
    background-color: var(--body-light-font);
  }

  .progress-steps-component-wrapper-v4 .progress-steps .step .step-num {
    background-color: var(--bg_light_theme) !important;
  }

  .tenant-onboard-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
  }

  .tenant-onboard-module-wrapper .cancel-application-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
  }

  .review-tenant-application-wrapper .basic-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .review-tenant-application-wrapper .unitDetail-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .review-tenant-application-wrapper .household-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .review-tenant-application-wrapper .household-wrapper .detail-box .header {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  .initial-agreement-form-wrapper .payment-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .initial-agreement-form-wrapper .property-wrapper {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .terms-page-wrapper .detail-card {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .unit-slider-tenant-home .slider-item {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .tenant-home-module-wrapper .box {
      background-color: var(--bg_light_theme);
      color: var(--body-light-font);
    }
  }

  .unitKey-module-wrapper{
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .unitKey-module-wrapper .unit-wrapper{
    background-color: var(--body-dark-font) !important;
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* MODULE - GUEST PASS */
  .add-guestPass-module-wrapper .add-new-form .form-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
  }

  .edit-guestPass-module-wrapper .edit-form .form-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
  }

  /* MODULE - MAINTENANCE */
  .tenant-maintenance-detail-wrapper .detail-card {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .tenant-maintenance-detail-wrapper
    .timeline-list-wrapper
    .log-wrapper:first-child
    .time-line {
    background-color: #37bdb0;
    color: var(--body-light-font);
  }

  .tenant-maintenance-detail-wrapper
    .timeline-list-wrapper
    .log-wrapper
    .time-line {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .tenant-maintenance-detail-wrapper .timeline-list-wrapper .dot {
    background-color: #c0bebe;
  }

  .tenant-maintenance-detail-wrapper
    .timeline-list-wrapper
    .quotation-log
    .part-list,
  .tenant-maintenance-detail-wrapper
    .timeline-list-wrapper
    .task-log
    .part-list {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  /* MODULE - CONTRACTS */
  .tenant-move-out-pending-clearence-wrapper .report-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* MODULE - SECURITY */

  .security-complaint-report-component-wrapper
    .security-report-timeline-list-wrapper
    .log-wrapper:first-child
    .time-line {
    background-color: #37bdb0;
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .security-complaint-report-component-wrapper
    .security-report-timeline-list-wrapper
    .dot {
    background-color: #c0bebe;
  }

  .security-complaint-report-component-wrapper
    .security-report-timeline-list-wrapper
    .log-wrapper
    .time-line {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .wrapper-security-detail .report-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* .security-complaint-report-component-wrapper
    .security-report-timeline-list-wrapper
    .log-wrapper
    .detail-box-timeLine
    .main-timeline-wrapper
    .detail
    .data
    .current-status {
    background-color: #151517;
    color: var(--body-light-font);
  }*/

  .wrapper-security-detail
    .report-wrapper
    .detail-box-timeLine
    .main-timeline-wrapper
    .detail
    .data
    .current-status {
    /* background-color: var(--bg_light_theme); */
    background-color: #262626;
    color: var(--body-light-font);
  }

  /* MODULE - ANNOCEMENT */
  .tenant-annoucement-wrapper .annoucemnt-list-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .tenant-annoucement-wrapper
    .annoucemnt-list-wrapper
    .annoucement-detail-wrapper {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
  }

  /* MODULE - INVOICE */
  .tenant-invoicing-report-component-wrapper .detail-card {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .tenant-invoicing-report-component-wrapper
    .detail-card
    .payment-data
    .trans-table {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .tenant-invoicing-report-component-wrapper
    .detail-card
    .payment-data
    .pay-data {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .card-list-wrapper-main {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
  }

  .tenant-invoicing-report-component-wrapper .transaction-status-wrapper-main {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .tenant-invoicing-report-component-wrapper
    .detail-card
    .payment-trans-data
    .trans-table {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .tenant-invoicing-wrapper .under_process_wrapper {
    background-color: var(--bg_light_theme);
    color: var(--body-light-font);
    border: 2px solid #37bdb0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* MODULE - REQUEST */
  .add-request-module-wrapper .form-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
  }

  /* MODULE - LOST & Found */
  .add-lost-module-wrapper .add-new-form .form-wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
  }

  .lost-wrapper .report-wrapper .detail-box {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* EMERGENCY MODAL */
  .emergency-modal-wrapper .modal-body {
    background-color: var(--body-dark-font) !important;
    color: var(--body-light-font) !important;
  }

  /* E-SIGN DOCUMENT MODAL */
  .esign-doc-modal-wrapper .modal-body {
    background-color: var(--bg_light_theme) !important;
    color: var(--body-light-font) !important;
  }

  /* GROUND RULES */
  .tenant_rules_wrapper {
    background-color: var(--body-dark-font);
    color: var(--body-light-font);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* MODAL BODY */

  /* .modal-content {
    background: transparent;
    border: 0;
  } */

  /* .modal-body {
    background-color: var(--body-dark-font) !important;
    color: var(--body-light-font) !important;
  } */

  /* .success-modal-wrapper .modal-body{
    background-color: var(--body-dark-font) !important;
    color: var(--body-light-font) !important;
  } */
}

/* .dark_theme {
  background-color: var(--bg_gray);
  color: var(--body-dark-font) !important;
} */

/* .light_theme_font{
  color: #444444;
} */

/* LIGHT COLOR THEMES - End */

.bgShade {
  display: none;
}

/* Global background color starts */
.bg_black {
  background-color: #000000;
}
.bg_lightgrey {
  background-color: #424243;
}
.bg_lightishgrey {
  background-color: #1d1d1e;
}
.bg_grey {
  background-color: #262626;
}
.bg_darkgrey {
  /* background-color: #151517; */
  background-color: var(--bg_gray);
}
.bg_blue {
  background-color: #37bdb0;
}
.bg_red {
  background-color: #ff1e1e;
}
.bg_lightgreen {
  background-color: #0f2e2b;
}
.bg_yellow {
  background-color: #ffef27;
}

.bg_darkTheme {
  background-color: #3d3d3d;
}

.bg_lightThemeInput {
  background-color: var(--bg_light_theme);
}

/* MODAL FOR LIGHT THEME */

.bg_lightTheme {
  background-color: var(--body-dark-font);
  color: var(--body-light-font) !important;
  border: 2px solid #37bdb0 !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bg_lightThemeDetail {
  background-color: var(--bg_light_theme);
  color: var(--body-light-font) !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* Global background color ends */

/* Global text color starts */
.font_white {
  color: #ffffff;
}
.font_blue {
  color: #37bdb0;
}
.font_red {
  color: #ff1e1e;
}
.font_green {
  color: #00cd3e;
}
.font_yellow {
  /* color: #ffef27; */
  /* color: #ffb900; */
  color: #ffc11c;
}
.font_grey {
  /* color: #5f5e5e; */
  color: #7f7c7c;
  /* color: #D2B48C; */
}

.font_orange {
  color: #e37e12;
}

.font_lightgrey {
  color: #939393;
}

.font_brown {
  color: #88103d;
}

.font_black {
  color: #151517;
}
.font_darkblue {
  color: #013f73;
}

.font_lightTheme {
  color: var(--body-light-font);
}
/* Global text color ends */

/* Global font size starts */
.font_xxs {
  font-size: 15px;
}
.font_xs {
  font-size: 16px;
}
.font_s {
  font-size: 17px;
}
.font_m {
  font-size: 18px;
}
.font_l {
  font-size: 20px;
}
.font_xl {
  font-size: 24px;
}
.font_xxl {
  font-size: 29px;
}
/* Global font size ends */

/* Global font weight starts */
.fw_1 {
  font-weight: 100;
}
.fw_2 {
  font-weight: 200;
}
.fw_3 {
  font-weight: 300;
}
.fw_4 {
  font-weight: 400;
}
.fw_5 {
  font-weight: 500;
}
.fw_6 {
  font-weight: 600;
}
.fw_7 {
  font-weight: 700;
}
.fw_8 {
  font-weight: 800;
}
.fw_9 {
  font-weight: 900;
}
/* Global font weight ends */

/* Global position starts */
.p_relative {
  position: relative;
}

.p_absolute {
  position: absolute;
}
/* Global position ends */

/* Sizing starts */
.w_100 {
  width: 100%;
}
.h_100vh {
  height: 100vh;
}
.h_100 {
  height: 100%;
}
/* Sizing ends */

/* Container sizing starts */
.container_sm {
  width: 100%;
  margin: 0 auto;
  max-width: 900px;
}
.container_md {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  max-width: calc(1800px - 30px);
}
.container_lg {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  max-width: 2000px;
}
/* Container sizing ends */

/* Display style starts */
.d_block {
  display: block;
}
.d_flex {
  display: flex;
}
.d_inlineblock {
  display: inline-block;
}
/* Display style ends */

/* Global Modal style starts */
.modal-content {
  background: transparent;
  border: 0;
}

.modal-body {
  /* background-color: #3d3d3d !important; */
  border-radius: 10px;
  border: 2px solid #7f7c7c;
}
/* Global Modal style ends */

/* Global Table style starts */
.custom-table-wrapper {
  background-color: #262626;
  padding: 30px;
  border-radius: 10px;
  /* color: var(--body-dark-font); */
}
.custom-table-wrapper .custom-table {
}
.custom-table-wrapper .custom-table thead {
  background-color: #151517;
}
.custom-table-wrapper .custom-table thead tr {
}
.custom-table-wrapper .custom-table thead tr th {
  /* color: #939393; */

  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  white-space: nowrap;
  font-weight: 300;
}
.custom-table-wrapper .custom-table thead tr th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.custom-table-wrapper .custom-table thead tr th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.custom-table-wrapper .custom-table thead tr th:first-child,
.custom-table-wrapper .custom-table tbody tr td:first-child {
  padding-left: 20px;
}
.custom-table-wrapper .custom-table thead tr th:last-child,
.custom-table-wrapper .custom-table tbody tr td:last-child {
  padding-right: 20px;
}
.custom-table-wrapper .custom-table tbody {
}
.custom-table-wrapper .custom-table tbody tr {
}
.custom-table-wrapper .custom-table tbody tr:first-child td {
  padding-top: 20px;
}
.custom-table-wrapper .custom-table tbody tr td {
  padding-bottom: 15px;
  /* color: white; */
}
.custom-table-wrapper .custom-table .action-btn-cell {
  white-space: nowrap;
}
.custom-table-wrapper .custom-table .action-btn-cell button {
  height: 30px;
  margin-left: 20px;
  white-space: nowrap;
}
.custom-table-wrapper .custom-table .action-btn-cell button img {
  height: 100%;
}
.custom-table-wrapper .custom-table .action-btn-cell button:first-child {
  /* margin-left: 0px; */
}
/* Global Table style ends */

/* Global other style ends */
.v_middle {
  vertical-align: middle;
}
.txt-nowrap {
  white-space: nowrap;
}
.transparent-input,
.transparent-input:focus,
.transparent-input:hover {
  width: 100%;
  display: block;
  background-color: transparent;
  border: 0;
  outline: none;
}
/* Global other style ends */
/* ************* Global styles ends ************* */

/* ************************ Responsive media queries ************************ */
@media only screen and (max-width: 1800px) {
  /* Global font size starts */
  .font_xxs {
    font-size: 14px;
  }
  .font_xs {
    font-size: 15px;
  }
  .font_s {
    font-size: 16px;
  }
  .font_m {
    font-size: 17px;
  }
  .font_l {
    font-size: 19px;
  }
  .font_xl {
    font-size: 22px;
  }
  .font_xxl {
    font-size: 27px;
  }
  /* Global font size ends */

  /* Container sizing starts */
  .container_sm {
  }
  .container_md {
    max-width: calc(1600px - 30px);
  }
  .container_lg {
  }
  /* Container sizing ends */

  /* Global Table style starts */
  .custom-table-wrapper {
    padding: 27px;
  }
  .custom-table-wrapper .custom-table {
  }
  .custom-table-wrapper .custom-table thead {
  }
  .custom-table-wrapper .custom-table thead tr {
  }
  .custom-table-wrapper .custom-table thead tr th {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .custom-table-wrapper .custom-table thead tr th:first-child {
  }
  .custom-table-wrapper .custom-table thead tr th:last-child {
  }
  .custom-table-wrapper .custom-table thead tr th:first-child,
  .custom-table-wrapper .custom-table tbody tr td:first-child {
    padding-left: 18px;
  }
  .custom-table-wrapper .custom-table thead tr th:last-child,
  .custom-table-wrapper .custom-table tbody tr td:last-child {
    padding-right: 18px;
  }
  .custom-table-wrapper .custom-table tbody {
  }
  .custom-table-wrapper .custom-table tbody tr {
  }
  .custom-table-wrapper .custom-table tbody tr td {
  }
  .custom-table-wrapper .custom-table .action-btn-cell button {
    height: 28px;
    margin-left: 18px;
  }
  /* Global Table style ends */
}

@media only screen and (max-width: 1600px) {
  /* Global font size starts */
  .font_xxs {
    font-size: 13px;
  }
  .font_xs {
    font-size: 14px;
  }
  .font_s {
    font-size: 15px;
  }
  .font_m {
    font-size: 16px;
  }
  .font_l {
    font-size: 18px;
  }
  .font_xl {
    font-size: 20px;
  }
  .font_xxl {
    font-size: 25px;
  }
  /* Global font size ends */

  /* Container sizing starts */
  .container_sm {
  }
  .container_md {
    max-width: calc(1400px - 30px);
  }
  .container_lg {
  }
  /* Container sizing ends */

  /* Global Table style starts */
  .custom-table-wrapper {
    padding: 24px;
  }
  .custom-table-wrapper .custom-table {
  }
  .custom-table-wrapper .custom-table thead {
  }
  .custom-table-wrapper .custom-table thead tr {
  }
  .custom-table-wrapper .custom-table thead tr th {
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .custom-table-wrapper .custom-table thead tr th:first-child {
  }
  .custom-table-wrapper .custom-table thead tr th:last-child {
  }
  .custom-table-wrapper .custom-table tbody {
  }
  .custom-table-wrapper .custom-table thead tr th:first-child,
  .custom-table-wrapper .custom-table tbody tr td:first-child {
    padding-left: 16px;
  }
  .custom-table-wrapper .custom-table thead tr th:last-child,
  .custom-table-wrapper .custom-table tbody tr td:last-child {
    padding-right: 16px;
  }
  .custom-table-wrapper .custom-table tbody tr {
  }
  .custom-table-wrapper .custom-table tbody tr td {
  }
  .custom-table-wrapper .custom-table .action-btn-cell button {
    height: 26px;
    margin-left: 16px;
  }
  /* Global Table style ends */
}

@media only screen and (max-width: 1400px) {
  /* Global font size starts */
  .font_xxs {
    font-size: 12px;
  }
  .font_xs {
    font-size: 13px;
  }
  .font_s {
    font-size: 14px;
  }
  .font_m {
    font-size: 15px;
  }
  .font_l {
    font-size: 16px;
  }
  .font_xl {
    font-size: 18px;
  }
  .font_xxl {
    font-size: 23px;
  }
  /* Global font size ends */

  /* Container sizing starts */
  .container_sm {
  }
  .container_md {
    max-width: calc(1200px - 30px);
  }
  .container_lg {
  }
  /* Container sizing ends */

  /* Global Table style starts */
  .custom-table-wrapper {
    padding: 21px;
  }
  .custom-table-wrapper .custom-table {
  }
  .custom-table-wrapper .custom-table thead {
  }
  .custom-table-wrapper .custom-table thead tr {
  }
  .custom-table-wrapper .custom-table thead tr th {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .custom-table-wrapper .custom-table thead tr th:first-child {
  }
  .custom-table-wrapper .custom-table thead tr th:last-child {
  }
  .custom-table-wrapper .custom-table thead tr th:first-child,
  .custom-table-wrapper .custom-table tbody tr td:first-child {
    padding-left: 14px;
  }
  .custom-table-wrapper .custom-table thead tr th:last-child,
  .custom-table-wrapper .custom-table tbody tr td:last-child {
    padding-right: 14px;
  }
  .custom-table-wrapper .custom-table tbody {
  }
  .custom-table-wrapper .custom-table tbody tr {
  }
  .custom-table-wrapper .custom-table tbody tr td {
  }
  .custom-table-wrapper .custom-table .action-btn-cell button {
    height: 24px;
    margin-left: 14px;
  }
  /* Global Table style ends */
}

@media only screen and (max-width: 1200px) {
  /* Global font size starts */
  /* Global font size ends */

  /* Container sizing starts */
  .container_sm {
    max-width: 800px;
  }
  .container_md {
    padding: 0 50px;
    max-width: none;
  }
  .container_lg {
    padding: 0 25px;
  }
  /* Container sizing ends */

  /* Global Table style starts */
  .custom-table-wrapper {
    padding: 21px;
  }
  .custom-table-wrapper .custom-table {
  }
  .custom-table-wrapper .custom-table thead {
  }
  .custom-table-wrapper .custom-table thead tr {
  }
  .custom-table-wrapper .custom-table thead tr th {
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .custom-table-wrapper .custom-table thead tr th:first-child,
  .custom-table-wrapper .custom-table tbody tr td:first-child {
    padding-left: 12px;
  }
  .custom-table-wrapper .custom-table thead tr th:last-child,
  .custom-table-wrapper .custom-table tbody tr td:last-child {
    padding-right: 12px;
  }
  .custom-table-wrapper .custom-table tbody {
  }
  .custom-table-wrapper .custom-table tbody tr {
  }
  .custom-table-wrapper .custom-table tbody tr td {
  }
  /* Global Table style ends */
}

@media only screen and (max-width: 900px) {
  /* Global font size starts */
  .font_xxs {
    font-size: 11px;
  }
  .font_xs {
    font-size: 12px;
  }
  .font_s {
    font-size: 13px;
  }
  .font_m {
    font-size: 14px;
  }
  .font_l {
    font-size: 15px;
  }
  .font_xl {
    font-size: 16px;
  }
  .font_xxl {
    font-size: 20px;
  }
  /* Global font size ends */

  /* Container sizing starts */
  .container_sm {
    padding: 0 40px;
    max-width: none;
  }
  .container_md {
    padding: 0 30px;
  }
  .container_lg {
    padding: 0 20px;
  }
  /* Container sizing ends */

  /* Global Table style starts */
  .custom-table-wrapper {
    padding: 18px;
  }
  .custom-table-wrapper .custom-table {
  }
  .custom-table-wrapper .custom-table thead {
  }
  .custom-table-wrapper .custom-table thead tr {
  }
  .custom-table-wrapper .custom-table thead tr th {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .custom-table-wrapper .custom-table thead tr th:first-child {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  .custom-table-wrapper .custom-table thead tr th:last-child {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .custom-table-wrapper .custom-table thead tr th:first-child,
  .custom-table-wrapper .custom-table tbody tr td:first-child {
    padding-left: 10px;
  }
  .custom-table-wrapper .custom-table thead tr th:last-child,
  .custom-table-wrapper .custom-table tbody tr td:last-child {
    padding-right: 10px;
  }
  .custom-table-wrapper .custom-table tbody {
  }
  .custom-table-wrapper .custom-table tbody tr {
  }
  .custom-table-wrapper .custom-table tbody tr td {
  }
  .custom-table-wrapper .custom-table .action-btn-cell button {
    height: 22px;
    margin-left: 12px;
  }
  /* Global Table style ends */
}

@media only screen and (max-width: 600px) {
  /* Global font size starts */
  /* Global font size ends */

  /* Container sizing starts */
  .container_sm,
  .container_md,
  .container_lg {
    padding: 0 10px;
  }
  /* Container sizing ends */

  /* Global Table style starts */
  .custom-table-wrapper {
    padding: 15px;
  }
  .custom-table-wrapper .custom-table {
  }
  .custom-table-wrapper .custom-table thead {
  }
  .custom-table-wrapper .custom-table thead tr {
  }
  .custom-table-wrapper .custom-table thead tr th {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .custom-table-wrapper .custom-table thead tr th:first-child,
  .custom-table-wrapper .custom-table tbody tr td:first-child {
    padding-left: 9px;
  }
  .custom-table-wrapper .custom-table thead tr th:last-child,
  .custom-table-wrapper .custom-table tbody tr td:last-child {
    padding-right: 9px;
  }
  .custom-table-wrapper .custom-table tbody {
  }
  .custom-table-wrapper .custom-table tbody tr {
  }
  .custom-table-wrapper .custom-table tbody tr td {
  }
  /* Global Table style ends */
}

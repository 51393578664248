.add-advertisment-module-wrapper {
  padding-bottom: 100px;

  .add-new-form {
    .form-wrapper {
      background-color: #262626;
      padding: 3%;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
    }
    .button-wrapper {
      margin-top: 30px;
      .custom-text-btn {
        margin-top: 20px;
      }
    }

    // .image-preview{
    //   width: 100%;
    //   img{
    //     width: 100%;
    //     height: 275px;
    //   }
    // }

    .aspect-ratio-box {
      position: relative;
      width: 100%; /* Adjust based on parent container */
      padding-top: calc(100% / 5.18); /* Maintains 5.18:1 aspect ratio */
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; /* Ensures cropping instead of distortion */
      }
    }

    .form-group {
      width: 49%;
      margin-bottom: 20px;
    }
    .form-group-full {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .custom-radio-component-wrapper {
        margin-right: 50px;
      }
      .custom-form-control {
        width: 100%;
      }
    }
    @media only screen and (max-width: 1800px) {
    }
    @media only screen and (max-width: 1600px) {
    }
    @media only screen and (max-width: 1400px) {
    }
    @media only screen and (max-width: 1200px) {
    }
    @media only screen and (max-width: 900px) {
    }
    @media only screen and (max-width: 600px) {
      .form-group {
        width: 100%;
      }
    }
  }
}

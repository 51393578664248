.custom-bread-crumb {
  margin-top: 100px;
  list-style-type: none;
  display: flex;
  li {
    .seperator {
      display: inline-block;
      margin: 0 10px;
    }

    &:last-of-type {
      .link-color {
        color: #939393;
      }
    }
  }
}

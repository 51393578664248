.tenant-renewal-consent {
  .card-wrapper {
    padding: 5%;
    border-radius: 10px;
  }

  .submit-cancel-btn-wrapper {
    text-align: center;
    margin-top: 4%;
    button {
        margin-top: 2%;
        font-weight: 600;
    }
  }
}

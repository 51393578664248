.so-report-modal-wrapper {
  .modal-header-custom {
    padding: 5px;
  }
  .modal-body {
    padding: 5% 8%;
    border: 2px solid grey;
  }
  .modal-body-custom {
    .form-wrapper {
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
    }

    .button-wrapper {
      margin-top: 30px;
      .custom-text-btn {
        margin-top: 20px;
      }
    }

    .form-group {
      width: 100%;
      margin-bottom: 20px;
      margin-top: 5px;

      .add-image {
        margin-top: 20px;
      }

      .txtBtn {
        color: #37bdb0;
        margin: 10px;
        padding-right: 75px;
      }

      .action-button {
        display: inline-block;

        .remove {
          outline: none;
          color: red;
          background: transparent;
        }
      }
    }

    hr {
      width: 100%;
      display: block;
      height: 0;
      border: 1px solid rgba(211, 211, 211, 0.656);
      margin: 30px 0;
    }

    .form-group-full {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .custom-radio-component-wrapper {
        margin-right: 50px;
      }
      .custom-form-control {
        width: 100%;
      }
    }

    // .report-doc-wrapper {
    //   padding: 15px;
    //   border-radius: 10px;
    //   position: relative;

    //   img {
    //     width: 85px;
    //     height: 85px;
    //     border-radius: 10px;
    //     cursor: pointer;
    //     object-fit: cover;
    //     margin: 0 10px;
    //   }

    //   .cancel-btn {
    //     width: 20px;
    //     height: 20px;
    //     border-radius: 50%;
    //     background-color: #ff1e1e;
    //     text-align: center;
    //     position: absolute;
    //     left: 12%;
    //     cursor: pointer;
    //   }
    // }

    .prev-docs-section {
      h3 {
        margin: 20px 0;
      }
    }
    .prev-docs {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-around;
      .doc-wrapper {
        width: 150px;
        height: 150px;
        border-radius: 7px;
        overflow: hidden;
        position: relative;
        .delete-btn {
          top: 7px;
          right: 7px;
          position: absolute;
          z-index: 1;
          padding: 5px;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.7);
          img {
            width: 100%;
            display: block;
          }
        }
        .custom-image-wrapper {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
  }
}

.layout-2-wrapper {
  .header-wrapper {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .body {
    // padding-top: calc(80px + 30px);
    // padding-top: 30px;
    padding-bottom: 100px;
  }

  @media only screen and (max-width: 1800px) {
    .body {
      // padding-top: calc(75px + 30px);
    }
  }
  @media only screen and (max-width: 1600px) {
    .body {
      // padding-top: calc(70px + 30px);
    }
  }
  @media only screen and (max-width: 1400px) {
    .body {
      // padding-top: calc(65px + 30px);
    }
  }
  @media only screen and (max-width: 1200px) {
    .body {
      // padding-top: calc(60px + 30px);
    }
  }
  @media only screen and (max-width: 900px) {
    .body {
      // padding-top: calc(55px + 30px);
    }
  }
  @media only screen and (max-width: 600px) {
    .body {
      // padding-top: calc(50px + 30px);
    }
  }
}

.privacy-policy-modal-wrapper {
  .modal-header-custom {
    .icon-wrapper {
      img {
        width: 15%;
      }
    }
    h2 {
      font-weight: 600;
    }
  }
  .modal-body {
    padding: 7% 7%;
    border: 2px solid #939393;
    position: relative;
  }
  .description {
    margin: 5% 0;
  }

  .modal-footer-custom {
    display: flex;
    justify-content: center;
    button {
      width: 50%;
    }
  }
  .close-icn {
    width: 25px;
    position: absolute;
    top: 1.5%;
    right: 3%;
  }
  .bg_lightTheme {
    .modal-body-custom {
      * {
        color: #000;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
    .close-icn {
      width: 20px;
    }
  }
}

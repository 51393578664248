.home-page-wrapper {
  .heading {
    font-weight: 600;
  }

  .overview-wrapper {
    padding-top: 10px;
  }
  .heading-navigation {
    display: flex;
    justify-content: space-between;

    .left {
      z-index: 10;
      a {
        font-weight: 500;
        display: inline-block;
        margin-left: 20px;
        z-index: 10;
      }
    }
  }

  .no-property-community {
    img {
      min-width: 100px;
      width: 7%;
    }
  }
  .communities,
  .properties {
    margin-top: 50px;
  }

  .properties .heading,
  .communities .heading {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .no-comm-prop-added {
    margin-top: 20px;
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

.voilation-table {
  padding: 1.5rem;

  .heading {
    // font-weight: 600;
    // text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    position: relative;
    z-index: 10;

    // .link {
    //   text-align: right;
    // }
  }

  .voilation-table {
    // tbody {
    //   position: relative;
    //   .new-label {
    //     position: absolute;
    //     border-radius: 50%;
    //     height: 10px;
    //     width: 10px;
    //     background: #00cd3e;
    //     margin-top: 15px;
    //     margin-right: 20px;
    //   }
    // }

    .action-button {
      height: 25px;
    }
  }
}

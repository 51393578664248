.header-wrapper {
  .header {
    width: 100%;
    // background-color: #ff1e1e;
    background-color: #37bdb0;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .left {
    height: 80%;
    .logo-wrapper {
      display: block;
    }
  }

  .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .notification {
    height: 40%;
    margin-right: 40px;
  }

  .red-dot {
    position: absolute;
    top: -5px;
    right: -5px;
    background: #ff1e1e;
    color: #ffffff;
    font-size: 12px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 15px;
    font-weight: 600;
  }

  .count {
    margin-right: 50px;
    position: absolute;
    right: 110px;
  }

  .profile-btn {
    height: 0;
    width: 0;
    padding: 30px;
    background-size: cover;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
  }

  .custom-dropdown .menu ul li {
    font-weight: 600;
    vertical-align: middle;
    img {
      width: 30px;
    }
  }

  @media only screen and (max-width: 1800px) {
    .header {
      height: 75px;
    }

    .red-dot {
      top: 20px;
      right: 100px;
    }

    .profile-btn {
      padding: 27px;
    }
    .custom-dropdown .menu ul li img {
      width: 27px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .header {
      height: 70px;
    }

    .red-dot {
      top: 20px;
      right: 90px;
    }

    .profile-btn {
      padding: 24px;
    }
    .custom-dropdown .menu ul li img {
      width: 24px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .header {
      height: 65px;
    }

    .red-dot {
      top: 15px;
      right: 85px;
    }

    .profile-btn {
      padding: 21px;
    }
    .custom-dropdown .menu ul li img {
      width: 20px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .header {
      height: 60px;
    }
    .custom-dropdown .menu ul li img {
      width: 24px;
    }

    .red-dot {
      top: 15px;
      right: 80px;
    }
  }
  @media only screen and (max-width: 900px) {
    .header {
      height: 55px;
    }

    .red-dot {
      top: 10px;
      right: 75px;
    }
  }
  @media only screen and (max-width: 600px) {
    .header {
      height: 50px;
    }

    .red-dot {
      top: 10px;
      right: 65px;
    }
  }
}

.uirc-p1-review-modal-wrapper {
  .modal-body {
    padding: 5% 7%;
  }
  .form-wrapper {
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
  }
  .button-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
  .form-group {
    width: 49%;
    margin-top: 20px;
  }
  .form-group-full {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .custom-radio-component-wrapper {
      margin-right: 50px;
    }
    .custom-form-control,
    .custom-file-input-wrapper {
      width: 100%;
    }
  }
  .action-btn {
    button {
      margin-left: 20px;
    }
  }
  .attachment,
  .description,
  .prev-docs-section {
    h3 {
      margin: 20px 0;
    }
  }
  .prev-docs {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
    .doc-wrapper {
      width: 150px;
      height: 150px;
      border-radius: 7px;
      overflow: hidden;
      position: relative;
      .delete-btn {
        top: 7px;
        right: 7px;
        position: absolute;
        z-index: 1;
        padding: 5px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.7);
        img {
          width: 100%;
          display: block;
        }
      }
      .custom-image-wrapper {
        width: 100%;
        height: 100%;
      }
    }
  }
  .title-number {
    margin-bottom: 10px;
  }
}
.remove-btn {
  margin-bottom: 20px;
}
.item-wrapper {
  margin-bottom: 20px;
}
.attachment-wrapper {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  // gap: 3%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  gap: 3%;

  .attachment {
    // padding: 10%;
    // background-size: cover;
    // margin-bottom: 4%;
    height: 120px;
    padding: 5px 0;
  }
  .total-wrapper {
    border-top: 3px solid #37bdb078;
    margin-top: 10px;
    padding-top: 10px;
  }
}

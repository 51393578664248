.receipt-component-wrapper {
  p {
    margin: 0;
  }
  .receipt-body {
    padding: 5% 6%;
    border-radius: 10px;
    background-color: #262626;
  }
  .logo-print {
    margin-bottom: 50px;
  }
  .logo-wrapper {
    width: 10%;
    min-width: 60px;
    img {
      width: 100%;
    }
  }
  .name-address {
    .name,
    .address {
      display: flex;
    }

    .address {
      margin-top: 20px;
    }
    .left {
      width: 15%;
    }
  }
  hr {
    width: 100%;
    display: block;
    height: 0;
    border: 1px solid rgba(211, 211, 211, 0.656);
    margin: 30px 0;
  }
  .transaction-status-wrapper-main {
    position: relative;
    height: auto;
  }
  .button-wrapper {
    margin-top: 30px;
    .custom-text-btn {
      margin-top: 20px;
    }
  }
  .right-sec {
    margin-left: 30px;
  }
  .left-sec {
    width: 30%;
  }
  .val {
    margin-top: 10px;
  }
  .sec-flex {
    display: flex;
  }
  .sec-1 {
    justify-content: space-between;
  }
  .divide{
    margin: 5px !important;
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .sec-1 {
      display: block;
    }
    .transaction-id {
      margin-top: 10px;
    }
    .logo-print {
      margin-bottom: 30px;
    }
    hr {
      margin: 15px 0;
    }
  }
  @media only screen and (max-width: 600px) {
    .right-sec {
      margin-left: 10px;
    }
    .left-sec {
      width: 50%;
    }
    .val {
      margin-top: 5px;
    }

    .sec-3,
    .sec-5 {
      display: block;
      .right-sec {
        width: 100%;
        margin: 20px 0 0;
      }
      .left-sec {
        width: 100%;
      }
    }
  }
}

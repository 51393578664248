.tenant-onboard-module-wrapper {
  p {
    margin: 0;
  }
  .already-approved-warning {
    margin-top: 5px;
  }

  .form-wrapper {
    background-color: #262626;
    padding: 3%;
    border-radius: 10px;
  }
  .form-wrapper-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
  }
  .cancel-application {
    margin-top: 20px;
  }
  .form-section-group {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    margin-top: 20px;
    padding-top: 30px;
    border-top: 2px solid rgba(255, 255, 255, 0.223);
    &:first-child {
      margin: 0;
      border: 0;
      padding: 0;
    }
  }
  .button-wrapper {
    margin-top: 30px;
    .custom-text-btn {
      margin-top: 20px;
    }
  }
  .list-counter {
    width: 49%;
    margin-bottom: 20px;
  }
  .max-allowed {
    width: 100%;
    margin-bottom: 20px;
  }
  .form-group {
    width: 49%;
    margin-bottom: 20px;
  }
  .form-group-full {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .custom-form-control {
      width: 100%;
    }
    .custom-radio-component-wrapper {
      margin-right: 50px;
    }
  }
  .action-button {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    .custom-btn {
      width: 150px;
    }
    .remove {
      margin-left: 50px;
    }
  }
  .title-bubble {
    margin-bottom: 10px;
  }

  .basic-detail {
  }

  .commercial-detail {
  }

  .id-verification {
  }

  .household-members {
  }

  .disability {
    .radio-btn-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 40%;
    }
    .radio-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 20%;
    }
  }

  .vehicles {
  }

  .pets {
  }

  .rental-history {
  }

  .review {
  }

  .application-fee {
  }

  .initial-agreement-form-wrapper-outer,
  .final-agreement-form-wrapper-outer {
    // height: 80%;
    // display: block;
    // overflow: auto;
    .main-heading {
    }
    .agreement-description {
      margin: 20px 0;
    }
    .view-doc-btn {
      width: 50%;
      margin-top: 10px;
    }
    .doc-modal-view-wrapper {
      padding: 3% 3%;
      border-radius: 10px;
      .icon {
        width: 27px;
      }
    }
  }

  .initial-agreement {
  }

  .payment {
  }

  .cancel-application-wrapper {
    background-color: #262626;
    padding: 3%;
    border-radius: 10px;
    margin-top: 30px;
    .cancel-application-btn-wrapper {
      margin-top: 20px;
      width: 50%;
      .cancel-application-btn {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .form-wrapper {
      background-color: #262626;
      padding: 20px;
      border-radius: 10px;
    }
    .action-button {
      margin-top: 20px;
    }
    .form-section-group {
      margin-top: 10px;
      padding-top: 20px;
    }
  }
  @media only screen and (max-width: 600px) {
    .list-counter {
      width: 100%;
    }
    .form-group {
      width: 100%;
    }
  }
}

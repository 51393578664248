.ratings-wrapper {
  padding: 1.5rem;
  .ratings-heading {
    font-weight: 600;
    text-align: start;
  }

  .rated-wrapper {
    display: flex;
    align-items: center;
    // a
    // justify-content: center;

    .rate-value {
      padding: 1rem 1rem 0 0.5rem;

      p{
        margin-left: 5px;
      }

    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
    // .add-found {
    //   //   // position: relative;
    //   left: 85%;
    // }
  }
  @media only screen and (max-width: 900px) {
    // .add-found {
    //   //   // position: relative;
    //   left: 80%;
    // }
  }
  @media only screen and (max-width: 600px) {
  }
}

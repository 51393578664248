.human-verification-wrapper {
  p {
    margin: 0;
    padding: 0;
  }
  .input {
    width: 295px;
    height: 30px;
    border: rgb(122, 195, 238) 2px solid;
    display: block;
    margin-bottom: 10px;
    border-radius: 3px;
  }

  .input:focus {
    border: none;
  }

  .transparent-wrapper {
    width: auto;
    height: auto;
  }

  label {
    display: block;
    margin-bottom: 2px;
    font-family: "Courier New", Courier, monospace;
  }
  .text_height-1 {
  }
  .loading_text {
    display: flex;
    align-items: center;
  }
  .svg-icon-loader {
    display: inline-block;
    width: 100px;
    height: 100px;
  }

  .loading_text {
    padding: 20px 0;
    justify-content: center;
    p {
      height: max-content;
      padding: 0 0 0 15px;
    }
  }
  .success-verified {
    display: block;
    width: 25px;
  }
  .font-dark-green {
    color: #04423d;
  }
  .success-text {
    padding: 0 0 0 10px !important;
  }

  // loader
  @keyframes ldio-x2uulkbinbj {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  .ldio-x2uulkbinbj div {
    position: absolute;
    width: 58px;
    height: 58px;
    border: 4px solid #04423d;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .ldio-x2uulkbinbj div {
    animation: ldio-x2uulkbinbj 0.6493506493506493s linear infinite;
    top: 50px;
    left: 50px;
  }
  .loadingio-spinner-rolling-nq4q5u6dq7r {
    width: 30px;
    height: 30px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-x2uulkbinbj {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.3);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-x2uulkbinbj div {
    box-sizing: content-box;
  }
  /* [ldio] generated by https://loading.io */
  /* [ldio] generated by https://loading.io */
}

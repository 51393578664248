.form-input-wrapper {
  // padding: 10px 10px;
  // border-radius: 10px;
  position: relative;
  label {
    display: block;
    font-weight: 600;
    position: absolute;
    top: 17px;
    left: 22px;
  }
  select {
    -webkit-appearance: none;
    display: block;
    width: 100%;
    border: 0;
    padding: 42px 22px 17px;
    border-radius: 10px;
    &:focus {
      outline: none;
      border: 2px solid #37BDB0;
    }
  }

  .select-opt {
    position: absolute;
    height: 30px;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
.error {
  margin: 5px 0 0;
  font-weight: 400;
}

@media only screen and (max-width: 1800px) {
  .form-input-wrapper {
    select {
      padding: 39px 19px 14px;
    }
    label {
      top: 14px;
      left: 19px;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .form-input-wrapper {
    select {
      padding: 35px 15px 10px;
    }
    label {
      top: 10px;
      left: 15px;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .form-input-wrapper {
    select {
      padding: 30px 14px 9px;
    }
    label {
      top: 9px;
      left: 14px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .form-input-wrapper {
    select {
      border-radius: 7px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .form-input-wrapper {
    select {
      padding: 26px 12px 7px;
    }
    label {
      top: 7px;
      left: 12px;
    }
  }
}

@media only screen and (max-width: 600px) {
}

/* ******************************************************************************************************************************** */

.custom-select-input-v2 {
  
}

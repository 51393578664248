/* Variant 1 Starts */
.custom-datetime-input-main-wrapper-v1 {
  position: relative;
  label {
    display: block;
    font-weight: 600;
    position: absolute;
    top: 17px;
    left: 22px;
  }
  input {
    display: block;
    width: 100%;
    border: 0;
    padding: 42px 22px 17px;
    border-radius: 10px;
    cursor: pointer;
    &:focus {
      outline: none;
      border: 2px solid #37bdb0;
    }
  }
  .react-datepicker {
    // position: absolute;
    // top: 100%;
    // left: 0;
  }
  .back-drop {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
  }
  .react-datepicker__year-option,
  .react-datepicker__year-read-view--selected-year,
  .react-datepicker__navigation--years {
    color: #202020;
    font-weight: 500;
  }
  .react-datepicker__year-read-view--selected-year {
    font-weight: 600;
  }
  .react-datepicker__navigation--years {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
    background-image: url("../../../../assets//png_icons/arrow_up_black.png");
  }
  .react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
    background-image: url("../../../../assets//png_icons/arrow_down_black.png");
  }
  .react-datepicker__input-time-container {
    * {
      color: #000;
    }
  }
  .error {
    margin: 5px 0 0;
    font-weight: 400;
  }

  @media only screen and (max-width: 1800px) {
    input {
      padding: 39px 19px 14px;
    }
    label {
      top: 14px;
      left: 19px;
    }
  }
  @media only screen and (max-width: 1600px) {
    input {
      padding: 35px 15px 10px;
    }
    label {
      top: 10px;
      left: 15px;
    }
  }
  @media only screen and (max-width: 1400px) {
    input {
      padding: 30px 14px 9px;
    }
    label {
      top: 9px;
      left: 14px;
    }
  }
  @media only screen and (max-width: 1200px) {
    input {
      border-radius: 7px;
    }
  }
  @media only screen and (max-width: 900px) {
    input {
      padding: 26px 12px 7px;
    }
    label {
      top: 7px;
      left: 12px;
    }
  }
  @media only screen and (max-width: 600px) {
  }
}
/* Variant 1 Ends */

/* ************************************************************************************************************************** */

/* Variant 2 Starts */
.custom-datetime-input-component-wrapper-v2 {
  .react-calendar {
    * {
      color: #000;
    }
  }
  .datepicker {
    position: relative;
  }
  .react-datetime-picker__inputGroup__amPm {
    padding-top: 0;
    padding-bottom: 0;
    // background-color: #000;
    option {
      background-color: #000000;
    }
    &:focus {
      outline: none !important;
      border: 0 !important;
    }
  }
  .react-datetime-picker {
    width: 100%;
    border-radius: 10px;
  }
  input {
    outline: none !important;
    border: 0 !important;
    padding-top: 0;
    padding-bottom: 0;
    width: 18px;
    line-height: 0;
    &:focus {
      outline: none !important;
      border: 0 !important;
    }
  }
  label {
    display: block;
    font-weight: 600;
    position: absolute;
    top: 17px;
    left: 22px;
  }
  .react-datetime-picker__wrapper {
    border: 0;
    padding: 42px 22px 17px;
    // background-color: #000;
    border-radius: 10px;
  }
  .react-datetime-picker__inputGroup__divider {
    padding-top: 0;
    padding-bottom: 0;
  }
  .react-datetime-picker__clear-button {
    display: none;
  }
  .react-datetime-picker__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
  .react-datetime-picker__button svg {
    fill: #939393;
    stroke: #939393;
  }
  .react-datetime-picker__button:enabled:hover
    .react-datetime-picker__button__icon,
  .react-datetime-picker__button:enabled:focus
    .react-datetime-picker__button__icon {
    fill: #fff;
    stroke: #fff;
  }
  .react-datetime-picker__inputGroup__year {
    width: 50px !important;
  }

  @media only screen and (max-width: 1800px) {
    .react-datetime-picker__wrapper {
      padding: 39px 19px 14px;
    }
    label {
      top: 14px;
      left: 19px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .react-datetime-picker__wrapper {
      padding: 35px 15px 10px;
    }
    label {
      top: 10px;
      left: 15px;
    }
  }

  @media only screen and (max-width: 1400px) {
    .react-datetime-picker__wrapper {
      padding: 30px 14px 9px;
    }
    label {
      top: 9px;
      left: 14px;
    }
  }

  @media only screen and (max-width: 1200px) {
  }

  @media only screen and (max-width: 900px) {
    .react-datetime-picker__wrapper {
      padding: 26px 12px 7px;
    }
    label {
      top: 7px;
      left: 12px;
    }
  }

  @media only screen and (max-width: 600px) {
  }
}
/* Variant 2 Ends */

/* ************************************************************************************************************************** */

/* Variant 3 Starts */
/* Variant 3 Ends */

.overview-item-component-wrapper {
  margin: 10px 20px 0 0;
  border-radius: 10px;
  padding: 4%;
  display: flex;
  justify-content: space-between;
  background-color: #262626;
  height: 425px;

  .box {
    margin: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .heading {
      padding: 15px 0 0 0;
    }

    .heading-text {
      .viewImg {
        margin-left: 5px;
        width: 20px;
        height: 20px;
        transition: transform 0.3s ease; // Smooth transition for rotation
        filter: invert(0) saturate(1) brightness(1); // Default filter
      }

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      // Apply rotating animation and red color during loading
      .rotating {
        animation: rotate 1s linear infinite; // Infinite rotation
        filter: invert(24%) sepia(97%) saturate(6575%) hue-rotate(2deg)
          brightness(92%) contrast(100%);
      }

      .tooltip-text {
        visibility: visible;
        width: 120px;
        background-color: #333;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 75%; /* Position above the tooltip container */
        left: 50%;
        margin-left: -75px; /* Center the tooltip */
        opacity: 0;
        transition: opacity 0.3s, visibility 0.3s;
        transition-delay: 1s;
      }

      &:hover .tooltip-text {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
      }
    }

    /* Background label for "Last Year" */
    .background-label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-60deg); /* Rotates the label 60 degrees */
      z-index: 1;
      pointer-events: none; /* Prevents interaction with the background label */
      text-transform: uppercase;
      font-size: 4rem; /* Adjust size based on your needs */
      font-weight: bold;
      color: rgba(255, 255, 255, 0.05); /* Semi-transparent white */
      white-space: nowrap; /* Prevents text wrapping */
      text-align: center;
    }

    .lightbackground-label{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-60deg); /* Rotates the label 60 degrees */
      z-index: 1;
      pointer-events: none; /* Prevents interaction with the background label */
      text-transform: uppercase;
      font-size: 4rem; /* Adjust size based on your needs */
      font-weight: bold;
      color: rgba(72, 72, 72, 0.073); /* Semi-transparent white */
      white-space: nowrap; /* Prevents text wrapping */
      text-align: center;
    }

    .main-label {
      display: flex;
      // justify-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;

      .label {
        color: #37bdb0;
        // display: flex;
        align-items: center;
        justify-content: start;
        position: relative;
        display: inline-block;
        cursor: pointer;

        .viewImg {
          margin-left: 5px;
          width: 15px;
          height: 15px;
        }

        .tooltip-text {
          visibility: visible;
          width: 120px;
          background-color: #333;
          color: #fff;
          text-align: center;
          border-radius: 5px;
          padding: 5px;
          position: absolute;
          z-index: 1;
          bottom: 75%; /* Position above the tooltip container */
          left: 50%;
          margin-left: -75px; /* Center the tooltip */
          opacity: 0;
          transition: opacity 0.3s, visibility 0.3s;
          transition-delay: 1s;
        }

        &:hover .tooltip-text {
          opacity: 1;
          visibility: visible;
          transition-delay: 0s;
        }
      }

      .image-container {
        position: relative;
        display: inline-block;

        .overlay {
          position: absolute;
          width: 250px;
          top: 0;
          left: 20%;
          right: 0;
          bottom: 0;
          background-color: rgba(
            0,
            0,
            0,
            0.5
          ); /* Semi-transparent background */
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: opacity 0.3s;
          text-align: center;
          padding: 10px 15px;
        }

        &:hover .overlay {
          opacity: 1;
        }
      }

      .result {
        display: flex;
        align-items: flex-start;
        justify-content: start;
        // color: #939393;
        color: #37bdb0;
      }
    }

    .spare-wrapper {
      display: flex;
      justify-content: start;
      gap: 50px;

      div {
        flex: 1;
      }
    }

    .other-detail-info {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .label-data {
        color: #939393;
      }
    }
  }

  @media only screen and (max-width: 1800px) {
    .details .address .location-pin {
      height: 23px;
    }
    .details .missing-section {
      height: 85px;
    }
    .details .missing-section .missing .warning {
      height: 23px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .details .address .location-pin {
      height: 21px;
    }
    .details .missing-section {
      height: 80px;
    }
    .details .missing-section .missing .warning {
      height: 21px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .details .address .location-pin {
      height: 19px;
    }
    .details .missing-section {
      height: 75px;
    }
    .details .missing-section .missing .warning {
      height: 19px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .details .address .location-pin {
      height: 17px;
    }
    .details .missing-section {
      height: 65px;
    }
    .details .missing-section .missing .warning {
      height: 17px;
    }
  }
  @media only screen and (max-width: 600px) {
    padding: 20px;
    margin: 20px 10px 0 0;
  }
}

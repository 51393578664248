.quote-generate-modal-wrapper {
  p {
    margin: 0;
  }
  .modal-body {
    padding: 5% 5%;
  }
  .modal-header-custom {
    margin: 0 0 50px;
  }
  .form-wrapper {
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
  }
  .button-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
  .form-group {
    width: 49%;
    margin-bottom: 20px;
  }

  .otherTheme {
    .react-date-picker__inputGroup {
      * {
        color: #444444 !important;
      }
    }
  }

  .form-each {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .form-group-full {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .custom-radio-component-wrapper {
      margin-right: 50px;
    }
    .custom-form-control,
    .custom-file-input-wrapper {
      width: 100%;
    }
  }
  .action-btn {
    button {
      margin-left: 20px;
    }
  }
  .attachment,
  .description {
    h3 {
      margin: 20px 0;
    }
  }
  .total {
    display: flex;
    justify-content: space-between;
    margin: 3% 0 0;
    padding: 3% 0 0;
    border-top: 2px solid rgb(104, 104, 104);
  }
  .form-each {
    margin-top: 3%;
  }
  .cost-cover-by-section {
    width: 100%;
    .please-select-text {
      margin: 20px 0 20px;
    }
    .custom-radio-component-wrapper {
      margin-bottom: 10px;
    }
    .generate-inv-txt {
      margin: 20px 0 30px;
      text-align: center;
    }
    .tenant-details {
      width: 100%;
      margin: 0 0 20px;
      p {
        margin: 0;
      }
      .custom-line {
        display: flex;
        margin-bottom: 5px;
        .label {
          width: 25%;
        }
        .value {
        }
      }
    }
    .fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .invoice-generate-form {
      }
    }
  }
  .invoice-description {
    margin-top: 30px;
  }
}

.unit-slider-tenant-home {
  p {
    margin: 0;
  }

  .default_message {
    margin: 5% 10%;
    padding: 3% 10%;

    img {
      width: 10%;
      // height: 50px;
    }
  }

  // SLIDER 1 ( IMAGE )
  .slider2-wrapper {
    width: 100%;
    height: 300px;

    .image-wrapper {
      // overflow: hidden;
      position: relative;
      width: 100%; /* Full width */
      padding-top: calc(100% / 5.18); /* Aspect ratio 5.18:1 */
      overflow: hidden;
      background-color: #f0f0f0; /* Placeholder background */

      img {
        // width: 100%;
        // height: 300px;
        // object-fit: fill; // Prevents distortion while ensuring full coverage
        // // object-position: 50% 50%;
        cursor: pointer;
        // aspect-ratio: 1/1;
        position: absolute;
        top: 0;
        left: 0;
        width: max-content;
        height: 100%;
        object-fit: fill; /* Ensures cropping instead of distortion */
      }
    }
  }
  // .slider2-wrapper {
  //   width: 100%;
  //   height: 275px;

  //   // display: flex;
  //   // justify-content: space-between;

  //   .image-wrapper {
  //     // flex: 2;
  //     overflow: hidden;

  //     img {
  //       width: 100%;
  //       height: 100%;
  //       object-fit: fill;
  //       cursor: pointer;
  //     }
  //   }

  //   // .details-wrapper {
  //   //   background-color: #e1ebf5;
  //   //   flex: 1.5;
  //   //   margin-right: 20px;
  //   //   display: flex;
  //   //   align-items: center;
  //   //   justify-content: center;
  //   //   flex-direction: row;

  //   //   .detail {
  //   //     padding: 8px 20px;
  //   //     .text-1 {
  //   //       padding: 5px;

  //   //       .tag {
  //   //         // padding: 0 25px;
  //   //         display: flex;
  //   //         align-items: center;
  //   //         justify-content: center;

  //   //         p {
  //   //           padding: 0 25px;
  //   //           background-color: #013f73;
  //   //         }
  //   //       }
  //   //     }
  //   //   }
  //   // }
  // }

  // .slider-item {
  //   padding: 2%;
  //   margin: 20px 20px 0 0;
  //   border-radius: 10px;
  //   // min-height: 800px;
  //   background-color: #262626;
  //   .unit-name,
  //   .prop-name,
  //   .address {
  //     width: 100%;
  //     white-space: nowrap;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     margin-bottom: 1%;
  //   }
  //   .address {
  //     margin-bottom: 3%;
  //     vertical-align: middle;
  //     .location-pin {
  //       height: 25px;
  //       vertical-align: middle;
  //     }
  //     span {
  //       vertical-align: middle;
  //     }
  //   }
  // }
  // .title-header {
  //   justify-content: space-between;
  //   gap: 20px;
  //   border-bottom: 1px solid #5a5a5a;
  //   padding-bottom: 20px;
  //   margin-bottom: 20px;

  //   .property-detail {
  //     display: flex;
  //     justify-content: space-between;
  //     .property_logo {
  //       img {
  //         width: 100px;
  //         height: 100px;
  //       }
  //     }

  //     .location-data {
  //       display: flex;
  //       align-items: center;
  //       justify-content: start;
  //       // gap: 10px;

  //       img {
  //         width: 25px;
  //         height: 25px;
  //       }
  //     }

  //     .property-data {
  //       margin-right: 15px;

  //       // .location-data{
  //       //   display: flex;
  //       //   align-items: center;
  //       //   justify-content: start;
  //       //   gap: 10px;
  //       //   border: 2px solid rebeccapurple;

  //       //   img{
  //       //     width: 25px;
  //       //   }

  //       // }
  //     }
  //   }

  //   .unit-detail {
  //     width: 30%;
  //     display: flex;
  //     flex-direction: column;
  //   }

  //   // .left {
  //   //   width: 29%;
  //   // }
  //   // .right {
  //   //   width: 69%;
  //   //   display: flex;
  //   //   justify-content: flex-end;
  //   //   .ammenties {
  //   //     width: 69%;
  //   //     margin-right: 10px;
  //   //   }
  //   //   .unitName {
  //   //     width: 29%;
  //   //   }
  //   // }
  // }
  // .tenant-home-module-wrapper {
  //   display: grid;
  //   // grid-template-columns: 1fr 1fr 1fr 1fr;
  //   grid-template-columns: repeat(5, 260px);
  //   gap: 15px;
  //   .box {
  //     padding: 5% 7%;
  //     border-radius: 10px;
  //     display: flex;
  //     background-color: #151517;
  //     // flex-direction: column;
  //     align-items: center;
  //     justify-content: space-between;
  //     height: 75px;
  //     background-image: url("../../../../assets/images/Bg-img.jpg");
  //     background-repeat: no-repeat;
  //     background-size: 100% auto;
  //     background-position: center;
  //     background-attachment: fixed;

  //     .missing {
  //       position: relative;

  //       img {
  //         width: 25px;
  //       }
  //     }

  //     .img-wrapper {
  //       height: 30px;
  //       margin-right: 10%;
  //       position: relative;

  //       .module-icon {
  //         height: 35px;
  //       }
  //     }

  //     .img-wrapper-contract {
  //       height: 30px;
  //       display: flex;
  //       justify-content: space-between;

  //       .module-icon {
  //         height: 35px;
  //       }
  //     }

  //     .count {
  //       position: absolute;
  //       left: 29%;
  //       margin-bottom: 20px;
  //       background-color: red;
  //       border-radius: 50%;
  //       padding: 3px;
  //     }
  //   }
  // }

  .slider-item {
    padding: 2%;
    margin: 20px 20px 0 0;
    border-radius: 10px;
    min-height: 500px;
    background-color: #262626;
    .unit-name,
    .prop-name,
    .address {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 1%;
    }
    .address {
      margin-bottom: 3%;
      vertical-align: middle;
      .location-pin {
        height: 25px;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
      }
    }
  }
  .title-header {
    justify-content: space-between;
    gap: 20px;
    border-bottom: 1px solid #5a5a5a;
    padding-bottom: 20px;
    margin-bottom: 20px;

    .property-detail {
      display: flex;
      justify-content: space-between;
      .property_logo {
        img {
          width: 100px;
          height: 100px;
        }
      }

      .location-data {
        display: flex;
        align-items: center;
        justify-content: start;
        // gap: 10px;

        img {
          width: 25px;
          height: 25px;
        }
      }

      .property-data {
        margin-right: 15px;

        // .location-data{
        //   display: flex;
        //   align-items: center;
        //   justify-content: start;
        //   gap: 10px;
        //   border: 2px solid rebeccapurple;

        //   img{
        //     width: 25px;
        //   }

        // }
      }
    }

    .unit-detail {
      width: 30%;
      display: flex;
      flex-direction: column;
    }

    // .left {
    //   width: 29%;
    // }
    // .right {
    //   width: 69%;
    //   display: flex;
    //   justify-content: flex-end;
    //   .ammenties {
    //     width: 69%;
    //     margin-right: 10px;
    //   }
    //   .unitName {
    //     width: 29%;
    //   }
    // }
  }
  .tenant-home-module-wrapper {
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(5, 260px);
    gap: 15px;
    .box {
      padding: 5% 7%;
      border-radius: 10px;
      display: flex;
      background-color: #151517;
      // flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 75px;
      background-image: url("../../../../assets/images/Bg-img.jpg");
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      background-attachment: fixed;
      &.box-orange {
        background-image: url("../../../../assets/images/Bg-img-orange.png");
      }
      &.box-red {
        background-image: url("../../../../assets/images/Bg-img-red.png");
      }

      .missing {
        position: relative;

        img {
          width: 25px;
        }
      }

      .img-wrapper {
        height: 30px;
        margin-right: 10%;
        position: relative;

        .module-icon {
          height: 35px;
        }
      }

      .img-wrapper-contract {
        height: 30px;
        display: flex;
        justify-content: space-between;

        .module-icon {
          height: 35px;
        }
      }

      .count {
        position: absolute;
        left: 29%;
        margin-bottom: 20px;
        background-color: red;
        border-radius: 50%;
        padding: 3px;
      }
    }
  }

  // .future-module {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr 1fr 1fr;
  //   gap: 20px;
  //   .box {
  //     padding: 7%;
  //     border-radius: 7px;
  //     display: flex;
  //     // align-items: center;
  //     background-color: #151517;
  //     flex-direction: column;
  //     div {
  //       display: flex;
  //       // align-items: center;
  //       // justify-content: center;
  //       // gap: 10px;
  //       .img-wrapper {
  //         height: 30px;
  //         margin-right: 10%;
  //         position: relative;
  //       }
  //     }

  //     // .count {
  //     //   position: absolute;
  //     //   left: 29%;
  //     //   margin-bottom: 20px;
  //     //   background-color: red;
  //     //   border-radius: 50%;
  //     //   padding: 3px;
  //     // }
  //   }
  // }
  .rejection-statuses-wrapper {
    padding: 5% 20% 0;
    .heading {
      padding: 0 0 3%;
    }
  }

  // .cancel-application-slider-item-wrapper {
  //   padding: 5% 5%;
  //   border-radius: 10px;
  //   margin: 80px auto 0;
  //   .cancel-application-btn-wrapper {
  //     margin-top: 50px;
  //     width: 100%;
  //     .cancel-application-btn {
  //       width: 100%;
  //       // max-width: 300px;
  //     }
  //   }
  // }
  // .unit-status-and-module-section-wrapper {
  //   // min-height: 800px;
  //   min-height: 700px;
  //   position: relative;
  //   .unit-status-and-module-section {
  //     width: 100%;
  //     // position: absolute;
  //     // left: 0;
  //     // top: 50%;
  //     // transform: translateY(-50%);
  //     .tenant-unit-details-page-wrapper {
  //       width: 80%;
  //       margin: 0 auto;
  //     }
  //   }
  // }
  .contract_renewal_status {
    margin-top: 30px;
    text-align: center;
    img {
      vertical-align: middle;
      width: 32px;
      margin-right: 10px;
    }
    span {
      vertical-align: middle;
    }
  }

  @media only screen and (max-width: 1800px) {
    // .unit-slider-tenant-home .slider2-wrapper .image-wrapper{
    //   width: 100%;
    // }

    .slider2-wrapper {
      width: 100%;
      height: unset;
      // display: flex;
      // justify-content: space-between;

      .image-wrapper {
        // width: 47%;
        width: 100%;

        img {
          width: 100%;
        }
      }

      // .details-wrapper {
      //   width: 53%;

      //   .detail {
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     flex-direction: column;
      //     height: 100%;
      //   }
      // }
    }

    .slider-item .address .location-pin {
      height: 23px;
    }
    .tenant-home-module-wrapper .box .img-wrapper {
      height: 27px;
    }
    .contract_renewal_status {
      img {
        width: 28px;
      }
    }
  }

  @media only screen and (max-width: 1600px) {
    // .unit-slider-tenant-home .slider2-wrapper .image-wrapper{
    //   width: 100%;
    // }

    .slider2-wrapper {
      width: 100%;
      // height: 50vh;
      // display: flex;
      // justify-content: space-between;

      .image-wrapper {
        // width: 47%;
        width: 100%;
      }

      // .details-wrapper {
      //   width: 53%;

      //   .detail {
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     flex-direction: column;
      //     height: 100%;
      //   }
      // }
    }

    .slider-item .address .location-pin {
      height: 21px;
    }

    .tenant-home-module-wrapper {
      grid-template-columns: repeat(4, 280px);
      gap: 15px;
    }

    .tenant-home-module-wrapper .box .img-wrapper {
      height: 24px;
    }

    .contract_renewal_status {
      img {
        width: 25px;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    .slider2-wrapper {
      width: 100%;
      // height: 50vh;
      // display: flex;
      // justify-content: space-between;

      .image-wrapper {
        // width: 47%;
        width: 100%;
      }

      // .details-wrapper {
      //   width: 53%;

      //   .detail {
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     flex-direction: column;
      //     height: 100%;
      //   }
      // }
    }

    .slider-item .address .location-pin {
      height: 19px;
    }

    .tenant-home-module-wrapper {
      grid-template-columns: repeat(3, 300px);
      gap: 15px;
    }

    .tenant-home-module-wrapper .box .img-wrapper {
      height: 21px;
    }

    .contract_renewal_status {
      img {
        width: 23px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .slider2-wrapper {
      width: 100%;
      // height: 50vh;
      display: flex;
      justify-content: space-between;

      .image-wrapper {
        // width: 47%;
        width: 100%;
      }

      .details-wrapper {
        width: 53%;

        .detail {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;
        }
      }
    }

    .tenant-home-module-wrapper {
      grid-template-columns: 1fr 1fr 1fr;

      .box {
        .count {
          left: 70%;
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .slider2-wrapper {
      width: 100%;
      // height: 50vh;
      display: flex;
      justify-content: space-between;

      .image-wrapper {
        // width: 55%;
        width: 100%;
      }

      .details-wrapper {
        width: 45%;

        .detail {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;
        }
      }
    }

    .slider-item .address .location-pin {
      height: 17px;
    }
    .slider-item {
      padding: 20px;
      .unit-name,
      .prop-name,
      .address {
        margin-bottom: 5px;
      }
      .address {
        margin-bottom: 15px;
      }
    }
    .title-header {
      .left {
        width: 49%;
      }
      .right {
        width: 49%;
        flex-direction: column-reverse;
        text-align: right;
        .ammenties {
          width: 100%;
          margin: 10px 0 0;
        }
      }
    }
    .tenant-home-module-wrapper {
      // grid-template-columns: 1fr 1fr;
      .tenant-home-module-wrapper {
        grid-template-columns: repeat(2, 350px);
        gap: 15px;
      }
    }
    .tenant-home-module-wrapper .box .img-wrapper {
      height: 18px;
    }

    .tenant-home-module-wrapper {
      .box {
        .count {
          left: 55%;
        }
      }
    }
    .contract_renewal_status {
      img {
        width: 20px;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .slider2-wrapper {
      width: 100%;
      // height: 50vh;
      display: flex;
      flex-direction: column;

      .image-wrapper {
        height: 50%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          cursor: pointer;
        }
      }

      .details-wrapper {
        width: 100%;

        .detail {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
      }
    }

    .slider-item {
      margin: 20px 5px 0;
    }
    .title-header {
      // display: block !important;

      .property-detail {
        flex-direction: column;
      }

      // .left {
      //   width: 100%;
      // }
      // .right {
      //   margin-top: 10px;
      //   width: 100%;
      //   text-align: left;
      // }
    }
    .tenant-home-module-wrapper {
      grid-template-columns: 1fr 1fr;

      .box {
        .count {
          left: 13%;
        }
      }
    }
  }
}

.wrapper-facility-detail {
  display: flex;
  align-items: center;
  flex-direction: column;

  .header-facility {
    position: fixed;
    width: 100%;
    text-align: center;
    background-color: #151517;
    z-index: 1;

    h2 {
      padding: 15px;
    }
  }

  .report-wrapper {
    margin-top: 4rem;
    width: 50%;

    .detail-box {
      margin-top: 20px;
      border-radius: 10px;
      padding: 15px;
      .facility-report-detail {
        margin: 15px;
        display: flex;
        flex-direction: column;

        .facility-main-wrapper {
          display: flex;

          .facility-data {
            flex: 2;
          }

          .facility-img {
            flex: 1;
            padding: 0 0 0 15px;
            img {
              width: 100%;
              height: 90%;
              border-radius: 10px;
              object-fit: cover;
            }
          }
        }

        .tenant-data {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .date-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 15px;
          background: #151517;
          border-radius: 10px;
        }

        .vehicle-wrapper {
          margin: 10px 0;
          display: flex;
          flex-direction: column;

          .vehicle-data {
            width: 100%;
            padding: 15px;
            border-radius: 10px;
            background: #151517;
            display: grid;
            grid-template-columns: auto auto auto;
            column-gap: 20px;
          }
        }
      }
    }
  }
}

.pagination-wrapper {
  margin-top: 15px;
  padding: 5px 25px;
  background: #262626;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;

  .total {
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    .data {
      display: flex;
      flex-direction: row;
    }
  }

  .other-option {
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 35px;

    .drop-down-opt {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;

      .select-opt {
        border: none;
        border-bottom: 0.5px solid gray;
        outline: none;
        color: #939393;
        background: transparent;

        option {
          background: #262626;
          color: #939393;
          line-height: 2;
          border: 10px;
        }
      }
    }

    .option-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;

      .icon {
        cursor: pointer;
        color: gray;
      }

      .disable-btn {
        cursor: not-allowed;
      }
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }

  @media only screen and (max-width: 600px) {
    .pagination-wrapper {
      display: flex !important;
      flex-direction: column !important;
    }

    .total {
      display: grid !important;
      grid-template-columns: auto auto auto !important;
    }
  }
}

.pop-up-date-picker-wrapper {
  position: relative;
  .backdrop {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  label {
    display: block;
    font-weight: 600;
    position: absolute;
    top: 17px;
    left: 22px;
  }
  input {
    display: block;
    width: 100%;
    border: 0;
    padding: 42px 22px 17px;
    border-radius: 10px;
    outline: none;
    &.glowOnFocus:focus {
      outline: none;
      border: 2px solid #37bdb0;
    }
  }

  @media only screen and (max-width: 1800px) {
    input {
      padding: 39px 19px 14px;
    }
    label {
      top: 14px;
      left: 19px;
    }
  }
  @media only screen and (max-width: 1600px) {
    input {
      padding: 35px 15px 10px;
    }
    label {
      top: 10px;
      left: 15px;
    }
  }
  @media only screen and (max-width: 1400px) {
    input {
      padding: 30px 14px 9px;
    }
    label {
      top: 9px;
      left: 14px;
    }
  }
  @media only screen and (max-width: 1200px) {
    input {
      border-radius: 7px;
    }
  }
  @media only screen and (max-width: 900px) {
    input {
      padding: 26px 12px 7px;
    }
    label {
      top: 7px;
      left: 12px;
    }
  }
  @media only screen and (max-width: 600px) {
  }
}

.date-picker-modal-wrapper {
  .modal-header-custom {
    padding: 20px 0;
    position: relative;
    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 25px;
    }
  }
  .modal-body-custom {
    padding: 0 0 40px;
  }
  .react-calendar {
    margin: 0 auto;
    * {
      color: #202020;
    }
    .react-calendar__tile {
      * {
        color: #202020;
        font-weight: 500;
      }
    }
  }
}

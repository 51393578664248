.manage-tenants {
  padding: 1.5rem;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .link{
      color: #37bdb0;
      cursor: pointer;

      &:hover{
        color: white;
      }
    }

  }

  // Existing Tenants Table
  .existing-tenants-table {
    .action-button {
      height: 30px;
      // margin-left: 20px;
    }
  }

  // Transitioned Tenants Table
  .transitioned-table {
    .action-button {
      height: 30px;
      // margin-left: 20px;
    }
  }

  // New Tanants Table
  .new-tenant-table {
    .status {
      // color: #e7da20;
      color: #ffff00;
    }

    .action-button {
      height: 30px;
      // margin-left: 20px;
    }
  }

  // Previous Tenants Table
  .prevoius-tenats-table {
    .action-button {
      height: 30px;
      // margin-left: 20px;
    }
  }

  // Request Tenants Table
  .request-table {
    .approve {
      color: #37bdb0;
    }

    .reject {
      color: red;
    }

    .pending {
      color: #e7da20;
    }

    .action-button {
      height: 30px;
      // margin-left: 20px;
    }
  }

  // Move Out Table
  .move-out-table {
    .status {
      color: #e7da20;
    }

    .action-button {
      height: 30px;
      // margin-left: 20px;
    }
  }

  // Renewal Table
  .renewal-table {
    .renew {
      color: green;
    }
    .expire {
      color: red;
    }

    .action-button {
      height: 30px;
      // margin-left: 20px;
    }
  }
  .resend-cancel-btn {
    button {
      margin-left: 10px;
    }
  }
}

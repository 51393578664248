.manage-maintenance-wrapper {
  padding: 1.5rem;

  .heading {
    font-weight: 600;
    // text-align: center;
    margin-bottom: 2rem;
  }

  // New Request Table
  .newRequest-table {
    .action-button {
      height: 30px;
      margin-left: 20px;
    }
  }

  // On Going Request Table
  .ongoing-table {
    .action-button {
      height: 30px;
      margin-left: 20px;
    }
  }

  // Spare Part Request Table
  .spareRequest-table {
    // Status
    // .complete {
    //   color: green;
    // }
    // .unassign {
    //   color: #37bdb0;
    // }

    // .pending {
    //   color: goldenrod;
    // }
    // .waiting {
    //   color: goldenrod;
    // }

    .action-button {
      height: 30px;
      margin-left: 20px;
    }
  }

  // Completed Request Table
  .completeRequest-table {
    .action-button {
      height: 30px;
      margin-left: 20px;
    }
  }

  // Rejected Request Table
  .rejectRequest-table {
    .action-button {
      height: 30px;
      margin-left: 20px;
    }
  }

  // Escalated Request Table
  .escalated-table {
    .action-button {
      height: 30px;
      margin-left: 20px;
    }
  }

  // Move In Request Table
  .moveInRequest-table {
    // Status
    .complete {
      color: green;
    }
    .unassign {
      color: #37bdb0;
    }

    .pending {
      color: goldenrod;
    }
    .waiting {
      color: goldenrod;
    }

    .action-button {
      height: 30px;
      margin-left: 20px;
    }
  }

  // Move Out Request Table
  .moveOutRequest-table {
    // Status
    .complete {
      color: green;
    }
    .unassign {
      color: #37bdb0;
    }

    .pending {
      color: goldenrod;
    }
    .waiting {
      color: goldenrod;
    }

    .action-button {
      height: 30px;
      margin-left: 20px;
    }
  }

  // Unit Request Table
  .unitRequest-table {
    .action-button {
      height: 30px;
      margin-left: 20px;
    }
  }

  // Common Request Table
  .commonRequest-table {
    .action-button {
      height: 30px;
      margin-left: 20px;
    }
  }

  // Closed Request Table
  .closedRequest-table {
    .action-button {
      height: 30px;
      margin-left: 20px;
    }
  }
}

.add-date-modal-wrapper {
  .modal-header-custom {
    margin-bottom: 40px;
    .icon-wrapper {
      img {
        width: 19%;
      }
    }
    h2 {
      font-weight: 600;
    }
  }
  .modal-body {
    padding: 8% 10%;
  }
  .modal-body-custom {
    width: 100%;
  }

  .otherTheme {
    color: #444444;
    .react-date-picker__inputGroup {
      * {
        color: #444444 !important;
      }
    }
  }

  .modal-footer-custom {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }

  .modal-date-value-wrapper {
    margin-bottom: 20px;
  }

  .header-description {
    margin: 0 0 20px;
  }

  .footer-description {
  }

  @media only screen and (max-width: 900px) {
    .modal-header-custom {
      margin-bottom: 20px;
    }

    .description {
      margin: 20px 0;
    }

    .modal-footer-custom {
      flex-direction: column-reverse;
      button {
        margin-top: 20px;
        width: 100%;
      }
    }

    .modal-date-value-wrapper {
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
  }
}

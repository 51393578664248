.esign-doc-modal-wrapper {
  padding: 0 !important;
  * {
    color: #000000;
  }
  .modal-body-custom {
    // background-color: aliceblue;
    // span[data-tip="Take Photo"] {
    //   background-color: #000000 !important;
    // }
    // .tooltip {
    //   display: none !important;
    // }
    // .docuseal-form-sign-component {
    //   .ds {
    //     display: none;
    //   }
    // }
  }

  .close-btn {
    padding: 10px 30px;
  }
  .modal-body-custom {
    .hidden {
      display: none;
    }
    .docuseal-loading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 80vh;
    }

    .docuseal-form-sign-component {
      border-radius: 10px;
      padding: 4rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      background: #f1ffff;
      height: auto;

      .image-wrapper {
        // flex: 2;

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          cursor: pointer;
        }
      }

      .document-wrapper {
        display: flex;
        flex-direction: column;
        // height: 100vh;
        .description {
          img {
            width: 100%;
            height: 450px;
            object-fit: cover;
          }
        }
        // .description {
        //   position: relative;
        //   // padding-bottom: 56.25%; /* 16:9 aspect ratio */
        //   // height: 0;
        //   // overflow: hidden;
        //   // max-width: 100%;

        //   width: 100%;
        //   max-width: 100vw;
        //   // max-height: 100vh;
        //   box-sizing: border-box;
        //   overflow-x: auto; /* Adds horizontal scroll if content exceeds width */
        //   overflow-y: auto; /* Hides vertical scroll */

        //   img {
        //     max-width: 100%;
        //     height: auto;
        //     object-fit: cover;
        //   }

        //   iframe {
        //     position: absolute;
        //     // top: 0;
        //     // left: 0;
        //     // width: 100%;
        //     // height: 100%;
        //     video {
        //       position: absolute;
        //       top: 0;
        //       left: 0;
        //       max-width: 100%;
        //       height: auto;
        //     }
        //   }
        // }

        .doc-image-wrapper {
          width: 100%;
          height: 60%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
        }

        // PDF CONTAINER
        .pdf-container {
          border-radius: 10px;
          width: 100%;
          height: 450px;
          overflow-x: hidden;
          overflow-y: scroll;
        }

        /* Scrollbar style starts */
        /* width */
        ::-webkit-scrollbar {
          width: 10px;
          margin-right: 1rem;
        }
        /* Track */
        ::-webkit-scrollbar-track {
          background-color: #707070;
          scroll-padding: 1rem;
        }
        /* Handle */
        ::-webkit-scrollbar-thumb {
          background-color: #3d3d3d;
          background-clip: padding-box;
        }
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #272727;
          cursor: pointer;
        }
        /* Scrollbar style ends */
      }
    }
  }
}

.emergency-contract-modal-main-wrapper {
  .modal-body {
    padding: 3%;
  }
  .form-wrapper {
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    margin-top: 50px;
  }
  .button-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    gap: 5%;
    button {
      width: 50%;
    }
  }

  .form-group {
    width: 49%;
    margin-bottom: 20px;
  }

  .otherTheme {
    .react-tel-input .form-control {
      background: #f1ffff !important;
      color: #444444 !important;
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
    .form-group {
      width: 100%;
    }
  }
}

.internal-communication-detail-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .header-security {
    position: fixed;
    width: 100%;
    text-align: center;
    background-color: #151517;
    z-index: 1;

    h2 {
      padding: 15px;
    }
  }

  .report-wrapper {
    margin-top: 4rem;
    width: 50%;

    .detail-box {
      margin-top: 20px;
      border-radius: 10px;
      padding: 15px;
      .communication-detail-box {
        margin: 15px 30px 5px 30px;

        .task-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .uploaded-doc {
          display: flex;
          justify-content: space-between;

          .img-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: space-between;

            img {
              width: 90px;
              height: 90px;
              border-radius: 10px;
              cursor: pointer;
            }
          }
        }
      }
    }

    // TIME-LINE

    .detail-box-timeLine {
      border-radius: 10px;
      padding: 15px 10px;

      .main-timeline-wrapper {
        display: flex;

        .dot {
          flex: 0.15;
          margin: 5px;

          .dot-1 {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #37bdb0;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .dot-2 {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: #000;
          }

          .vl {
            border-left: 1px solid grey;
            margin-left: 14px;
            height: 135%;
          }
        }

        .detail {
          flex: 2;
          margin: 5px 30px 5px 0;

          .data {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .name {
              padding: 0 5px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              p {
                span {
                  margin-left: 10px;
                }
              }
            }

            .current-status {
              width: 100%;
              border-radius: 10px;
              background: #151517;

              p {
                margin: 10px 15px;
              }
            }
          }
        }
      }

      &:last-of-type {
        .main-timeline-wrapper {
          .dot {
            .vl {
              height: 0;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

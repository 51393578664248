.counter-input-component-wrapper {
  gap: 2%;
  flex-wrap: wrap;
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  label {
    display: block;
    font-weight: 600;
    position: absolute;
    top: 17px;
    left: 22px;
  }
  input {
    display: block;
    width: 100%;
    padding: 42px 22px 17px;
    border-radius: 10px;
    outline: none;
    border: 3px solid #37bdb0 !important;
    &:focus {
      outline: none;
      border: 2px solid #37BDB0;
      // outline: 1px solid #37bdb0;
    }
  }

  .form-input-wrapper {
    width: 68%;
    flex-grow: 10;
  }

  .validation {
    margin-top: 10px;
  }

  button {
    border: none;
    border-radius: 10px;
    width: 14%;
    font-size: 40px;
    flex-grow: 2;
  }

  @media only screen and (max-width: 1800px) {
    input {
      padding: 39px 19px 14px;
    }
    label {
      top: 14px;
      left: 19px;
    }
  }
  @media only screen and (max-width: 1600px) {
    input {
      padding: 35px 15px 10px;
    }
    label {
      top: 10px;
      left: 15px;
    }
  }
  @media only screen and (max-width: 1400px) {
    input {
      padding: 30px 14px 9px;
    }
    label {
      top: 9px;
      left: 14px;
    }
  }
  @media only screen and (max-width: 1200px) {
    input {
      border-radius: 7px;
    }
  }
  @media only screen and (max-width: 900px) {
    input {
      padding: 26px 12px 7px;
    }
    label {
      top: 7px;
      left: 12px;
    }
    button {
        font-size: 30px;
    }
  }
  @media only screen and (max-width: 600px) {
  }
}

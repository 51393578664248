.wrapper-security-detail {
  display: flex;
  align-items: center;
  flex-direction: column;

  .header-security {
    width: 100%;
    text-align: center;

    h2 {
      padding: 15px;
    }
  }

  .report-wrapper {
    .detail-box {
      margin-top: 20px;
      border-radius: 10px;
      padding: 15px;
      background-color: #262626;
      .security-unit-detail-box {
        margin: 10px;
        .num {
          margin-bottom: 15px;
        }

        .title {
          color: rgb(122, 122, 122);
        }

        .last-update {
          padding: 10px;
          background: #151517;
          border-radius: 10px;

          span {
            position: relative;
            float: right;
          }
        }

        .ticket-wrapper {
          p {
            color: rgb(122, 122, 122);

            span {
              position: relative;
              float: right;
              // color: #37bdb0;
            }
          }
        }

        // .image-wrapper {
        //   display: flex;
        //   align-items: center;
        //   flex-direction: row;
        //   flex-wrap: wrap;
        //   gap: 15px;

        //   .proof-img {
        //     display: flex;
        //     align-items: center;
        //     justify-content: space-between;

        //     img {
        //       width: 125px;
        //       height: 125px;
        //       object-fit: cover;
        //       border-radius: 10px;
        //       cursor: pointer;
        //     }
        //   }
        // }

        .image-wrapper {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 15px;

          .proof-img {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .media {
              width: 125px;
              height: 125px;
              // object-fit: cover;
              border-radius: 10px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .detail-box-timeLine {
      border-radius: 10px;
      padding: 15px 10px;

      .main-timeline-wrapper {
        display: flex;

        .dot {
          flex: 0.15;
          margin: 5px;

          .dot-1 {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #37bdb0;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .dot-2 {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: #000;
          }

          .vl {
            border-left: 3px solid grey;
            margin-left: 14px;
            height: 135%;
          }
        }

        .detail {
          flex: 2;
          margin: 5px 0;

          .data {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .name {
              padding: 0 5px;

              p {
                span {
                  margin-left: 10px;
                }
              }
            }

            .current-status {
              width: 100%;
              border-radius: 10px;
              background: #151517;

              p {
                margin: 0 10px 0 10px;
              }

              .action-button {
                width: 30px;
                height: 30px;
                margin-left: 10px;
              }

              .currentStatus {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 10px;

                .action-button {
                  width: 30px;
                  height: 30px;
                  margin-left: 10px;
                }
              }
            }

            .violation-status {
              width: 100%;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              background: #151517;
              padding: 10px 20px 10px 20px;

              .action-button {
                width: 30px;
                height: 30px;
                margin-left: 10px;
              }

              .currentStatus {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 10px;

                .action-button {
                  width: 30px;
                  height: 30px;
                  margin-left: 10px;
                }
              }
            }

            // REPORT
            .so-report-wrapper {
              display: flex;
              flex-direction: column;

              .report-doc-wrapper {
                margin: 0 0 10px 10px;
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 15px;

                .proof-img {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .media {
                    width: 125px;
                    height: 125px;
                    // object-fit: cover;
                    border-radius: 10px;
                    cursor: pointer;
                  }
                }
              }
            }
          }

          .date {
            margin: 10px;

            p {
              text-align: right;
            }
          }
        }
      }

      &:last-of-type {
        .main-timeline-wrapper {
          .dot {
            .vl {
              height: 0;
            }
          }
        }
      }
    }
  }
}

.change-password-module-wrapper {
  width: 100%;
  .inner-heading {
    margin-bottom: 30px;
  }
  .submit-button-wrapper {
    margin: 20px 0;
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

.change-password-module-wrapper-specific {
  .form-wrapper {
    padding: 3%;
    border-radius: 10px;
    background-color: #262626;
  }
  .form-group {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

.forget-password-module-wrapper {
  min-height: 100vh;
  overflow: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 30px 0;

  .center {
    width: 100%;
    border-radius: 10px;
    padding: 70px 70px;
    background-color: #262626;
  }

  .form-group {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
    .center {
      padding: 50px 40px;
    }
  }
  @media only screen and (max-width: 900px) {
    .form-group {
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width: 600px) {
    .center {
      padding: 40px 30px;
    }
  }
}

.add-visitor-module-wrapper {
  padding-bottom: 100px;

  .add-new-form {
    .form-wrapper {
      background-color: #262626;
      padding: 3%;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
    }
    .button-wrapper {
      margin-top: 30px;
      .custom-text-btn {
        margin-top: 20px;
      }
    }

    .form-group {
      width: 49%;
      margin-bottom: 20px;

      .add-image {
        margin-top: 20px;
      }

      .txtBtn {
        color: #37bdb0;
        margin: 10px;
        padding-right: 75px;
      }

      .action-button {
        display: inline-block;

        .remove {
          outline: none;
          color: red;
          background: transparent;
        }
      }
    }

    .tenant {
      border-radius: 10px;

      p {
        margin: 10px 0 7px 15px;
        color: lightgray;

        span {
          color: white;
        }
      }
    }
    .form-group-full {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .custom-radio-component-wrapper {
        margin-right: 50px;
      }
      .custom-form-control {
        width: 100%;
      }
    }
    @media only screen and (max-width: 1800px) {
    }
    @media only screen and (max-width: 1600px) {
    }
    @media only screen and (max-width: 1400px) {
    }
    @media only screen and (max-width: 1200px) {
    }
    @media only screen and (max-width: 900px) {
    }
    @media only screen and (max-width: 600px) {
      .form-group {
        width: 100%;
      }
    }
  }
}

.error-modal-wrapper {
  .modal-header-custom {
    .icon-wrapper {
      img {
        width: 19%;
      }
    }
    h2 {
      font-weight: 600;
    }
  }
  .modal-body {
    padding: 8% 10%;
    border: 2px solid #939393;
  }
  .description {
    margin: 5% 0;
  }

  .modal-footer-custom {
    display: flex;
    justify-content: center;
    button {
      width: 50%;
    }
  }

  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
  }
}

.violation-report-component-wrapper {
  p {
    margin: 0;
  }
  .black-container-sm {
    padding: 1.5% 2%;
    border-radius: 10px;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .icon-sm {
    width: 25px;
  }

  .detail-card {
    padding: 5%;
    border-radius: 10px;
    background-color: #262626;

    .title {
      margin-bottom: 12px;
    }

    .location-wrapper {
      width: 100%;
      display: flex;
      align-items: center;

      div {
        position: relative;
        width: 100%;

        .issuer_data {
          position: absolute;
          float: right;
        }
        .issuer_role {
          position: absolute;
          float: left;
        }
      }
    }

    .image-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 15px;

      .proof-img {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 75px;
          height: 75px;
          object-fit: cover;
          border-radius: 10px;
          cursor: pointer;
        }
      }
    }

    .ticket-wrapper {
      margin: 10px 0;
      span {
        margin-left: 175px;
      }
    }

    .last-update {
      padding: 5px;
      // background: #151517;
      border-radius: 10px;

      p {
        margin: 5px 0;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .document-wrapper {
    display: flex;
    flex-direction: column;
    // height: 100vh;
    .description {
      img {
        width: 100%;
        height: 450px;
        object-fit: cover;
      }
    }
    // .description {
    //   position: relative;
    //   // padding-bottom: 56.25%; /* 16:9 aspect ratio */
    //   // height: 0;
    //   // overflow: hidden;
    //   // max-width: 100%;

    //   width: 100%;
    //   max-width: 100vw;
    //   // max-height: 100vh;
    //   box-sizing: border-box;
    //   overflow-x: auto; /* Adds horizontal scroll if content exceeds width */
    //   overflow-y: auto; /* Hides vertical scroll */

    //   img {
    //     max-width: 100%;
    //     height: auto;
    //     object-fit: cover;
    //   }

    //   iframe {
    //     position: absolute;
    //     // top: 0;
    //     // left: 0;
    //     // width: 100%;
    //     // height: 100%;
    //     video {
    //       position: absolute;
    //       top: 0;
    //       left: 0;
    //       max-width: 100%;
    //       height: auto;
    //     }
    //   }
    // }

    .doc-image-wrapper {
      width: 100%;
      height: 60%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    // PDF CONTAINER
    .pdf-container {
      border-radius: 10px;
      width: 100%;
      height: 450px;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    /* Scrollbar style starts */
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
      margin-right: 1rem;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background-color: #707070;
      scroll-padding: 1rem;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background-color: #3d3d3d;
      background-clip: padding-box;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #272727;
      cursor: pointer;
    }
    /* Scrollbar style ends */
  }

  @media only screen and (max-width: 1800px) {
    .icon-sm {
      width: 23px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .detail-card {
      .date-wrapper {
        gap: 180px;
      }

      .image-wrapper .proof-img img {
        width: 125px;
        height: 125px;
      }

      .ticket-wrapper span {
        // margin-left: 25px;
        float: right;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    .detail-card {
      .date-wrapper {
        gap: 195px;
      }

      .image-wrapper .proof-img img {
        width: 125px;
        height: 125px;
      }

      .ticket-wrapper span {
        // margin-left: 25px;
        float: right;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .detail-card {
      .date-wrapper {
        gap: 155px;
      }

      .ticket-wrapper span {
        // margin-left: 25px;
        float: right;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .detail-card {
      .date-wrapper {
        gap: 175px;
      }

      .ticket-wrapper span {
        // margin-left: 250px;
        float: right;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .detail-card {
      .date-wrapper {
        gap: 65px;
      }

      .ticket-wrapper span {
        margin-left: 25px;
      }
    }
  }
}

// NEW STYLE
.security-complaint-report-component-wrapper {
  p {
    margin: 0;
  }
  .black-container-sm {
    padding: 1.5% 2%;
    border-radius: 10px;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .icon-sm {
    width: 25px;
  }

  .detail-card {
    padding: 5%;
    border-radius: 10px;
    background-color: #262626;

    .title {
      margin-bottom: 12px;
    }

    .date-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 117px;
    }

    .location-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 100px;
    }

    .image-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 15px;

      .proof-img {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .media {
          width: 125px;
          height: 125px;
          // object-fit: cover;
          border-radius: 10px;
          cursor: pointer;
        }
      }
    }

    .ticket-wrapper {
      margin: 10px 0;
      span {
        margin-left: 175px;
      }
    }

    .last-update {
      padding: 5px;
      border-radius: 10px;

      p {
        margin: 5px 0;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .security-report-timeline-list-wrapper {
    .log-wrapper {
      .detail-box-timeLine {
        border-radius: 10px;
        padding: 15px 10px;

        .main-timeline-wrapper {
          display: flex;

          .dot {
            margin: 5px;

            .dot-1 {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background-color: #37bdb0;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .dot-2 {
              width: 15px;
              height: 15px;
              border-radius: 50%;
              background-color: #000;
            }

            .vl {
              border-left: 3px solid grey;
              margin-left: 16px;
              height: 140%;
            }
          }

          .detail {
            flex: 2;
            margin: 5px 30px 5px 0;

            .data {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              .name {
                padding: 0 5px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                  span {
                    margin-left: 10px;
                  }
                }
              }

              .current-status {
                width: 100%;
                border-radius: 10px;
                background: #151517;
                margin-top: 10px;

                p {
                  margin: 10px;
                }

                .current {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  p {
                    margin: 10px;
                  }

                  .action-button {
                    width: 30px;
                    height: 30px;
                    padding-right: 40px;
                  }
                }
              }

              hr {
                width: 100%;
                display: block;
                height: 0;
                border: 1px solid rgba(211, 211, 211, 0.656);
                margin: 10px 0;
              }

              .btn-wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 10px;
                margin-top: 10px;

                button {
                  padding: 10px 50px;
                  cursor: pointer;
                  background: #37bdb0;
                  border-radius: 10px;
                  outline: none;
                  border: none;
                }

                .reject-btn {
                  padding: 10px 50px;
                  cursor: pointer;
                  background: #ff1e1e;
                  border-radius: 10px;
                  outline: none;
                  border: none;
                }
              }

              .so-report-wrapper {
                display: flex;
                flex-direction: column;

                .report-doc-wrapper {
                  margin: 0 0 10px 10px;
                  display: flex;
                  align-items: center;
                  flex-direction: row;
                  gap: 15px;

                  .proof-img {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .media {
                      width: 100px;
                      height: 100px;
                      // object-fit: cover;
                      border-radius: 10px;
                      cursor: pointer;
                    }
                  }
                }
              }

              // SM - RESOLVE
              .sm-report-form {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 10px;
                margin-top: 10px;
              }

              .form-wrapper {
                .select-reason {
                  margin: 20px 0;
                }
              }

              .form-btn-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                gap: 10px;
                padding: 10px;

                .btn1 {
                  background: none;
                  color: #37bdb0;
                  border: 2px solid #37bdb0;
                  border-radius: 10px;
                }

                button {
                  &:disabled {
                    background-color: #505050;
                    cursor: not-allowed;
                  }
                }
              }

              .remark-wrapper {
                padding: 20px 25px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                border-radius: 10px;

                .task-descr {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  gap: 10px;

                  .desc {
                    width: 60%;
                    padding: 0 20px;
                  }
                }

                .uploaded-doc {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  gap: 10px;

                  .doc-wrapper {
                    width: 57%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .doc {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      flex-direction: row;
                      gap: 10px;

                      img {
                        height: 20px;
                        width: 20px;
                        cursor: pointer;
                      }
                    }
                  }
                }
              }

              .action-btns {
                margin: 15px 0;
                padding: 5px 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 25px;
              }
            }
          }
        }
      }

      .active{
        background-color: #0f2e2b;
      }

      .inactive{
        background-color: #262626;
      }

      &:nth-last-child(2) {
        .vl {
          height: unset !important;
        }
      }
    }
  }

  @media only screen and (max-width: 1800px) {
    .icon-sm {
      width: 23px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .detail-card {
      .date-wrapper {
        gap: 180px;
      }

      .image-wrapper .proof-img {
        .media {
          width: 125px;
          height: 125px;
          // object-fit: cover;
          border-radius: 10px;
          cursor: pointer;
        }
      }

      .ticket-wrapper span {
        // margin-left: 25px;
        float: right;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    .detail-card {
      .date-wrapper {
        gap: 195px;
      }

      .image-wrapper .proof-img {
        .media {
          width: 125px;
          height: 125px;
          // object-fit: cover;
          border-radius: 10px;
          cursor: pointer;
        }
      }

      .ticket-wrapper span {
        // margin-left: 25px;
        float: right;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .detail-card {
      .date-wrapper {
        gap: 155px;
      }

      .ticket-wrapper span {
        // margin-left: 25px;
        float: right;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .detail-card {
      .date-wrapper {
        gap: 175px;
      }

      .ticket-wrapper span {
        // margin-left: 250px;
        float: right;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .detail-card {
      .date-wrapper {
        gap: 65px;
      }

      .ticket-wrapper span {
        margin-left: 25px;
      }
    }
  }
}

.tenant-moveout-detail-wrapper {
  .display-move-out {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .text_pending_clearance_docs {
    margin-top: 30px;
  }

  .status-wrapper {
    margin: 30px 0 0;
    .warning-icon {
      width: 100%;
      max-width: 100px;
      margin: 0 auto;
    }
    .status-text {
      margin: 20px 0 0;
    }
  }
}

.internal-communication-wrapper {
  padding: 1.5rem;
  .internal-communication-heading {
    font-weight: 600;
    text-align: center;

    .link {
      text-align: right;
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
    // .add-found {
    //   //   // position: relative;
    //   left: 85%;
    // }
  }
  @media only screen and (max-width: 900px) {
    // .add-found {
    //   //   // position: relative;
    //   left: 80%;
    // }
  }
  @media only screen and (max-width: 600px) {
  }
}

.create-invoice-modal-wrapper {
  .modal-body {
    padding-right: 30px;
    padding-left: 30px;
  }
  .modal-header-custom {
    padding: 10px 0 20px;
  }
  .modal-body-custom {
    .description {
      margin-bottom: 20px;
    }
    .cost-covered-by-radio-button {
        display: flex;
        justify-content: space-around;
        width: 50%;
        padding: 20px 0;
      .radio-group {
       
      }
    }
    .tenant-detail-section {
      width: 100%;
      padding: 0 30px 30px;
      .row-custom {
        display: flex;
        padding-bottom: 0px;
        .left {
          width: 20%;
        }
        .right {
        }
      }
    }
    .form-fields {
      padding-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .form-group {
        width: 48%;
        margin-bottom: 30px;
      }
      .form-group-full {
        width: 100%;
      }
    }
  }
  .modal-footer-custom {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 20px;
    .custom-btn {
      width: 48%;
    }
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

.manage-cards-page-wrapper {
  .card-list-wrapper-main {
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    padding: 30px;
    background-color: #262626;
  }

  .button-wrapper {
    margin-top: 30px;
    .custom-text-btn {
      margin-top: 20px;
    }
  }
}

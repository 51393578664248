.custom-slider-wrapper-main {
  .custom-carousel-container {
    padding-bottom: 50px;
    .react-multi-carousel-track {
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
  }

  //   .custom-slider-wrapper-main .react-multiple-carousel__arrow {
  //     background-color: green;
  //     /* z-index: 10; */
  //     bottom: 0px;
  //     /* left: 10px; */
  // }

  .react-multiple-carousel__arrow {
    // background-color: rgba(0, 0, 0, 0.8);
    background-color: #37bdb0;
    bottom: 0px;
    z-index: 10;
    // &:hover {
    //   background-color: rgba(0, 0, 0, 1);
    //   // background-color: #37bdb0;
    // }
  }

  .react-multiple-carousel__arrow--left {
    left: calc(40% + 1px);
  }

  .react-multiple-carousel__arrow--right {
    right: calc(40% + 1px);
  }

  .custom-dot-list-style {
    .react-multi-carousel-dot {
      button {
        background-color: #fff;
        border-color: #fff;
      }
      &.react-multi-carousel-dot--active {
        button {
          background-color: #37bdb0;
          border-color: #37bdb0;
        }
      }
    }
  }
  @media only screen and (max-width: 1800px) {
    .react-multiple-carousel__arrow--left {
      left: calc(40% + 1px);
    }
  
    .react-multiple-carousel__arrow--right {
      right: calc(40% + 1px);
    }
  }
  @media only screen and (max-width: 1600px) {
    .react-multiple-carousel__arrow--left {
      left: calc(40% + 1px);
    }
  
    .react-multiple-carousel__arrow--right {
      right: calc(40% + 1px);
    }
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
    .react-multiple-carousel__arrow--left {
      left: calc(35% + 1px);
    }
  
    .react-multiple-carousel__arrow--right {
      right: calc(35% + 1px);
    }
  }
  @media only screen and (max-width: 900px) {

    .react-multiple-carousel__arrow--left {
      left: calc(30% + 1px);
    }
  
    .react-multiple-carousel__arrow--right {
      right: calc(30% + 1px);
    }

  }
  @media only screen and (max-width: 600px) {
    .react-multiple-carousel__arrow--left {
      left: calc(10% + 1px);
    }
  
    .react-multiple-carousel__arrow--right {
      right: calc(10% + 1px);
    }
  }
}

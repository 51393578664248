.searchbar-component-wrapper {
  position: relative;
  .search-form {
    input {
      border: 0;
      height: 50px;
      border-radius: 5px;
      padding: 20px 50px 20px 20px;
      border: 2px solid rgb(201, 201, 201);
      &:focus {
        border: 2px solid #37bdb0;
        outline: none;
      }
    }
  }
  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    height: 80%;
  }

  .dash-search {
    input {
      border: 0;
      height: 65px;
      border-radius: 5px;
      padding: 20px;
      &:focus {
        outline: none;
      }
    }
  }

  @media only screen and (max-width: 1800px) {
    .search-form input {
      height: 45px;
      padding: 0 40px 0 17px;
    }
    .icon {
      right: 8px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .search-form input {
      height: 40px;
      padding: 0 35px 0 14px;
    }
    .icon {
      right: 6px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .search-form input {
      height: 35px;
      padding: 0 30px 0 11px;
    }
    .icon {
      right: 6px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .dash-search input {
      height: 30px;
      padding: 0 30px 0 11px;
    }
    .icon {
      right: 6px;
    }
  }
  @media only screen and (max-width: 900px) {
    .search-form input {
      height: 30px;
      padding: 5px 25px 5px 8px;
    }
    .icon {
      height: 80%;
    }
  }
  @media only screen and (max-width: 600px) {
    .search-form input {
      height: 25px;
      padding: 5px 25px 5px 8px;
    }
    .icon {
      height: 75%;
    }
  }
}

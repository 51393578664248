.faqs-page-wrapper {
  .dash-search {
    margin: 1rem auto 2rem auto;
  }

  .accordion {
    background-color: #262626;
    border-radius: 10px;
    padding: 5px 10px;

    .item {
      background-color: #151515;
      margin-bottom: 10px;
      padding: 20px 25px;
      border-radius: 10px;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .icon {
          fill: #939393;
        }
      }

      .content {
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s cubic-bezier(0, 1, 0, 3);
      }

      .show {
        padding: 5px 0 10px 0;
        height: auto;
        max-height: 9999px;
        transition: all 0.5s cubic-bezier(1, 0, 1, 0);
      }
    }
  }

  @media only screen and (max-width: 1800px) {
  }

  @media only screen and (max-width: 1600px) {
  }

  @media only screen and (max-width: 1400px) {
  }

  @media only screen and (max-width: 1200px) {
  }

  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

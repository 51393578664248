.security-table {

  tbody {
    position: relative;
    .new-label {
      position: absolute;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      background: #00cd3e;
      margin-top: 15px;
      margin-right: 20px;
    }
  }

  .action-button {
    height: 30px;
    margin-left: 20px;
  }
}

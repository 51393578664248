.create-invoice-wrapper {
  // ADD TENANT-INVOICE
  .add-tenant-invoice-module-wrapper {
    padding-bottom: 100px;

    .add-new-form {
      .form-wrapper {
        background-color: #262626;
        padding: 3%;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
      }

      .tenant-detail {
        border: 1px solid grey;
        border-radius: 10px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .button-wrapper {
        margin-top: 30px;
        .custom-text-btn {
          margin-top: 20px;
        }
      }

      .form-group {
        width: 49%;
        margin-bottom: 20px;
      }
      .form-group-full {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .custom-radio-component-wrapper {
          margin-right: 50px;
        }
        .custom-form-control {
          width: 100%;
        }
      }
    }
  }

  // ADD PROPERTY-INVOICE
  .add-property-invoice-module-wrapper {
    padding-bottom: 100px;

    .add-new-form {
      .form-wrapper {
        background-color: #262626;
        padding: 3%;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
      }

      .button-wrapper {
        margin-top: 30px;
        .custom-text-btn {
          margin-top: 20px;
        }
      }

      .form-group {
        width: 49%;
        margin-bottom: 20px;
      }
      .form-group-full {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .custom-radio-component-wrapper {
          margin-right: 50px;
        }
        .custom-form-control {
          width: 100%;
        }
      }
    }
  }

  // ADD PROPERTY-INVOICE
  .add-party-invoice-module-wrapper {
    padding-bottom: 100px;

    .options-wrapper {
      margin-bottom: 15px;
      border-radius: 10px;
      padding: 35px 25px;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 100px;
      background-color: #262626;
    }

    .add-new-form {
      .form-wrapper {
        background-color: #262626;
        padding: 3%;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
      }

      .button-wrapper {
        margin-top: 30px;
        .custom-text-btn {
          margin-top: 20px;
        }
      }

      .form-group {
        width: 49%;
        margin-bottom: 20px;
      }

      .spareParts-wrapper {
        border-radius: 10px;
        margin-bottom: 15px;
        width: 100%;
        background-color: #151517;

        .sparePart-details {
          padding: 20px 35px;
          display: flex;
          flex-direction: column;
          width: 100%;

          .sparePart {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .icon {
            //   rotate: 90deg;
              cursor: pointer;
            }
          }
        }
      }

      .form-group-full {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .custom-radio-component-wrapper {
          margin-right: 50px;
        }
        .custom-form-control {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
    .form-group {
      width: 100%;
    }
  }
}

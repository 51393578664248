.emergency-contract-list-modal-main-wrapper {
  .modal-body {
    padding: 3%;
  }
  p {
    margin: 0;
  }
  .contract-list {
    margin: 30px 0 40px;
  }

  .r {
    border-radius: 7px;
    padding: 10px;
  }
  .button-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    gap: 5%;
    button {
      width: 50%;
    }
  }
  .editing {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
  .r {
    display: flex;
    justify-content: space-between;
    .lg {
      width: 40%;
    }
    .sm {
      width: 15%;
    }
  }
  .form-group {
    width: 49%;
    margin-bottom: 20px;
  }
  .icon {
    width: 25px;
    margin-right: 10px;
    img {
      width: 100%;
    }
  }
  .contact-inp {
    border: 0;
  }
  .empty {
    margin-top: 20px;
  }
  @media only screen and (max-width: 1800px) {
    .icon {
      width: 23px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .icon {
      width: 21px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .icon {
      width: 21px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .icon {
      width: 19px;
    }
  }
  @media only screen and (max-width: 600px) {
    .form-group {
      width: 100%;
    }
  }
}

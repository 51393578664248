.advertiments-table-wrapper {
  margin-top: 1rem;
  padding-top: 1rem;
  width: 100%;

  .active {
    color: green;
  }
  .expired {
    color: red;
  }

  .action-button {
    height: 25px;
  }
}

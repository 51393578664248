.edit-profile-page-wrapper {
  .form-wrapper {
    background-color: #262626;
    padding: 3%;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
  }

  .button-wrapper {
    margin-top: 30px;
    .custom-text-btn {
      margin-top: 20px;
    }
  }

  .form-group {
    width: 49%;
    margin-bottom: 20px;
  }
  .form-group-full {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .custom-form-control {
      width: 100%;
    }
  }
  .photo {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .detail {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .prof-pic {
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 300px;
    height: 300px;
    cursor: pointer;
    position: relative;
    .custom-image-wrapper {
      border-radius: 50%;
    }
  }
  .hidden {
    display: none;
  }
  .upload-icon {
    position: absolute;
    right: 8%;
    bottom: 5%;
    z-index: 1;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #151517;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
    }
  }

  @media only screen and (max-width: 1800px) {
    .prof-pic {
      width: 270px;
      height: 270px;
    }
    .upload-icon {
      width: 45px;
      height: 45px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .prof-pic {
      width: 240px;
      height: 240px;
    }
    .upload-icon {
      width: 40px;
      height: 40px;
    }
  }

  @media only screen and (max-width: 1400px) {
    .prof-pic {
      width: 210px;
      height: 210px;
    }
    .upload-icon {
      width: 35px;
      height: 35px;
    }
  }

  @media only screen and (max-width: 1200px) {
  }

  @media only screen and (max-width: 900px) {
    .prof-pic {
      width: 180px;
      height: 180px;
    }
    .upload-icon {
      width: 30px;
      height: 30px;
    }
  }

  @media only screen and (max-width: 600px) {
    .form-group {
      width: 100%;
    }
    .prof-pic {
      width: 150px;
      height: 150px;
    }
    .upload-icon {
      width: 25px;
      height: 25px;
    }
  }
}

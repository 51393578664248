.spare-part-request-modal-wrapper {
  .modal-body {
    padding: 5% 5%;
  }
  .form-wrapper {
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
  }
  .button-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
  .form-group {
    width: 49%;
    margin-bottom: 20px;
  }
  .form-each {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .form-group-full {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .custom-radio-component-wrapper {
      margin-right: 50px;
    }
    .custom-form-control,
    .custom-file-input-wrapper {
      width: 100%;
    }
  }
  .action-btn {
    button {
      margin-left: 20px;
    }
  }
  .attachment,
  .description {
    h3 {
      margin: 20px 0;
    }
  }
  .total {
    display: flex;
    justify-content: space-between;
    margin: 3% 0 0;
    padding: 3% 0 0;
    border-top: 2px solid rgb(104, 104, 104);
  }
  .form-each {
    margin-top: 3%;
  }
  .part-wrapper{
    margin-bottom: 40px;
  }
  .part-wrapper-inner {
    border: 2px solid #37bdb0;
    padding: 4%;
    border-radius: 10px;
    margin-bottom: 20px;
  }
}

.progress-steps-component-wrapper-v1 {
  position: relative;
  .progress-steps {
    position: relative;
    height: 190px;
    overflow-x: hidden;
    .line {
      background-color: whitesmoke;
      border-radius: 100px;
      height: 3px;
      border: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 8%;
      width: 85%;

      // border-radius: 100px;
      // height: 3px;
      // border: 0;
      // margin: 0;
      // position: absolute;
      // top: 15%;
      // transform: translateY(-50%);
      // left: 14%;
      // // right: 15%;
      // width: 70%;
    }
    .step-list {
      position: relative;
      width: 100%;
      margin: 0 auto;
      width: 90%;
    }
    .step {
      position: relative;
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
      .step-num {
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 3px solid #37bdb0;
        background: #151517;
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .step-title {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  @media only screen and (max-width: 1800px) {
    .progress-steps .step .step-num {
      width: 45px;
      height: 45px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .progress-steps .step .step-num {
      width: 40px;
      height: 40px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .progress-steps .step .step-num {
      width: 35px;
      height: 35px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .progress-steps .line {
      height: 2px;
    }
    .progress-steps .step .step-num {
      width: 30px;
      height: 30px;
    }
  }
  @media only screen and (max-width: 600px) {
    .progress-steps .line {
      left: 17% !important;
      width: 65% !important;
    }

    .progress-steps {
      height: 100px;
    }
    .progress-steps .step .step-title {
      display: none;
    }

    .progress-steps .step-list {
      width: 66%;
    }
  }
}

/* *********************************************************************************************************************************************************************************************************************** */

.progress-steps-component-wrapper-v2 {
  position: relative;
  .progress-steps {
    position: relative;
    height: 130px;
    overflow-x: hidden;
    .line {
      background-color: whitesmoke;
      // border-radius: 100px;
      // height: 3px;
      // border: 0;
      // margin: 0;
      // position: absolute;
      // top: 50%;
      // transform: translateY(-50%);
      // left: 0;
      // display: none;
      border-radius: 100px;
      height: 3px;
      border: 0;
      margin: 0;
      position: absolute;
      top: 15%;
      transform: translateY(-50%);
      left: 15%;
      // right: 15%;
      width: 70%;

      // &:last-child(){
      //   width: 0;
      // }
    }
    .step-list {
      width: 100%;
      margin: 0 auto;
      width: 90%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;

      // &:last-child{
      //   width: 0;
      //   border: 2px solid red;
      // }
    }
    .step {
      width: 25%;
      flex-shrink: 0;
      // display: inline-block;
      // position: absolute;
      // top: 50%;
      // transform: translateY(-50%);
      // z-index: 0;
      .step-num {
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 3px solid #37bdb0;
        background: #151517;
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .step-title {
        // position: absolute;
        // top: 100%;
        // left: 50%;
        // transform: translateX(-50%);
      }
    }
    .active {
      background-color: #37bdb0;
    }
  }
  @media only screen and (max-width: 1800px) {
    .progress-steps .step .step-num {
      width: 45px;
      height: 45px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .progress-steps .step .step-num {
      width: 40px;
      height: 40px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .progress-steps .step .step-num {
      width: 35px;
      height: 35px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .progress-steps .line {
      height: 2px;
    }
    .progress-steps .step .step-num {
      width: 30px;
      height: 30px;
    }
  }
  @media only screen and (max-width: 600px) {
    .progress-steps {
      height: 100px;
    }

    .progress-steps .line {
      left: 17% !important;
      width: 65% !important;
    }

    .progress-steps .step .step-title {
      display: none;
    }

    .progress-steps .step-list {
      width: 66%;
    }
  }
}

/*   **************************************************** */

.progress-steps-component-wrapper-v3 {
  position: relative;
  .progress-steps {
    position: relative;
    height: 130px;
    overflow-x: hidden;
    .line {
      background-color: whitesmoke;
      // border-radius: 100px;
      // height: 3px;
      // border: 0;
      // margin: 0;
      // position: absolute;
      // top: 50%;
      // transform: translateY(-50%);
      // left: 0;
      // display: none;
      border-radius: 100px;
      height: 3px;
      border: 0;
      margin: 0;
      position: absolute;
      top: 15%;
      transform: translateY(-50%);
      left: 25%;
      // right: 15%;
      width: 45%;

      // &:last-child(){
      //   width: 0;
      // }
    }
    .step-list {
      width: 100%;
      margin: 0 auto;
      width: 90%;
      display: flex;
      justify-content: center;
      position: relative;

      // &:last-child{
      //   width: 0;
      //   border: 2px solid red;
      // }
    }
    .step {
      width: 25%;
      flex-shrink: 0;
      // display: inline-block;
      // position: absolute;
      // top: 50%;
      // transform: translateY(-50%);
      // z-index: 0;
      .step-num {
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 3px solid #37bdb0;
        background: #151517;
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .step-title {
        // position: absolute;
        // top: 100%;
        // left: 50%;
        // transform: translateX(-50%);
      }
    }
    .active {
      background-color: #37bdb0;
    }
  }
  @media only screen and (max-width: 1800px) {
    .progress-steps .step .step-num {
      width: 45px;
      height: 45px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .progress-steps .step .step-num {
      width: 40px;
      height: 40px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .progress-steps .step .step-num {
      width: 35px;
      height: 35px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .progress-steps .line {
      height: 2px;
    }
    .progress-steps .step .step-num {
      width: 30px;
      height: 30px;
    }
  }
  @media only screen and (max-width: 600px) {
    .progress-steps {
      height: 100px;
    }

    .progress-steps .line {
      left: 25% !important;
      width: 50% !important;
    }

    .progress-steps .step .step-title {
      display: none;
    }

    .progress-steps .step-list {
      width: 100%;
    }
  }
}

/*   **************************************************** */

.progress-steps-component-wrapper-v4 {
  position: relative;
  .progress-steps {
    position: relative;
    height: 130px;
    overflow-x: hidden;
    .line {
      background-color: whitesmoke;

      border-radius: 100px;
      height: 3px;
      border: 0;
      margin: 0;
      position: absolute;
      top: 18%;
      transform: translateY(-50%);
      left: 15%;
      width: 70%;
    }
    .step-list {
      width: 100%;
      margin: 0 auto;
      width: 90%;
      display: flex;
      justify-content: center;
      position: relative;

      // &:last-child{
      //   width: 0;
      //   border: 2px solid red;
      // }
    }
    .step {
      width: 25%;
      flex-shrink: 0;
      // display: inline-block;
      // position: absolute;
      // top: 50%;
      // transform: translateY(-50%);
      // z-index: 0;
      .step-num {
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 3px solid #37bdb0;
        background: #151517;
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .step-title {
        // position: absolute;
        // top: 100%;
        // left: 50%;
        // transform: translateX(-50%);
      }
    }
    .active {
      background-color: #37bdb0;
    }
  }
  @media only screen and (max-width: 1800px) {
    .progress-steps .step .step-num {
      width: 45px;
      height: 45px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .progress-steps .step .step-num {
      width: 40px;
      height: 40px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .progress-steps .step .step-num {
      width: 35px;
      height: 35px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .progress-steps .line {
      height: 2px;
    }
    .progress-steps .step .step-num {
      width: 30px;
      height: 30px;
    }
  }
  @media only screen and (max-width: 600px) {
    .progress-steps {
      height: 100px;
    }

    .progress-steps .line {
      left: 10% !important;
      width: 75% !important;
    }

    .progress-steps .step .step-title {
      display: none;
    }

    .progress-steps .step-list {
      width: 100%;
    }
  }
}

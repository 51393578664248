.advertisment-wrapper {
  padding: 0 2rem;

  .heading-wrapper {
    margin-bottom: 15px;
    position: relative;
    z-index: 10;

    .register-wrapper {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .link-security {
        text-align: right;
      }
    }

    .heading {
      text-align: center;
    }
  }
}

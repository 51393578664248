.tenat-detail-view-modal-wrapper {
  p {
    margin: 0;
  }
  .close-button {
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .custom-modal-body {
    padding: 50px 30px 30px;
  }
  .right {
    width: 100%;
    .wrapper {
      border-radius: 10px;
      padding: 2.5%;
    }
  }

  .custom_row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    .key {
      width: 42%;
    }
    .val {
      width: 56%;
    }
    margin-bottom: 20px;
    &:last-child {
      margin: 0;
    }
  }
  .approve-request {
    margin-top: 30px;
    margin-left: auto;
    display: flex;
    width: 40%;
    gap: 10%;
    button {
      width: 100%;
    }
  }
  .view {
    height: 30px;
  }
  @media only screen and (max-width: 1800px) {
    .view {
      height: 28px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .view {
      height: 26px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .view {
      height: 24px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .details-content {
      display: block;
      .left {
        width: 100%;
        margin-bottom: 30px;
        .profile_pic {
          padding-bottom: 35%;
          width: 35%;
        }
      }
      .right {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .view {
      height: 22px;
    }
  }
  @media only screen and (max-width: 600px) {
    .details-content {
      .profile_pic {
        padding-bottom: 50%;
        width: 50%;
      }
    }

    .custom-modal-body {
      padding: 50px 15px 30px;
    }
    .custom_row .val {
      width: 100%;
    }
    .custom_row .key {
      width: 100%;
    }
    .right .wrapper {
      padding: 10px;
    }
    .approve-request {
      width: 100%;
    }
  }
}

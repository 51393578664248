.security-incident-table {
  // margin-top: 0.5rem;

  // Status
  .open {
    color: #00cd3e;
  }

  .close {
    color: #ff1e1e;
  }
  .need-more {
    color: #ffef27;
  }

  .resolve {
    color: #37bdb0;
  }
  .pending {
    color: #ffef27;
  }

  tbody {
    position: relative;
    .new-label {
      position: absolute;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      background: #00cd3e;
      margin-top: 15px;
      margin-right: 20px;
    }
  }

  .action-button {
    height: 30px;
    margin-left: 20px;
  }
}

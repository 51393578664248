.visitor-log-book-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .visitor-log-book-header {
    text-align: center;
  }

  .visitor-report-wrapper {
    .visitor-detail-box {
      margin-top: 20px;
      border-radius: 10px;
      padding: 15px;
      background-color: #262626;
      .visitor-unit-detail-box {
        margin: 15px 35px;
        // color: white;

        .log-header {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 20px;

          .img-wrapper {
            // padding: 20px;

            // background: #151517;

            img {
              width: 100px;
              height: 85px;
              object-fit: cover;
              border-radius: 10px;
            }
          }

          .detail {
            padding: 10px;

            .num {
              margin-bottom: 10px;
            }

            p {
              // color: #505050;
              margin-top: 10px;

              span {
                margin-left: 10px;
              }
            }
          }
        }

        .visitor-info {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;

          // div {
          //   p {
          //     // color: #505050;
          //   }
          // }

          .car-info {
            p {
              // color: #505050;

              span {
                color: #37bdb0;
              }
            }
          }
        }

        .time-info {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 85px;

          div {
            p {
              // color: #505050;
            }
          }
        }

        .notes {
          p {
            // color: #505050;
          }
        }

        .id-proof {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 75px;

          // p {
          //   color: #fff;
          // }

          .proofs {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            .proof-img {
              img {
                width: 85px;
                height: 85px;
                object-fit: cover;
                border-radius: 10px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

// .html-editor {
//   //   .se-wrapper-wysiwyg {
//   //     .sun-editor-editable {
//   //       height: 400px !important;
//   //     }
//   //   }

//   .sun-editor .se-wrapper .se-wrapper-inner {
//     min-height: 400px !important;
//   }
// }

.text-main-wrapper {
  .sun-editor .se-toolbar {
    background-color: #8a8a8b;
    // color: #000000 !important;
  }

  .sun-editor {
    border: none;
  }

  .sun-editor .se-wrapper .se-wrapper-inner {
    min-height: 300px;
  }

  .sun-editor-editable {
    color: #fff;
    background-color: #000000;
  }

  .sun-editor .se-resizing-bar {
    display: none;
  }
}

.custom-text-btn {
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  &:hover {
    color: #ffffff;
  }
}

.otherTheme {
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  &:hover {
    color: #444444;
  }
}

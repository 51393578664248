.utility-doc-registration-modal {
  .modal-header-custom {
    margin-bottom: 30px;
    .icon-wrapper {
      img {
        width: 25%;
      }
    }
    h2 {
      font-weight: 600;
    }
  }
  .modal-body {
    padding: 8% 10%;
    border: 2px solid #939393;
  }
  .description {
    margin: 5% 0;
  }

  .modal-footer-custom {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
  .doc-wrapper {
    margin-bottom: 10px;
  }
  .error_text {
    padding-top: 5px;
    border-top: 2px solid #ff1e1e;
  }
  .doc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .default-state {
      border: 0 !important;
    }
    .on-edit {
      border: 2px solid #37bdb0 !important;
    }
    .input-field {
      width: 65%;
    }
    .actions {
      width: 30%;
      display: flex;
      justify-content: end;
    }
    .custom-form-control {
      width: 100%;
    }
    // input {
    //   width: 100%;
    //   display: block;
    //   background: transparent;
    //   border: 0;
    //   &:focus {
    //     border: 0;
    //     outline: 0;
    //   }
    // }
    // &.underline {
    //   border-bottom: 2px solid #939393;
    // }
  }
  .icon {
    width: 25px;
  }
  .remove {
    margin-left: 10px;
  }
  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
    .icon {
      width: 20px;
    }
  }
}

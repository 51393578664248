.property-item-component-wrapper {
  margin: 20px 20px 0 0;
  border-radius: 10px;
  padding: 4%;
  display: flex;
  justify-content: space-between;
  background-color: #262626;
  p {
    margin: 0;
  }
  .image {
    width: 40%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    .inner-img {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 10px;
    }
  }
  .details {
    width: 58%;
    .name,
    .address,
    .type,
    .owner,
    .missing {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .address {
      vertical-align: middle;
      .location-pin {
        height: 25px;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
      }
    }
    .hide {
      display: none;
    }
    .missing-section {
      border-top: 1px solid transparent;
      padding-top: 5px;
      margin-top: 5px;
      height: 90px;
      &.missing-available {
        border-top: 1px solid #ffc11c;
      }
      .missing {
        margin-bottom: 2px;
        span {
          vertical-align: middle;
        }
        .warning {
          height: 25px;
          vertical-align: middle;
        }
      }
    }
  }
  .warning {
    width: 6%;
  }
  .missing-header {
  }
  .address,
  .type,
  .owner {
    margin: 0;
  }
  .name {
    margin-bottom: 5px;
  }
  @media only screen and (max-width: 1800px) {
    .details .address .location-pin {
      height: 23px;
    }
    .details .missing-section {
      height: 85px;
    }
    .details .missing-section .missing .warning {
      height: 23px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .details .address .location-pin {
      height: 21px;
    }
    .details .missing-section {
      height: 80px;
    }
    .details .missing-section .missing .warning {
      height: 21px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .details .address .location-pin {
      height: 19px;
    }
    .details .missing-section {
      height: 75px;
    }
    .details .missing-section .missing .warning {
      height: 19px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .details .address .location-pin {
      height: 17px;
    }
    .details .missing-section {
      height: 65px;
    }
    .details .missing-section .missing .warning {
      height: 17px;
    }
  }
  @media only screen and (max-width: 600px) {
    padding: 20px;
    margin: 20px 0 0;
  }
}

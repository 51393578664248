.contractor-table {
  // margin-top: 0.5rem;

  .approved {
    color: #37bdb0;
  }

  .rejected {
    color: red;
  }

  .request {
    color: green;
  }
  .pending {
    color: #e7da20;
  }

  tbody {
    position: relative;
    .new-label {
      position: absolute;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      background: #00cd3e;
      margin-top: 15px;
      margin-right: 20px;
    }
  }

  .action-button {
    height: 30px;
    margin-left: 20px;
    color: #37bdb0;
  }
}

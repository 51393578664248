.review-tenant-application-wrapper {
  // Basic Details
  .basic-wrapper {
    .detail-box {
      padding: 10px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: auto;
      border-radius: 10px;
      .detail {
        margin: 10px 15px;
      }

      .noData {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .file-icon {
      width: 20px;
    }
    &.commercial-wrapper {
      margin-top: 15px;
    }
  }

  // Unit Details
  .unitDetail-wrapper {
    .heading-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }

    .detail-box {
      padding: 10px;
      background-color: #262626;

      border-radius: 10px;
      .heading {
        text-align: start;
        padding: 5px 20px;
        background-color: #151517;
        border-radius: 10px;
        width: 100%;
      }
      .basic {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
      }

      .detail {
        margin: 5px 20px;

        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 150px;
          gap: 10px;
          cursor: pointer;

          .approve {
            padding: 5px 10px;
            color: #37bdb0;
            border: 1px solid #37bdb0;
            border-radius: 10px;
          }
          .reject {
            padding: 5px 10px;
            color: red;
            border: 1px solid red;
            border-radius: 10px;
          }
        }
      }
    }
  }

  .file-icon {
    width: 20px;
  }
  // ID Verification
  .idVerify-wrapper {
    margin-top: 15px;
    .custom-table-wrapper {
      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  // Household Members
  .household-wrapper {
    margin-top: 10px;
    .detail-box {
      padding: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 10px;

      .header {
        text-align: start;
        padding: 5px 20px;
        background-color: #151517;
        border-radius: 10px;
        width: 100%;
      }

      .household {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
        border-radius: 10px;
        width: 100%;

        .household-detail {
          margin: 0 15px;
          padding: 5px;
        }
      }
    }
  }

  // Vehicle
  .vehicle-wrapper {
    margin-top: 15px;
    .custom-table-wrapper {
      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  // Pet
  .pet-wrapper {
    margin-top: 15px;
    .custom-table-wrapper {
      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  // Rent History
  .rent-wrapper {
    margin-top: 15px;
    .custom-table-wrapper {
      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  .accept-terms {
    margin-top: 15px;
  }

  .check {
    display: flex;
    align-items: center;
    p {
      margin: 0;
      margin-left: 10px;

      .termsText {
        color: #37bdb0;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    img {
      width: 25px;
    }
  }
}

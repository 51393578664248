.annoucement-table-wrapper {
  margin-top: 1rem;
  padding-top: 1rem;
  width: 100%;

  tbody {
    position: relative;

    .main-desc {
      white-space: nowrap;
      width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .new-label {
      position: absolute;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      background: #00cd3e;
      margin-top: 15px;
      margin-right: 20px;
    }
  }

  .action-button {
    height: 30px;
  }
}

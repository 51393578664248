.edit-unit-module-wrapper {
  .heading {
    margin-bottom: 30px;
  }

  .unit-num {
    margin-bottom: 30px;
  }

  .form-wrapper {
    background-color: #262626;
    padding: 3%;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
  }

  .button-wrapper {
    margin-top: 30px;
    .custom-text-btn {
      margin-top: 20px;
    }
  }

  .form-group {
    width: 49%;
    margin-bottom: 20px;
  }

  .form-group-flex {
    display: flex;
    justify-content: space-between;
    .form-group-inner {
      width: 49%;
    }
  }

  .form-group-full {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .custom-form-control {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

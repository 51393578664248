.add-role-under-owner-wrapper {
    padding-bottom: 100px;
    .form-wrapper {
      background-color: #262626;
      padding: 3%;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
    }
    .button-wrapper {
      margin-top: 30px;
      .custom-text-btn {
        margin-top: 20px;
      }
    }
  
    .form-group {
      width: 49%;
      margin-bottom: 20px;
    }
    .form-group-full {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .custom-radio-component-wrapper {
        margin-right: 50px;
      }
    }
    @media only screen and (max-width: 1800px) {
    }
    @media only screen and (max-width: 1600px) {
    }
    @media only screen and (max-width: 1400px) {
    }
    @media only screen and (max-width: 1200px) {
    }
    @media only screen and (max-width: 900px) {
    }
    @media only screen and (max-width: 600px) {
      .form-group {
        width: 100%;
      }
    }
  }
  
.transaction-status-wrapper-main {
  // position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 3%;
  .icon {
    padding: 30px 0;
    img {
      width: 10%;
      min-width: 70px;
    }
  }
  .details {
    padding: 20px 0;
  }
  .button-wrapper {
    margin: 0 !important;
    button {
      width: 20%;
      min-width: 100px;
    }    
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

.calender-wrapper {
  // border: 1px solid red;
  //   .fc .fc-toolbar {
  //     // display: none;
  //   }

  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: 2em;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    // /* margin-right: 2px; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid yellow;
    background: #45e4eb;
  }
  .fc-daygrid-event-dot {
    display: none;
  }

  .fc-daygrid-dot-event .fc-event-title {
    flex-grow: 1;
    flex-shrink: 1;
    font-weight: 700;
    min-width: 0px;
    overflow: hidden;
    /* border: 1px solid red; */
    border-radius: 10px;
    // border: 1px solid #45e4eb;
    background: white;
    color: black;
    padding: 5px 10px;
  }
}

.tenant-detail-page-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  // width: 100%;
  // height: 100vh;

  .header {
    margin-bottom: 10px;
    // position: fixed;
    // width: 100%;
    // text-align: center;
    // background-color: #151517;
    // position: sticky;
    // width: 100%;
  }

  // Details Page
  .detail-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    background-color: #262626;

    .main-data-wrapper {
      display: flex;
      flex-direction: column;

      .tenant-unit-detail {
        padding: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #5a5a5a;

        .unit-data{
          width: 25%;
        }

      }

      .tenant-profile-wrapper {
        display: flex;
        // flex-direction: row;
        align-items: center;
        justify-content: space-between;
        

        .profile-wrapper {
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: 30px;
          gap: 10px;
          flex: 1;

          img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .basic-wrapper {
          padding: 25px 25px 0 0;
          flex: 2;

          .detail-box {
            padding: 10px;
            display: grid;
            grid-template-columns: repeat(4, max-content) ;
            grid-column-gap: 35px;

            .detail {
              margin: 10px;
            }
          }
        }
      }
    }
  }

  // Unit Details
  .unit-detail-wrapper {
    width: 100%;
    margin-top: 25px;

    .unit-detail-data {
      border: 1px solid green;
      border-radius: 10px;
      padding: 10px 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 5px;
      height: 75px;

      // .title-header {
      //   justify-content: space-between;
      //   // border-bottom: 1px solid #5a5a5a;
      //   padding-bottom: 20px;
      //   margin-bottom: 20px;
      //   .left {
      //     width: 29%;
      //   }
      //   .right {
      //     width: 69%;
      //     justify-content: flex-end;
      //     .ammenties {
      //       width: 69%;
      //       margin-right: 10px;
      //     }
      //     .unitName {
      //       // width: 29%;
      //     }
      //   }
      // }
    }
  }

  .detail-table-wrapper {
    margin: 20px 0 10px 0;
    width: 100%;
  }

  @media only screen and (max-width: 1800px) {
    .detail-wrapper {
      .basic-wrapper {
        .detail-box {
          display: grid;
          grid-template-columns: max-content max-content max-content max-content;
          grid-column-gap: 125px;
        }
      }
    }
  }
  @media only screen and (max-width: 1600px) {
    .detail-wrapper {
      .basic-wrapper {
        .detail-box {
          display: grid;
          grid-template-columns: max-content max-content max-content max-content;
          grid-column-gap: 100px;
        }
      }
    }
  }
  @media only screen and (max-width: 1400px) {
    .detail-wrapper {
      .basic-wrapper {
        .detail-box {
          display: grid;
          grid-template-columns: max-content max-content max-content max-content;
          grid-column-gap: 75px;
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .detail-wrapper {
      .basic-wrapper {
        .detail-box {
          display: grid;
          grid-template-columns: max-content max-content max-content !important;
          grid-column-gap: 50px;
        }
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .detail-wrapper {
      .basic-wrapper {
        flex: 2;
        .detail-box {
          display: grid;
          grid-template-columns: max-content max-content !important;
          grid-column-gap: 30px;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .detail-wrapper {
      .main-data-wrapper {
        .tenant-profile-wrapper {
          display: flex;
          flex-direction: column;
          .basic-wrapper {
            .detail-box {
              display: grid;
              grid-template-columns: max-content max-content !important;
              grid-column-gap: 15px;
            }
          }
        }
      }
    }
  }
}

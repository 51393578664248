.add-unit-module-wrapper {
  position: relative;
  z-index: 10;
  .heading {
    margin-bottom: 30px;
  }

  .form-wrapper {
    background-color: #262626;
    padding: 3%;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
  }

  .button-wrapper {
    margin-top: 30px;
    .custom-text-btn {
      margin-top: 20px;
    }
  }

  .warning-wrapper-text {
    border-radius: 10px;
    margin-top: 30px;
    padding: 3%;
    display: flex;
    align-items: flex-start;
    .icon {
      width: 3%;
      min-width: 20px;
      flex-shrink: 0;
      position: relative;
      top: 3px;
    }
    p {
      padding-left: 10px;
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

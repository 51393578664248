.found-details-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .header {
    text-align: center;
  }

  .report-wrapper {
    .detail-box {
      margin-top: 10px;
      border-radius: 10px;
      padding: 10px;
      background-color: #262626;
      .unit-detail-box {
        margin: 1rem 3rem;
        .title {
          margin-bottom: 15px;
          color: #848484;
        }

        .report-date {
          // border: 1x solid red;
          // display: flex;
          flex-direction: row;
          gap: 35px;
        }

        .date-wrapper {
          display: flex;
          // align-items: center;
          // justify-content: space-between;
          flex-direction: row;

          // gap: 35px;

          .detail {
            padding-top: 10px;

            p {
              color: #848484;
              font-size: 14px;
              span {
                font-size: 14px;
              }
            }
          }
        }

        .image-wrapper {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 15px;

          .proof-img {
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
              width: 75px;
              height: 75px;
              object-fit: cover;
              border-radius: 10px;
              cursor: pointer;
            }
          }

          .undefined-img {
            display: flex;
            align-items: center;
            img {
              padding: 5px;
              width: 75px;
              height: 75px;
              object-fit: cover;
              border-radius: 10px;
              background-color: #fff;
            }
          }
        }

        .foundBy-wrapper {
          display: flex;
          flex-direction: row;
          p {
            color: #848484;
            span {
              margin-left: 80px;

              font-size: 14px;
            }
          }
        }

        .ticket-wrapper {
          p {
            color: #848484;
            span {
              margin-left: 70px;

              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1800px) {
    .report-date {
      display: flex;
    }

    .date-wrapper {
      gap: 5rem;
    }

    .proofs {
      flex-direction: row;
      gap: 2rem;
    }

    .foundBy-wrapper p span {
      margin-left: 150px;

      font-size: 14px;
    }

    //   .font_l {
    //     font-size: 28px;
    // }
    //   .font_m {
    //     font-size: 24px;
    // }
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .date-wrapper {
      flex-direction: row;
      gap: 30px;
    }
  }
  @media only screen and (max-width: 600px) {
    .date-wrapper {
      flex-wrap: wrap;
    }

    .foundBy-wrapper {
      flex-direction: column;
    }

    .proofs {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 15px;
    }
  }
}

.search-account-wrapper-main {
  .button-wrapper {
    margin: 30px 0;
    button {
    //   width: 30%;
    //   min-width: 100px;
    }
  }
  .search-result {
    padding: 2%;
    border-radius: 10px;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
    .search-result {
      padding: 10px;
    }
  }
}

.tenant-invoicing-report-component-wrapper {
  // TENANT-DETAIL-CARD
  .detail-card {
    padding: 5%;
    border-radius: 10px;
    background-color: #262626;

    .title {
      margin-bottom: 12px;
    }

    .property-wrapper {
      display: flex;
      flex-direction: column;

      //   gap: 117px;

      .property-data {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 25px;
          height: 25px;
        }
      }
    }

    .item-data {
      display: flex;
      // align-items: center;
      flex-direction: row;
      gap: 195px;
    }

    .invoice-data {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 165px;
    }

    .status-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 210px;
    }

    .invoice-data-price {
      display: flex;
      // align-items: center;
      flex-direction: row;
      gap: 175px;
    }

    .spareParts-wrapper {
      border-radius: 10px;

      .sparePart {
        padding: 15px 25px;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }

    .service-data {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 165px;
    }

    .doc-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 200px;

      .doc {
        img {
          width: 35px;
          height: 35px;
          cursor: pointer;
        }
      }
    }

    .payment-data {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;

      display: flex;
      flex-direction: column;

      .trans-table {
        border-radius: 10px 15px 0 15px;
        background-color: #151517;
        padding: 10px;

        .action-button {
          margin-right: 10px;
          width: 20px;
        }
      }

      .pay-data {
        background-color: #151517;
        padding: 1rem;
        border-radius: 10px;
      }
    }

    .payment-trans-data {
      display: flex;
      flex-direction: column;

      .trans-table {
        border-radius: 10px 15px 0 15px;
        background-color: #151517;
        padding: 10px;

        .action-button {
          width: 20px;
        }
      }
    }

    .note-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 230px;
    }

    .receipt-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 195px;

      .doc {
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .payment-method-check {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;

    img {
      width: 75px;
      height: 75px;
    }
  }

  .transaction-status-wrapper-main {
    position: relative;
    height: auto;
  }

  // PARTIES-DETAIL-CARD
  // .parties-detail-card {
  //   padding: 5%;
  //   border-radius: 10px;

  //   .title {
  //     margin-bottom: 12px;
  //   }

  //   .property-wrapper {
  //     display: flex;
  //     flex-direction: column;

  //     .property-data {
  //       display: flex;
  //       align-items: center;
  //       justify-content: space-between;

  //       img {
  //         width: 25px;
  //         height: 25px;
  //       }
  //     }
  //   }

  //   .item-data {
  //     display: flex;
  //     align-items: center;
  //     flex-direction: row;
  //     gap: 175px;
  //   }

  //   .invoice-data {
  //     display: flex;
  //     align-items: center;
  //     flex-direction: row;
  //     gap: 165px;
  //   }

  //   .status-wrapper {
  //     display: flex;
  //     align-items: center;
  //     flex-direction: row;
  //     gap: 220px;
  //   }

  //   .spareParts-wrapper {
  //     border-radius: 10px;
  //     margin-bottom: 15px;

  //     .sparePart-details {
  //       padding: 20px 35px;
  //       display: flex;
  //       flex-direction: column;

  //       .sparePart {
  //         display: flex;
  //         align-items: center;
  //         justify-content: space-between;

  //         .icon {
  //           // rotate: 90deg;
  //           cursor: pointer;
  //         }
  //       }
  //     }
  //   }

  //   .service-data {
  //     display: flex;
  //     align-items: center;
  //     flex-direction: row;
  //     gap: 165px;
  //   }

  //   .doc-wrapper {
  //     display: flex;
  //     align-items: center;
  //     flex-direction: row;
  //     gap: 165px;

  //     .doc {
  //       img {
  //         width: 35px;
  //         height: 35px;
  //       }
  //     }
  //   }

  //   .payment-data {
  //     display: flex;
  //     align-items: center;
  //     flex-direction: row;
  //     gap: 195px;
  //   }

  //   .note-wrapper {
  //     display: flex;
  //     align-items: center;
  //     flex-direction: row;
  //     gap: 230px;
  //   }

  //   .receipt-wrapper {
  //     display: flex;
  //     align-items: center;
  //     flex-direction: row;
  //     gap: 195px;

  //     .doc {
  //       img {
  //         width: 30px;
  //         height: 30px;
  //       }
  //     }
  //   }
  // }
}

.license-overview-detail-component-wrapper {
  display: flex;
  flex-wrap: wrap;
  p {
    margin: 0;
  }
  .box {
    width: 32.666%;
    border-radius: 10px;
    margin-right: 1%;
    padding: 2%;
    background-color: #262626;

    // .num{
    //   color: #37bdb0;
    // }

    &:last-child {
      margin: 0;
    }
    .title {
      margin-bottom: 10px;
    }
    .plan {
      border-radius: 4px;
      padding: 3px 6px;
      background-color: #151517;
    }
    .footer-wrapper {
      margin-top: 20px;
    }
    .button-wrapper {
      display: flex;
      gap: 2%;
      button {
        width: 49%;
      }
    }
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
    .box {
      width: 49%;
      margin-right: 2%;
      margin-bottom: 2%;
      padding: 25px;
      &:nth-child(2) {
        margin-right: 0;
      }
    }
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
    .box {
      width: 100%;
      margin-bottom: 20px;
      margin: 0 0 20px;
      padding: 20px;
    }
  }
}

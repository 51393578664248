.utility-registration-form-wrapper {
  .form-wrapper {
    .txtBtn {
      margin-left: 87%;
      color: #37bdb0;
      cursor: pointer;
    }
    .form-group {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: row;
      gap: 30px;
      margin-top: 10px;

      div {
        width: 100%;
      }
    }
  }

  .action-button {
    margin: 0;
    .remove {
      outline: none;
      color: red;
      background: transparent;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

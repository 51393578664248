.custom-form-control-2 {
  input {
    display: block;
    width: 100%;
    border: 0;
    background-color: transparent;
    &:focus {
      outline: none;
      border: 2px solid #37bdb0;
    }
  }
}

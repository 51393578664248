.tab-button-component-wrapper {
  margin-top: 15px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 10;
  .disabled.active {
    background-color: rgb(136, 136, 136) !important;
  }
  .disabled.sub-active {
    border-bottom: 3px solid rgb(136, 136, 136) !important;
  }
  button {
    // background-color: #262626;
    background: inherit;
    width: 225px;
    padding: 7px;
    border: none;
    border-radius: 10px;
    // border-top-left-radius: 10px;
    color: #939393;
    margin-bottom: 5px;
  }
  .active {
    background-color: #37bdb0;
    color: white;
  }

  .sub-active {
    color: white;
    border-radius: 0 !important;
    border-bottom: 3px solid #37bdb0;
  }

  // Designing Tabs Buttons
  .manage-tenants-btn {
    background-color: inherit;
    width: 175px;
    padding: 7px;
    // border: 1px solid #939393;
    border: none;
    border-radius: 10px;
    color: #939393;
    margin-bottom: 10px;
  }
  .active {
    background-color: #37bdb0;
    color: white;
  }

  // Designing Tabs Buttons
  .detail-tab-btn {
    background-color: inherit;
    max-width: 187px;
    padding: 7px;
    border: none;
    border-radius: 10px;
    color: #939393;
    margin-bottom: 10px;
  }
  .active {
    background-color: #37bdb0;
    color: white;
  }

  .invoicing_sub_tab {
    background-color: inherit;
    border: none;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    color: #939393;
    margin-bottom: 3px;
    padding: 7px;
    width: 200px;
  }

  .sub-active {
    color: white;
    border-bottom: 3px solid #37bdb0;
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

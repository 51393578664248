.edit-community-page-wrapper {
  .header {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
  }
  .option-button {
    display: flex;
    align-items: center;
  }
  .option-button img {
    width: 30px;
  }
  @media only screen and (max-width: 1800px) {
    .option-button img {
      width: 28px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .option-button img {
      width: 26px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .option-button img {
      width: 24px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .option-button img {
      width: 22px;
    }
  }
  @media only screen and (max-width: 600px) {
  }
}

.or-under-owner-table-module-wrapper {
  .custom-table-wrapper {
    // padding: 0;

    // TESTING
    .col-different1 {
      width: 50px;
    }

    .col-different2 {
      width: 175px;
    }

    .col-different3 {
      width: 75px;

      // margin-right: 10px;
    }

    .col-different4 {
      width: 100px;
    }

    .action-btn {
      height: 30px;

      // &:last-child{
      //   margin-left: 15px;
      // }
    }
  }

  .not-added-yet-employee {
    padding: 10px 20px;
    h3 {
      border-radius: 10px;
      padding: 30px 0;
    }
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

.routine-dashboard-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 2rem 3rem;
  position: relative;

  .action-wrapper {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    top: 0;

    div {
      cursor: pointer;

      img {
        width: 25px;
        height: 25px;
        padding-right: 5px;
      }
    }
  }

  // .header {
  //   position: fixed;
  //   width: 100%;
  //   text-align: center;
  //   background-color: #151517;
  //   z-index: 1;

  //   h2 {
  //     padding: 15px;
  //   }
  // }

  .report-chart-wrapper {
    width: 100%;
    // margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .detail-box {
      width: 100%;
      margin-top: 20px;
      border-radius: 10px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      background-color: #262626;
    }
  }

  // .search-box {
  //   width: 100%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   gap: 20px;

  //   .search {
  //     width: 35%;
  //   }

  //   .date {
  //     width: 23%;
  //   }

  //   .btn-wrapper {
  //     width: 20%;
  //     display: flex;
  //     align-items: center;
  //     // justify-content: space-between;
  //     gap: 10px;

  //     button {
  //       width: 50%;
  //       padding: 20px 50px;
  //       border: none;
  //       border-radius: 10px;
  //       cursor: pointer;
  //       background-color: #37bdb0;
  //     }

  //     .btn-clear {
  //       width: 50%;
  //       padding: 20px 50px;
  //       border: 2px solid #262626;
  //       border-radius: 10px;
  //       cursor: pointer;
  //       background-color: transparent;
  //     }
  //   }
  // }

  .search-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .search {
      width: 60%;
    }

    .date {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 40%;
      gap: 10px;

      div {
        width: 100%;
      }
    }

    .btn-wrapper {
      width: 20%;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 10px;

      button {
        width: 50%;
        padding: 20px 50px;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        background-color: #37bdb0;
      }

      .btn-clear {
        width: 50%;
        padding: 20px 50px;
        border: 2px solid #262626;
        border-radius: 10px;
        cursor: pointer;
        background-color: transparent;
      }
    }
  }

  .routine-table-wrapper {
    width: 100%;
    .routine-table {
      .complete {
        color: #00cd3e;
      }

      .in-complete {
        color: #ffef27;
      }

      .action-button {
        height: 30px;
      }
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
    .report-chart-wrapper {
      justify-content: space-between;

      .detail-box {
        width: 50%;
      }
    }

    .search-box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      .search {
        width: 100%;
      }

      .date {
        width: 100%;
      }
      .btn-wrapper {
        width: 100%;
        gap: 5px;
        button {
          padding: 3px 25px;
        }

        .btn-clear {
          padding: 3px 25px;
        }
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .report-chart-wrapper {
      flex-direction: column;

      .detail-box {
        width: 100%;
      }
    }

    .search-box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      .search {
        width: 100%;
      }

      .date {
        width: 100%;
      }
      .btn-wrapper {
        width: 100%;
        gap: 5px;
        button {
          padding: 3px 25px;
        }

        .btn-clear {
          padding: 3px 25px;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .report-chart-wrapper {
      flex-direction: column;

      .detail-box {
        width: 100%;
      }
    }
    .search-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .search {
        width: 100%;
      }

      .date {
        width: 100%;
      }

      .btn-wrapper {
        width: 100%;
        gap: 5px;

        button {
          padding: 1px 12px;
        }

        .btn-clear {
          padding: 1px 12px;
        }
      }
    }
  }
}

.success-modal-wrapper-main {
  .modal-header-custom {
    .icon-wrapper {
      display: flex;
      justify-content: center;
      .circle {
        border: 7px solid #37bdb0;
        width: 20%;
        padding: 3%;
        border-radius: 50%;
      }
      img {
        width: 100%;
      }
    }
    h2 {
      font-weight: 600;
      margin: 20px 0 0;
    }
    .description {
      margin: 3% 0;
    }
  }

  .otp-wrapper {
    display: flex;
    justify-content: center;
    .otp {
      width: 100%;
      justify-content: center;
    }
    .seperator {
      display: inline-block;
      width: 20px;
    }
    .otp-digit {
      input {
        background: #000000;
        border: 0;
        width: 100% !important;
        color: #ffffff !important;
        font-weight: 600;
        border-radius: 10px;
        padding: 20px 0;
        width: 60px !important;
        &:focus {
          outline: none;
          box-shadow: 0px 0px 4px 2px #37bdb0;
        }
      }
    }

    .otherTheme {
      input {
        background: #f1ffff;
        border: 0;
        width: 100% !important;
        color: #444444 !important;
        font-weight: 600;
        border-radius: 10px;
        padding: 20px 0;
        width: 60px !important;
        &:focus {
          outline: none;
          box-shadow: 0px 0px 4px 2px #37bdb0;
        }
      }
    }
  }

  .resend-wrapper {
    margin: 3% 0 0;
    .custom-text-btn {
      font-weight: 600;
    }
  }

  .modal-body {
    padding: 7% 7%;
    border: 2px solid #939393;
  }

  .verify-loader {
    margin: 3% 0 0;
    font-weight: 500;
  }

  .button-wrapper {
    margin: 5% 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    button {
      width: 45%;
    }
  }
  .btn_grey_custom {
    background-color: #444444;
  }

  @media only screen and (max-width: 1200px) {
    .otp-wrapper .otp-digit input {
      width: 50px !important;
    }
  }

  @media only screen and (max-width: 900px) {
    .resend-wrapper,
    .button-wrapper,
    .verify-loader {
      margin-top: 30px;
    }
  }

  @media only screen and (max-width: 600px) {
    .otp-wrapper .otp-digit input {
      border-radius: 5px;
      padding: 10px 0;
      width: 30px !important;
    }

    .otp-wrapper .seperator {
      width: 10px;
    }

    .modal-header-custom .description {
      margin: 20px 0;
    }

    .resend-wrapper {
      margin: 20px 0;
    }
  }
}

.checkbox-multi-select-dropdown-wrapper {
  position: relative;
  p {
    margin: 0;
  }
  .back-drop {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
  }
  .drop-menu {
    position: absolute;
    top: 100%;
    left: -0%;
    z-index: 2;
    background-color: #262626;
    border: 2px solid #3d3d3d;
    width: max-content;
    // width: 100%;
    padding: 15px;
    border-radius: 10px;
    .drop-item {
      padding: 5px 0;
      span {
        padding: 0 0 0 10px;
        color: #979797;
      }
    }
  }
  .main-label {
    cursor: pointer;
  }
  .down_icon {
    transform: rotate(90deg);
    width: 25px;
    margin-left: 5px;
  }

  @media only screen and (max-width: 1800px) {
    .down_icon {
      width: 23px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .down_icon {
      width: 21px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .down_icon {
      width: 19px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .down_icon {
      width: 17px;
    }
  }
  @media only screen and (max-width: 600px) {
  }
}

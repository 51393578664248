.property-setting-module-wrapper {
  .mb_1 {
    margin-bottom: 10px;
  }
  .mb_2 {
    margin-bottom: 15px;
  }
  .mb_3 {
    margin-bottom: 20px;
  }
  .mt_1 {
    margin-top: 10px;
  }
  .mt_2 {
    margin-top: 15px;
  }
  .mt_3 {
    margin-top: 20px;
  }
  .mr_1{margin-right: 10px;}
  .mr_2{margin-right: 15px;}
  .mr_3{margin-right: 20px;}

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .mb_1 {
      margin-bottom: 5px;
    }
    .mb_2 {
      margin-bottom: 10px;
    }
    .mb_3 {
      margin-bottom: 15px;
    }
    .mt_1 {
      margin-top: 5px;
    }
    .mt_2 {
      margin-top: 10px;
    }
    .mt_3 {
      margin-top: 15px;
    }
    .mt_3-900 {
      margin-top: 15px;
    }
  }
  @media only screen and (max-width: 600px) {
  }
}

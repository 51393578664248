.visitor-access-pass-info-wrapper {
  padding: 50px 0 80px;
  p {
    margin: 0;
  }
  .component-header {
    margin-bottom: 50px;
  }
  .custom-card {
    padding: 6%;
    border-radius: 10px;
    .custom-card-header {
      .main-logo-wrapper {
        img {
        }
      }
    }
  }

  .otherTheme {
    background-color: #ffffff;
    color: #444444;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .custom-card-body {
    .custom-image-wrapper {
      width: 100%;
    }
    .square-img {
      width: 225px;
      height: 225px;
    }
    .divider {
      width: 100%;
      border: 0;
      background-color: #636363;
      height: 2px;
      margin: 3% 0;
    }
    .divider.top {
      margin: 3% 0 6%;
    }
    .divider.bottom {
      margin: 3% 0 6%;
    }
    .custom-row {
      display: flex;
      justify-content: space-between;
      .custom-column {
        width: 48%;
      }
    }
    .key-value-row {
      .key {
      }
      .value {
      }
    }
    .row-1 {
      .custom-column {
      }
      .propety-name {
      }
      .unit-name {
      }
    }

    .row-2 {
      .custom-column {
      }
      .visitor-name {
        .type {
          margin-top: 10px;
        }
      }
      .visitor-photo {
        display: flex;
        justify-content: flex-end;
      }
    }

    .row-3 {
      .custom-column {
      }
      .guest-type {
      }
      .reason {
      }
    }

    .row-3 {
      .custom-column {
      }
      .valid-from {
      }
      .valid-till {
      }
    }

   .row-6{
    display: flex;align-items: center;
    justify-content: center;
   }

  }
  .custom-card-footer {
    .created-at {
      margin-top: 5%;
      padding: 1.5% 2%;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      .label {
        width: 48%;
      }
      .value {
        width: 48%;
      }
    }
  }
  .btn-icon {
    height: 30px;
    margin-right: 20px;
  }
  .action-btn-wrapper {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

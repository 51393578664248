.manage-license-module-wrapper {
  .heading {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .property-license-slider-component-wrapper,
  .community-license-slider-component-wrapper {
    margin-top: 50px;
  }
  
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
    .community-properties .community-name-button {
      padding: 20px 30px;
    }
  }
  @media only screen and (max-width: 900px) {
    .community-properties .community-name-button {
      border-radius: 7px;
    }
  }
  @media only screen and (max-width: 600px) {
    .community-properties .community-name-button {
      padding: 10px 15px;
    }
  }
}

.dashboard-page-wrapper {
  .options {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    p {
      margin: 0;
    }
    .box {
      padding: 2%;
      width: 15%;
      border-radius: 10px;
      border: 3px solid #939393;
      margin-top: 2%;
      position: relative;
    }
    .text {
      margin-top: 10%;
    }
    .icon {
      height: 50px;

      //  img .tenant-main{
      //     width: 50px;
      //   }
    }
  }

  .divider {
    margin-top: 3rem;
    border: 2px solid #939393;
  }

  .future-options {
    // margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    p {
      margin: 0;
    }
    .box {
      padding: 2%;
      width: 15%;
      border-radius: 10px;
      border: 3px solid #939393;
      margin-top: 2%;
      position: relative;
    }
    .text {
      margin-top: 10%;
    }
    .icon {
      height: 50px;

      //  img .tenant-main{
      //     width: 50px;
      //   }
    }
  }

  .other-detail {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .grid_full {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    p {
      margin: 0 0 5px;
    }
  }
  @media only screen and (max-width: 1800px) {
    .options .icon {
      height: 45px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .options .icon {
      height: 40px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .options .icon {
      height: 35px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .options {
      gap: 3%;
      .box {
        width: 22.75%;
        margin-top: 3%;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .options {
      gap: 27.53px;
      .box {
        padding: 20px;
        width: 30%;
        margin: 0;
      }
    }
    .other-detail {
      display: block;
    }
    .options .icon {
      height: 30px;
    }
    .detail-box {
      .image {
        width: 40%;
        padding-top: 22%;
      }
      .details {
        width: 58%;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .options {
      display: block;
      .box {
        padding: 20px;
        width: 100%;
        max-width: 200px;
        margin: 30px auto 0;
      }
    }
    .detail-box {
      display: block;
      .image {
        width: 100%;
        padding-top: 56.25%;
      }
      .details {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}

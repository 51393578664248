.cost-covered-by-modal-wrapper {
  .modal-header-custom {
    .icon-wrapper {
      img {
        width: 25%;
      }
    }
    h2 {
      font-weight: 600;
    }
  }
  .modal-body-custom {
    margin-top: 30px;
  }
  .modal-body {
    padding: 5% 7%;
    border: 2px solid #939393;
  }
  .description {
    margin: 5% 0;
  }

  .modal-footer-custom {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
  .radio-group {
    margin-top: 20px;
  }
  .please-select-text {
  }
  .generate-invoice-group {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid lightgray;
  }
  .form-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .form-group {
    width: 49%;
  }
  .form-group-full {
    width: 100%;
  }
  .generate-invoice-heading {
    margin-bottom: 30px;
  }
  .quotation-description {
    margin: 20px 0;
  }
  .tenant-details {
    width: 100%;
    margin: 20px 0;
    p {
      margin: 0;
    }
    .custom-line {
      display: flex;
      margin-bottom: 5px;
      .label {
        width: 25%;
      }
      .value {
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
  }
}

.installment-plan-detail-modal-wrapper {
  p {
    margin: 0;
  }
  .modal-header-custom {
    // padding: 20px 0;
    .icon-wrapper {
      img {
        width: 25%;
      }
    }
    h2 {
      font-weight: 600;
    }
  }
  .modal-body {
    position: relative;
    padding: 8% 4%;
    border: 2px solid #939393;
  }
  .modal-body-custom {
    margin-top: 4%;

    // .detail {
    //   background-color: #df0d0d !important;
    //   //
    // }
  }

  .otherTheme {
    background-color: #f1ffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .table-responsive {
      background-color: #f1ffff;

      * {
        color: #444444;
      }

      table > thead {
        background-color: #ffffff;
      }
    }
  }

  .custom-table-wrapper {
    // border-bottom-left-radius: 0;
    // border-bottom-right-radius: 0;
    .install {
      &:nth-child(even) {
        background-color: #151517;
        border-radius: 10px;
        margin: 5px 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
  .view {
    height: 25px;
  }
  .more-details {
    // display: grid;
    // grid-template-columns: repeat(3, 250px);
    display: flex;
    flex-direction: column;

    .more-data {
      display: flex;
      justify-content: space-between;
    }
    p {
      margin: 0;
    }
    .c-row {
      display: flex;
      margin-bottom: 10px;
      // justify-content: space-around;
      width: 375px;
      .key {
        width: 50%;
      }
    }
  }

  .modal-footer-custom {
    display: flex;
    justify-content: center;
    button {
      width: 50%;
    }
  }
  .close-icon-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .close-icon-img {
    width: 30px;
  }
  .view-inv-btn {
    width: 25px;
  }

  .view-inv-btn1{
    width: 18px;
  }
  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
    .view-inv-btn {
      width: 20px;
    }
  }
}

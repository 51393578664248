.accountant-moveout-clearance-wrapper {
  .section {
    margin-bottom: 30px;
  }
  .doc-images-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 20px;
    .doc-images {
      height: 150px;
    }
  }
  .file-icon {
    width: 25px;
  }
  .clearence-file-icon {
    width: 30px;
  }
  .show-clearence-box {
    padding: 3%;
    border-radius: 10px;
  }
  .clearence-status {
    margin-top: 30px;
  }
  .create-inv-wrapper {
    margin-bottom: 30px;
  }
  .btn-wrapper {
    display: flex;
    gap: 2%;
  }
  .icon-md {
    width: 30px;
  }
  .status-wrapper {
    margin-top: 30px;
  }
  .unit-status-heading {
    margin-top: 30px;
  }

  .action-button {
    height: 25px;
  }

  .causes_wrapper {
    border-radius: 10px;
    padding: 20px;
    margin: 50px 0 30px;
    h3,
    p {
      margin: 0;
    }

    .causes_list {
      width: 100%;
      display: grid;
      grid-template-columns: auto auto auto;
      // gap: 10px;
      .causes_list_item {
        margin: 20px 0 0 0;
        display: flex;
        justify-content: start;
        align-items: center;

        img {
          width: 30px;
          height: 30px;
        }

        p {
          padding-left: 10px;
        }
      }
    }
    .icon-sm {
      min-width: 20px;
      width: 2%;
    }
  }

  .installmentDetail {
    .more-details {
      display: flex;
      flex-direction: column;

      .more-data {
        display: flex;

        justify-content: space-between;
      }

      .more-data-duration {
        display: flex;
        flex-direction: column;
      }
      p {
        margin: 0;
      }
      .c-row {
        display: flex;
        margin-bottom: 10px;
        // justify-content: space-around;
        width: 375px;
        .key {
          width: 50%;
        }
      }
    }
  }

  // Rent History
  .rent-wrapper {
    margin-top: 20px;

    .rent-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }

    .custom-table-wrapper {
      img {
        width: 25px;
        height: 25px;
      }

      .action-button {
        height: 30px;
        margin-top: 6px;
      }

      .action {
        .btn-wrapper {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          // width: 150px;
          gap: 10px;
          cursor: pointer;

          .approve {
            padding: 5px 10px;
            color: #37bdb0;
            border: 1px solid #37bdb0;
            border-radius: 10px;
          }
          .reject {
            padding: 5px 10px;
            color: red;
            border: 1px solid red;
            border-radius: 10px;
          }
        }
      }
    }
    .txt-nowrap {
      white-space: nowrap;
    }
  }

  // Rent Details

  .rent-detail-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label {
      padding-left: 20px;
    }
    .time {
      color: rgb(145, 143, 143);
    }
  }

  .custom-table-wrapper {
    // border-bottom-left-radius: 0;
    // border-bottom-right-radius: 0;
    .install {
      &:nth-child(even) {
        background-color: #151517;
        border-radius: 10px;
        margin: 5px 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    // .install {
    //   &:nth-child(even) {
    //     background-color: #151517;
    //     border-radius: 10px;
    //     margin: 5px 0;
    //     border-bottom-left-radius: 10px;
    //     border-bottom-right-radius: 10px;
    //   }
    // }
    .action {
      .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 150px;
        gap: 10px;
        cursor: pointer;

        .approve {
          padding: 5px 10px;
          color: #37bdb0;
          border: 1px solid #37bdb0;
          border-radius: 10px;
        }
        .reject {
          padding: 5px 10px;
          color: red;
          border: 1px solid red;
          border-radius: 10px;
        }
      }
    }
  }
  .button-wrapper {
    width: 100%;
    margin: auto;
    margin-top: 20px;
    // border-bottom-left-radius: 7px;
    // border-bottom-right-radius: 7px;
    // padding: 3%;
  }
  .amount-column-wrapper {
    width: 300px;
  }
  .date-column-wrapper {
    width: 300px;
    .custom-date-input-main-wrapper-v2 {
      width: 100%;
      .datepicker {
        width: 100%;
        .react-calendar {
          width: 300px;
        }
      }
    }
  }

  @media only screen and (max-width: 1800px) {
    .icon-md {
      width: 27px;
    }
    .file-icon {
      width: 23px;
    }
    .clearence-file-icon {
      width: 27px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .icon-md {
      width: 24px;
    }
    .file-icon {
      width: 21px;
    }
    .clearence-file-icon {
      width: 24px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .icon-md {
      width: 21px;
    }
    .file-icon {
      width: 19px;
    }
    .clearence-file-icon {
      width: 21px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .icon-md {
      width: 18px;
    }
    .file-icon {
      width: 17px;
    }
    .clearence-file-icon {
      width: 19px;
    }
  }
  @media only screen and (max-width: 600px) {
  }
}

.application-rejection-modal-wrapper {
  .modal-header-custom {
    margin-bottom: 40px;
    .icon-wrapper {
      img {
        width: 19%;
      }
    }
    h2 {
      font-weight: 600;
    }
  }
  .modal-body {
    padding: 8% 10%;
  }
  .description {
    margin: 5% 0;
  }

  .modal-footer-custom {
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }

  .note-wrapper {
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 900px) {
    .modal-header-custom {
      margin-bottom: 20px;
    }

    .description {
      margin: 20px 0;
    }

    .modal-footer-custom {
      flex-direction: column-reverse;
      button {
        margin-top: 20px;
        width: 100%;
      }
    }

    .note-wrapper {
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
  }
}

.cheque-detail-modal-wrapper {
  p {
    margin: 0;
  }

  .modal-body {
    // padding: 8% 10%;
    border: 2px solid #939393;
  }

  .custom-mocal-header {
    padding: 30px 0;
  }
  .radiolist {
    padding: 30px;
    display: flex;
    justify-content: space-between;
    .radio-group {
      width: 49%;
    }
    .r-button {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      p {
        margin-left: 10px;
      }
    }
  }
  .form-wrapper {
    padding: 0 30px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-group {
      width: 49%;
      margin-bottom: 15px;
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 30px 30px;
    .custom-btn {
      width: 49%;
    }
  }
  .checkbox-text {
    padding-left: 10px;
  }
  .form-group-full {
    width: 100% !important;
  }
}

.community-detail-page-wrapper {
  .header {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .community-action {
      .option-button {
        width: 10%;
        margin-left: 25px;
        min-width: 25px;

        .opt {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 5px;

          img {
            height: 20px;
          }
        }
      }
    }
  }
  p {
    margin-bottom: 5px;
  }

  .community-wrap {
    border-radius: 12px;
  }

  .detail-box {
    border-radius: 10px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    .image {
      position: relative;
      width: 28%;
      padding-top: 16.875%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      // border-radius: 10px;
      height: 0;
      .image-inner {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 12px;
      }
    }
    .details {
      width: 69%;
      // margin-left: 2rem;
    }
  }

  .description {
    margin-top: 20px;
  }

  .location {
    width: 30px;
  }
  .units-licenses {
    display: flex;
  }

  .eye {
    height: 25px;
  }

  .options {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    // width: 69%;
    // float: right;
    gap: 2%;
    p {
      margin: 0;
    }
    .box {
      padding: 2%;
      width: 18%;
      border-radius: 10px;
      border: 3px solid #939393;
      margin-top: 2%;
      position: relative;
      .missing {
        position: absolute;
        top: 5%;
        right: 5%;
        width: 15%;
      }
    }
    .text {
      margin-top: 10%;
    }
    .icon {
      height: 50px;
    }
  }

  .future-options {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    // width: 69%;
    // float: right;
    gap: 2%;
    p {
      margin: 0;
    }
    .box {
      padding: 2%;
      width: 18%;
      border-radius: 10px;
      border: 3px solid #939393;
      margin-top: 2%;
      position: relative;
      .missing {
        position: absolute;
        top: 5%;
        right: 5%;
        width: 15%;
      }
    }
    .text {
      margin-top: 10%;
    }
    .icon {
      height: 50px;
    }
  }

  .other-detail {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;
    .grid_full {
      grid-column-start: 1;
      grid-column-end: 4;
    }
    p {
      margin: 0 0 5px;
    }
  }

  .licenses {
    margin-left: 2%;
  }
  .community-properties {
    margin-top: 50px;
  }
  .property-slider-heading {
    display: flex;
    justify-content: space-between;
  }
  @media only screen and (max-width: 1800px) {
    .location {
      width: 27px;
    }
    .options .icon {
      height: 45px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .location {
      width: 24px;
    }
    .options .icon {
      height: 40px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .location {
      width: 21px;
    }
    .options .icon {
      height: 35px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .options {
      gap: 3%;
      .box {
        width: 22.75%;
        margin-top: 3%;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .location {
      width: 19px;
    }
    .detail-box {
      .image {
        width: 40%;
        padding-top: 22%;
      }
      .details {
        width: 58%;
      }
    }
    .units-licenses {
      display: block;
      .licenses {
        margin: 0;
      }
    }
    .options {
      gap: 27.53px;
      .box {
        padding: 20px;
        width: 30%;
        margin: 0;
      }
    }

    .future-options {
      gap: 27.53px;
      .box {
        padding: 20px;
        width: 30%;
        margin: 0;
      }
    }

    .other-detail {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 15px;
      .grid_full {
        grid-column-start: 1;
        grid-column-end: 4;
      }
      p {
        margin: 0 0 5px;
      }
    }

    .options .icon {
      height: 30px;
    }
  }
  @media only screen and (max-width: 600px) {
    .header {
      .community-action {
        .option-button {
          width: 20%;
          margin-left: 50px;
          min-width: 20px;
        }
      }
    }

    .detail-box {
      display: block;
      .image {
        width: 100%;
        padding-top: 56.25%;
      }
      .details {
        margin-top: 20px;
        width: 100%;
      }
    }
    .options {
      display: block;
      .box {
        padding: 20px;
        width: 100%;
        max-width: 200px;
        margin: 30px auto 0;
      }
    }
    .future-options {
      display: block;
      .box {
        padding: 20px;
        width: 100%;
        max-width: 200px;
        margin: 30px auto 0;
      }
    }
  }
}

.security-complaint-report-component-wrapper {
  p {
    margin: 0;
  }
  .black-container-sm {
    padding: 1.5% 2%;
    border-radius: 10px;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .icon-sm {
    width: 25px;
  }
  .section-divider {
    border: 0;
    border-top: 3px solid #939393;
    width: 100%;
    height: 0;
    margin: 2% 0;
  }
  .detail-card {
    padding: 5%;
    border-radius: 10px;
    background-color: #262626;

    .main-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .action-button {
        height: 20px;
      }
    }

    .pair-section {
      display: flex;
      flex-wrap: wrap;
      .left {
        width: 35%;
      }
      .right {
        width: 50%;
      }
    }
    .summary {
      margin-top: 1%;
    }
    .ticket-unit {
      display: flex;
      justify-content: space-between;
      margin-top: 2%;
    }
    .section2 {
      display: flex;
      .category-column {
        width: calc(100% / 3);
      }
    }
    .section5 {
      .assigned {
        display: flex;
        justify-content: space-between;
        p {
          width: 48%;
        }
      }
    }
    .section8 {
      margin-top: 15px;

      .ratingAndReview {
        display: flex;
        flex-direction: column;

        .ticket-assign {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;

          p {
            flex: 1;
          }

          .assign {
            flex: 2;
          }
        }

        .ratings {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;

          .left {
            flex: 1;
          }

          .rate {
            flex: 2;
          }
        }

        .reviews {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          .left {
            flex: 1;
          }

          .assign {
            flex: 2;
          }
        }
      }
    }
    .docs-list {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
    }
    .media {
      cursor: pointer;
      width: 0;
      height: 0;
      position: relative;
      padding: 10%;
      margin-right: 6.66%;
      border-radius: 5px;
      &:last-child {
        margin: 0;
      }
      .thumbnail {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .timeline-list-wrapper {
    position: relative;
    .dot {
      display: block;
      width: 0;
      height: 0;
      padding: 2%;
      position: absolute;
      top: 30px;
      left: 2%;
      border-radius: 50%;
      z-index: 12;
      opacity: 10;
      background-color: #37bdb0;
      &::after {
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #000000;
        border-radius: 50%;
        height: 45%;
        width: 45%;
      }
    }
    .log-wrapper {
      .time-line {
        padding: 5%;
        padding-left: 8%;
        border-radius: 10px;
        margin: 20px 0;
        position: relative;
        display: flex;
        // flex-direction: row;
        width: 100%;
        background-color: #262626;

        .dot-wrapper {
          // flex: 1;
          .vl {
            position: absolute;
            border-left: 3px solid grey;
            margin-left: 16px;
            height: 115%;
            left: 15px;
            z-index: 10;
            opacity: 1;
          }
        }

        .detail-wrapper {
          flex: 2;

          .viewImg {
            width: 25px;
            height: 25px;
          }
        }
      }
      &:first-child .time-line {
        background-color: #0f2e2b;
      }

      &:last-child {
        .vl {
          height: unset !important;
        }
      }
    }
    .button-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
    .custom-btn {
      margin-top: 4%;
      width: 100%;
    }
    .log-footer {
    }
    .simple-log {
      .comments {
        margin-top: 20px;
      }

      .so-report-wrapper {
        margin-top: 10px;
        padding: 10px 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 10px;
        background-color: #000000;

        .report-doc-wrapper {
          margin: 0 0 10px 10px;
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 15px;

          .proof-img {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100px;
            height: 100px;

            .media {
              object-fit: cover;
              border-radius: 10px;
              cursor: pointer;
            }

            // img {
            //   width: 100px;
            //   height: 100px;
            //   object-fit: cover;
            //   border-radius: 10px;
            //   cursor: pointer;
            // }
          }
        }
      }

      .spare-parts-start-work-radio {
        width: 100%;
        .custom-radio-component-wrapper {
          &:last-child {
            // width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
    .title {
      margin-bottom: 3%;
    }
    .quotation-log,
    .spare-part-log {
      .part-list {
        padding: 30px;
        border-radius: 10px;
        background-color: #000000;
      }
    }
    .quotation-log,
    .task-log,
    .spare-part-log {
      hr {
        display: block;
        width: 100%;
        height: 2px;
        border: 0;
        background-color: #939393;
      }
      .sub-title {
        margin-bottom: 1.5%;
      }
      .complete-task-details {
        padding: 5%;
        border-radius: 10px;
        .row {
          display: flex;
          margin-bottom: 20px;
          justify-content: space-between;
          .key {
            width: 25%;
          }
          .value {
            width: 75%;
            .attachments {
              display: flex;
              .attachment-document-wrapper {
                width: 200px;
                height: 200px;
                margin-right: 20px;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
      .sub-title {
        margin: 3% 0 2%;
      }
      .flex-row-2 {
        display: flex;
        justify-content: space-between;
        .value {
          margin-top: 2px;
        }
        .c {
          width: 49%;
        }
      }
      .part {
        margin-bottom: 3%;
        padding-bottom: 3%;
        border-bottom: 2px solid #454545;
        .r {
          margin-top: 2%;
          .value {
            margin-top: 2px;
          }
        }
        .flex-row-3 {
          display: flex;
          justify-content: space-between;
          .c {
            width: 30%;
          }
        }
      }
      .total {
        display: flex;
        justify-content: space-between;
      }
      .value.button-wrapper {
        .custom-btn {
          margin: 0;
        }
      }
      .attachment-row {
        .value {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          .task-attachment {
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }

  // .security-report-timeline-list-wrapper {
  //   position: relative;
  //   .dot {
  //     display: block;
  //     width: 0;
  //     height: 0;
  //     padding: 2%;
  //     position: absolute;
  //     top: 30px;
  //     left: 2%;
  //     border-radius: 50%;
  //     z-index: 12;
  //     opacity: 10;
  //     background-color: #37bdb0;
  //     &::after {
  //       display: block;
  //       content: "";
  //       position: absolute;
  //       top: 50%;
  //       left: 50%;
  //       transform: translate(-50%, -50%);
  //       background-color: #000000;
  //       border-radius: 50%;
  //       height: 45%;
  //       width: 45%;
  //     }
  //   }
  //   .log-wrapper {
  //     .time-line {
  //       padding: 5%;
  //       padding-left: 8%;
  //       border-radius: 10px;
  //       margin: 20px 0;
  //       position: relative;
  //       display: flex;
  //       // flex-direction: row;
  //       width: 100%;
  //       background-color: #262626;

  //       .dot-wrapper {
  //         // flex: 1;
  //         .vl {
  //           position: absolute;
  //           border-left: 3px solid grey;
  //           margin-left: 16px;
  //           height: 115%;
  //           left: 15px;
  //           z-index: 10;
  //           opacity: 1;
  //         }
  //       }

  //       .detail-wrapper {
  //         flex: 2;
  //       }
  //     }
  //     &:first-child .time-line {
  //       background-color: #0f2e2b;
  //     }

  //     &:last-child {
  //       .vl {
  //         height: unset !important;
  //       }
  //     }
  //   }
  //   .button-wrapper {
  //     display: flex;
  //     justify-content: space-between;
  //     gap: 10px;
  //   }
  //   .custom-btn {
  //     margin-top: 4%;
  //     width: 100%;
  //   }
  //   .log-footer {
  //   }
  //   .simple-log {
  //     .comments {
  //       margin-top: 20px;
  //     }

  //     .currentStatus {
  //       display: flex;
  //       align-items: center;
  //       justify-content: space-between;
  //       margin-top: 20px;
  //       padding: 10px 15px;
  //       border-radius: 10px;

  //       .action-button {
  //         width: 30px;
  //         height: 30px;
  //       }
  //     }

  //     .so-report-wrapper {
  //       margin-top: 10px;
  //       padding: 10px 15px;
  //       display: flex;
  //       flex-direction: column;
  //       gap: 10px;
  //       border-radius: 10px;
  //       background-color: #000000;

  //       .report-doc-wrapper {
  //         margin: 0 0 10px 10px;
  //         display: flex;
  //         align-items: center;
  //         flex-direction: row;
  //         gap: 15px;

  //         .proof-img {
  //           display: flex;
  //           align-items: center;
  //           justify-content: space-between;
  //           width: 100px;
  //           height: 100px;

  //           // img {
  //           //   width: 100px;
  //           //   height: 100px;
  //           //   object-fit: cover;
  //           //   border-radius: 10px;
  //           //   cursor: pointer;
  //           // }

  //           .media {
  //             // width: 100px;
  //             // height: 100px;
  //             // object-fit: cover;
  //             border-radius: 10px;
  //             cursor: pointer;
  //           }
  //         }
  //       }
  //     }

  //     .btn-wrapper {
  //       width: 100%;
  //       display: flex;
  //       align-items: center;
  //       justify-content: flex-end;
  //       gap: 10px;
  //       margin-top: 10px;

  //       // button {
  //       //   padding: 10px 50px;
  //       //   cursor: pointer;
  //       //   background: #37bdb0;
  //       //   border-radius: 10px;
  //       //   outline: none;
  //       //   border: none;
  //       // }

  //       // .reject-btn {
  //       //   padding: 10px 50px;
  //       //   cursor: pointer;
  //       //   background: #ff1e1e;
  //       //   border-radius: 10px;
  //       //   outline: none;
  //       //   border: none;
  //       // }
  //     }

  //     .so-report-wrapper {
  //       display: flex;
  //       flex-direction: column;

  //       .report-doc-wrapper {
  //         margin: 0 0 10px 10px;
  //         display: flex;
  //         align-items: center;
  //         flex-direction: row;
  //         gap: 15px;

  //         .proof-img {
  //           display: flex;
  //           align-items: center;
  //           justify-content: space-between;
  //           width: 100px;
  //           height: 100px;

  //           .media {
  //             // object-fit: cover;
  //             border-radius: 10px;
  //             cursor: pointer;
  //           }
  //         }
  //       }
  //     }

  //     // SM - RESOLVE
  //     .sm-report-form {
  //       display: flex;
  //       flex-direction: column;
  //       width: 100%;
  //       gap: 10px;
  //       margin-top: 10px;
  //     }

  //     .form-wrapper {
  //       .select-reason {
  //         margin: 20px 0;
  //       }
  //     }

  //     .form-btn-wrapper {
  //       display: flex;
  //       justify-content: space-between;
  //       align-items: center;
  //       flex-direction: row;
  //       gap: 10px;
  //       padding: 10px;

  //       .btn1 {
  //         background: none;
  //         color: #37bdb0;
  //         border: 2px solid #37bdb0;
  //         border-radius: 10px;
  //       }

  //       button {
  //         &:disabled {
  //           background-color: #505050;
  //           cursor: not-allowed;
  //         }
  //       }
  //     }

  //     .remark-wrapper {
  //       padding: 20px 25px;
  //       display: flex;
  //       flex-direction: column;
  //       gap: 10px;
  //       border-radius: 10px;

  //       .task-descr {
  //         width: 100%;
  //         display: flex;
  //         justify-content: space-between;
  //         gap: 10px;

  //         .desc {
  //           width: 60%;
  //           padding: 0 20px;
  //         }
  //       }

  //       .uploaded-doc {
  //         width: 100%;
  //         display: flex;
  //         justify-content: space-between;
  //         gap: 10px;

  //         .doc-wrapper {
  //           width: 57%;
  //           display: flex;
  //           align-items: center;
  //           flex-direction: column;

  //           .doc {
  //             width: 100%;
  //             display: flex;
  //             align-items: center;
  //             flex-direction: row;
  //             gap: 10px;

  //             img {
  //               height: 20px;
  //               width: 20px;
  //               cursor: pointer;
  //             }
  //           }
  //         }
  //       }
  //     }

  //     .action-btns {
  //       margin: 15px 0;
  //       padding: 5px 0;
  //       width: 100%;
  //       display: flex;
  //       align-items: center;
  //       justify-content: space-between;
  //       gap: 25px;
  //     }
  //   }
  //   .title {
  //     margin-bottom: 3%;
  //   }
  //   .quotation-log,
  //   .spare-part-log {
  //     .part-list {
  //       padding: 30px;
  //       border-radius: 10px;
  //       background-color: #000000;
  //     }
  //   }
  //   .quotation-log,
  //   .task-log,
  //   .spare-part-log {
  //     hr {
  //       display: block;
  //       width: 100%;
  //       height: 2px;
  //       border: 0;
  //       background-color: #939393;
  //     }
  //     .sub-title {
  //       margin-bottom: 1.5%;
  //     }
  //     .complete-task-details {
  //       padding: 5%;
  //       border-radius: 10px;
  //       .row {
  //         display: flex;
  //         margin-bottom: 20px;
  //         justify-content: space-between;
  //         .key {
  //           width: 25%;
  //         }
  //         .value {
  //           width: 75%;
  //           .attachments {
  //             display: flex;
  //             .attachment-document-wrapper {
  //               width: 200px;
  //               height: 200px;
  //               margin-right: 20px;
  //               margin-bottom: 20px;
  //             }
  //           }
  //         }
  //       }
  //     }
  //     .sub-title {
  //       margin: 3% 0 2%;
  //     }
  //     .flex-row-2 {
  //       display: flex;
  //       justify-content: space-between;
  //       .value {
  //         margin-top: 2px;
  //       }
  //       .c {
  //         width: 49%;
  //       }
  //     }
  //     .part {
  //       margin-bottom: 3%;
  //       padding-bottom: 3%;
  //       border-bottom: 2px solid #454545;
  //       .r {
  //         margin-top: 2%;
  //         .value {
  //           margin-top: 2px;
  //         }
  //       }
  //       .flex-row-3 {
  //         display: flex;
  //         justify-content: space-between;
  //         .c {
  //           width: 30%;
  //         }
  //       }
  //     }
  //     .total {
  //       display: flex;
  //       justify-content: space-between;
  //     }
  //     .value.button-wrapper {
  //       .custom-btn {
  //         margin: 0;
  //       }
  //     }
  //     .attachment-row {
  //       .value {
  //         display: flex;
  //         flex-wrap: wrap;
  //         gap: 10px;
  //         .task-attachment {
  //           width: 100px;
  //           height: 100px;
  //         }
  //       }
  //     }
  //   }
  // }

  // .uric-p1-log {
  //   .uirc-detail {
  //     margin-top: 2%;
  //     .r {
  //       margin-top: 3%;
  //       &:first-child {
  //         margin-top: 0;
  //         padding-top: 0;
  //         border: 0;
  //       }
  //       display: flex;
  //       justify-content: space-between;
  //       .c1 {
  //         width: 69%;
  //         white-space: pre-line;
  //       }
  //       .c2 {
  //         width: 29%;
  //         .edit-section {
  //           display: flex;
  //           .custom-icon-btn {
  //             margin-left: 5%;
  //           }
  //         }
  //         .sm-btn-wrapper {
  //           margin-top: 4%;
  //           .custom-btn {
  //             width: 40%;
  //           }
  //         }
  //       }
  //     }
  //     .thumbnail-wrapper {
  //       width: 22%;
  //       height: 150px;
  //       .thumbnail {
  //         width: 100%;
  //         height: 100%;
  //       }
  //     }
  //   }
  // }

  @media only screen and (max-width: 1800px) {
    .icon-sm {
      width: 23px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .icon-sm {
      width: 21px;
    }
  }

  @media only screen and (max-width: 1400px) {
    .icon-sm {
      width: 19px;
    }
  }

  @media only screen and (max-width: 1200px) {
  }

  @media only screen and (max-width: 900px) {
    .icon-sm {
      width: 17px;
    }
    .detail-card {
      padding: 20px;
    }
    .section-divider {
      margin: 20px 0;
    }
    .detail-card .summary {
      margin-top: 10px;
    }
    .detail-card .ticket-unit {
      margin-top: 20px;
    }
    .detail-card .section2 {
      flex-wrap: wrap;
      .category-column {
        width: 50%;
        margin-bottom: 10px;
      }
    }
    .detail-card .media {
      padding: 80px;
      margin-right: 50px;
      margin-bottom: 20px !important;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    .detail-card .section8 {
      margin-top: 20px;
    }
  }

  @media only screen and (max-width: 600px) {
    .detail-card .pair-section {
      display: block;
      .left {
        width: 100%;
      }
      .right {
        width: 100%;
        margin-top: 10px;
      }
    }
    .detail-card .ticket-unit {
      display: block;
      .ticket-no,
      .unit-name {
        width: 100%;
      }
      .unit-name {
        margin-top: 7px;
      }
    }
    .detail-card .section2 .category-column {
      width: 100%;
    }
    .section5 {
      .assign,
      .reassign {
        text-align: start !important;
      }
    }

    // .internal-maintenance-detail-wrapper
    .security-report-timeline-list-wrapper
      .log-wrapper
      .time-line
      .dot-wrapper
      .vl {
      left: 7px;
      height: 115%;
      top: 35px;
    }
  }
}

.unit-table-filter-modal-wrapper {
  .modal-body {
    padding: 30px 50px;
  }
  .sub-status {
    display: flex;
    flex-wrap: wrap;
  }
  .status {
    display: flex;
    flex-wrap: wrap;
  }
  h2 {
    margin-bottom: 30px;
  }
  h3 {
    margin-top: 30px;
  }
  .filter-radio-btn {
    margin-right: 20px;
    margin-top: 10px;
  }
  .reset-btn {
    margin-top: 30px;
  }
  .button-wrapper {
    display: flex;
    gap: 5%;
    margin-top: 50px;
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
    .modal-body {
      padding: 30px 30px;
    }
  }
  @media only screen and (max-width: 900px) {
    .button-wrapper {
      margin-top: 30px;
    }
    .modal-body {
      padding: 30px 20px;
    }
  }
  @media only screen and (max-width: 600px) {
  }
}

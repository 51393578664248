.manage-teams-page-wrapper {
  .owner-table-module-wrapper,
  .pmc-table-module-wrapper,
  .fmc-table-module-wrapper,
  .smc-table-module-wrapper {
    margin-bottom: 50px;
  }
  .page-heading {
    margin-bottom: 50px;
  }
  .action-txt-btn {
    margin-left: 10px;
  }
  .missing-wrapper {
    background: #262626;
    position: relative;
    z-index: 5;
    border-radius: 10px;
    padding: 3%;
    margin-bottom: 50px;
    h3 {
      margin-bottom: 30px;
    }
    // .missing-list {
    //   background: transparent;
    //   list-style-type: none;
    .missing-item {
      display: flex;
      margin-bottom: 15px;
      align-items: center;
      * {
        color: #ffb900;
      }
      .icon-wrapper {
        width: 25px;
        margin-right: 10px;
      }
      .missing-role {
        margin: 0;
      }
    }
    // }
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

.security {
  padding: 1.5rem;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    position: relative;
    z-index: 10;
    // font-weight: 600;
    // text-align: center;

    // .link {
    //   text-align: right;
    // }
  }
}

.vacations-module-wrapper {
  padding-bottom: 100px;

  .add-new-form {
    .form-wrapper {
      background-color: #262626;
      padding: 3%;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
    }
    .button-wrapper {
      margin-top: 30px;
      .custom-text-btn {
        margin-top: 20px;
      }
    }

    .form-group {
      width: 49%;
      margin-bottom: 20px;
    }

    hr {
      width: 100%;
      display: block;
      height: 0;
      border: 1px solid rgba(211, 211, 211, 0.656);
      margin: 10px 0;
    }

    .form-group-full {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .custom-radio-component-wrapper {
        margin-right: 50px;
      }
      .custom-form-control {
        width: 100%;
      }
    }

    .holiday {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
    }

    .details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    @media only screen and (max-width: 1800px) {
    }
    @media only screen and (max-width: 1600px) {
    }
    @media only screen and (max-width: 1400px) {
    }
    @media only screen and (max-width: 1200px) {
    }
    @media only screen and (max-width: 900px) {
    }
    @media only screen and (max-width: 600px) {
      .form-group {
        width: 100%;
      }
    }
  }
}

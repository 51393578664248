.custom-dropdown {
  position: relative;
  .back-drop {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
  }
  .menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #ffffff;
    border-radius: 10px;
    z-index: 2;
    ul {
      list-style-type: none;
      padding: 10px 20px;   
      margin: 0;
      li {
        padding: 7px 0;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }
}

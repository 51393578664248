.cheque-status-change-modal-wrapper {
  .modal-header-custom {
    padding: 5px 10px;
  }
  .modal-body-custom {
    padding: 2% 5%;
  }

  .option {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    h3 {
      margin: 0;
    }
  }

  .form-wrapper {
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
  }

  .button-wrapper {
    margin-top: 30px;
    .custom-text-btn {
      margin-top: 20px;
    }
  }

  .form-group {
    width: 49%;
    margin-bottom: 20px;
    margin-top: 5px;
  }

  .otherTheme {
    .react-date-picker__inputGroup {
      * {
        color: #444444 !important;
      }
    }
  }

  .form-group-full {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;
    // flex-wrap: wrap;

    // .custom-radio-component-wrapper {
    //   margin-right: 50px;
    // }
    .custom-form-control {
      width: 100%;
    }
  }

  .note-wrapper {
    width: 100%;
  }

  .radio-btn {
    margin-left: 60px;
  }

  .modal-footer-custom {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    button {
      width: 48%;
    }
  }
}

.property-detail-page-wrapper {
  // .slider{
  //   border: 1px solid red;
  // }

  .header {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .property-action {
      .option-button {
        width: 5%;
        margin-left: 20px;
        min-width: 25px;
      }
    }
  }
  p {
    margin-bottom: 5px;
  }
  .detail-box {
    border-radius: 10px;
    padding: 3% 0;
    display: flex;
    justify-content: space-between;
    .image {
      width: 28%;
      padding-top: 16.875%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      //   // border-radius: 10px;
      height: 0;
      position: relative;
      // border-radius: 10px;

      // .slideshow-container {
      //   position: relative;
      //   // max-width: 50%;
      //   overflow: hidden;
      //   // width: 28%;
      //   // padding-top: 16.875%;
      //   background-repeat: no-repeat;
      //   background-position: center center;
      //   background-size: cover;
      //   // border-radius: 10px;
      //   // height: 0;
      //   // position: relative;

      //   .mySlides {
      //     display: none;

      //     img {
      //       width: 100%;

      //     }
      //   }
      // }

      .inner-img {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 12px;
      }
    }
    .details {
      width: 69%;
      // margin-left: 2rem;
    }
  }
  .description {
    margin-top: 20px;
  }
  .location {
    width: 30px;
  }
  .units-owner {
    display: flex;
  }
  .owner {
    margin-left: 2%;
  }
  .eye {
    height: 25px;
  }

  .options {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    // float: right;
    // width: 69%;
    gap: 2%;
    p {
      margin: 0;
    }
    .box {
      padding: 2%;
      width: 18%;
      border-radius: 10px;
      border: 3px solid #939393;
      margin-top: 2%;
      position: relative;
      .missing {
        position: absolute;
        top: 5%;
        right: 5%;
        width: 15%;
      }
    }
    .text {
      margin-top: 10%;
    }
    .icon {
      height: 50px;
    }
  }

  .divider {
    margin-top: 3rem;
    border: 2px solid #939393;
  }

  .future-options {
    // margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    p {
      margin: 0;
    }
    .box {
      padding: 2%;
      width: 18%;
      border-radius: 10px;
      border: 3px solid #939393;
      margin-top: 2%;
      position: relative;
      .missing {
        position: absolute;
        top: 5%;
        right: 5%;
        width: 15%;
      }
    }
    .text {
      margin-top: 10%;
    }
    .icon {
      height: 50px;
    }
  }

  .other-detail {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .grid_full {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    p {
      margin: 0 0 5px;
    }
  }
  @media only screen and (max-width: 1800px) {
    .location {
      width: 27px;
    }
    .eye {
      height: 23px;
    }
    .options .icon {
      height: 45px;
    }

    .future-options .icon {
      height: 45px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .location {
      width: 24px;
    }
    .eye {
      height: 21px;
    }
    .options .icon {
      height: 40px;
    }

    .future-options .icon {
      height: 40px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .location {
      width: 21px;
    }
    .eye {
      height: 19px;
    }
    .options .icon {
      height: 35px;
    }

    .future-options .icon {
      height: 35px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .options {
      gap: 3%;
      .box {
        width: 22.75%;
        margin-top: 3%;
      }
    }

    .future-options {
      gap: 3%;
      .box {
        width: 22.75%;
        margin-top: 3%;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .location {
      width: 19px;
    }
    .eye {
      height: 17px;
    }
    .options {
      gap: 27.53px;
      .box {
        padding: 20px;
        width: 30%;
        margin: 0;
      }
    }

    .future-options {
      gap: 27.53px;
      .box {
        padding: 20px;
        width: 30%;
        margin: 0;
      }
    }

    .other-detail {
      display: block;
    }
    .options .icon {
      height: 30px;
    }
    .detail-box {
      .image {
        width: 40%;
        padding-top: 22%;
      }
      .details {
        width: 58%;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .box {
      border-radius: 10px;
      padding: 20px;
    }
    .units-owner {
      display: block;
    }
    .owner {
      margin-left: 0;
    }
    .options {
      display: block;
      .box {
        padding: 20px;
        width: 100%;
        max-width: 200px;
        margin: 30px auto 0;
      }
    }

    .future-options {
      display: block;
      .box {
        padding: 20px;
        width: 100%;
        max-width: 200px;
        margin: 30px auto 0;
      }
    }

    .detail-box {
      display: block;
      .image {
        width: 100%;
        padding-top: 56.25%;
      }
      .details {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}

.editor-modal-wrapper {
  .modal-header-custom {
    padding: 5px;
  }
  .modal-body {
    padding: 5% 8%;
    border: 2px solid grey;
  }
  .modal-body-custom {
    .form-wrapper {
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
    }

    .button-wrapper {
      margin-top: 30px;

      .custom-text-btn {
        margin-top: 20px;
      }
    }

    .form-group {
      width: 49%;
      margin-bottom: 20px;
      margin-top: 5px;

      .add-image {
        margin-top: 20px;
      }

      .txtBtn {
        color: #37bdb0;
        margin: 10px;
        padding-right: 75px;
      }

      .action-button {
        display: inline-block;

        .remove {
          outline: none;
          color: red;
          background: transparent;
        }
      }
    }

    .form-group-full {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .custom-radio-component-wrapper {
        margin-right: 50px;
      }
      .custom-form-control {
        width: 100%;
      }
    }

    .otherTheme {
      .text-main-wrapper .sun-editor-editable {
        background: #f1ffff !important;
        color: #444444 !important;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
  }
}

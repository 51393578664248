.pdf-viewer-modal-wrapper {
  overflow-x: auto;
  padding-top: 70px;
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .react-pdf__Document {
    width: max-content;
    margin: 0 auto;
    margin-bottom: 100px;
  }
  .react-pdf__Page {
    margin-bottom: 20px;
  }
  .tool-btn-wrapper {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 70px;
    width: 15%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    padding: 1% 1.5% 0;
    justify-content: space-between;
    align-items: center;
    .tool-btn {
      width: 15%;
      cursor: pointer;
    }
  }
  .close {
    position: fixed ;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    width: 50px;
    padding: 13px;
    border-radius: 50%;
    // border-bottom-left-radius: 100px;
    // border-top-left-radius: 100px;
  } 
  @media only screen and (max-width: 1800px) {
    .tool-btn-wrapper {
      height: 60px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .tool-btn-wrapper {
      height: 50px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .tool-btn-wrapper {
      height: 40px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .tool-btn-wrapper {
      height: 40px;
      border-radius: 10px;
      padding: 10px;
      width: 150px;
    }
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

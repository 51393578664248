.time-select-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: 2px solid #37bdb0;
  border-radius: 10px;

  label {
    display: block;
    font-weight: 600;
    position: absolute;
    top: 17px;
    left: 22px;
    width: 100%;
  }

  .select-wrap {
    -webkit-appearance: none;
    display: block;
    width: 100%;
    border: 0;
    background-color: rgb(0,0,0);
    // padding: 42px 15px 15px 20px;
    padding: 42px 22px 17px;

    border-radius: 10px;
    outline: none;
  }
}

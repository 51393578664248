.add-management-type-wrapper-main {
  // padding-bottom: 75px;
  p,
  h4 {
    margin: 0;
  }
  .transparent {
    color: transparent;
  }
  .form-wrapper {
    padding: 2%;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
  }
  .description {
    margin: 20px 0;
  }
  .radio_wrapper {
    min-height: 230px;
  }

  .select-mgmt-model {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .button-wrapper {
    margin-top: 30px;
    .custom-text-btn {
      margin-top: 20px;
    }
  }

  .form-group {
    width: 49%;
    margin-bottom: 20px;
    background-color: #262626;
    padding: 3%;
    border-radius: 10px;
    // display: flex;
    flex-direction: column;
    gap: 1rem;
    // min-height: 275px;
  }
  .form-group-full {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .custom-radio-component-wrapper {
      margin-right: 50px;
    }
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

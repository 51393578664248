.contract-wrapper {
  position: relative;
  z-index: 10;
  .renew-wrapper {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid #ffef27;
    padding: 20px 30px;

    .other-detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      .action-button {
        height: 25px;
      }
    }
  }

  .contract-table-wrapper {
    margin-top: 1rem;

    .action-button {
      &:last-child {
      }
      height: 25px;
      margin-right: 10px;
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

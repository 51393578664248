.unitKey-module-wrapper {
  padding: 1.5% 2%;
  border-radius: 10px;
  background-color: #262626;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  .img-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      width: 125px;
      height: 125px;
    }

    p {
      margin: 20px 0;
      text-align: center;
    }
  }

  .unit-wrapper {
    padding: 1.5% 2%;
    border-radius: 10px;
    background-color: #151517;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
    .rejection-section {
      width: 100%;
      max-width: 500px;
      padding: 10px 20px;
      margin: 30px auto 0;
      .rejection-reason {
        margin-top: 20px;
      }
    }
    .attachments-heading {
      margin: 10px;
    }
    .file-grid-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      .file {
        height: 125px;
        width: max-content;
        padding: 8%;
      }
    }
  
  }

 

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

.details-wrapper {
  width: 80%;
  padding: 10px;

  // Property Details
  .property-wrapper {
    margin: 25px 0;
    .detail-box {
      padding: 10px;
      border-radius: 10px;
      background-color: #262626;

      .heading {
        margin-left: 15px;
      }

      .detail-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .location-wrapper {
          display: flex;
          flex-direction: column;

          margin-left: 15px;

          .location {
            padding-right: 50px;
            img {
              width: 25px;
              height: 25px;
            }
            span {
              padding-right: 15px;
            }
          }

          .unit-detail {
            display: flex;
            flex-direction: row;
            gap: 20px;
          }
        }
        .request {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 20px;
          margin-right: 15px;
        }
      }
    }
  }
  .commercial-wrapper {
    margin-top: 20px;
    .view-icon {
      width: 25px;
    }
  }

  // Basic Details
  .basic-wrapper,
  .commercial-wrapper {
    .detail-box {
      padding: 10px;
      background-color: #262626;

      border-radius: 10px;
      .heading {
        text-align: start;
        padding: 5px 20px;
        background-color: #151517;
        border-radius: 10px;
        width: 100%;
      }
      .basic {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
      }

      .detail {
        margin: 5px 20px;

        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 150px;
          gap: 10px;
          cursor: pointer;

          .approve {
            padding: 5px 10px;
            color: #37bdb0;
            border: 1px solid #37bdb0;
            border-radius: 10px;
          }
          .reject {
            padding: 5px 10px;
            color: red;
            border: 1px solid red;
            border-radius: 10px;
          }
        }
      }
    }
  }

  // ID Verification
  .idVerify-wrapper {
    margin-top: 20px;

    .heading-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }

    .custom-table-wrapper {
      img {
        width: 25px;
        height: 25px;
      }

      .view {
        color: #37bdb0;
        text-decoration: underline;
      }

      .action {
        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 150px;
          gap: 10px;
          cursor: pointer;

          .approve {
            padding: 5px 10px;
            color: #37bdb0;
            border: 1px solid #37bdb0;
            border-radius: 10px;
          }
          .reject {
            padding: 5px 10px;
            color: red;
            border: 1px solid red;
            border-radius: 10px;
          }
        }
      }
    }
  }

  // Household Members
  .household-wrapper {
    margin-top: 20px;

    .heading-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }
    .detail-box {
      padding: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 10px;

      .header {
        text-align: start;
        padding: 5px 20px;
        background-color: #151517;
        border-radius: 10px;
        width: 100%;
      }

      .household {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
        border-radius: 10px;
        width: 100%;

        .household-detail {
          margin: 5px 20px;

          p {
            img {
              width: 25px;
              height: 25px;
            }
            .view {
              color: #37bdb0;
              text-decoration: underline;
            }
          }

          .btn-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 150px;
            gap: 10px;
            cursor: pointer;

            .approve {
              padding: 5px 10px;
              color: #37bdb0;
              border: 1px solid #37bdb0;
              border-radius: 10px;
            }
            .reject {
              padding: 5px 10px;
              color: red;
              border: 1px solid red;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }

  // Vehicle
  .vehicle-wrapper {
    margin-top: 20px;

    .vehicle-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }

    .custom-table-wrapper {
      img {
        width: 25px;
        height: 25px;
      }
      .view {
        color: #37bdb0;
        text-decoration: underline;
      }

      .action {
        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 150px;
          gap: 10px;
          cursor: pointer;

          .approve {
            padding: 5px 10px;
            color: #37bdb0;
            border: 1px solid #37bdb0;
            border-radius: 10px;
          }
          .reject {
            padding: 5px 10px;
            color: red;
            border: 1px solid red;
            border-radius: 10px;
          }
        }
      }
    }
  }

  // Pet
  .pet-wrapper {
    margin-top: 20px;

    .pet-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }

    .custom-table-wrapper {
      img {
        width: 25px;
        height: 25px;
      }
      .view {
        color: #37bdb0;
        text-decoration: underline;
      }

      .action {
        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 150px;
          gap: 10px;
          cursor: pointer;

          .approve {
            padding: 5px 10px;
            color: #37bdb0;
            border: 1px solid #37bdb0;
            border-radius: 10px;
          }
          .reject {
            padding: 5px 10px;
            color: red;
            border: 1px solid red;
            border-radius: 10px;
          }
        }
      }
    }
  }

  // Rent History
  .rent-wrapper {
    margin-top: 20px;

    .rent-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }

    .custom-table-wrapper {
      img {
        width: 25px;
        height: 25px;
      }

      .action-button {
        height: 30px;
        margin-top: 6px;
      }

      .action {
        .btn-wrapper {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          // width: 150px;
          gap: 10px;
          cursor: pointer;

          .approve {
            padding: 5px 10px;
            color: #37bdb0;
            border: 1px solid #37bdb0;
            border-radius: 10px;
          }
          .reject {
            padding: 5px 10px;
            color: red;
            border: 1px solid red;
            border-radius: 10px;
          }
        }
      }
    }
    .txt-nowrap {
      white-space: nowrap;
    }
  }

  // Application Fee Status
  .application-wrapper {
    margin-top: 20px;

    .application-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }

    .custom-table-wrapper {
      .paid {
        color: #37bdb0;
      }
      .unpaid {
        color: red;
      }

      .action {
        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 150px;
          gap: 10px;
          cursor: pointer;

          .approve {
            padding: 5px 10px;
            color: #37bdb0;
            border: 1px solid #37bdb0;
            border-radius: 10px;
          }
          .reject {
            padding: 5px 10px;
            color: red;
            border: 1px solid red;
            border-radius: 10px;
          }
        }
      }
    }
  }

  // Create installment wrapper
  .rent-installment-wrapper {
    margin-top: 20px;
    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }
    .box {
      padding: 3%;
      border-radius: 10px;
    }
  }

  // Rent Details
  .rent-detail-wrapper {
    margin-top: 20px;

    .rent-detail-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }

    .custom-table-wrapper {
      // border-bottom-left-radius: 0;
      // border-bottom-right-radius: 0;
      .install {
        &:nth-child(even) {
          background-color: #151517;
          border-radius: 10px;
          margin: 5px 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
      .action {
        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 150px;
          gap: 10px;
          cursor: pointer;

          .approve {
            padding: 5px 10px;
            color: #37bdb0;
            border: 1px solid #37bdb0;
            border-radius: 10px;
          }
          .reject {
            padding: 5px 10px;
            color: red;
            border: 1px solid red;
            border-radius: 10px;
          }
        }
      }
    }
    .button-wrapper {
      width: 50%;
      margin: auto;
      // border-bottom-left-radius: 7px;
      // border-bottom-right-radius: 7px;
      // padding: 3%;
    }
  }

  // Initial Agreement
  .initial-agreement-wrapper {
    margin-top: 20px;

    .initial-agreement-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }

    .custom-table-wrapper {
      img {
        width: 25px;
        height: 25px;
      }
      .view {
        color: #37bdb0;
        text-decoration: underline;
      }

      .action {
        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 150px;
          gap: 10px;
          cursor: pointer;

          .approve {
            padding: 5px 10px;
            color: #37bdb0;
            border: 1px solid #37bdb0;
            border-radius: 10px;
          }
          .reject {
            padding: 5px 10px;
            color: red;
            border: 1px solid red;
            border-radius: 10px;
          }
        }
      }
    }
  }

  // Utility Details
  .utility-wrapper {
    margin-top: 20px;

    .utility-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }

    .custom-table-wrapper {
      img {
        width: 25px;
        height: 25px;
      }
      .view {
        color: #37bdb0;
        text-decoration: underline;
      }

      .action {
        .approve {
          color: green;
        }
        .reject {
          color: red;
        }
        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 150px;
          gap: 10px;
          cursor: pointer;

          .approve {
            padding: 5px 10px;
            color: #37bdb0;
            border: 1px solid #37bdb0;
            border-radius: 10px;
          }
          .reject {
            padding: 5px 10px;
            color: red;
            border: 1px solid red;
            border-radius: 10px;
          }
        }
      }
    }
  }

  // Contract Details
  .contract-wrapper {
    margin-top: 20px;
    position: relative;
    z-index: 10;

    .contract-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }

    .custom-table-wrapper {
      img {
        width: 25px;
        height: 25px;
      }
      .view {
        color: #37bdb0;
        text-decoration: underline;
      }

      .action {
        .approve {
          color: green;
        }
        .reject {
          color: red;
        }
        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 150px;
          gap: 10px;
          cursor: pointer;

          .approve {
            padding: 5px 10px;
            color: #37bdb0;
            border: 1px solid #37bdb0;
            border-radius: 10px;
          }
          .reject {
            padding: 5px 10px;
            color: red;
            border: 1px solid red;
            border-radius: 10px;
          }
        }
      }
    }
  }

  // Move In Details
  .move-wrapper {
    margin-top: 20px;

    .move-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }

    .custom-table-wrapper {
      .ticket {
        color: #37bdb0;
        text-decoration: underline;
        cursor: pointer;
      }
      img {
        width: 25px;
        height: 25px;
      }
      .view {
        color: #37bdb0;
        text-decoration: underline;
      }

      .status {
        color: goldenrod;
      }

      .action {
        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 150px;
          gap: 10px;
          cursor: pointer;

          .approve {
            padding: 5px 10px;
            color: #37bdb0;
            border: 1px solid #37bdb0;
            border-radius: 10px;
          }
          .reject {
            padding: 5px 10px;
            color: red;
            border: 1px solid red;
            border-radius: 10px;
          }
        }
      }
    }
  }

  // Keys Details
  .keys-wrapper {
    margin-top: 20px;

    .keys-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }

    .custom-table-wrapper {
      img {
        width: 25px;
        height: 25px;
      }
      .view {
        color: #37bdb0;
        text-decoration: underline;
      }

      // .action {
      .approve {
        color: #37bdb0;
      }

      .reject {
        color: red;
      }

      .action {
        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 150px;
          gap: 10px;
          cursor: pointer;

          .approve {
            padding: 5px 10px;
            color: #37bdb0;
            border: 1px solid #37bdb0;
            border-radius: 10px;
          }
          .reject {
            padding: 5px 10px;
            color: red;
            border: 1px solid red;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .btn-wrapper-lg {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    .custom-btn {
      width: 100%;
    }
  }
  .status-icon-wrapper {
    width: 5%;
    margin: 20px auto;
  }
  .action-btn {
    height: 30px;
  }
  .amt-input {
    width: max-content;
    display: inline-block;
  }
  .edit-input {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
  // .deposit-row {
  //   border-bottom: 2px solid #606060;
  // }
  .more-details-edit {
    margin-bottom: 20px;
    .more-details {
      // display: grid;
      // grid-template-columns: repeat(3, 250px);
      display: flex;
      flex-direction: column;

      .more-data {
        display: flex;
        // align-items: center;
        justify-content: space-between;
      }
      p {
        margin: 0;
      }
      .c-row {
        display: flex;
        margin-bottom: 10px;
        // justify-content: space-around;
        width: 300px;
        .key {
          width: 50%;
        }
      }
    }
    .edit-wrapper {
      margin-bottom: 20px;
      display: flex;
      align-items: flex-end;
      .installment-plan-edit-btn {
        margin-right: 10px;
      }
    }
  }
  .under-maintenance {
    margin: 30px 0;
  }
  .icon-m {
    width: 30px;
  }
  .initAgreementTemplate {
    margin-top: 30px;
    padding: 3% 3%;
    border-radius: 10px;
  }
  .installment-summ-wrapper {
    padding-left: 15px;
    width: 50%;
    margin-top: 5px;
    .custom-row {
      display: flex;
      .right {
        margin-left: 275px;
      }
    }
  }
  .force-move-out-btn {
    width: 50%;
  }
  .invitaion-action-btn-wrapper {
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    .custom-btn {
      width: 49%;
    }
  }

  .reject-label {
    padding-left: 30px;
  }

  .rejection-box {
    border-radius: 10px;
    background-color: #262626;
  }

  .rejection_invite-box {
    padding: 3%;
    border-radius: 10px;
    background-color: #262626;
  }
  .view-app-fee {
    width: 25px;
  }
  .pending-cheque-attach-text {
    margin: 30px 0;
  }
  .txt-inv-cancelled {
    background-color: #262626;
    padding: 1rem 2rem;
    border-radius: 10px;
  }
  .status-txt {
    margin-top: 30px;
    font-size: 24px;
  }
  .resend-utility-review-btn-wrapper {
    margin-top: 20px;
  }
  @media only screen and (max-width: 1800px) {
    .action-btn {
      height: 27px;
      margin-left: 10px;
    }
    .icon-m {
      width: 27px;
    }
  }
  .view-app-fee {
    width: 23px;
  }
  @media only screen and (max-width: 1600px) {
    .action-btn {
      height: 24px;
    }
    .icon-m {
      width: 24px;
    }
    .view-app-fee {
      width: 21px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .action-btn {
      height: 21px;
    }
    .icon-m {
      width: 21px;
    }
    .view-app-fee {
      width: 19px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .btn-wrapper-lg {
      margin-top: 20px;
    }
    .action-btn {
      height: 18px;
    }

    .basic {
      display: grid;
      grid-template-columns: repeat(2, 1fr) !important;
      grid-auto-rows: auto;
    }
    .icon-m {
      width: 19px;
    }

    .more-details {
      display: grid;
      grid-template-columns: repeat(2, 1fr) !important;

      .c-row {
        display: flex;
        margin-bottom: 10px;
        justify-content: unset !important;
        width: unset !important;
      }
    }
    .view-app-fee {
      width: 17px;
    }
  }
  @media only screen and (max-width: 600px) {
    .basic {
      display: grid;
      grid-template-columns: repeat(2, 1fr) !important;
      grid-auto-rows: auto;
    }

    .more-details {
      grid-template-columns: repeat(2, 1fr) !important;

      .c-row {
        display: flex;
        margin-bottom: 10px;
        justify-content: unset !important;
        width: unset !important;
      }
    }
  }
  .initiate-renewal-action {
    margin-top: 30px;
    .renewal-action {
      display: flex;
      justify-content: space-between;
      .custom-btn {
        width: 48%;
      }
    }
  }
  .rent-detail-wrapper {
    .button-wrapper {
      margin-top: 20px;
    }
  }
}

.contract-renewal-detail-wrapper {
  p {
    margin: 0;
  }
  .time {
    display: none;
  }
  .card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    border-radius: 10px;
    padding: 2%;
    background-color: #262626;
  }
  .card-wrapper {
    padding: 2%;
    border-radius: 10px;
    margin: 3% 0;
    background-color: #262626;
  }
  .card-heading {
    grid-column-start: 1;
    grid-column-end: 5;
    padding: 1%;
    border-radius: 10px;
    background-color: #151517;
  }
  .pair {
    margin: 5%;
  }
  .file-icon,
  .rent-detail-wrapper .action-btn,
  .initial-agreement-wrapper .custom-icon-btn {
    width: 25px;
    img {
      width: 100%;
    }
  }

  // Household Members
  .household-wrapper {
    margin-top: 20px;

    .heading-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }
    .detail-box {
      padding: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 10px;

      .header {
        text-align: start;
        padding: 5px 20px;
        background-color: #151517;
        border-radius: 10px;
        width: 100%;
      }

      .household {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
        border-radius: 10px;
        width: 100%;

        .household-detail {
          margin: 5px 20px;

          p {
            img {
              width: 25px;
              height: 25px;
            }
            .view {
              color: #37bdb0;
              text-decoration: underline;
            }
          }

          .btn-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 150px;
            gap: 10px;
            cursor: pointer;

            .approve {
              padding: 5px 10px;
              color: #37bdb0;
              border: 1px solid #37bdb0;
              border-radius: 10px;
            }
            .reject {
              padding: 5px 10px;
              color: red;
              border: 1px solid red;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }

  .section {
    margin: 3% 0;

    .unit-table {
      .action-button {
        height: 25px;
        // margin-left: 20px;
      }
    }
  }
  .action-btn-wrapper {
    white-space: nowrap;
    button {
      width: auto;
      height: auto;
      padding: 1% 6%;
      background: none;
      margin-right: 10%;
      border-radius: 7px;
    }
    .accept {
      border: 2px solid #00cd3e;
    }
    .reject {
      border: 2px solid #ff1e1e;
    }
  }
  .renewal-action {
    margin-top: 3%;
    display: flex;
  }
  .rent-detail-wrapper {
    .edit-wrapper {
      .installment-plan-edit-btn {
        margin-right: 1%;
      }
    }

    .install {
      &:nth-child(even) {
        background-color: #151517;
        border-radius: 10px;
        margin: 5px 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    .action {
      .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 150px;
        gap: 10px;
        cursor: pointer;

        .approve {
          padding: 5px 10px;
          color: #37bdb0;
          border: 1px solid #37bdb0;
          border-radius: 10px;
        }
        .reject {
          padding: 5px 10px;
          color: red;
          border: 1px solid red;
          border-radius: 10px;
        }
      }
    }
    // .more-details {
    //   margin: 1.5% 0;
    //   .c-row {
    //     display: flex;
    //     .value {
    //       margin-left: 10px;
    //     }
    //   }
    // }
    .more-details {
      // display: grid;
      // grid-template-columns: repeat(3, 250px);
      display: flex;
      flex-direction: column;

      .more-data {
        display: flex;
        // align-items: center;
        justify-content: space-between;
      }
      p {
        margin: 0;
      }
      .c-row {
        display: flex;
        margin-bottom: 10px;
        // justify-content: space-around;
        width: 375px;
        .key {
          width: 50% !important;
        }
      }
    }
  }

  .status-icon-wrapper {
    width: 5%;
    margin: 20px auto;
  }

  .revision {
    margin: 3% 0 0;
    p {
      margin: 0;
    }
    .box {
      margin-bottom: 15px;
    }
    .message-wrapper {
      padding: 30px;
      border-radius: 10px;
    }
    .message {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 30px;
    }
    .revision-action-btn-wrapper {
      button {
        padding: 0 20px;
      }
      .reject {
        margin-left: 10px;
      }
    }
  }
  .initial-agreement-wrapper {
    margin: 3% 0 0;
    .action {
      .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 150px;
        gap: 10px;
        cursor: pointer;

        .approve {
          padding: 5px 10px;
          color: #00cd3e;
          border: 1px solid #00cd3e;
          border-radius: 10px;
        }
        .reject {
          padding: 5px 10px;
          color: red;
          border: 1px solid red;
          border-radius: 10px;
        }
      }
    }
  }
  .btn-wrapper-lg {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    .custom-btn {
      width: 48%;
    }
  }
  .aprrove-contract-renewal-btn {
    margin-top: 30px;
  }

  // Contract Details
  .contract-wrapper {
    position: relative;
    z-index: 10;
    margin-top: 3%;

    .contract-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .time {
        color: rgb(145, 143, 143);
      }
    }
  }
  .icon-m {
    width: 30px;
  }
  .initAgreementTemplate {
    margin-top: 30px;
    padding: 3% 3%;
    border-radius: 10px;
  }
  // .installment-summ-wrapper {
  //   border-radius: 10px;

  //   margin-top: 10px;
  //   .custom-row {
  //     display: flex;
  //     .right {
  //       margin-left: 10px;
  //     }
  //   }
  // }
  .installment-summ-wrapper {
    padding-left: 15px;
    width: 60%;
    margin-top: 5px;
    .custom-row {
      display: flex;

      .left {
        width: 50%;
      }

      .right {
        margin-left: 0;
      }
    }
  }
  .moveout_text {
    margin: 30px 0;
  }
  .contract-renewal-status-wrapper {
    margin-top: 30px;
    width: 100%;
    .img-wrapper {
      max-width: 100px;
      margin: 0 auto;
      .icon-l {
        width: 100%;
      }
    }
    .status-text {
      margin-top: 20px;
    }
  }
  .renewal-final-step-wrapper {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    .custom-btn {
      width: 49%;
    }
    .btn_full {
      width: 100% !important;
    }
  }
  .cancel_renewal_wrapper {
    margin: 30px 0;
  }
  .causes_wrapper {
    border-radius: 10px;
    padding: 20px;
    margin: 50px 0 30px;
    h3,
    p {
      margin: 0;
    }

    .causes_list {
      width: 100%;
      display: grid;
      grid-template-columns: auto auto auto;
      // gap: 10px;
      .causes_list_item {
        margin: 20px 0 0 0;
        display: flex;
        justify-content: start;
        align-items: center;

        img {
          width: 30px;
          height: 30px;
        }

        p {
          padding-left: 10px;
        }
      }
    }
    .icon-sm {
      min-width: 20px;
      width: 2%;
    }
  }
  @media only screen and (max-width: 1800px) {
    .file-icon,
    .rent-detail-wrapper .action-btn,
    .initial-agreement-wrapper .custom-icon-btn {
      width: 23px;
    }
    .icon-m {
      width: 27px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .file-icon,
    .rent-detail-wrapper .action-btn,
    .initial-agreement-wrapper .custom-icon-btn {
      width: 21px;
    }
    .icon-m {
      width: 24px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .file-icon,
    .rent-detail-wrapper .action-btn,
    .initial-agreement-wrapper .custom-icon-btn {
      width: 19px;
    }
    .icon-m {
      width: 21px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .file-icon,
    .rent-detail-wrapper .action-btn,
    .initial-agreement-wrapper .custom-icon-btn {
      width: 17px;
    }
    .icon-m {
      width: 19px;
    }
  }

  @media only screen and (max-width: 600px) {
  }
}

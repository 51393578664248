.community-property-slider-license-module-wrapper {
  margin-top: 50px;
  .property-license-slider-component-wrapper {
    margin-top: 20px !important;
  }
  .community-name-button {
    padding: 2% 3%;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #262626;
  }

  .no-comm-prop {
    margin-top: 50px;
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

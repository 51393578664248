.maintenance-detail-component-wrapper {
  p {
    margin: 0;
  }
  .black-container-sm {
    padding: 1.5% 2%;
    border-radius: 10px;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .icon-sm {
    width: 25px;
  }
  .section-divider {
    border: 0;
    border-top: 3px solid #939393;
    width: 100%;
    height: 0;
    margin: 4% 0;
  }
  .detail-card {
    padding: 5%;
    border-radius: 10px;
    background-color: #262626;
    .pair-section {
      display: flex;
      flex-wrap: wrap;
      .left {
        width: 20%;
      }
      .right {
        width: 80%;
      }
    }
    .rejected-uirc-section {
      .left {
        width: 50%;
      }
      .right {
        width: 30%;
      }
    }
    .summary {
      margin-top: 1%;
    }
    .ticket-unit {
      display: flex;
      justify-content: space-between;
      margin-top: 2%;
    }
    .section2 {
      display: flex;
      .category-column {
        width: calc(100% / 3);
      }
    }
    .section5 {
      .assigned {
        display: flex;
        justify-content: space-between;
        p {
          width: 48%;
        }
      }
    }
    .section8 {
      margin-top: 30px;

      .ticket-assign {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: 20px;

        p {
          flex: 1;
        }

        .assign {
          flex: 2;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .ratings {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .left {
          flex: 1;
        }

        .rate {
          flex: 2;
        }
      }

      .reviews {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .left {
          flex: 1;
        }

        .assign {
          flex: 2;
        }
      }
    }
    .docs-list {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
    }
    .media {
      cursor: pointer;
      width: 0;
      height: 0;
      position: relative;
      padding: 10%;
      margin-right: 6.66%;
      border-radius: 5px;
      &:last-child {
        margin: 0;
      }
      .thumbnail {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .timeline-list-wrapper {
    position: relative;
    .dot {
      display: block;
      width: 0;
      height: 0;
      padding: 2%;
      position: absolute;
      top: 20px;
      left: 2%;
      border-radius: 50%;
      z-index: 12;
      opacity: 10;
      background-color: #37bdb0;
      &::after {
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #000000;
        border-radius: 50%;
        height: 45%;
        width: 45%;
      }
    }
    .log-wrapper {
      .time-line {
        padding: 5%;
        padding-left: 8%;
        border-radius: 10px;
        margin: 20px 0;
        position: relative;
        display: flex;
        // flex-direction: row;
        width: 100%;
        background-color: #262626;

        .dot-wrapper {
          // flex: 1;
          .vl {
            position: absolute;
            border-left: 3px solid grey;
            margin-left: 16px;
            height: 105%;
            left: 17px;
            z-index: 10;
            opacity: 1;
          }
        }

        .detail-wrapper {
          flex: 2;

          .viewImg {
            width: 30px;
            height: 30px;
          }
        }
      }
      &:first-child .time-line {
        background-color: #0f2e2b;
      }

      &:last-child {
        .vl {
          height: unset !important;
        }
      }
    }
    .button-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
    .custom-btn {
      margin-top: 4%;
      width: 100%;
    }
    .log-footer {
    }
    .simple-log {
      .comments {
        margin-top: 20px;
      }
    }
    .title {
      margin-bottom: 3%;
    }

    .quotation-log,
    .task-log {
      hr {
        display: block;
        width: 100%;
        height: 2px;
        border: 0;
        background-color: #939393;
      }
      .sub-title {
        margin-bottom: 1.5%;
      }
      .part-list {
        padding: 5%;
        border-radius: 10px;
      }
      .sub-title {
        margin: 3% 0 2%;
      }
      .flex-row-2 {
        display: flex;
        justify-content: space-between;
        .value {
          margin-top: 2px;
        }
        .c {
          width: 49%;
        }
      }
      .part {
        margin-bottom: 3%;
        padding-bottom: 3%;
        border-bottom: 2px solid #454545;
        .r {
          margin-top: 2%;
          .value {
            margin-top: 2px;
          }
        }
        .flex-row-3 {
          display: flex;
          justify-content: space-between;
          .c {
            width: 30%;
          }
        }
      }
      .total {
        display: flex;
        justify-content: space-between;
      }
      .value.button-wrapper {
        .custom-btn {
          margin: 0;
        }
      }
      .attachment-row {
        .value {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          .task-attachment {
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }

  .uric-p1-log {
    .uirc-detail {
      margin-top: 2%;

      .uirc-data {
        background-color: #000000;
      }

      .r {
        // margin-top: 3%;
        margin-top: 10px;
        &:first-child {
          margin-top: 0;
          padding-top: 0;
          border: 0;
        }
        display: flex;
        justify-content: space-between;
        .c1 {
          width: 69%;
          white-space: pre-line;
        }
        .c2 {
          width: 29%;
          .edit-section {
            display: flex;
            .custom-icon-btn {
              margin-left: 5%;
            }
          }
          .sm-btn-wrapper {
            margin-top: 4%;
            .custom-btn {
              width: 40%;
            }
          }
        }
      }
      .thumbnail-wrapper {
        width: 22%;
        height: 150px;
        .thumbnail {
          width: 100%;
          height: 100%;
        }
      }
    }
    .report-item {
      margin-top: 30px;
      .report-item-heading {
        margin-bottom: 10px;
      }
    }
    .attachments-txt {
      margin: 10px 0;
    }
    .doc-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 2%;
      .doc-item {
        height: 100px;
      }
    }
  }
  .fm-respond-pm-rejects {
    width: 100%;
    margin-top: 20px;
    .custom-radio-component-wrapper .radio-button .circle {
      border: 2px solid #fff;
    }
    .custom-radio-component-wrapper .radio-button.active .circle .inner-dot {
      background-color: #fff;
    }
    .custom-radio-component-wrapper {
      margin-top: 10px;
      .label {
        color: #fff;
      }
    }
  }
  .key-reject-doc-log {
    .docs {
      margin-top: 20px;
      padding: 2%;
    }
    .doc-list-wrapper {
      display: flex;
      flex-wrap: wrap;
      .document-wrapper {
        padding: 15px;
        height: 160px;
        width: 25%;
      }
    }
    .no-doc {
      margin: 20px 0;
    }
    .sub-heading {
      margin: 0 0 10px 0;
    }
    .reason {
      margin: 0 0 30px 0;
    }
  }
  .additional-report-item {
    .r {
      display: block !important;
      width: 100% !important;
      .c1 {
        width: 100% !important;
        .doc-row {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
          .doc-item {
            height: 130px !important;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1800px) {
    .icon-sm {
      width: 23px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .icon-sm {
      width: 21px;
    }
  }

  @media only screen and (max-width: 1400px) {
    .icon-sm {
      width: 19px;
    }
  }

  @media only screen and (max-width: 1200px) {
  }

  @media only screen and (max-width: 900px) {
    .icon-sm {
      width: 17px;
    }
    .detail-card {
      padding: 20px;
    }
    .section-divider {
      margin: 20px 0;
    }
    .detail-card .summary {
      margin-top: 10px;
    }
    .detail-card .ticket-unit {
      margin-top: 20px;
    }
    .detail-card .section2 {
      flex-wrap: wrap;
      .category-column {
        width: 50%;
        margin-bottom: 10px;
      }
    }
    .detail-card .media {
      padding: 80px;
      margin-right: 50px;
      margin-bottom: 20px !important;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    .detail-card .section8 {
      margin-top: 20px;
    }
  }

  @media only screen and (max-width: 600px) {
    .detail-card .pair-section {
      display: block;
      .left {
        width: 100%;
      }
      .right {
        width: 100%;
        margin-top: 10px;
      }
    }
    .detail-card .ticket-unit {
      display: block;
      .ticket-no,
      .unit-name {
        width: 100%;
      }
      .unit-name {
        margin-top: 7px;
      }
    }
    .detail-card .section2 .category-column {
      width: 100%;
    }
    .section5 {
      .assign,
      .reassign {
        text-align: start !important;
      }
    }
  }
}

// Rent Details
.transitioned-tenant-accountant-view {
  p {
    margin: 0;
  }
  .basic-wrapper,
  .commercial-wrapper {
    .detail-box {
      padding: 10px;
      background-color: #262626;

      border-radius: 10px;
      .heading {
        text-align: start;
        padding: 5px 20px;
        background-color: #151517;
        border-radius: 10px;
        width: 100%;
      }
      .basic {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
      }

      .detail {
        margin: 5px 20px;

        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 150px;
          gap: 10px;
          cursor: pointer;

          .approve {
            padding: 5px 10px;
            color: #37bdb0;
            border: 1px solid #37bdb0;
            border-radius: 10px;
          }
          .reject {
            padding: 5px 10px;
            color: red;
            border: 1px solid red;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .invitaion-action-btn-wrapper {
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    .custom-btn {
      width: 49%;
    }
  }
  .status {
    margin: 30px 0;
  }
  .rent-detail-wrapper {
    margin-top: 20px;

    .rent-detail-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        padding-left: 30px;
      }
      .time {
        color: rgb(145, 143, 143);
      }
    }

    .custom-table-wrapper {
      // border-bottom-left-radius: 0;
      // border-bottom-right-radius: 0;
      .install {
        &:nth-child(even) {
          background-color: #151517;
          border-radius: 10px;
          margin: 5px 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
      .action {
        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 150px;
          gap: 10px;
          cursor: pointer;

          .approve {
            padding: 5px 10px;
            color: #37bdb0;
            border: 1px solid #37bdb0;
            border-radius: 10px;
          }
          .reject {
            padding: 5px 10px;
            color: red;
            border: 1px solid red;
            border-radius: 10px;
          }
        }
      }
    }
    .button-wrapper {
      width: 50%;
      margin: auto;
      // border-bottom-left-radius: 7px;
      // border-bottom-right-radius: 7px;
      // padding: 3%;
    }

    .more-details-edit {
      margin-bottom: 20px;
      .more-details {
        // display: grid;
        // grid-template-columns: repeat(2, 250px);
        display: flex;
        flex-direction: column;

        .more-data {
          //   display: grid;
          // grid-template-columns: repeat(2, 350px);
          display: flex;
          // align-items: center;
          justify-content: space-between;
        }

        .more-data-duration {
          //   display: grid;
          // grid-template-columns: repeat(2, 350px);
          display: flex;
          flex-direction: column;
          // align-items: center;
          // justify-content: space-between;
        }
        p {
          margin: 0;
        }
        .c-row {
          display: flex;
          margin-bottom: 10px;
          // justify-content: space-around;
          width: 375px;
          .key {
            width: 50%;
          }
        }
      }
      .edit-wrapper {
        margin-bottom: 20px;
        display: flex;
        align-items: flex-end;
        .installment-plan-edit-btn {
          margin-right: 10px;
        }
      }
    }

    .action-btn {
      height: 30px;
    }
    .installment-summ-wrapper {
      padding-left: 15px;
      width: 50%;
      margin-top: 5px;
      .custom-row {
        display: flex;
        .right {
          margin-left: 250px;
        }
      }
    }
    .icon-md {
      width: 30px;
    }
    .generate-inv-btn-wrapper {
      margin-top: 30px;
    }

    @media only screen and (max-width: 1800px) {
      .action-btn {
        height: 27px;
        margin-left: 10px;
      }
      .icon-md {
        width: 27px;
      }
    }
    @media only screen and (max-width: 1600px) {
      .action-btn {
        height: 24px;
      }
      .icon-md {
        width: 24px;
      }
    }
    @media only screen and (max-width: 1400px) {
      .action-btn {
        height: 21px;
      }
      .icon-md {
        width: 21px;
      }
    }
    @media only screen and (max-width: 1200px) {
    }
    @media only screen and (max-width: 900px) {
      .btn-wrapper-lg {
        margin-top: 20px;
      }
      .action-btn {
        height: 18px;
      }
      .icon-md {
        width: 18px;
      }
    }
    @media only screen and (max-width: 600px) {
    }
  }
  .contract-pdf-wrapper {
    margin-top: 20px;
  }
  .status-wrapper {
    margin: 30px 0 0;
    .warning-icon {
      width: 100%;
      max-width: 100px;
      margin: 0 auto;
    }
    .status-text {
      margin: 20px 0 0;
    }
  }

  .button-wrapper {
    margin-top: 20px;
    width: 50%;
    margin-left: auto;
  }
  .contract-wrapper {
    margin-top: 30px;
  }

  .icon-md {
    width: 30px;
  }

  @media only screen and (max-width: 1800px) {
    .icon-md {
      width: 27px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .icon-md {
      width: 24px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .icon-md {
      width: 21px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .icon-md {
      width: 18px;
    }
  }
  @media only screen and (max-width: 600px) {
  }
}

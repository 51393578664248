.guest-pass-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .guest-pass-header {
    text-align: center;
  }

  .report-wrapper {
    // margin-top: 3rem;

    .detail-box {
      margin-top: 20px;
      border-radius: 10px;
      padding: 15px;

      .contractor-unit-detail-box {
        margin: 15px 35px;
        color: white;

        .log-header {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 20px;

          .img-wrapper {
            img {
              width: 100px;
              height: 100px;
              border-radius: 10px;
              object-fit: cover;
            }
          }

          .detail {
            padding: 10px;

            .num {
              margin-bottom: 10px;
              color: #37bdb0;
            }

            p {
              margin-top: 10px;

              span {
                margin-left: 10px;
              }
            }
          }
        }

        .visitor-info {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;

          .reason-info {
            p {
              span {
                color: #37bdb0;
              }
            }
          }
        }

        .time-info {
          display: flex;
          align-items: center;
          justify-content: space-between;

          div {
            width: 190px;

            p {
              .approve {
                color: #37bdb0;
              }

              .reject {
                color: red;
              }

              .request {
                color: green;
              }

              .pending {
                color: #e7da20;
              }
            }
          }
        }

        .request_by {
          p {
            display: flex;
            flex-direction: row;
            gap: 7rem;
          }
        }

        .actions-btn {
          display: flex;
          // align-items: center;
          justify-content: flex-end;
          gap: 15px;

          .edit-btn {
            width: 125px;
            padding: 5px 15px;
            text-align: center;
            border-radius: 0.5rem;
            background-color: #37bdb0;
            color: white;
            outline: none;
            border: none;
          }

          .revoke-btn {
            width: 125px;
            padding: 5px 15px;
            text-align: center;
            border-radius: 0.5rem;
            background-color: red;
            color: white;
            outline: none;
            border: none;
          }

          div {
            display: flex;
            align-items: center;
            cursor: pointer;
          }

          .action-button {
            height: 20px;
          }

          .btn {
            .test {
              border: 1px solid red;
            }

            .rws-container {
              max-width: 24rem;
              width: 90%;
              background-color: burlywood !important;
              border-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
              border-bottom-left-radius: 0.5rem;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }

            .action-button {
              width: 35px;
              height: 35px;
            }

            // button {
            //   padding: 7px 50px;
            //   border-radius: 10px;
            //   cursor: pointer;
            //   background-color: #37bdb0;
            //   color: white;
            //   border: none;
            // }
          }
        }
      }
    }
  }
}

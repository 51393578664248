.tenant-invoicing-wrapper {
  padding: 1.5rem;

  .heading {
    margin: 15px 0;
    font-weight: 600;
    text-align: center;
  }

  .under_process_wrapper {
    margin-top: 2rem;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    border: 2px solid #37bdb0;

    img {
      height: 50px;
      margin-bottom: 1rem;
    }
  }

  // TENANT UNPAID INVOICE Table
  .unpaid-invoice-table {
    .action-button {
      height: 30px;

      &:last-child {
        margin-left: 15px;
      }
    }
  }

  // TENANT PAID INVOICE Table
  .paid-invoice-table {
    .action-button {
      height: 30px;

      &:last-child {
        margin-left: 15px;
      }
    }
  }
}

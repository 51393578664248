.settings-page-wrapper {
  .heading {
    margin-bottom: 30px;
  }

  .settings-content-wrapper {
    background-color: #262626;
    padding: 3%;
    border-radius: 10px;
  }
  .links {
    padding: 0 15%;
    .link-wrapper {
      background-color: #151517;
      margin-bottom: 20px;
      &:last-child {
        margin: 0;
      }
    }
  }
  .link {
    color: #ffffff;
    border-radius: 7px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    .icon {
      position: absolute;
      top: 50%;
      right: 2%;
      transform: translateY(-50%);
      height: 60%;
    }
  }
  .link-date {
    // color: #ffffff;
    border-radius: 7px;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    .icon {
      position: absolute;
      top: 50%;
      right: 2%;
      transform: translateY(-50%);
      height: 60%;
    }
  }
  @media only screen and (max-width: 1800px) {
    .link {
      padding: 13px 18px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .link {
      padding: 11px 16px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .link {
      padding: 9px 14px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .links {
      padding: 0 10%;
    }
  }
  @media only screen and (max-width: 900px) {
    .settings-content-wrapper {
      padding: 20px;
    }

    .link {
      border-radius: 5px;
      padding: 7px 12px;
    }
  }
  @media only screen and (max-width: 600px) {
    .links {
      padding: 0 0;
    }
  }
}

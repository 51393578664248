.notification-list-wrapper {
  padding: 2%;
  border-radius: 10px;
  background-color: #000000;
  p {
    margin: 0;
  }
  .item {
    cursor: pointer;
    margin-bottom: 20px;
    padding: 1.5%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: #151517;
    flex-wrap: wrap;
    // .end {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   gap: 10px;
    // }
  }

  .not-seen {
    cursor: pointer;
    margin-bottom: 20px;
    padding: 1.5%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: #151517;
    border: 2px solid #37bdb0;
    flex-wrap: wrap;

    // .end {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   gap: 10px;
    // }
  }
  // .red_dot {
  //   position: absolute;
  //   top: -8%;
  //   right: -0.5%;
  //   border-radius: 100%;
  //   width: 15px;
  //   height: 15px;
  // }
  .icon {
    width: 30px;
  }
  .notification-title-overview {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .notification-text {
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .notification-date-time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 25%;
  }
  .notification-action {
    width: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .additional-details {
    padding-top: 5px;
    .additional-detail-row {
      display: flex;
    }
    .additional-detail-row-right {
      padding-left: 5px;
    }
  }
  @media only screen and (max-width: 1800px) {
    .icon {
      width: 27px;
    }
    // .red_dot {
    //   width: 14px;
    //   height: 14px;
    // }
  }
  @media only screen and (max-width: 1600px) {
    .icon {
      width: 24px;
    }
    // .red_dot {
    //   width: 13px;
    //   height: 13px;
    // }
  }
  @media only screen and (max-width: 1400px) {
    .icon {
      width: 21px;
    }
    // .red_dot {
    //   width: 12px;
    //   height: 12px;
    // }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .icon {
      width: 19px;
    }
    // .red_dot {
    //   width: 10px;
    //   height: 10px;
    // }
  }
  @media only screen and (max-width: 600px) {
  }
}

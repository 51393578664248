.property-setting-wrapper {
  p {
    margin: 0;
  }
  .tab_list {
    margin-top: 20px;
    .tab {
      border: 0;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      padding: 1% 6%;
      cursor: pointer;
      background-color: inherit;
    }

    .active {
      background-color: #37bdb0;
    }
  }
  .setting-wrapper {
    padding: 3%;
    border-radius: 10px;
    border-top-left-radius: 0px;
    background-color: #262626;
    .main_heading {
      padding-bottom: 2%;
      border-bottom: 1px solid #939393;
      margin-bottom: 2%;
    }
    .sub_heading {
      padding-bottom: 2%;
      border-bottom: 1px solid #939393;
      margin-bottom: 2%;
    }
    .option {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid #939393;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
    .label {
      width: 20%;
    }
    .setting {
      width: 78%;
    }
    .setting-input,
    .setting-select {
      border-radius: 5px;
      border: 2px solid #707070;
      line-height: 0;
      padding: 5px 10px;
      &.lg {
        width: 200px;
      }
      &.sm {
        width: 55px;
      }
    }
    .setting-select {
      line-height: normal;
    }
  }
  .save-btn {
    width: 100px;
  }
  .radio-wrapper {
    display: flex;
    align-items: center;
    .radio-input {
      margin-right: 20px;
    }
  }
  .warning {
    img {
      width: 25px;
    }
  }
  .icon {
    width: 25px;
  }

  // MISSING PROPERTY SETTINGS DETAILS
  .settings-option {
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 20px;
    // padding-bottom: 20px;
    border-bottom: 1px solid #939393;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }

    .settings-container {
      display: grid;
      grid-template-columns: auto auto auto;
      // display: flex;
      // // align-items: center;
      // flex-direction: column;
      padding: 10px;

      .settings-item {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
      }
    }

    .warning {
      height: 25px;
      vertical-align: middle;
    }
  }

  /* SLA common Starts */
  .sla-common-option {
    .row1 {
      width: 100%;
      display: flex;
      .enable-switch {
        display: flex;
        align-items: center;
        margin-right: 20px;
        p {
          margin: 0 0 0 5px;
        }
      }
    }
    .check-boxes {
      display: flex;
      margin-left: 10px;
    }
    .check-box {
      display: flex;
      align-items: center;
      align-items: center;
      margin-left: 10px;
      p {
        margin-left: 10px;
        white-space: nowrap;
      }
    }
    .additional-email-label {
      margin-right: 10px;
    }
    .radio-container {
      display: flex;
      align-items: center;
      margin-left: 10px;
      p {
        margin-left: 5px;
      }
    }
    .row2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 70%;
      .notify-owner-wrapper {
        display: flex;
        align-items: center;
        p {
          margin-right: 10px;
        }
      }
    }
    .emaileach {
      display: flex;
      .custom-form-control-2 {
        width: 40%;
      }
      .icon {
        margin-left: 10px;
      }
    }
  }
  /* SLA common Ends */
  .enable-switch {
    display: flex;
    align-items: center;
    margin-right: 20px;
    p {
      margin: 0 0 0 5px;
    }
  }
  /* Tenant Application Fee Starts */
  .tenant-application-fees {
    .accept-fee-wrapper,
    .application-fee-amt {
      width: 48%;
    }
    .row1 {
      display: flex;
      justify-content: space-between;
    }
  }
  /* Tenant Application Fee Ends */

  /* Household Member Starts */
  .household-member-option {
    .row1 {
      display: flex;
      justify-content: space-between;
      .member-count,
      .id-count {
        width: 48%;
      }
    }
  }
  /* Household Member Ends */

  /* Rental History Starts */
  .tenant-rental-history,
  .tenant-vehicle-details,
  .tenant-pet-details {
    .row1 {
      display: flex;
      justify-content: space-between;
      .custom-col {
        width: 48%;
      }
    }
  }
  /* Rental History Ends */

  /* Emergency Contacts Starts */
  .emergency-contract-option {
    .contacts-add-view {
      button {
        margin-right: 20px;
      }
    }
  }
  /* Emergency Contacts Ends */

  /* Property Logo Starts */
  .property-logo {
    .file-box {
      border: 2px solid #939393;
      display: inline-block;
      padding: 2px 10px;
      border-radius: 5px;
      .inp {
        display: none;
      }
    }
    .view-file {
      margin-left: 20px;
    }
  }
  /* Property Logo Ends */

  /* Collect Tax Starts */
  .collect-tax {
    .tax-val {
      margin-top: 10px;
      span {
        margin-left: 10px;
      }
    }
  }
  /* Collect Tax Ends */

  .duration {
    display: flex;
    .custom-form-control {
      margin-right: 10px;
    }
  }
  .template-date-format-wrapper {
    display: flex;
    justify-content: space-between;
    .label {
      width: 20%;
      .custom-form-control {
      }
    }
    .value {
      width: 78%;
      .input-wrapper {
        width: 100%;
        max-width: 300px;
      }
      .save-btn-wrapper {
        margin-top: 20px;
        .custom-btn {
          width: 100%;
          max-width: 100px;
        }
      }
    }
  }

  /* ******************************************************************************** */
  /* Responsive screen break-points ********************************************* */
  @media only screen and (max-width: 1800px) {
    .setting-wrapper {
      .option {
      }
      .label {
      }
      .setting {
      }
      .setting-input,
      .setting-select {
        border-radius: 5px;
        padding: 4px 9px;
        &.sm {
          width: 50px;
        }
      }
    }

    .settings-container {
      display: grid;
      grid-template-columns: auto auto auto auto !important;
    }

    .warning {
      img {
        width: 23px;
      }
    }
    .icon {
      width: 23px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .setting-wrapper {
      .option {
      }
      .label {
      }
      .setting {
      }
      .setting-input,
      .setting-select {
        border-radius: 5px;
        padding: 3px 8px;
        &.sm {
          width: 45px;
        }
      }
    }

    .settings-container {
      display: grid;
      grid-template-columns: auto auto auto auto !important;
    }

    .warning {
      img {
        width: 21px;
      }
    }
    .icon {
      width: 21px;
    }
  }

  @media only screen and (max-width: 1400px) {
    .setting-wrapper {
      .option {
      }
      .label {
      }
      .setting {
      }
      .setting-input,
      .setting-select {
        border-radius: 5px;
        padding: 2px 7px;
        &.sm {
          width: 41px;
        }
      }
    }

    .settings-container {
      display: grid;
      grid-template-columns: auto auto auto !important;
    }

    .warning {
      img {
        width: 19px;
      }
    }
    .icon {
      width: 19px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .setting-wrapper {
      .label {
        width: 30%;
      }
      .setting {
        width: 68%;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .setting-wrapper {
      .label {
        width: 40%;
      }
      .setting {
        width: 58%;
      }
      .setting-input,
      .setting-select {
        border-radius: 4px;
        padding: 1px 6px;
        &.sm {
          width: 38px;
        }
      }
      .option {
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
    }

    .settings-container {
      display: grid;
      grid-template-columns: auto auto auto !important;
    }

    .warning {
      img {
        width: 17px;
      }
    }
    .icon {
      width: 17px;
    }

    /* Tenant Application Fee Starts */
    .tenant-application-fees {
      .row1 {
        display: block;
        .accept-fee-wrapper,
        .application-fee-amt {
          width: 100%;
        }
      }
    }
    /* Tenant Application Fee Ends */

    /* Household Member Starts */
    .household-member-option {
      .row1 {
        display: block;
        .member-count,
        .id-count {
          width: 100%;
        }
      }
    }
    /* Household Member Ends */

    /* Rental History Starts */
    .tenant-rental-history,
    .tenant-vehicle-details,
    .tenant-pet-details {
      .row1 {
        display: block;
        .custom-col {
          width: 100%;
        }
      }
    }
    /* Rental History Ends */
  }

  @media only screen and (max-width: 600px) {
    .setting-wrapper {
      padding: 20px;
      .option {
        display: block;
      }
      .label {
        width: 100%;
        margin-bottom: 20px;
      }
      .setting {
        width: 100%;
      }
    }

    .settings-container {
      display: grid;
      grid-template-columns: auto auto !important;
    }

    .custom-radio-component-wrapper .label {
      margin-bottom: 0;
    }

    /* Rental History Starts */
    .rental-history {
    }
    /* Rental History Ends */
  }
}

.invoicing-report-component-wrapper {
  // TENANT-DETAIL-CARD
  .detail-card {
    padding: 5%;
    border-radius: 10px;
    background-color: #262626;

    .title {
      margin-bottom: 12px;
    }

    .property-wrapper {
      display: flex;
      flex-direction: column;

      //   gap: 117px;

      .property-data {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 25px;
          height: 25px;
        }
      }
    }

    .item-data {
      display: flex;
      // align-items: center;
      flex-direction: row;
      gap: 175px;
    }

    .invoice-data-price {
      display: flex;
      // align-items: center;
      flex-direction: row;
      gap: 75px;
    }

    .invoice-data {
      display: flex;
      // align-items: center;
      flex-direction: row;
      gap: 165px;
    }

    .status-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 220px;
    }

    .spareParts-wrapper {
      border-radius: 10px;

      .sparePart {
        padding: 15px 25px;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }

    .service-data {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 165px;
    }

    .doc-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 215px;
      margin-bottom: 10px;

      .doc {
        display: flex;
        gap: 10px;
        img {
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
      }
    }

    .payment-data {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;

      display: flex;
      flex-direction: column;

      .trans-table {
        border-radius: 10px 15px 0 15px;
        background-color: #151517;
        padding: 10px;

        .action-button {
          margin-right: 10px;
          width: 20px;
        }
      }

      .pay-data {
        background-color: #151517;
        padding: 1rem;
        border-radius: 10px;
      }
    }

    .note-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 230px;
    }

    // .receipt-wrapper {
    //   display: flex;
    //   align-items: center;
    //   flex-direction: row;
    //   gap: 195px;

    //   .doc {
    //     img {
    //       width: 30px;
    //       height: 30px;
    //     }
    //   }
    // }

    .receipt-wrapper {
      display: flex;
      flex-direction: row;
      gap: 195px;

      .payment-doc-wrapper {
        .doc {
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  // PROPERTY-DETAIL-CARD
  .property-detail-card {
    padding: 5%;
    border-radius: 10px;
    background-color: #262626;

    .title {
      margin-bottom: 12px;
    }

    .property-wrapper {
      display: flex;
      flex-direction: column;

      //   gap: 117px;

      .property-data {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 25px;
          height: 25px;
        }
      }
    }

    .item-data {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 175px;
    }

    .invoice-data-price {
      display: flex;
      // flex-direction: row;
      // align-items: center;
      justify-content: space-between;
      gap: 85px;
    }

    .invoice-data {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 165px;
    }

    .status-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 220px;
    }

    .service-data {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 155px;
    }

    .doc-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 165px;

      .doc {
        img {
          width: 35px;
          height: 35px;
          cursor: pointer;
        }
      }
    }

    .invoice-status-wrapper {
      margin-top: 15px;
      display: flex;
      //   align-items: center;
      flex-direction: row;
      gap: 160px;

      .status-selector {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }

    .payment-data {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;

      display: flex;
      flex-direction: column;

      .trans-table {
        border-radius: 10px 15px 0 15px;
        background-color: #151517;
        padding: 10px;

        .action-button {
          margin-right: 10px;
          width: 20px;
        }
      }

      .pay-data {
        background-color: #151517;
        padding: 1rem;
        border-radius: 10px;
      }
    }

    .form-group-wrapper {
      display: flex;
      flex-direction: row;

      .note-wrapper {
        flex: 1;
      }
      .input-wrapper {
        flex: 2;
      }
    }

    .pay-form-wrapper {
      display: flex;
      justify-content: space-between;

      .form-group {
        width: 49%;
      }
    }

    .payment-data {
      display: flex;
      flex-direction: column;

      .trans-table {
        border-radius: 10px 15px 0 15px;
        background-color: #151517;
        padding: 10px;

        .action-button {
          width: 20px;
        }
      }
    }

    .receipt-wrapper {
      display: flex;
      flex-direction: row;
      gap: 195px;

      .payment-doc-wrapper {
        .doc {
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  // PARTIES-DETAIL-CARD
  .parties-detail-card {
    padding: 5%;
    border-radius: 10px;
    background-color: #262626;

    .title {
      margin-bottom: 12px;
    }

    .property-wrapper {
      display: flex;
      flex-direction: column;

      .property-data {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 25px;
          height: 25px;
        }
      }
    }

    .item-data {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 175px;
    }

    .invoice-data-price {
      display: flex;
      flex-direction: row;
      gap: 140px;
    }

    .invoice-data {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 180px;
    }

    .status-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 220px;
    }

    .spareParts-wrapper {
      border-radius: 10px;
      margin-bottom: 15px;

      .sparePart-details {
        padding: 20px 35px;
        display: flex;
        flex-direction: column;

        .sparePart {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .icon {
            // rotate: 90deg;
            cursor: pointer;
          }
        }
      }
    }

    .service-data {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 165px;
    }

    .doc-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 165px;

      .doc {
        img {
          width: 35px;
          height: 35px;
        }
      }
    }

    .payment-data {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;

      display: flex;
      flex-direction: column;

      .trans-table {
        border-radius: 10px 15px 0 15px;
        background-color: #151517;
        padding: 10px;

        .action-button {
          margin-right: 10px;
          width: 20px;
        }
      }

      .pay-data {
        background-color: #151517;
        padding: 1rem;
        border-radius: 10px;
      }
    }

    .receipt-wrapper {
      display: flex;
      flex-direction: row;
      gap: 195px;

      .payment-doc-wrapper {
        .doc {
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  .refund_text {
    margin-top: 10px;
    padding: 15px 30px;
    border: 2px solid #37bdb0;
    border-radius: 10px;
    color: #37bdb0;
    cursor: pointer;
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
    .invoice-data {
      gap: 135px !important;
    }
  }
}

.unit-table-module-wrapper {
  .action-button {
    height: 30px;
    margin-left: 20px;
  }
  .document {
    margin: 0 !important;
  }
  .filter-add-btn-wrapper {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .unit {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      z-index: 10;
    }
  }
  .filter-btn {
    width: 30px;
  }

  // .search-box{
  //   width: 100%;
  //   display: flex;
  //   align-items: center;
  //   // flex-direction: row;
  // }

  .search-box {
    width: 100%;

    div {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 10px;
    }

    .search {
      // width: 80%;
    }

    .btn-wrapper {
      width: 20%;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 10px;

      button {
        width: 50%;
        padding: 20px 50px;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        background-color: #37bdb0;
      }

      .btn-clear {
        width: 50%;
        padding: 20px 50px;
        border: 2px solid #262626;
        border-radius: 10px;
        cursor: pointer;
        background-color: transparent;
      }
    }
  }

  @media only screen and (max-width: 1800px) {
    .action-button {
      height: 28px;
      margin-left: 18px;
    }
    .filter-btn {
      width: 27px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .action-button {
      height: 26px;
      margin-left: 16px;
    }
    .filter-btn {
      width: 24px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .action-button {
      height: 24px;
      margin-left: 14px;
    }
    .filter-btn {
      width: 21px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .search-box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      .search {
        width: 100%;
      }

      .btn-wrapper {
        width: 100%;
        gap: 5px;
        button {
          padding: 15px;
        }

        .btn-clear {
          padding: 15px;
        }
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .action-button {
      height: 22px;
      margin-left: 12px;
    }
    .filter-btn {
      width: 18px;
    }

    .search-box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      .search {
        width: 100%;
      }

      .btn-wrapper {
        width: 100%;
        gap: 5px;
        button {
          padding: 15px;
        }

        .btn-clear {
          padding: 15px;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .search-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .search {
        width: 100%;
      }

      .btn-wrapper {
        width: 100%;
        gap: 5px;

        button {
          padding: 12px;
        }

        .btn-clear {
          padding: 12px;
        }
      }
    }
  }
}

.rejected-report-modal-wrapper {
  p,
  h3 {
    margin: 0;
  }
  .modal-header-custom {
    position: relative;
    padding: 3%;
  }
  .modal-body-custom {
    padding: 3% 3%;
  }
  .report-item-label {
    margin-top: 2%;
  }
  .submitted-by {
    margin-top: 4%;
  }
  .report-item {
    border: 2px solid #37bdb0;
    padding: 3%;
    border-radius: 10px;
    margin-bottom: 30px;
    // background-color: #151517;
  }
  .report-item-header {
    margin-top: 5%;
  }
  .attachment-stripe {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2%;
    margin-top: 10px;
    .attachment-item {
      height: 130px;
    }
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    img {
      width: 30px;
    }
  }
}

.edit-unit-page-wrapper {
  .header {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 10;
  }
  .option-button {
    display: flex;
    align-items: center;
  }
  .option-button img {
    width: 30px;
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

.uirc-p2-modal-wrapper {
  .modal-body {
    padding: 5% 7%;
  }
  .form-wrapper {
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
  }
  .button-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
  .form-group {
    width: 49%;
    margin-bottom: 20px;
  }
  .form-group-full {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .custom-radio-component-wrapper {
      margin-right: 50px;
    }
    .custom-form-control,
    .custom-file-input-wrapper {
      width: 100%;
    }
  }
  .action-btn {
    button {
      margin-left: 20px;
    }
  }
  .attachment, .description {
    h3 {
      margin: 20px 0;
    }
  }
  .doc-item {
    margin-bottom: 20px;
  }
}

.payment-card-wrapper-main {
  width: 49%;
  border-radius: 10px;
  padding: 3%;
  margin-bottom: 10px;
  background-color: #8a8a8b;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    img {
      width: 7%;
      min-width: 30px;
    }
    .dot {
      border: 3px solid #262626;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .inner-dot {
        height: 69%;
        width: 70%;
        border-radius: 50%;
      }
    }
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75%;
  }

  &.selected {
    background-color: #37bdb0;
    .dot {
      border: 3px solid #ffffff;
      .inner-dot {
        background-color: #ffffff;
      }
    }

    .num,
    .name,
    .btn {
      color: white;
    }
  }

  .num {
    margin: 20px 0 0;
  }
  @media only screen and (max-width: 1800px) {
    .top .dot {
      width: 27px;
      height: 27px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .top .dot {
      width: 25px;
      height: 25px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .top .dot {
      width: 23px;
      height: 23px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .top .dot {
      width: 20px;
      height: 20px;
    }
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

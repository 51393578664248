.community-item-component-wrapper {
  margin: 20px 20px 0 0;
  border-radius: 10px;
  padding: 4%;
  display: flex;
  justify-content: space-between;
  background-color: #262626;
  height: 250px;
  p {
    margin: 0;
  }
  .image {
    width: 40%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 10px;
  }
  .details {
    width: 58%;
    .name,
    .address,
    .type,
    .owner,
    .missing {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .address {
      vertical-align: middle;
      .location-pin {
        height: 25px;
        vertical-align: middle;
      }
    }
  }
  @media only screen and (max-width: 1800px) {
    height: 170px;
    .details .address .location-pin {
      height: 23px;
    }
  }
  @media only screen and (max-width: 1600px) {
    height: 160px;
    .details .address .location-pin {
      height: 21px;
    }
  }
  @media only screen and (max-width: 1400px) {
    height: 150px;
    .details .address .location-pin {
      height: 19px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    height: 140px;
    .details .address .location-pin {
      height: 17px;
    }
  }
  @media only screen and (max-width: 600px) {
    padding: 20px;
    margin: 20px 0 0;
  }
}

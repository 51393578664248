.tenant-move-out-pending-clearence-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    margin: 0;
  }

  .tenant-move-out-pending-header {
    width: 100%;
    text-align: center;

    h2 {
      padding: 15px;
    }
  }
  .doc-status {
    margin-top: 5px;
  }
  .report-wrapper {
    .detail-box {
      // margin-top: 75px;
      border-radius: 10px;
      // padding: 10px 20px;
      background-color: #262626;
      .moveout-unit-detail-box {
        margin: 15px 30px;
        // padding: 10px 20px;

        .log-header {
          p {
            margin: 10px 0;
          }
        }

        hr {
          width: 100%;
          display: block;
          height: 0;
          border: 1px solid rgba(211, 211, 211, 0.656);
          margin: 15px 0;
        }

        .unit-info {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            margin: 10px 0;

            unit {
              margin-right: 50%;
            }
          }
        }

        .time-info {
          padding: 0;
        }

        .form-btn-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 15px;
          padding: 10px;
        }

        .form-wrapper {
          border-radius: 10px;
          display: flex;
          flex-wrap: wrap;
          gap: 2%;
        }

        .form-group {
          width: 49%;
          margin-bottom: 20px;

          .add-more {
            display: inline-flex;
            gap: 15px;
            position: relative;
            float: left;
            margin-left: 70%;

            p {
              cursor: pointer;
              img {
                width: 20px;
                height: 20px;
              }

              &:first-child:hover {
                color: white;
              }
            }
          }
        }

        .form-group-full {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          .custom-form-control {
            width: 100%;
          }
        }
      }
    }
  }
  .util_doc{
    padding: 10px 20px;
    .doc-section-each {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      margin: 15px 0;
      .key {
        width: 49%;
      }
      .value {
        width: 49%;
      }
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

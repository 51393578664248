.custom-file-input-wrapper {
  position: relative;
  label {
    display: block;
    font-weight: 600;
    position: absolute;
    top: 17px;
    left: 22px;
  }
  .file {
    display: none;
  }
  .filename {
    display: block;
    width: 100%;
    border: 0;
    padding: 42px 50px 17px 22px;
    border-radius: 10px;
    &:focus {
      outline: none;
      border: 2px solid #37BDB0;
    }
  }
  .error {
    margin: 5px 0 0;
    font-weight: 400;
  }
  .upload {
    position: absolute;
    height: 40px;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
  .filechosen {
    label {
      color: #ffffff;
    }
    .filename {
      background-color: #37bdb0;
    }
  }
  @media only screen and (max-width: 1800px) {
    .filename {
      padding: 39px 43px 14px 19px;
    }
    label {
      top: 14px;
      left: 19px;
    }
    .upload {
      right: 8px;
      height: 35px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .filename {
      padding: 35px 36px 10px 15px;
    }
    label {
      top: 10px;
      left: 15px;
    }
    .upload {
      right: 6px;
      height: 30px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .filename {
      padding: 30px 29px 9px 14px;
    }
    label {
      top: 9px;
      left: 14px;
    }
    .upload {
      right: 4px;
      height: 25px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .filename {
      border-radius: 7px;
    }
  }
  @media only screen and (max-width: 900px) {
    .filename {
      padding: 26px 22px 7px 12px;
    }
    label {
      top: 7px;
      left: 12px;
    }
    .upload {
      right: 2px;
      height: 20px;
    }
  }
  @media only screen and (max-width: 600px) {
  }
}

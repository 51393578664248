.onboard-key-rejection-modal-wrapper {
  .modal-header-custom {
    padding: 5% 0 0;
  }
  .modal-body-custom {
    padding: 5% 5%;
    .attachment-heading {
      margin: 50px 0 30px 0px;
    }
    .file-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      .form-control-wrapper {
        width: 65%;
      }
      .action-btn {
        display: flex;
        width: 25%;
        justify-content: space-between;
      }
    }
  }
  .no-attacthments-added {
    .no-attacthments-added-text {
      margin-bottom: 10px;
    }
  }
  .modal-footer-custom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 2% 5% 5%;
    .custom-btn {
      width: 45%;
    }
  }
}

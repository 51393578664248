.rnd-wrapper {
  padding: 100px 0 0 0;
  width: 100%;
  .datepicker-wrapper {
    padding: 30px;
    background-color: white;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
}

.owner-table-module-wrapper {
  .main-heading {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    z-index: 10;
  }
  .custom-table-wrapper {
    width: 100%;
  }

  .wrapper {
    padding: 30px;
    border-radius: 10px;
    width: 100%;
    background-color: #262626;
    .company_wrapper_outer {
      margin-bottom: 15px;

      .accordion-button {
        background-color: #262626;
        color: #37bdb0;
        font-weight: 600;
      }

      .accord-heading-wrapper {
        width: 100%;
        display: flex;
        margin-right: 2rem;

        .accord-one {
          display: flex;
          flex-direction: row;
          gap: 2rem;
          flex: 2;

          .name {
            // background-color: #262626;
            color: #37bdb0;
            font-weight: 600;
          }
        }

        .accord-two {
          display: flex;
          justify-content: end;
          flex: 1;
        }
      }

      .accordion-button::after {
        color: #37bdb0 !important;
        // background-image: none;
        position: absolute;
        right: 30px;
        top: 25px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='17.5' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill='%2337bdb0' d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'/%3E%3C/svg%3E");
      }

      .accordion-button:not(.collapsed) {
        color: #37bdb0;
        background-color: #151517;
        box-shadow: none;
      }

      .accordion-button:focus {
        z-index: unset;
        border-color: unset;
        box-shadow: unset;
      }

      .accordion-item {
        background-color: inherit;
        border: solid #151517;
        // border: none;
      }

      .accordion-item:last-of-type .accordion-collapse {
        border: solid #151517;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
  .company_wrapper {
    border: 3px solid #151517;
    border-radius: 13px;
    .custom-table-wrapper .custom-table thead tr th:first-child {
      border-bottom-left-radius: 0px;
    }
    .custom-table-wrapper .custom-table thead tr th:last-child {
      border-bottom-right-radius: 0px;
    }
  }
  // TESTING
  .col-different1 {
    width: 50px;
  }

  .col-different2 {
    width: 175px;
  }

  .col-different3 {
    width: 75px;
  }

  .col-different4 {
    width: 100px;
  }

  .action-button {
    height: 30px;
    // margin-left: 20px;
  }
  .add-or-btn {
    padding-left: 10px;
  }
  .bottom-arrow {
    border-bottom: 5px solid #151517;
  }
  .bottom-arrow:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 30px solid #151517;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
  }
  .employee-heading {
    margin: 40px 0 20px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    h3 {
      margin: 0;
    }
  }

  .not-added-yet {
    padding: 2% 0;
    margin: 0;
  }

  @media only screen and (max-width: 1800px) {
    .wrapper {
      padding: 27px;
    }

    .employee-heading {
      padding: 0 18px;
    }

    .col-different2 {
      width: 250px;
    }

    .action-button {
      height: 28px;
      margin-left: 18px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .employee-heading {
      padding: 0 16px;
    }

    .wrapper {
      padding: 24px;
    }

    .col-different2 {
      width: 215px;
    }
    .custom-table-wrapper {
      .col-different2 {
        width: 225px;
      }
    }
    .action-button {
      height: 26px;
      margin-left: 16px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .wrapper {
      padding: 21px;
    }

    .col-different2 {
      width: 200px;
    }

    .action-button {
      height: 30px;
    }

    .employee-heading {
      padding: 0 14px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .employee-heading {
      padding: 0 12px;
    }
  }
  @media only screen and (max-width: 900px) {
    .employee-heading {
      padding: 0 10px;
    }

    .wrapper {
      padding: 18px;

      .company_wrapper_outer .accordion-button::after {
        right: 60px;
        top: 55px;
      }
    }

    .action-button {
      height: 24px;
    }
  }
  @media only screen and (max-width: 600px) {
    .wrapper {
      padding: 15px;
    }
    .custom-table-wrapper {
      .action-btn {
        height: 20px;
      }
    }
    .employee-heading {
      padding: 0 9px;
    }
    .not-added-yet {
      padding: 20px 0;
    }
  }
}

.change-password-modal {
  .modal-header-custom {
    .icon-wrapper {
      display: flex;
      justify-content: center;
      img {
        width: 20%;
        min-width: 100px;
      }
    }
    h2 {
      font-weight: 600;
      margin: 20px 0 0;
    }
  }
  .description {
    margin: 30px 0;
  }
  .modal-body {
    padding: 7% 7%;
  }
  .button-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .button-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 3% 0 0;
    button {
      width: 45%;
    }
    .resend-wrapper {
      width: 100%;
      margin: 0 0 3%;
      .custom-text-btn {
        font-weight: 600;
        width: auto;
      }
    }
  }

  width: 100%;
  .inner-heading {
    margin-bottom: 30px;
  }
  .submit-button-wrapper {
    margin: 20px 0;
  }
  .form-group {
    margin-bottom: 30px;

    .form-input-wrapper input {
      background-color: #f1ffff !important;
      color: #272727 !important;
    }
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
    .modal-header-custom .description {
      margin: 20px 0;
    }
  }
}

.assign-license-modal-wrapper {
  .button-wrapper {
    display: flex;
    gap: 2%;
    margin-top: 40px;
    button {
      width: 50%;
    }
  }
  .body {
    padding: 7% 10%;

    .insuff-wrapper{
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        padding: 10px 45px;
        background-color: #262626;
        border: 2px solid #37bdb0;
        border-radius: 10px;
        color: #37bdb0;
      }

    }

    .otherTheme {
      .form-input-wrapper input {
        background-color: #f1ffff;
        color: #444444;
      }

      button{
        background-color: #f1ffff;
        color: #444444;
      }
    }


  }
  .heading {
    margin-bottom: 40px;
  }
  .warning {
    margin: 10px 0 40px;
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .body {
      padding: 40px 30px;
    }
  }
  @media only screen and (max-width: 600px) {
    .body {
      padding: 30px 20px;
    }
  }
}

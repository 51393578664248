.lost-wrapper {
  margin: 0 2.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  .report-wrapper {
    .detail-box {
      margin-top: 20px;
      border-radius: 10px;
      padding: 15px;
      background-color: #262626;
      .unit-detail-box {
        margin: 20px 40px;

        .desc {
          margin-top: 15px;
        }
      }
    }

    .image-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 15px;

      .proof-img {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 75px;
          height: 75px;
          object-fit: cover;
          border-radius: 10px;
          cursor: pointer;
        }

        .video-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .icon {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            // color: #37bdb0 !important;
            cursor: pointer;
          }

          video {
            width: 75px;
            height: 75px;
            object-fit: cover;
            border-radius: 10px;
            cursor: pointer;
          }
        }
      }

      .undefined-img {
        display: flex;
        align-items: center;
        img {
          padding: 5px;
          width: 75px;
          height: 75px;
          object-fit: cover;
          border-radius: 10px;
          background-color: #fff;
        }
      }
    }

    .back-btn {
      margin-top: 2.5rem;
      padding: 10px;
      width: 100%;
      border: none;
      border-radius: 10px;
      background-color: #37bdb0;
    }
  }
}

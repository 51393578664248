.community-license-slider-item-component-wrapper {
  margin: 20px 20px 0 0;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 4%;
  background-color: #262626;
  p {
    margin: 0;
  }

  .image {
    width: 40%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .details {
    width: 58%;
  }

  .name-license {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
    .name {
      width: 60%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .address {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
  }

  .location-pin {
    height: 25px;
  }

  .units {
    width: 100%;
  }
  @media only screen and (max-width: 1800px) {
    .location-pin {
      height: 23px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .location-pin {
      height: 21px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .location-pin {
      height: 19px;
    }
  }
  @media only screen and (max-width: 1200px) {
    margin: 20px 0 0 0;
  }
  @media only screen and (max-width: 900px) {
    .location-pin {
      height: 17px;
    }
  }
  @media only screen and (max-width: 600px) {
    display: block;
    .image {
      width: 100%;
      padding-bottom: 40%;
      height: 0;
      margin-bottom: 20px;
    }
    .details {
      width: 100%;
    }
  }
}

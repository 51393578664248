.custom-bread-crumb {
  margin-top: 100px;
  list-style-type: none;
  display: flex;
  padding-left: 0 !important;
  z-index: 999;
  li {
    .seperator {
      display: inline-block;
      margin: 0 10px;
      z-index: 10;
    }

    &:last-of-type {
      .link-color {
        color: #939393;
        // cursor: not-allowed;
      }
    }
  }
}

.purchaseLicenseWrapper {
  .summary {
    border-radius: 10px;
    padding: 3%;
  }
  .details_amt {
    justify-content: space-between;
    align-items: flex-end;
    .right {
        margin-left: 10px;
    }
  }
  .load-summary {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #000000;
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

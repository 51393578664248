.installment-modal-wrapper {
  p {
    margin: 0;
  }
  .modal-body {
    padding: 5%;
  }

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    .custom-btn {
      width: 48%;
    }
  }

  .form-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .form-group {
      width: 49%;
      margin-top: 20px;
    }

    .otherTheme {
      .react-date-picker__inputGroup {
        * {
          color: #444444 !important;
        }
      }
    }
    .form-group-full {
      width: 100%;
    }
    .form-group-flex {
      display: flex;
    }
    .check {
      margin-right: 20px;
      .check-text {
        margin-left: 7px;
      }
    }
  }

  .installments-wrapper {
    .heading {
      margin-top: 20px;
      padding: 10px;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon-wrapper {
        width: 25px;
        transform: rotate(90deg);
        transition: 500ms;
        &.open {
          transform: rotate(270deg);
        }
      }
    }
  }

  .installment-lists {
    padding: 2%;
    margin-top: 10px;
    border-radius: 7px;
  }

  .button-wrapper {
    margin-top: 20px;
  }

  .installment-item {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #d3d3d342;
    &:first-child {
      margin-top: 0;
      border: 0;
    }
  }

  .custom-row {
    display: flex;
    margin-top: 5px;
    .key {
      width: 25%;
    }
  }
  .deposit-list {
    width: 100%;
    .deposit-item {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .add-desc-btn,
    .remove-desc-btn {
      margin-top: 20px;
    }
  }
  .deposit-tax-check-wrapper {
    width: 100%;
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

.tenant-lost-table {
  padding-top: 0.5rem;

  .action-button {
    height: 30px;

    &:last-child {
      margin-left: 20px;
    }
  }
}

.tenant-move-out-detail-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .tenant-move-out-detail-header {
    width: 100%;
    text-align: center;
    background-color: #151517;

    h2 {
      padding: 15px;
    }
  }

  .report-wrapper {
    width: 50%;

    .detail-box {
      border-radius: 10px;
      padding: 10px 20px;
      .moveout-unit-detail-box {
        margin: 15px 30px;

        .log-header {
          p {
            margin: 10px 0;
          }
        }

        hr {
          width: 100%;
          display: block;
          height: 0;
          border: 1px solid rgba(211, 211, 211, 0.656);
          margin: 15px 0;
        }

        .unit-info {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            margin: 10px 0;

            unit {
              margin-right: 50%;
            }
          }
        }

        .time-info {
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .documents-wrapper {
          display: flex;
          align-items: center;
          flex-direction: column;

          div {
            display: flex;
            width: 100%;
            justify-content: space-between;

            padding: 5px 0;

            p {
              // display: grid;
              // grid-column: auto;
              img {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

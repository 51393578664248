.text-main-wrapper {
  // background: black;
  padding: 5px 5px 10px 5px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .text-editor {
    margin: 10px 15px;
    .quill {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 100%;

      .ql-toolbar.ql-snow {
        margin: 0 15px;
        background: #8a8a8b;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ql-picker-options {
          background-color: #a39c9c;
        }
      }

      .ql-container.ql-snow {
        border: none;
        width: 100%;
        height: 175px;

        .ql-editor {
          padding: 10px 5px;
        }

        //   .ql-snow .ql-tooltip {
        //     background-color: #fff;
        //     border: 1px solid #ccc;
        //     box-shadow: 0px 0px 5px #ddd;
        //     color: #444;
        //     padding: 5px 12px;
        //     white-space: nowrap;
        //     margin-right: 500px;
        //     border: 1px solid red;
        // }

        .ql-snow .ql-tooltip.ql-editing input[type="text"] {
          display: inline-block;
          margin-left: 25px;
        }
        .ql-snow .ql-tooltip input[type="text"] {
          display: none;
          border: 1px solid #ccc;
          font-size: 13px;
          height: 26px;
          margin: 0px;
          padding: 3px 50px;
          width: 170px;
        }
      }
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

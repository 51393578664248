.date-time-change-format-modal-wrapper {
  .modal-header-custom {
    margin-bottom: 50px;
    .icon-wrapper {
      img {
        width: 25%;
      }
    }
    h2 {
      font-weight: 600;
    }
  }
  .modal-body {
    padding: 8% 10%;
    border: 2px solid #939393;
  }
  .description {
    margin: 5% 0;
  }
  .form-group {
    margin-bottom: 20px;
  }
  .form-action-btn-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .custom-btn {
      width: 48%;
    }
  }
  .modal-footer-custom {
    display: flex;
    justify-content: center;
    button {
      width: 50%;
    }
  }

  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
  }
}

.claimed-details-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .header {
    text-align: center;
  }

  .report-wrapper {
    .detail-box {
      border-radius: 10px;
      padding: 15px;
      background-color: #262626;
      .unit-detail-box {
        margin: 1.5rem 3rem;
        .title {
          margin-bottom: 15px;
          color: #848484;
          font-size: 14px;
        }

        .date {
          span {
            padding-top: 10px;
            color: white;
            font-size: 14px;
          }

          span:last-child {
            margin-left: 50px;
          }
        }

        .date-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 65px;

          .detail {
            padding-top: 10px;

            p {
              color: #848484;
              font-size: 14px;
              span {
                font-size: 14px;
              }
            }
          }
        }

        .image-wrapper {
          img {
            width: 75px;
            height: 75px;
            object-fit: cover;
            border-radius: 10px;
            background: #fff;
          }
        }

        .ticket-wrapper {
          color: #848484;
          padding: 10px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          span {
          }
        }

        .ticket-id-wrapper {
          color: #848484;
          padding: 10px 0;
          display: flex;
          // align-items: center;
          // justify-content: space-between;
          gap: 20px;

          span {
          }
        }

        .claim-by {
          p {
            span {
              margin-left: 75px;
            }
          }
        }
      }
    }
  }
}

.custom-check-input-component-wrapper {
  .variant-2 {
    display: block;
    .checkmark {
      display: block;
      width: 30px;
      height: 30px;
      border: 3px solid #6f6f6f;
      border-radius: 5px;
      position: relative;
    }
    input {
      display: none;
    }
    input:checked ~ .checkmark::after {
      content: "";
      display: block;
      position: absolute;
      top: 12%;
      left: 11%;
      width: 73%;
      height: 42%;
      border-left: 3.5px solid lightgray;
      border-bottom: 3.5px solid lightgray;
      transform: rotate(317deg);
    }
  }
  @media only screen and (max-width: 1800px) {
    .variant-2 .checkmark {
      width: 27px;
      height: 27px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .variant-2 .checkmark {
      width: 24px;
      height: 24px;
    }
  }

  @media only screen and (max-width: 1400px) {
    .variant-2 .checkmark {
      width: 21px;
      height: 21px;
    }
  }

  @media only screen and (max-width: 1200px) {
  }

  @media only screen and (max-width: 900px) {
    .variant-2 .checkmark {
      width: 19px;
      height: 19px;
    }
  }

  @media only screen and (max-width: 600px) {
  }
}

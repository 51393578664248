.not-supported-browsers-wrapper {
  p {
    margin: 0;
  }
  .close-btn {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 40px;
    img {
      width: 100%;
    }
  }
  padding-top: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100vh;
  overflow: auto;
  .logo-wrapper {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 50px;
    img {
      width: 100%;
    }
  }
  .heading {
    width: 100%;
    max-width: 500px;
    margin: 0 auto 30px;
  }
  .browser-details {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
  .key-value-pair {
    // display: flex;
    // justify-content: space-between;
    .key {
      //   width: 50%;
    }
    .value {
      //   width: 50%;
    }
  }
  .supported-browsers {
    width: 100%;
    max-width: 700px;
    margin: 50px auto 0;
    padding: 0 0 100px 0px;
    .supported-browsers-flex {
      margin-top: 20px;
      .r {
        display: flex;
        justify-content: space-between;
        border-top: 2px solid #3c3c3c;
        .c {
          padding: 15px 70px 15px;
        }
      }
    }
  }
}
.front-end-wrapper {
  &.disable-scroll {
    overflow: hidden;
    height: 100vh;
  }
}
.slider-button-component-wrapper {
  position: relative;
  input[type="checkbox"] {
    position: relative;
    width: 60px;
    height: 30px;
    -webkit-appearance: none;
    appearance: none;
    background: #939393;
    outline: none;
    border-radius: 2rem;
    cursor: pointer;
    box-shadow: inset 0 0 5px rgb(0 0 0 / 50%);
  }

  input[type="checkbox"]::before {
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s;
  }

  input[type="checkbox"]:checked::before {
    transform: translateX(100%);
    background: #fff;
  }

  input[type="checkbox"]:checked {
    background: #00cd3e;
  }

  &.diabled {
    input[type="checkbox"] {
      background: rgba(80, 80, 80, 1) !important;
    }

    input[type="checkbox"]::before,
    input[type="checkbox"]:checked::before {
      background: rgb(149, 149, 149) !important;
    }
  }

  @media only screen and (max-width: 1800px) {
    input[type="checkbox"] {
      width: 55px;
      height: 27.5px;
    }
    input[type="checkbox"]::before {
      width: 27.5px;
      height: 27.5px;
    }
  }

  @media only screen and (max-width: 1600px) {
    input[type="checkbox"] {
      width: 50px;
      height: 25px;
    }
    input[type="checkbox"]::before {
      width: 25px;
      height: 25px;
    }
  }
  @media only screen and (max-width: 1400px) {
    input[type="checkbox"] {
      width: 45px;
      height: 22.5px;
    }
    input[type="checkbox"]::before {
      width: 22.5px;
      height: 22.5px;
    }
  }
  @media only screen and (max-width: 1200px) {
    input[type="checkbox"] {
      width: 40px;
      height: 20px;
    }
    input[type="checkbox"]::before {
      width: 20px;
      height: 20px;
    }
  }
  @media only screen and (max-width: 900px) {
    input[type="checkbox"] {
      width: 35px;
      height: 17.5px;
    }
    input[type="checkbox"]::before {
      width: 17.5px;
      height: 17.5px;
    }
  }
  @media only screen and (max-width: 600px) {
    input[type="checkbox"] {
      width: 30px;
      height: 15px;
    }
    input[type="checkbox"]::before {
      width: 15px;
      height: 15px;
    }
  }
}

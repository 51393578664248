.claim-modal-wrapper {
  p {
    margin: 0;
  }
  .modal-header-custom {
    padding: 5px;
    margin-bottom: 20px;
  }
  .modal-body {
    padding: 5% 8%;
    border: 2px solid grey;
  }
  .modal-body-custom {
    .radiolist {
      display: flex;
      justify-content: space-between;
      .radio-group {
        width: 49%;
      }
      .r-button {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        p {
          margin-left: 7px;
        }
      }
    }

    .form-wrapper {
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
    }

    .button-wrapper {
      margin-top: 30px;
      .custom-text-btn {
        margin-top: 20px;
      }
    }

    .form-group {
      width: 49%;
      margin-bottom: 20px;
      margin-top: 5px;
    }

    .otherTheme {
      .react-date-picker__inputGroup {
        * {
          color: #444444 !important;
        }
      }
    }

    .form-group-full {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .custom-radio-component-wrapper {
        margin-right: 50px;
      }
      .custom-form-control {
        width: 100%;
      }
    }

    .radio-btn {
      margin-left: 60px;
    }

    .tenant-invoice-table {
      background-color: #ffffff;
      border: 2px solid #37bdb0;
      * {
        color: #444444;
      }
      .custom-table thead {
        // background-color: #ffffff;
        background-color: #f1ffff;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
  }
}

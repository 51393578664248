.application-fee-payment-module-wrapper {
  .transaction-status-wrapper-main {
    position: static;
    .icon {
      padding-top: 0 !important;
    }
  }
  .pay-online {
    margin: 20px 0;
  }

  .form-wrapper{

    .options-wrapper{
      margin-bottom: 15px;
      // border-radius: 10px;
      // padding: 35px 25px;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 100px;
      // background-color: #262626;
    }
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .pay-online {
      margin: 15px 0;
    }
  }
  @media only screen and (max-width: 600px) {
  }
}

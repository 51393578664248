.active-facility-table-wrapper {
  padding-top: 1rem;
  width: 100%;

  .sub-btn {
    border: 2px solid #37bdb0;
    padding: 5px 35px;
    color: #37bdb0;
    font-size: 12px;
    cursor: pointer;
    background: transparent;
    border-radius: 10px;
  }
}

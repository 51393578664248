.unit-detail-page-wrapper {
  .access-check {
    margin-top: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    border: 2px solid #37bdb0;
    border-radius: 10px;
    background-color: #262626;
    color: white;
    img {
      width: 75px;
      height: 75px;
    }
  }

  p {
    margin: 0 0 10px;
  }
  .title-actions {
    display: flex;
    justify-content: space-between;
  }
  .header {
    margin-bottom: 30px;
    .title-actions {
      position: relative;
      z-index: 10;
      .action-btn {
        width: 35px;
        margin-left: 20px;
      }
    }
  }
  .detail-box {
    border-radius: 10px;
    padding: 3%;
    background-color: #262626;
    .unit-detail-box {
      .title {
        margin-bottom: 20px;
      }
      .unitAge-title {
        margin-bottom: 20px;
        padding: 10px 0;
        border-radius: 10px;
        background-color: #151517;
      }
      .num {
        margin-bottom: 30px;
      }
      .unitAge-details-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .details-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    .desc {
      margin-top: 20px;
    }
    .grid-full {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
  .doc-icon {
    height: 35px;
  }
  .unit-history-wrapper {
    // max-width: 500px;
    margin-top: 15px;
    .heading {
      margin-bottom: 30px;
    }

    .unit-history-table {
      width: 100%;

      .action-button {
        height: 30px;
      }
    }

    .custom-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      p {
        margin: 0;
      }
    }
  }
  .remove-tenant-btn {
    margin-right: 20px;
  }
  @media only screen and (max-width: 1800px) {
    .header {
      .action-btn {
        width: 32px;
      }
    }
    .doc-icon {
      height: 32px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .header {
      .action-btn {
        width: 29px;
      }
    }
    .doc-icon {
      height: 29px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .header {
      .action-btn {
        width: 26px;
      }
    }
    .doc-icon {
      height: 26px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .details-content {
      grid-template-columns: 1fr 1fr;
      .grid-full {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .header {
      .action-btn {
        width: 23px;
      }
    }
    .details-content {
      display: block;
      p {
        margin-bottom: 20px;
      }
    }
    .doc-icon {
      height: 23px;
    }
  }
  @media only screen and (max-width: 600px) {
  }
}

.terms-page-wrapper {
  .detail-card {
    padding: 5%;
    border-radius: 10px;
    background-color: #262626;

    .title {
      margin-bottom: 12px;
    }
  }
}

.add-payment-setting-module-wrapper {
  margin-top: 30px !important;
  .form-wrapper {
    background-color: #262626;
    padding: 3%;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
  }
  .button-wrapper {
    margin-top: 30px;
    .custom-text-btn {
      margin-top: 20px;
    }
  }
  .form-group {
    width: 49%;
    margin-bottom: 20px;
  }
  .form-group-full {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .custom-radio-component-wrapper {
      margin-right: 50px;
    }
    .custom-form-control {
      width: 100%;
    }
  }
  .payment-details {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .payment-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    margin-top: 20px !important;
  }
  @media only screen and (max-width: 600px) {
    .form-group {
      width: 100%;
    }
  }
}

.tenant_rules_wrapper {
  padding: 5%;
  border-radius: 10px;
  background-color: #262626;
  // display: flex;
  // align-items: center;
  // justify-content: center;

  .description {
    img {
      width: 100%;
      height: 450px;
      object-fit: cover;
    }
  }
}

.tenant-annoucement-wrapper {
  padding: 1rem 2rem;
  .heading {
    font-weight: 600;
    text-align: center;
    margin-bottom: 1rem;
  }

  .annoucemnt-list-wrapper {
    border-radius: 10px;
    height: 75vh;
    padding: 10px;
    // overflow-y: scroll;

    .annoucement-detail-wrapper {
      margin: 10px 0;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      // background-color: #151517;
      cursor: pointer;

      .annouce-date-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
      }
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

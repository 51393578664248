.sign-up-swrapper {
    display: flex;
    flex-direction: row;
    height: 100vh;
    .image {
      width: 30%;
      height: 100%;
      background-image: url(../../assets/images/properties.jpeg);
      background-repeat: no-repeat;
      background-size: cover;
    }
    .form-wrapper {
      width: 70%;
      height: 100%;
      overflow: auto;
      padding: 5% 3%;
      .form {
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        .form-group {
          width: 49%;
          margin-bottom: 2%;
        }

        .text-area{
          width: 100%;
        }
      }
    }
    .form-group-flex {
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
      .custom-form-control {
        width: 49%;
      }
    }
    .logo-wrapper {
      text-align: center;
      margin-bottom: 30px;
      img {
        width: 15%;
        min-width: 100px;
      }
    }
  
    .footer {
      margin-top: 20px;
    }
  
    .sigining-up,
    .error-while-submitting {
      margin: 20px 0;
      font-weight: 500;
    }
  
    @media only screen and (max-width: 1200px) {
      .image {
        display: none;
      }
      .form-wrapper {
        width: 100%;
      }
    }
  
    @media only screen and (max-width: 600px) {
      display: block;
      .form-wrapper .form .form-group {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  
.invite-tenant-component-wrapper {
  p {
    margin: 0;
  }
  .form-wrapper .invitation-form {
    background-color: #262626;
    padding: 3%;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    // width: 100%;
    // max-width: 1000px;
    margin: 0 auto;
  }
  .search-tenant-component-wrapper {
    // width: 100%;
    // max-width: 1000px;
    margin: 0 auto;
  }
  .button-wrapper {
    // width: 100%;
    // max-width: 1000px;
    margin: 30px auto 0;
    .custom-text-btn {
      margin-top: 20px;
    }
  }

  .form-group {
    width: 49%;
    margin-bottom: 20px;
  }
  .form-group-full {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .custom-form-control {
      width: 100%;
    }
    .custom-radio-component-wrapper {
      margin-right: 50px;
    }
  }
  .installment-list-wrapper {
    width: 100%;
    width: 100%;
    max-width: 1200px;
    margin: 30px auto 0px;
    padding: 3%;
    border-radius: 10px;
    background-color: #262626;
    .table-responsive {
      overflow: initial;
    }
    .title {
      margin: 30px 0 20px;
    }
    .custom-table-wrapper {
      padding: 0;
    }
    .status-icon-wrapper {
      width: 10%;
      margin: 20px auto;
    }
    .action-btn {
      height: 30px;
    }
    .amt-input {
      width: max-content;
      display: inline-block;
    }
    .edit-input {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
    .amount-column-wrapper {
      width: 350px;
    }
    .date-column-wrapper {
      width: 350px;
      .custom-date-input-main-wrapper-v2 {
        width: 100%;
        .datepicker {
          width: 100%;
          .react-calendar {
            width: 300px;
          }
        }
      }
    }
  }
  .edit-white {
    height: 25px;
    img {
      height: 100%;
    }
  }
  .btn_mid {
    width: 100%;
  }
  .checkbox {
    display: flex;
  }
  .check-box-group-wrapper {
    margin-bottom: 20px;
  }
  .custom-check-input-component-wrapper {
    margin-right: 10px;
  }
  .checkbox-control {
    margin-right: 20px;
  }
  .deposit-form-group {
    margin-bottom: 20px;
    .check {
      width: 100%;
    }
  }
  .deposit-field-group {
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .deposit-remove-btn-wrapper {
    // margin-bottom: 20px;
  }
  .deposit-add-btn-wrapper {
    margin-bottom: 20px;
  }
  .installment-plan-detail-wrapper {
    .custom-row {
      margin-bottom: 20px;
      .custom-column {
        width: 48%;
        display: flex;
        justify-content: space-between;
        .label {
          width: 48%;
        }
        .value {
          width: 48%;
        }
      }
    }
    .custom-row-flex {
      display: flex;
      justify-content: space-between;
    }
    .custom-row-flex-2 .custom-column {
      width: 48%;
    }
  }
  .cheque-flow-btn { 
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 15px;
  }
  .icon-md {
    width: 30px;
  }
  .icon-lg {
    width: 35px;
  }
  .icon-xl {
    width: 40px;
  }
  .icon-xxl {
    width: 45px;
  }
  .icon-xxxl {
    width: 50px;
  }
  .action-column {
    // vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .last-row-cells {
    border-top: 3px solid #37bdb070;
    padding-top: 20px;
    vertical-align: middle;
  }
  @media only screen and (max-width: 1800px) {
    .installment-list-wrapper .action-btn {
      height: 27px;
      margin-left: 10px;
    }
    .edit-white {
      height: 23px;
    }
    .icon-md {
      width: 27px;
    }
    .icon-lg {
      width: 32px;
    }
    .icon-xl {
      width: 37px;
    }
    .icon-xxl {
      width: 42px;
    }
    .icon-xxxl {
      width: 47px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .installment-list-wrapper .action-btn {
      height: 24px;
    }
    .edit-white {
      height: 21px;
    }
    .icon-md {
      width: 24px;
    }
    .icon-lg {
      width: 29px;
    }
    .icon-xl {
      width: 34px;
    }
    .icon-xxl {
      width: 39px;
    }
    .icon-xxxl {
      width: 44px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .installment-list-wrapper .action-btn {
      height: 21px;
    }
    .edit-white {
      height: 19px;
    }
    .icon-md {
      width: 21px;
    }
    .icon-lg {
      width: 26px;
    }
    .icon-xl {
      width: 31px;
    }
    .icon-xxl {
      width: 36px;
    }
    .icon-xxxl {
      width: 41px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .installment-list-wrapper .action-btn {
      height: 18px;
    }
    .edit-white {
      height: 17px;
    }
    .icon-md {
      width: 18px;
    }
    .icon-lg {
      width: 23px;
    }
    .icon-xl {
      width: 28px;
    }
    .icon-xxl {
      width: 33px;
    }
    .icon-xxxl {
      width: 38px;
    }
  }
  @media only screen and (max-width: 600px) {
    .form-group {
      width: 100%;
    }
  }
}

.contract-fee-payment-module-wrapper {
  p {
    margin: 0;
  }
  .transaction-status-wrapper-main {
    position: static;
    .icon {
      padding-top: 0 !important;
    }
  }
  .pay-online {
    margin: 20px 0;
  }
  .amt-list {
    margin-bottom: 30px;
    padding: 1rem 2rem;
      border-radius: 10px;
    .amt-item {
      justify-content: space-between;
      display: flex;
      margin-bottom: 20px;

      .name,
      .totalAmt {
        width: 50%;
      }
    }
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .pay-online {
      margin: 15px 0;
    }
    .amt-list {
      margin-bottom: 20px;
      .amt-item {
        margin-bottom: 10px;
      }
    }
  }
  @media only screen and (max-width: 600px) {
  }
}

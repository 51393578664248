.custom-btn {
  border-radius: 10px;
  letter-spacing: 1px;
  font-weight: 600;
  border: 0;
  cursor: pointer;
  &.btn_lg {
    display: block;
    width: 100%;
    height: 55px;
  }
  &.btn_md {
    height: 45px;
    border-radius: 7px;
  }
  &.btn_sm {
    height: 40px;
    border-radius: 5px;
  }
  &.outline {
    border: 3px solid #37bdb0;
    background: transparent;
  }
  &.outline-red {
    border: 3px solid #ff1e1e;
    background: transparent;
  }
  &.yellow {
    // background-color: #ffef27a8;
    background-color: #ffb900;
  }

  @media only screen and (max-width: 1800px) {
    &.btn_lg {
      height: 52px;
    }
    &.btn_md {
      height: 42px;
    }
    &.btn_sm {
      height: 35px;
    }
  }

  @media only screen and (max-width: 1600px) {
    &.btn_lg {
      height: 47px;
    }
    &.btn_md {
      height: 37px;
    }
    &.btn_sm {
      height: 30px;
    }
  }

  @media only screen and (max-width: 1400px) {
    &.btn_lg {
      height: 41px;
    }
    &.btn_md {
      height: 31px;
    }
    &.btn_sm {
      height: 25px;
    }
  }

  @media only screen and (max-width: 1200px) {
    &.btn_lg {
    }
    &.btn_md {
      border-radius: 5px;
    }
    &.btn_sm {
      border-radius: 5px;
    }
    border-radius: 7px;
  }

  @media only screen and (max-width: 900px) {
    &.btn_lg {
      height: 35px;
    }
    &.btn_md {
      height: 25px;
    }
    &.btn_sm {
      height: 25px;
    }
  }

  @media only screen and (max-width: 600px) {
    .btn_lg {
    }
  }
}

.loader-component-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #000000;
  align-items: center;
  .logo-wrapper {
    margin: 0 auto;
    width: 20%;
    margin-bottom: 20px;
    min-width: 100px;
  }
  .progress-bar {
    max-width: 300px;
    margin: 0 auto;
    height: 10px;
    border: 2px solid #37bdb0;
    border-radius: 100px;
    .progress {
      transition: 500ms;
      height: 100%;
      background-color: #37bdb0;
    }
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

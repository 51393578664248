.profile-page-wrapper {
  padding-bottom: 100px;
  position: relative;
  z-index: 10;
  p {
    margin: 0;
  }
  .detail-wrapper {
    background-color: #262626;
    padding: 3%;
    border-radius: 10px;

    .deactive-btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: end;
      .deactive-btn {
        padding: 10px 20px 10px;
        border-radius: 10px;
        background-color: #ff1e1e;
        color: white;
        font-weight: 500;
        cursor: pointer;
        border: none;
      }
    }
  }
  .general-detail {
    .email > img {
      width: 20px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .prof-pic {
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    width: 300px;
    height: 300px;
  }
  .name {
    margin-top: 30px;
  }
  .role {
    margin-top: 20px;
  }
  .other-detail {
    width: 90%;
    margin: 50px auto 0;
    border-radius: 10px;
    padding: 5%;
    margin-bottom: 30px;
    background-color: #151517;
  }
  .detail-group {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .key {
      width: 25%;
    }
    .value {
      width: 70%;

      img {
        width: 20px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
  @media only screen and (max-width: 1800px) {
    .prof-pic {
      width: 270px;
      height: 270px;
    }
  }
  .edit {
    img {
      width: 27px;
    }
  }
  .bread-crumb-edit {
    display: flex;
    justify-content: space-between;
  }
  @media only screen and (max-width: 1600px) {
    .prof-pic {
      width: 240px;
      height: 240px;
    }
    .edit {
      img {
        width: 24px;
      }
    }
  }
  @media only screen and (max-width: 1400px) {
    .prof-pic {
      width: 210px;
      height: 210px;
    }
    .edit {
      img {
        width: 21px;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .prof-pic {
      width: 180px;
      height: 180px;
    }
    .name {
      margin-top: 20px;
    }
    .detail-group {
      margin-bottom: 10px;
    }
    .other-detail {
      margin-top: 30px;
    }
    .role {
      margin-top: 10px;
    }
    .detail-wrapper {
      padding: 30px 10px;
    }
    .edit {
      img {
        width: 18px;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .prof-pic {
      width: 150px;
      height: 150px;
    }
    .detail-group {
      display: block;
      .key {
        width: 100%;
      }
      .value {
        width: 100%;
      }
    }
  }
}

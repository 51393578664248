.purchase-license-page-wrapper {
  .card-list-wrapper-main {
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    background-color: #262626;
  }

  .button-wrapper {
    margin-top: 30px;
    .custom-text-btn {
      margin-top: 20px;
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

.visitor-sign-out-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .visitor-log-header {
    text-align: center;
  }

  .report-wrapper {
    .detail-box {
      margin-top: 20px;
      border-radius: 10px;
      padding: 15px;
      background-color: #262626;
      .visitor-unit-detail-box {
        margin: 15px 35px;
        color: white;

        .log-header {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 20px;

          .img-wrapper {
            img {
              width: 85px;
              height: 85px;
              object-fit: cover;
              border-radius: 10px;
            }
          }

          .detail {
            margin: 10px;

            .detail-report {
              padding-top: 25px;

              .num {
                margin-bottom: 10px;
                color: #37bdb0;
              }

              p {
                margin-top: 10px;

                span {
                  margin-left: 10px;
                }
              }
            }
          }
        }

        .visitor-info {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
        }

        .time-info {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 85px;
        }

        .id-proof {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 15px;

          .proofs {
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 15px;
            margin-left: 90px;
            .proof-img {
              display: flex;
              align-items: center;
              justify-content: space-between;

              img {
                width: 75px;
                height: 75px;
                object-fit: cover;
                border-radius: 10px;
              }
            }
          }
        }
      }
    }

    .detail-form-box {
      margin-top: 2rem;

      .signout-form {
        .form-wrapper {
          background-color: #262626;
          padding: 3%;
          border-radius: 10px;
          display: flex;
          flex-wrap: wrap;
          gap: 2%;
        }

        .button-wrapper {
          margin-top: 30px;
          .custom-text-btn {
            margin-top: 20px;
          }

          // button{

          //   &:disabled{
          //     background-color: #505050;
          //     cursor: not-allowed;
          //   }
          // }
        }

        .form-group {
          width: 49%;
          margin-bottom: 20px;
          margin-top: 20px;
        }
        .form-group-full {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          .custom-form-control {
            width: 100%;
          }
        }
      }
    }
  }
}

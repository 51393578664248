.wrapper-tenant-facility-detail {
  display: flex;
  align-items: center;
  flex-direction: column;

  .header-facility {
    position: fixed;
    width: 100%;
    text-align: center;
    background-color: #151517;
    z-index: 1;

    h2 {
      padding: 15px;
    }
  }

  .report-wrapper {
    margin-top: 4rem;
    width: 50%;

    .detail-box {
      margin-top: 20px;
      border-radius: 10px;
      padding: 15px;
      .facility-report-detail {
        margin: 15px;
        display: flex;
        flex-direction: column;

        .facility-main-wrapper {
          display: flex;

          .facility-data {
            flex: 2;
          }

          .facility-img {
            flex: 1;
            padding: 0 0 0 15px;
            img {
              width: 100%;
              height: 90%;
              border-radius: 10px;
              object-fit: cover;
            }
          }
        }

        .terms {
          margin: 15px 0 0 0;
          display: flex;
          flex-direction: row;
          gap: 10px;

          input {
            width: 20px;
            height: 20px;
            outline: #37bdb0;
          }
        }
      }
    }

    .add-card {
      margin: 15px 0;
      width: 100%;

      button {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        color: #37bdb0;
        border: 1px solid #37bdb0;
        background: transparent;
        cursor: pointer;
      }
    }

    .btn-wrapper {
      margin: 20px 0;
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .report-wrapper {
      width: 75%;
    }
  }
  @media only screen and (max-width: 600px) {
    .report-wrapper {
      width: 85%;
    }
  }
}

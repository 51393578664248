.change-mobile-wrapper {
  .center {
    width: 100%;
    border-radius: 10px;
    padding: 70px 70px;
  }

  .logo-wrapper {
    margin-bottom: 50px;
    img {
      width: 15%;
      min-width: 100px;
    }
  }

  .form-group {
    margin-bottom: 30px;
  }

  .button-wrapper {
    margin: 20px 0;

    .text-btn {
      margin-top: 20px;
      width: 100%;
    }
  }

  .error-while-submitting,
  .sigining-in {
    font-weight: 500;
  }

  @media only screen and (max-width: 1800px) {
    .center {
    }
  }
  @media only screen and (max-width: 1600px) {
    .center {
    }
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
    .logo-wrapper {
      margin-bottom: 30px;
    }

    .center {
      padding: 50px 40px;
    }
  }
  @media only screen and (max-width: 900px) {
    .logo-wrapper {
      margin-bottom: 20px;
    }
    .form-group {
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width: 600px) {
    .center {
      padding: 40px 30px;
    }
  }
}

.tenant-detail-table-module-wrapper {
  width: 100%;

  // Contract Table
  .contract-table {
    margin-top: 0.5rem;
    .action-button {
      height: 30px;
      margin-right: 20px;
    }
  }

  // Rent Table

  .wrapper {
    padding: 30px;
    border-radius: 10px;
    width: 100%;
    background-color: #262626;

    .accordion-button {
      background-color: #151515;
      color: #37bdb0;
      font-weight: 600;
    }

    .accordion-body {
      padding: 0 !important;
    }

    .accord-heading-wrapper {
      width: 100%;
      display: flex;
      margin-right: 2rem;

      .accord-one {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        flex: 2;

        .name {
          // background-color: #262626;
          color: #37bdb0;
          font-weight: 600;
        }
      }

      .accord-two {
        display: flex;
        justify-content: end;
        flex: 1;
      }
    }

    .accordion-button::after {
      color: #37bdb0 !important;
      // background-image: none;
      position: absolute;
      right: 30px;
      top: 25px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='17.5' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill='%2337bdb0' d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'/%3E%3C/svg%3E");
    }

    .accordion-button:not(.collapsed) {
      color: #37bdb0;
      background-color: #151517;
      box-shadow: none;
    }

    .accordion-button:focus {
      z-index: unset;
      border-color: unset;
      box-shadow: unset;
    }

    .accordion-item {
      background-color: inherit;
      border: solid #151517;
      // border: none;
    }

    .accordion-item:last-of-type .accordion-collapse {
      border: solid #151517;
    }

    &:last-child {
      margin: 0;
    }

    .rent-table {
      margin-top: 0.5rem;

      .paid {
        color: #37bdb0;
      }

      .unpaid {
        color: goldenrod;
      }

      .action-button {
        height: 25px;

        &:last-child{
          padding-left: 10px;
        }
      }
    }
  }

  // Deposit Table
  .deposit-table {
    margin-top: 0.5rem;

    .paid {
      color: #37bdb0;
    }

    .pending {
      color: goldenrod;
    }
    .modified {
      color: white;
    }

    .action-button {
      height: 30px;
    }
  }

  // Invoice Table
  .invoice-table {
    margin-top: 0.5rem;

    .paid {
      color: #37bdb0;
    }

    .unpaid {
      color: goldenrod;
    }
    .edit-button {
      padding-left: 15px;
      .edit-img {
        height: 30px;
      }
    }

    .action-button {
      height: 30px;

      .add {
        padding-right: 10px;
        color: #37bdb0;
        cursor: pointer;
      }
    }

    .action-btn {
      height: 30px;

      .add {
        padding-left: 10px;
        cursor: pointer;
      }
    }

    p {
      cursor: pointer;
    }
  }

  // Other Details Page
  .otherDetails-wrapper {
    // ID Details
    .idVerify-wrapper {
      margin-top: 15px;
      .custom-table-wrapper {
        img {
          width: 25px;
          height: 25px;
        }

        .view {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    // Vehicle
    .vehicle-wrapper {
      margin-top: 15px;
      .custom-table-wrapper {
        img {
          width: 25px;
          height: 25px;
        }

        .view {
          // color: #37bdb0;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    // Pet
    .pet-wrapper {
      margin-top: 15px;
      .custom-table-wrapper {
        img {
          width: 25px;
          height: 25px;
        }
        .view {
          color: #37bdb0;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    // Utility
    .utility-wrapper {
      margin-top: 15px;
      .custom-table-wrapper {
        img {
          width: 25px;
          height: 25px;
        }

        .view {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  // Tenant History Page
  .tenantHistory-wrapper {
    // Tenant Table
    .tenant-wrapper {
      margin-top: 15px;
      .custom-table-wrapper {
        img {
          width: 25px;
          height: 25px;
        }
      }
    }

    // Non tenant
    .nonTenant-wrapper {
      margin-top: 15px;
      .custom-table-wrapper {
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  // Facilities Table
  .facilities-table {
    margin-top: 0.5rem;

    .renewed {
      color: #37bdb0;
    }

    .pending {
      color: goldenrod;
    }
  }

  // Maintenance Table
  .maintenance-table {
    margin-top: 0.5rem;

    .action-button {
      height: 30px;
    }
  }

  // Security Table
  .security-table {
    margin-top: 0.5rem;

    .action-button {
      height: 30px;
    }
  }

  // Violation Table
  .violation-table {
    margin-top: 0.5rem;

    .action-button {
      height: 30px;
    }

    p {
      cursor: pointer;
    }
  }

  // Notification Table
  .notification-table {
    margin-top: 0.5rem;

    .action-button {
      height: 30px;
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        border-radius: 20px;
        background: transparent;
        border: 1px solid #37bdb0;
        padding: 10px 25px;
        cursor: pointer;
      }
    }
  }

  // Onboarding Page
  .onboarding-wrapper {
    padding: 15px 30px;
    border-radius: 10px;
    width: 100%;
    background-color: #262626;

    .accordion-button {
      background-color: #151515;
      color: #37bdb0;
      font-weight: 600;
    }

    // .accordion-body {
    //   padding: 0 !important;
    // }

    .accord-heading-wrapper {
      width: 100%;
      display: flex;
      margin-right: 2rem;

      .accord-one {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        flex: 2;

        .name {
          // background-color: #262626;
          color: #37bdb0;
          font-weight: 600;
        }
      }

      .accord-two {
        display: flex;
        justify-content: end;
        flex: 1;
      }
    }

    .accordion-button::after {
      color: #37bdb0 !important;
      // background-image: none;
      position: absolute;
      right: 30px;
      top: 25px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='17.5' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill='%2337bdb0' d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'/%3E%3C/svg%3E");
    }

    .accordion-button:not(.collapsed) {
      color: #37bdb0;
      background-color: #151517;
      box-shadow: none;
    }

    .accordion-button:focus {
      z-index: unset;
      border-color: unset;
      box-shadow: unset;
    }

    .accordion-item {
      background-color: inherit;
      border: solid #151517;
      // border: none;
    }

    .accordion-item:last-of-type .accordion-collapse {
      border: solid #151517;
    }

    &:last-child {
      margin: 0;
    }

    // ID Details
    .idVerify-wrapper {
      margin-top: 15px;
      margin-bottom: 10px;
      .custom-table-wrapper {
        background-color: #151517;

        .custom-table thead {
          background-color: #262626;
        }
        img {
          width: 25px;
          height: 25px;
        }

        .view {
          // color: #37bdb0;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    // Vehicle
    .vehicle-wrapper {
      margin-top: 15px;
      margin-bottom: 10px;

      .custom-table-wrapper {
        background-color: #151517;

        .custom-table thead {
          background-color: #262626;
        }

        img {
          width: 25px;
          height: 25px;
        }

        .view {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    // Pet
    .pet-wrapper {
      margin-top: 15px;
      margin-bottom: 10px;
      .custom-table-wrapper {
        background-color: #151517;

        .custom-table thead {
          background-color: #262626;
        }

        img {
          width: 25px;
          height: 25px;
        }
        .view {
          color: #37bdb0;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    // Utility
    .utility-wrapper {
      margin-top: 15px;
      margin-bottom: 10px;
      .custom-table-wrapper {
        background-color: #151517;

        .custom-table thead {
          background-color: #262626;
        }

        img {
          width: 25px;
          height: 25px;
        }

        .view {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    // Household Members
    .household-wrapper {
      margin-top: 20px;

      .heading-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .label {
          padding-left: 30px;
        }
        .time {
          color: rgb(145, 143, 143);
        }
      }
      .detail-box {
        padding: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        background-color: #151517;

        .header {
          text-align: start;
          padding: 5px 20px;
          background-color: #262626;
          border-radius: 10px;
          width: 100%;
        }

        .household {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-auto-rows: auto;
          border-radius: 10px;
          width: 100%;

          .household-detail {
            margin: 5px 20px;

            p {
              img {
                width: 25px;
                height: 25px;
              }
              .view {
                color: #37bdb0;
                text-decoration: underline;
              }
            }

            .btn-wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 150px;
              gap: 10px;
              cursor: pointer;

              .approve {
                padding: 5px 10px;
                color: #37bdb0;
                border: 1px solid #37bdb0;
                border-radius: 10px;
              }
              .reject {
                padding: 5px 10px;
                color: red;
                border: 1px solid red;
                border-radius: 10px;
              }
            }
          }
        }
      }
    }

    .household-wrapper .detail-box {
      padding: 15px 10px;
      border-radius: 10px;
    }

    //Application .otherDetails-wrapper
    .application-wrapper {
      .custom-table-wrapper {
        background-color: #151517;

        .custom-table thead {
          background-color: #262626;
        }

        img {
          width: 25px;
          height: 25px;
        }
        .view {
          color: #37bdb0;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    // Rent History Details
    .rent-wrapper {
      .custom-table-wrapper {
        background-color: #151517;

        .custom-table thead {
          background-color: #262626;
        }

        img {
          width: 25px;
          height: 25px;
        }
        .view {
          color: #37bdb0;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    // Rent Details
    .rent-detail-wrapper {
      margin-top: 20px;

      .rent-detail-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        // .label {
        //   padding-left: 30px;
        // }
        .time {
          color: rgb(145, 143, 143);
        }
      }

      .custom-table-wrapper {
        background-color: #151517;

        .custom-table thead {
          background-color: #262626;
        }
        // border-bottom-left-radius: 0;
        // border-bottom-right-radius: 0;
        .install {
          &:nth-child(even) {
            background-color: #151517;
            border-radius: 10px;
            margin: 5px 0;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
        .action {
          .btn-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 150px;
            gap: 10px;
            cursor: pointer;

            .approve {
              padding: 5px 10px;
              color: #37bdb0;
              border: 1px solid #37bdb0;
              border-radius: 10px;
            }
            .reject {
              padding: 5px 10px;
              color: red;
              border: 1px solid red;
              border-radius: 10px;
            }
          }
        }
      }
      .button-wrapper {
        width: 50%;
        margin: auto;
        // border-bottom-left-radius: 7px;
        // border-bottom-right-radius: 7px;
        // padding: 3%;
      }
      .more-details-edit {
        margin-bottom: 20px;
        .more-details {
          // display: grid;
          // grid-template-columns: repeat(3, 250px);
          display: flex;
          flex-direction: column;

          .more-data {
            display: flex;
            // align-items: center;
            justify-content: space-between;
          }
          p {
            margin: 0;
          }
          .c-row {
            display: flex;
            margin-bottom: 10px;
            // justify-content: space-around;
            width: 375px;
            .key {
              width: 50%;
            }
          }
        }
        .edit-wrapper {
          margin-bottom: 20px;
          display: flex;
          align-items: flex-end;
          .installment-plan-edit-btn {
            margin-right: 10px;
          }
        }
      }

      .installment-summ-wrapper {
        border-radius: 10px;
        padding: 3%;
        margin-top: 10px;
        .custom-row {
          display: flex;
          .right {
            margin-left: 10px;
          }
        }
      }
    }

    .initial-agreement-wrapper {
      margin-top: 20px;

      .initial-agreement-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        // .label {
        //   padding-left: 30px;
        // }
        // .time {
        //   color: rgb(145, 143, 143);
        // }
      }

      .custom-table-wrapper {
        background-color: #151517;

        .custom-table thead {
          background-color: #262626;
        }
        img {
          width: 25px;
          height: 25px;
        }
        .view {
          color: #37bdb0;
          text-decoration: underline;
        }

        .action {
          .btn-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 150px;
            gap: 10px;
            cursor: pointer;

            .approve {
              padding: 5px 10px;
              color: #37bdb0;
              border: 1px solid #37bdb0;
              border-radius: 10px;
            }
            .reject {
              padding: 5px 10px;
              color: red;
              border: 1px solid red;
              border-radius: 10px;
            }
          }
        }
      }
    }

    // Keys Details
    .keys-wrapper {
      .custom-table-wrapper {
        background-color: #151517;

        .custom-table thead {
          background-color: #262626;
        }

        img {
          width: 25px;
          height: 25px;
        }
        .view {
          color: #37bdb0;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  // Request Table
  .request-table {
    margin-top: 0.5rem;

    .action-button {
      height: 30px;
    }
  }

  // Move Out Table
  .move-out-table {
    .action-button {
      height: 30px;
    }
  }

  // Unit Details Page
  .unit-detail-wrapper {
    margin-top: 15px;
    padding: 15px 25px;
    border-radius: 10px;

    .property_name {
      margin-left: 20px;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 25px;
      padding-top: 10px;
    }

    .unit-detail {
      padding: 10px 25px;
      .unit-num {
        display: flex;
        // align-items: center;
        flex-direction: row;
        gap: 75px;
      }

      .unit-floor {
        margin-top: 10px;
        display: flex;
        flex-flow: row wrap;

        div {
          width: 140px;
          height: 75px;
        }
      }
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

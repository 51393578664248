.tenant-maintenance-table-wrapper {
  padding-top: 1rem;
  width: 100%;

  .main-desc {
    white-space: nowrap;
    width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .completed {
    color: #37bdb0;
  }

  .pending {
    color: goldenrod;
  }
  .rejected {
    color: red;
  }

  .action-button {
    height: 25px;
  }
}

.request-table-wrapper {
  margin-top: 1rem;
  padding-top: 1rem;
  width: 100%;

  .completed {
    color: #37bdb0;
  }
  .open {
    color: green;
  }
  .progress {
    color: goldenrod;
  }

  .action-button {
    height: 30px;
  }
}

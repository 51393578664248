.reassign-manager-modal-wrapper {
  .modal-header-custom {
    margin-bottom: 40px;
    .icon-wrapper {
      img {
        width: 19%;
      }
    }
    h2 {
      font-weight: 600;
    }
  }
  .modal-body {
    padding: 8% 10%;
  }
  .description {
    margin: 5% 0;
  }
  .warning-icon-wrapper {
    width: 100%;
    max-width: 100px;
    margin: 0 auto 30px;
    img {
      width: 100%;
    }
  }

  .modal-footer-custom {
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }

  .note-wrapper {
    margin-bottom: 40px;
    width: 100%;

    .userProfile {
      margin-top: 10px;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 20px;

      .userImage img {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  .details {
    margin: 10px 0;
    p {
      margin: 0 10px 0 0;
    }
    display: flex;
  }
  @media only screen and (max-width: 900px) {
    .modal-header-custom {
      margin-bottom: 20px;
    }

    .description {
      margin: 20px 0;
    }

    .modal-footer-custom {
      flex-direction: column-reverse;
      button {
        margin-top: 20px;
        width: 100%;
      }
    }

    .note-wrapper {
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
  }
}

.internal-maintenance-wrapper {
  padding: 1.5rem;

  .heading {
    font-weight: 600;
    text-align: center;
    margin-bottom: 1rem;
    position: relative;
    z-index: 10;
    // position: fixed;

    .link {
      text-align: right;
    }
  }

  // New Request Table
  .newRequest-table {
    .action-button {
      height: 30px;
      // margin-left: 20px;
    }
  }

  // On Going Request Table
  .ongoing-table {
    .action-button {
      height: 30px;
      margin-left: 20px;
    }
  }

  // Spare Part Request Table
  .spareRequest-table {
    // Status
    .complete {
      color: #37bdb0;
    }
    .pending {
      color: goldenrod;
    }

    .action-button {
      height: 30px;
      // margin-left: 20px;
    }
  }

  // Completed Request Table
  .completeRequest-table {
    .action-button {
      height: 30px;
      margin-left: 20px;
    }
  }

  // Rejected Request Table
  .rejectRequest-table {
    .action-button {
      height: 30px;
      margin-left: 20px;
    }
  }

  // Closed Request Table
  .closedRequest-table {
    .action-button {
      height: 30px;
      margin-left: 20px;
    }
  }
}

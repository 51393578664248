.tenant-notification-modal-wrapper {
  .modal-header-custom {
    .icon-wrapper {
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
    h2 {
      margin-top: 20px;
    }
  }
  .modal-body {
    padding: 8% 10%;
    border: 2px solid grey;
  }
  .modal-body-custom {
    margin: 1%;

    .form-wrapper {
      padding: 3%;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
    }

    .form-btn-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      gap: 10px;
      padding: 10px;

      .btn1 {
        background: none;
        color: #37bdb0;
        border: 2px solid #37bdb0;
        border-radius: 10px;
      }
    }

    .form-group {
      width: 49%;
      margin-bottom: 20px;
      margin-top: 20px;

      .add-image {
        margin-top: 20px;
      }

      .txtBtn {
        color: #37bdb0;
        margin: 10px;
        padding-right: 75px;
      }

      .action-button {
        display: inline-block;

        .remove {
          outline: none;
          color: red;
          background: transparent;
        }
      }
    }

    .form-group-full {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .custom-radio-component-wrapper {
        margin-right: 50px;
      }
      .custom-form-control {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
  }
}

.location-picker-modal-wrapper {
  // min-height: 70vh;

  .modal-header-custom {
    padding: 10px 30px;
    position: relative;
    .icon-wrapper {
      img {
        width: 25%;
      }
    }
    h2 {
      font-weight: 600;
    }
  }
  .modal-body {
    padding: 0;
    border: 2px solid #939393;
    background-color: #fff;
  }
  .description {
    margin: 5% 0;
  }

  .modal-footer-custom {
    display: flex;
    justify-content: center;
    button {
      width: 50%;
    }
  }

  .custom-search {
    padding: 10px;
    input {
      color: #000;
      border: 0;
      width: 100%;
      border: 2px solid lightgrey;
      padding: 5px 10px;
      border-radius: 5px;
    }
  }
  .close-btn {
    // position: absolute;
    // top: 30px;
    // right: 30px;
  }
  .close-icon {
    width: 27px;
  }
  .save-btn {
    margin-right: 30px;
  }
  .g-map-logo {
    position: absolute;
    top: 2px;
    left: 20px;
    height: 50px;
  }

  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
    .close-icon {
      width: 20px;
    }
  }
}

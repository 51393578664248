.custom-currency-input-wrapper-2 {
  input {
    display: block;
    width: 100%;
    border: 0;    
    background-color: transparent;
    &:focus {
      outline: none;
      border: 2px solid #37BDB0;
    }
  }
}

.account-setting-page-wrapper {
  .inner-wrapper {
    padding: 3%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: #262626;

    .option {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  @media only screen and (max-width: 1800px) {
  }

  @media only screen and (max-width: 1600px) {
  }

  @media only screen and (max-width: 1400px) {
  }

  @media only screen and (max-width: 1200px) {
  }

  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

/* Variant 1 Starts */
.custom-date-input-main-wrapper-v1 {
  position: relative;
  label {
    display: block;
    font-weight: 600;
    position: absolute;
    top: 17px;
    left: 22px;
    // white-space: nowrap;
  }
  input {
    display: block;
    width: 100%;
    border: 0;
    padding: 42px 22px 17px;
    border-radius: 10px;
    cursor: pointer;
    color: green !important;
    &:focus {
      outline: none;
      border: 2px solid #37bdb0;
    }
  }
  .react-datepicker {
    // position: absolute;
    // top: 100%;
    // left: 0;
  }
  .back-drop {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
  }
  .react-datepicker__year-option,
  .react-datepicker__year-read-view--selected-year,
  .react-datepicker__navigation--years {
    color: #f30808;
    font-weight: 500;
  }
  .react-datepicker__year-read-view--selected-year {
    font-weight: 600;
  }
  .react-datepicker__navigation--years {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
    background-image: url("../../../../assets//png_icons/arrow_up_black.png");
  }
  .react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
    background-image: url("../../../../assets//png_icons/arrow_down_black.png");
  }
  .react-datepicker__input-time-container {
    * {
      color: #eb1313;
    }
  }
  .error {
    margin: 5px 0 0;
    font-weight: 400;
  }

  @media only screen and (max-width: 1800px) {
    input {
      padding: 39px 19px 14px;
    }
    label {
      top: 14px;
      left: 19px;
    }
  }
  @media only screen and (max-width: 1600px) {
    input {
      padding: 35px 15px 10px;
    }
    label {
      top: 10px;
      left: 15px;
    }
  }
  @media only screen and (max-width: 1400px) {
    input {
      padding: 30px 14px 9px;
    }
    label {
      top: 9px;
      left: 14px;
    }
  }
  @media only screen and (max-width: 1200px) {
    input {
      border-radius: 7px;
    }
  }
  @media only screen and (max-width: 900px) {
    input {
      padding: 26px 12px 7px;
    }
    label {
      top: 7px;
      left: 12px;
    }
  }
  @media only screen and (max-width: 600px) {
  }
}
/* Variant 1 Ends */

/* ************************************************************************************************************************** */

/* Variant 2 Starts */
.custom-date-input-main-wrapper-v2 {
  .react-calendar {
    * {
      color: #000;
    }
  }
  .datepicker {
    position: relative;
  }
  .react-date-picker {
    width: 100%;
    border-radius: 15px;
  }
  input {
    outline: none !important;
    border: 0 !important;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 0;
    &:focus {
      outline: none !important;
      border: 0 !important;
    }
  }
  label {
    display: block;
    font-weight: 600;
    position: absolute;
    top: 17px;
    left: 22px;
    white-space: nowrap;
  }

  .react-date-picker__wrapper {
    border: 0;
    padding: 42px 22px 17px;
    // background-color: #000;
    border-radius: 10px;
  }
  .react-date-picker__inputGroup__divider {
    padding-top: 0;
    padding-bottom: 0;
  }
  .react-date-picker__clear-button {
    display: none;
  }
  .react-date-picker__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
  .react-date-picker__button svg {
    fill: #939393;
    stroke: #939393;
  }
  .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
  .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    fill: #fff;
    stroke: #fff;
  }
  .react-date-picker__inputGroup__year {
    width: 50px !important;
  }
  .error {
    margin: 5px 0 0;
    font-weight: 400;
  }
  .react-date-picker__calendar {
    z-index: 10;
  }

  @media only screen and (max-width: 1800px) {
    .react-date-picker__wrapper {
      padding: 39px 19px 14px;
    }
    label {
      top: 14px;
      left: 19px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .react-date-picker__wrapper {
      padding: 35px 15px 10px;
    }
    label {
      top: 10px;
      left: 15px;
    }
  }

  @media only screen and (max-width: 1400px) {
    .react-date-picker__wrapper {
      padding: 30px 14px 9px;
    }
    label {
      top: 9px;
      left: 14px;
    }
  }

  @media only screen and (max-width: 1200px) {
  }

  @media only screen and (max-width: 900px) {
    .react-date-picker__wrapper {
      padding: 26px 12px 7px;
    }
    label {
      top: 7px;
      left: 12px;
    }
  }

  @media only screen and (max-width: 600px) {
  }
}
/* Variant 2 Ends */

/* ************************************************************************************************************************** */

/* Variant 3 Starts */
.custom-date-input-main-wrapper-v3 {
  .datepicker {
    position: relative;
    label {
      display: block;
      font-weight: 600;
      position: absolute;
      top: 17px;
      left: 22px;
    }
    input {
      display: block;
      width: 100%;
      border: 0;
      padding: 42px 22px 17px;
      border-radius: 10px;
      position: relative;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
    input::-webkit-calendar-picker-indicator {
      position: absolute;
      right: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      opacity: 0;
      cursor: pointer;
    }
  }

  .error {
    margin: 5px 0 0;
    font-weight: 400;
  }

  @media only screen and (max-width: 1800px) {
    .datepicker {
      input {
        padding: 39px 19px 14px;
      }
      label {
        top: 14px;
        left: 19px;
      }
    }
  }

  @media only screen and (max-width: 1600px) {
    .datepicker {
      input {
        padding: 35px 15px 10px;
      }
      label {
        top: 10px;
        left: 15px;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    .datepicker {
      input {
        padding: 30px 14px 9px;
      }
      label {
        top: 9px;
        left: 14px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .datepicker {
      input {
        border-radius: 7px;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .datepicker {
      input {
        padding: 26px 12px 7px;
      }
      label {
        top: 7px;
        left: 12px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
  }
}
/* Variant 3 Ends */

.custom-date-input-main-wrapper-v2.floating-calender {
  // .custom-datepicker-calender {
  //   display: block !important;
  //   position: static !important;
  // }
  .react-date-picker--open.react-date-picker--enabled {
    display: block !important;
    &:last-child {
      display: block !important;
    }
  }
  .react-date-picker__calendar.react-date-picker__calendar--open {
    position: relative !important;
    height: auto !important;
  }
  .react-date-picker__calendar--open {
    inset: auto !important;
  }
}

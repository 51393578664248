.application-under-review-status {
  .status-icon {
    width: 100px;
  }
  .cancel_renewal {
    margin: 30px 0;
    text-align: center;
    width: 100%;
  }
}
.review_tenant_renewal_application {
  .cancel_renewal {
    margin: 30px 0;
    text-align: center;
    width: 100%;
  }
}
.tenant_renewal_info_update {
  .cancel_renewal {
    margin: 30px 0;
    text-align: center;
    width: 100%;
  }
}

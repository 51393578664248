.payment-setting-page-wrapper {
  .inner-wrapper {
    padding: 3%;
    border-radius: 10px;
    background-color: #262626;
  }
  .btn-wrapper {
    margin-bottom: 30px;
    position: relative;
    z-index: 10;
  }
  .heading {
    margin-bottom: 30px;
  }

  .stripe-wrapper {
    .flex-card {
      .custom_row {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
      }
      .key {
        width: 20%;
      }
      .value {
        width: 77%;
      }
      .row3 .value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .url {
          width: 90%;
          input {
            width: 100%;
            background: transparent;
            border: 0;
            &:focus {
              outline: none;
            }
          }
        }
        .icon-wrapper {
          flex-shrink: 0;
        }
      }
    }
    .copy-icon {
      width: 25px;
      margin-left: 20px;
    }
  }
  .edit-btn {
    color: white;
  }

  @media only screen and (max-width: 1800px) {
    .stripe-wrapper .copy-icon {
      width: 23px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .stripe-wrapper .copy-icon {
      width: 21px;
    }
  }

  @media only screen and (max-width: 1400px) {
    .stripe-wrapper .copy-icon {
      width: 19px;
    }
  }

  @media only screen and (max-width: 1200px) {
  }

  @media only screen and (max-width: 900px) {
    .btn-wrapper {
      margin-bottom: 20px;
    }
    .heading {
      margin-bottom: 20px;
    }
    .stripe-wrapper .copy-icon {
      width: 17px;
    }
  }
  @media only screen and (max-width: 600px) {
    .stripe-wrapper {
      .flex-card {
        .custom_row {
          display: block;
          margin-top: 30px;
        }
        .key {
          width: 100%;
          margin-bottom: 10px;
        }
        .value {
          width: 100%;
        }
      }
    }
  }
}

.found-wrapper {
  padding: 0 2rem;

  .heading-wrapper {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 10;
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

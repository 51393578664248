.assign-technician-modal-wrapper {
  .modal-header-custom {
    padding: 5px;
    margin-bottom: 20px;
  }
  .modal-body {
    padding: 5% 5%;
  }
  .form-group-wrapper {
    width: 100%;
  }
  .modal-body-custom {
    .form-wrapper {
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
    }

    .form-btn-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      gap: 15px;

      padding: 10px 0;
      button {
        width: 48%;
      }
      .btn1 {
        background: none;
        color: #37bdb0;
        border: 2px solid #37bdb0;
        border-radius: 10px;
      }
    }

    .form-group {
      width: 49%;
      margin-bottom: 20px;

      .tenant-time {
        width: 100%;
        display: flex;
        background-color: black;
        flex-direction: column;
        padding: 15px 12px 0 20px;
        border-radius: 10px;
        gap: 5px;
      }

      .num-wrapper {
        border-radius: 10px;
        display: flex;
        height: 60px;
        justify-content: space-between;

        gap: 8px;

        .number {
          flex: 2;
          display: flex;
          border-radius: 10px;

          justify-content: space-between;
          flex-direction: row;
          background-color: #353536;
          padding: 10px;

          .jobs {
            display: flex;
            height: 100%;
            flex: 2;
            justify-content: space-between;
            flex-direction: column;
          }

          .num-jobs {
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
          }
        }

        .icon {
          flex: 0.5;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          padding: 5px;
          background-color: #353536;

          img {
            width: 25px;
          }
        }
      }

      .add-image {
        margin-top: 20px;
      }

      .txtBtn {
        color: #37bdb0;
        margin: 10px;
        padding-right: 75px;
      }

      .action-button {
        display: inline-block;

        .remove {
          outline: none;
          color: red;
          background: transparent;
        }
      }
    }

    hr {
      width: 100%;
      display: block;
      height: 0;
      border: 1px solid rgba(211, 211, 211, 0.656);
      margin: 30px 0;
    }

    .form-group-full {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .add {
        width: 100%;
        display: flex;
        align-items: center;
        align-items: center;
        justify-content: space-between;

        p {
          &:last-child {
            cursor: pointer;

            &:hover {
              color: white;
            }
          }
        }
      }

      .custom-form-control {
        width: 100%;
      }
    }
  }
  .action-btn {
    margin: 20px 0 20px;
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 15px;
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
    .form-group {
      width: 100%;
    }
  }
}

.move-out-pm-review-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  // height: 100vh;

  .move-out-header {
    // position: fixed;
    width: 100%;
    text-align: center;

    h2 {
      padding: 15px;
    }
  }

  .move-out-report-wrapper {
    .moveOut-detail-box {
      margin-top: 20px;
      border-radius: 10px;
      padding: 10px;
      background-color: #262626;
      .moveOut-detail-box-wrapper {
        margin: 15px 35px;

        .tenant-moveOut-detail {
          display: grid;
          grid-template-columns: auto auto auto;
          grid-gap: 10px;
        }

        .contract-date-wrapper {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 140px;
        }

        .key-status {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
      }
    }

    .moveOut-timeline-list-wrapper {
      position: relative;

      .dot {
        display: block;
        width: 0;
        height: 0;
        padding: 2%;
        position: absolute;
        top: 20px;
        left: 2%;
        border-radius: 50%;
        z-index: 12;
        opacity: 10;
        background-color: #37bdb0;
        &::after {
          display: block;
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #000000;
          border-radius: 50%;
          height: 45%;
          width: 45%;
        }
      }
      .log-wrapper {
        .time-line {
          padding: 5%;
          padding-left: 8%;
          border-radius: 10px;
          margin: 20px 0;
          position: relative;
          display: flex;
          // flex-direction: row;
          width: 100%;
          background-color: #262626;

          .dot-wrapper {
            // flex: 1;
            .vl {
              position: absolute;
              border-left: 3px solid grey;
              margin-left: 16px;
              height: 112%;
              left: 18px;
              z-index: 10;
              opacity: 1;
            }
          }

          .detail-wrapper {
            flex: 2;

            .doc-list {
              background-color: #000000;
            }
          }
        }

        &:first-child .time-line {
          background-color: #0f2e2b;
        }

        &:last-child {
          .vl {
            height: unset !important;
          }
        }
      }

      .button-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 10px;
      }
      .custom-btn {
        margin-top: 4%;
        width: 100%;
      }
      // .detail-box-timeLine {
      //   border-radius: 10px;
      //   padding: 15px 10px;

      //   .main-timeline-wrapper {
      //     // display: flex;
      //     position: relative;

      //     .dot {
      //       display: block;
      //       width: 0;
      //       height: 0;
      //       padding: 2%;
      //       position: absolute;
      //       top: 20px;
      //       left: 2%;
      //       border-radius: 50%;
      //       z-index: 12;
      //       opacity: 10;
      //       background-color: #37bdb0;
      //       &::after {
      //         display: block;
      //         content: "";
      //         position: absolute;
      //         top: 50%;
      //         left: 50%;
      //         transform: translate(-50%, -50%);
      //         background-color: #000000;
      //         border-radius: 50%;
      //         height: 45%;
      //         width: 45%;
      //       }
      //     }
      //     .log-wrapper {
      //       .time-line {
      //         padding: 5%;
      //         padding-left: 8%;
      //         border-radius: 10px;
      //         margin: 20px 0;
      //         position: relative;
      //         display: flex;
      //         // flex-direction: row;
      //         width: 100%;
      //         background-color: #262626;

      //         .dot-wrapper {
      //           // flex: 1;
      //           .vl {
      //             position: absolute;
      //             border-left: 3px solid grey;
      //             margin-left: 16px;
      //             height: 116%;
      //             left: 20px;
      //             z-index: 10;
      //             opacity: 1;
      //           }
      //         }

      //         .detail-wrapper {
      //           flex: 2;
      //         }
      //       }

      //       &:first-child .time-line {
      //         background-color: #0f2e2b;
      //       }

      //       &:last-child {
      //         .vl {
      //           height: unset !important;
      //         }
      //       }
      //     }

      //     // .dot {
      //     //   flex: 0.15;
      //     //   margin: 5px;

      //     //   .dot-1 {
      //     //     width: 30px;
      //     //     height: 30px;
      //     //     border-radius: 50%;
      //     //     background-color: #37bdb0;
      //     //     display: flex;
      //     //     align-items: center;
      //     //     justify-content: center;
      //     //   }

      //     //   .dot-2 {
      //     //     width: 15px;
      //     //     height: 15px;
      //     //     border-radius: 50%;
      //     //     background-color: #000;
      //     //   }

      //     //   .vl {
      //     //     border-left: 1px solid grey;
      //     //     margin-left: 14px;
      //     //     height: 130%;
      //     //   }
      //     // }

      //     // .detail {
      //     //   flex: 2;
      //     //   margin: 5px 30px 5px 0;

      //     //   .data {
      //     //     display: flex;
      //     //     flex-direction: column;
      //     //     align-items: flex-start;

      //     //     .name {
      //     //       padding: 0 5px;
      //     //       width: 100%;
      //     //       display: flex;
      //     //       align-items: center;
      //     //       justify-content: space-between;

      //     //       p {
      //     //         span {
      //     //           margin-left: 10px;
      //     //         }
      //     //       }
      //     //     }

      //     //     .current-status {
      //     //       width: 100%;
      //     //       border-radius: 10px;
      //     //       background: #151517;

      //     //       p {
      //     //         margin: 10px;
      //     //       }
      //     //     }

      //     //     hr {
      //     //       width: 100%;
      //     //       display: block;
      //     //       height: 0;
      //     //       border: 1px solid rgba(211, 211, 211, 0.656);
      //     //       margin: 10px 0;
      //     //     }

      //     //     .remark-wrapper {
      //     //       padding: 20px 25px;
      //     //       display: flex;
      //     //       flex-direction: column;
      //     //       gap: 10px;
      //     //       border-radius: 10px;

      //     //       .task-descr {
      //     //         width: 100%;
      //     //         display: flex;
      //     //         justify-content: space-between;
      //     //         gap: 10px;

      //     //         .desc {
      //     //           width: 60%;
      //     //           padding: 0 20px;
      //     //         }
      //     //       }

      //     //       .uploaded-doc {
      //     //         width: 100%;
      //     //         display: flex;
      //     //         justify-content: space-between;
      //     //         gap: 10px;

      //     //         .doc-wrapper {
      //     //           width: 57%;
      //     //           display: flex;
      //     //           align-items: center;
      //     //           flex-direction: column;

      //     //           .doc {
      //     //             width: 100%;
      //     //             display: flex;
      //     //             align-items: center;
      //     //             flex-direction: row;
      //     //             gap: 10px;

      //     //             img {
      //     //               height: 20px;
      //     //               width: 20px;
      //     //               cursor: pointer;
      //     //             }
      //     //           }
      //     //         }
      //     //       }
      //     //     }

      //     //     .action-btns {
      //     //       margin: 15px 0;
      //     //       padding: 5px 0;
      //     //       width: 100%;
      //     //       display: flex;
      //     //       align-items: center;
      //     //       justify-content: space-between;
      //     //       gap: 25px;
      //     //     }
      //     //   }
      //     // }
      //   }

      //   // &:last-of-type {
      //   //   .main-timeline-wrapper {
      //   //     .dot {
      //   //       .vl {
      //   //         height: 0;
      //   //       }
      //   //     }
      //   //   }
      //   // }
      // }
    }
  }
  .icon {
    width: 30px;
  }
  .clearence-doc-group-wrapper {
    margin-top: 20px;
    padding: 3%;
    width: 100%;
    border-radius: 10px;
    .custom-row {
      h3 {
        margin: 0;
      }
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      align-items: center;
      .label {
        width: 50%;
      }

      .value {
        width: 50%;
      }
    }
  }
  @media only screen and (max-width: 1800px) {
    .icon {
      width: 27px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .icon {
      width: 24px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .icon {
      width: 21px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .moveOut-timeline-list-wrapper .log-wrapper .time-line .dot-wrapper .vl {
      left: 5px !important;
    }
  }
  @media only screen and (max-width: 900px) {
    .move-out-report-wrapper {
      width: 75%;

      .contract-date-wrapper {
        gap: 50px;
      }
    }
    .icon {
      width: 18px;
    }

    .moveOut-timeline-list-wrapper .log-wrapper .time-line .dot-wrapper .vl {
      left: 8px !important;
    }
  }
  @media only screen and (max-width: 600px) {
    .move-out-report-wrapper {
      width: 90%;

      .contract-date-wrapper {
        gap: 30px !important;
      }
    }

    .tenant-moveOut-detail {
      grid-template-columns: auto auto;
    }

    .moveOut-timeline-list-wrapper .log-wrapper .time-line .dot-wrapper .vl {
      left: 5px !important;
    }
  }
}

.move-out-pm-review-wrapper {
  p {
    margin: 0;
  }
  .black-container-sm {
    padding: 1.5% 2%;
    border-radius: 10px;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .icon-sm {
    width: 25px;
  }
  .section-divider {
    border: 0;
    border-top: 3px solid #939393;
    width: 100%;
    height: 0;
    margin: 4% 0;
  }
  .detail-card {
    padding: 5%;
    border-radius: 10px;
    .pair-section {
      display: flex;
      flex-wrap: wrap;
      .left {
        width: 20%;
      }
      .right {
        width: 80%;
      }
    }
    .summary {
      margin-top: 1%;
    }
    .ticket-unit {
      display: flex;
      justify-content: space-between;
      margin-top: 2%;
    }
    .section2 {
      display: flex;
      .category-column {
        width: calc(100% / 3);
      }
    }
    .section5 {
      .assigned {
        display: flex;
        justify-content: space-between;
        p {
          width: 48%;
        }
      }
    }
    .section8 {
      margin-top: 30px;
    }
    .docs-list {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
    }
    .media {
      cursor: pointer;
      width: 0;
      height: 0;
      position: relative;
      padding: 10%;
      margin-right: 6.66%;
      border-radius: 5px;
      &:last-child {
        margin: 0;
      }
      .thumbnail {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .timeline-list-wrapper {
    position: relative;
    .dot {
      display: block;
      width: 0;
      height: 0;
      padding: 2%;
      position: absolute;
      top: 20px;
      left: 2%;
      border-radius: 50%;
      z-index: 1;
      &::after {
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #000000;
        border-radius: 50%;
        height: 45%;
        width: 45%;
      }
    }
    .log-wrapper {
      .time-line {
        padding: 5%;
        padding-left: 8%;
        border-radius: 10px;
        margin: 20px 0;
        position: relative;
      }
      &:first-child .time-line {
        background-color: #0f2e2b;
      }
    }
    .button-wrapper {
      display: flex;
      justify-content: space-between;
    }
    .custom-btn {
      margin-top: 4%;
      width: 48%;
    }
    .log-footer {
    }
    .simple-log {
      .comments {
        margin-top: 20px;
      }
    }
    .title {
      margin-bottom: 3%;
    }

    .quotation-log,
    .task-log {
      hr {
        display: block;
        width: 100%;
        height: 2px;
        border: 0;
        background-color: #939393;
      }
      .sub-title {
        margin-bottom: 1.5%;
      }
      .doc-list {
        padding: 5%;
        border-radius: 10px;
        .table-header {
          padding-bottom: 10px;
        }
        .t-row {
          display: flex;
          margin: 0 0 10px 0;
          .t-col-1 {
            width: 50%;
          }
          .t-col-2 {
            width: 20%;
          }
          .t-col-3 {
            width: 30%;
          }
        }
        .action-txt-btn {
          margin-right: 10px;
        }
      }
      .sub-title {
        margin: 3% 0 2%;
      }
      .flex-row-2 {
        display: flex;
        justify-content: space-between;
        .value {
          margin-top: 2px;
        }
        .c {
          width: 49%;
        }
      }
      .part {
        margin-bottom: 3%;
        padding-bottom: 3%;
        border-bottom: 2px solid #454545;
        .r {
          margin-top: 2%;
          .value {
            margin-top: 2px;
          }
        }
        .flex-row-3 {
          display: flex;
          justify-content: space-between;
          .c {
            width: 30%;
          }
        }
      }
      .total {
        display: flex;
        justify-content: space-between;
      }
      .value.button-wrapper {
        .custom-btn {
          margin: 0;
        }
      }
      .attachment-row {
        .value {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          .task-attachment {
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }

  .uric-p1-log {
    .uirc-detail {
      margin-top: 2%;
      .r {
        margin-top: 3%;
        &:first-child {
          margin-top: 0;
          padding-top: 0;
          border: 0;
        }
        display: flex;
        justify-content: space-between;
        .c1 {
          width: 69%;
          white-space: pre-line;
        }
        .c2 {
          width: 29%;
          .edit-section {
            display: flex;
            .custom-icon-btn {
              margin-left: 5%;
            }
          }
          .sm-btn-wrapper {
            margin-top: 4%;
            .custom-btn {
              width: 40%;
            }
          }
        }
      }
      .thumbnail-wrapper {
        width: 22%;
        height: 150px;
        .thumbnail {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .view-inspection-report {
    margin-top: 20px;
  }

  @media only screen and (max-width: 1800px) {
    .icon-sm {
      width: 23px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .icon-sm {
      width: 21px;
    }
  }

  @media only screen and (max-width: 1400px) {
    .icon-sm {
      width: 19px;
    }
  }

  @media only screen and (max-width: 1200px) {
  }

  @media only screen and (max-width: 900px) {
    .icon-sm {
      width: 17px;
    }
    .detail-card {
      padding: 20px;
    }
    .section-divider {
      margin: 20px 0;
    }
    .detail-card .summary {
      margin-top: 10px;
    }
    .detail-card .ticket-unit {
      margin-top: 20px;
    }
    .detail-card .section2 {
      flex-wrap: wrap;
      .category-column {
        width: 50%;
        margin-bottom: 10px;
      }
    }
    .detail-card .media {
      padding: 80px;
      margin-right: 50px;
      margin-bottom: 20px !important;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    .detail-card .section8 {
      margin-top: 20px;
    }
  }

  @media only screen and (max-width: 600px) {
    .detail-card .pair-section {
      display: block;
      .left {
        width: 100%;
      }
      .right {
        width: 100%;
        margin-top: 10px;
      }
    }
    .detail-card .ticket-unit {
      display: block;
      .ticket-no,
      .unit-name {
        width: 100%;
      }
      .unit-name {
        margin-top: 7px;
      }
    }
    .detail-card .section2 .category-column {
      width: 100%;
    }
    .section5 {
      .assign,
      .reassign {
        text-align: start !important;
      }
    }
  }
}

.so-assign-modal-wrapper {
  .modal-header-custom {
    padding: 5px;
  }
  .modal-body {
    padding: 5% 8%;
    border: 2px solid grey;
  }
  .modal-body-custom {
    .form-wrapper {
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 2%;

      .userProfile {
        margin-top: 10px;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 20px;

        .userImage img {
          width: 75px;
          height: 75px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .details {
        margin: 10px 0;
        p {
          margin: 0 10px 0 0;
        }
        display: flex;
      }
    }

    .button-wrapper {
      margin-top: 30px;
      .custom-text-btn {
        margin-top: 20px;
      }
    }

    .form-group {
      width: 100%;
      margin-bottom: 20px;
      margin-top: 5px;
    }

    hr {
      width: 100%;
      display: block;
      height: 0;
      border: 1px solid rgba(211, 211, 211, 0.656);
      margin: 30px 0;
    }

    .form-group-full {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .custom-radio-component-wrapper {
        margin-right: 50px;
      }
      .custom-form-control {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
  }
}

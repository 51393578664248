.file-group-modal-wrapper {
  .modal-header-custom {
    margin: 0 0 6%;
    position: relative;
    padding-top: 8%;
    .icon-wrapper {
      img {
        width: 25%;
      }
    }
    h2 {
      font-weight: 600;
    }
  }
  .modal-body {
    padding: 0 0 8%;
    border: 2px solid #939393;
  }
  .description {
    margin: 5% 0;
  }

  .modal-footer-custom {
    display: flex;
    justify-content: center;
    button {
      width: 50%;
    }
  }
  .file-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0 6% 0;
    .file-img {
      width: 21%;
      margin: 0 2% 4%;
      // &:nth-child(4n + 0) {
      //   margin-right: 0;
      // }
    }
  }
  .close-btn {
    position: absolute;
    top: 15px;
    right: 20px;
  }
  .close-icon {
    width: 30px;
  }
  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
  }
}

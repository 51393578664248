@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");
.sign-up-tenant-wrapper {
  * {
    font-family: "Roboto", sans-serif;
  }
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: #f1ffff;

  /* **************************************** **************************************** */
  .image {
    width: 40%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .form-wrapper {
    width: 60%;
    height: 100%;
    overflow: auto;
    padding: 5% 10% 5% 3%;
    .form-group-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
      .form-group {
        width: 49%;
        margin-bottom: 2%;
      }
    }
  }
  .form-group-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    .custom-form-control {
      width: 49%;
    }
  }
  .logo-wrapper {
    // text-align: center;
    margin-bottom: 30px;
    img {
      width: 15%;
      min-width: 100px;
    }
  }

  .footer {
    margin-top: 20px;
  }

  .sigining-up,
  .error-while-submitting {
    margin: 20px 0;
    font-weight: 500;
  }

  /* ****************************************************************** */
  .bgShade {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
  }
  .image {
    height: 100%;
    overflow: auto;
    padding: 1% 10% 1% 3%;
    &::-webkit-scrollbar-track {
      background: transparent;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &::-webkit-scrollbar {
      display: block;
      width: 5px;
    }
  }
  .inner-wrapper {
    background-color: #78f4f6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 3px solid #871641;
    min-height: 100%;
    overflow: auto;
    padding: 20% 12% 20% 12%;
    border-radius: 40px;
    .heading {
      font-size: 60px;
      margin: 12% 0 0;
    }
    .content {
      font-size: 20px;
      margin-top: 10%;
    }
  }
  .big-h1 {
    font-size: 45px;
  }
  .font-darkgreen {
    color: #04423d;
  }
  .font_grey {
    color: #0c9488 !important;
    font-weight: 600 !important;
  }
  .custom-form-control {
    .form-input-wrapper {
      input {
        background-color: #ffffff !important;
        border: 2px solid lightgrey;
        color: #04423d !important;
        -webkit-text-fill-color: #04423d !important;
      }
      .bg_black {
        background-color: #ffffff !important;
      }
    }
  }
  .react-tel-input .form-control {
    background-color: #ffffff !important;
    border: 2px solid lightgrey !important;
    color: #04423d !important;
    -webkit-text-fill-color: #04423d !important;
    padding-top: 25px !important;
  }
  .form-group {
    label {
      font-weight: 400 !important;
      font-size: 15px !important;
    }
    input {
      font-size: 15px !important;
    }
    select {
      border: 2px solid lightgrey !important;
      color: #04423d !important;
      font-size: 15px;
      option {
        color: #04423d !important;
      }
    }
    .react-tel-input .selected-flag .flag {
      top: 28px !important;
    }
    .phone-input-label {
      top: 5px;
    }
    .form-input-wrapper label {
      top: 5px;
    }
    .form-input-wrapper input,
    .form-input-wrapper select {
      padding: 25px 15px 10px !important;
    }
  }
  .custom-btn-2 {
    border-radius: 100px;
    background-color: #ffb900;
    color: #04423d;
    text-align: left;
    padding: 0 3%;
  }
  .blur-blue {
    position: fixed;
    width: 125px;
    height: 125px;
    background: #1dc5b4 0% 0% no-repeat padding-box;
    opacity: 0.3;
    filter: blur(50px);
  }
  .blur-cherry {
    position: fixed;
    width: 78px;
    height: 78px;
    background: #88103d 0% 0% no-repeat padding-box;
    opacity: 1;
    filter: blur(50px);
  }
  .logo-icon {
    margin-right: 10px;
  }
  .custom-btn-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .icon {
    height: 30px;
  }
  .form-group {
    margin: 10px 0;
  }
  .forgot-password-wrapper {
    margin: 0 0 20px;
    .custom-text-btn {
      color: #04423d;
    }
  }
  /* ******************************************************************  */
  .terms-conditions-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .text {
      margin: 0;
      color: #04423d;
      display: inline-block;
      margin: 0 0 0 10px;
      span {
        cursor: pointer;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .image {
      display: none;
    }
    .form-wrapper {
      width: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    display: block;
    .form-wrapper .form .form-group {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.signin-outer-wrapper-valid {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f1ffff;

  .valid-wrapper {
    width: 100%;
    background-color: #ffffff;
    border: 2px solid #1dc5b4;
    border-radius: 10px;
    padding: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    img {
      width: 50px;
      height: 50px;
    }

    .back-link {
      padding: 10px 30px;
      border: 2px solid #1dc5b4;
      border-radius: 10px;
      background-color: #1dc5b4;
      color: white !important;
    }
  }
}

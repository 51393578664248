.handover-item-modal-wrapper {
  .modal-body {
    padding: 5% 7%;
  }
  .modal-header-custom {
    margin-bottom: 30px;
  }
  .form-wrapper {
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
  }
  .button-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
  .form-group {
    width: 49%;
    margin-bottom: 20px;
  }
  .form-group-full {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .custom-radio-component-wrapper {
      margin-right: 50px;
    }
    .custom-form-control,
    .custom-file-input-wrapper {
      width: 100%;
    }
  }
  .attachments-list {
    width: 100%;
  }
  .action-button-wrapper {
    .custom-text-btn {
      margin-right: 10px;
    }
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

.lost-details-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .header {
    text-align: center;
  }

  .report-wrapper {
    .detail-box {
      border-radius: 10px;
      padding: 15px;
      background-color: #262626;
      .unit-detail-box {
        margin: 1.5rem 3rem;
        .title {
          margin-bottom: 15px;
          color: #848484;
          font-size: 14px;
        }

        .date {
          span {
            padding-top: 10px;

            font-size: 14px;
          }

          span:last-child {
            margin-left: 50px;
          }
        }

        .date-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 6rem;

          .detail {
            padding-top: 10px;

            p {
              color: #848484;
              font-size: 14px;
              span {
                font-size: 14px;
              }
            }
          }
        }

        .image-wrapper {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 15px;

          .proof-img {
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
              width: 75px;
              height: 75px;
              object-fit: cover;
              border-radius: 10px;
              cursor: pointer;
            }
          }

          .undefined-img {
            display: flex;
            align-items: center;
            img {
              padding: 5px;
              width: 75px;
              height: 75px;
              object-fit: cover;
              border-radius: 10px;
              background-color: #fff;
            }
          }
        }

        .ticket-wrapper {
          color: #848484;
          // p {
          //   display: flex;
          //   align-items: center;
          //   justify-content: space-between;
            span {
              font-size: 14px;
              margin-left: 150px;
            }
          // }
        }
      }
    }
  }
}

.add-new-card-wrapper-main {
  .input-wrapper {
    position: relative;
    margin-top: 20px;
    label {
      display: block;
      font-weight: 600;
      position: absolute;
      top: 17px;
      left: 22px;
    }
    input {
      display: block;
      width: 100%;
      border: 0;
      padding: 42px 22px 17px;
      border-radius: 10px;
      &:focus {
        outline: none;
      }
    }
    .error {
      margin: 5px 0 0;
      font-weight: 400;
    }
  }
  @media only screen and (max-width: 1800px) {
    .input-wrapper {
      input {
        padding: 39px 19px 14px;
      }
      label {
        top: 14px;
        left: 19px;
      }
    }
  }
  @media only screen and (max-width: 1600px) {
    .input-wrapper {
      input {
        padding: 35px 15px 10px;
      }
      label {
        top: 10px;
        left: 15px;
      }
    }
  }
  @media only screen and (max-width: 1400px) {
    .input-wrapper {
      input {
        padding: 30px 14px 9px;
      }
      label {
        top: 9px;
        left: 14px;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .input-wrapper {
      input {
        border-radius: 7px;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .input-wrapper {
      input {
        padding: 26px 12px 7px;
      }
      label {
        top: 7px;
        left: 12px;
      }
    }
  }
  @media only screen and (max-width: 600px) {
  }
}

.emergency-modal-wrapper {
  .modal-header-custom {
    padding: 5px;

    .icon {
      display: flex;
      justify-content: end;

      img {
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
    }
  }
  .modal-body {
    padding: 5% 8%;
    border: 2px solid grey;
  }
  .modal-body-custom {
    margin: 5px 15px;
    .contacts-wrapper {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .dept-contact {
        border: none;
        border-radius: 10px;
        background: #151517;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .number {
          padding: 5px 10px;
          border-radius: 10px;
          border: 2px solid red;
          background: #3b0000;
        }
      }

      .otherTheme {
        border: none;
        border-radius: 10px;
        background: #f1ffff;
        color: #444444 !important;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .number {
          padding: 5px 10px;
          border-radius: 10px;
          border: 2px solid #37bdb0;
          background: #ffffff;
        }
      }
    }

    .message {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: center;

      img {
        width: 30px;
        height: 45px;
      }

      p {
        margin-left: 10px;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
  }
}

.teams-rating-table-wrapper {
  padding-top: 1rem;
  width: 100%;

  .ratings {
    span {
      .css-1x1lh1c-MuiRating-root {
        display: inline-flex;
        position: relative;
        font-size: 2rem;
        border: 1px solid red;
        cursor: pointer;

        .css-1vooibu-MuiSvgIcon-root {
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          width: 1em;
          height: 1em;
          display: inline-block;
          //fill: yellow;
          -webkit-flex-shrink: 0;
          -ms-flex-negative: 0;
          flex-shrink: 0;
          -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          font-size: inherit;
        }
      }
    }
  }

  .action-button {
    height: 25px;
  }
}

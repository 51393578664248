.found-wrapper {
  padding: 1.5rem;
  .found-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    position: relative;
    z-index: 10;
    // font-weight: 600;
    // text-align: center;

    // .add-found {
    //   position: fixed;
    //   left: 87%;
    // }

    // .link {
    //   text-align: right;
    // }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
    // .add-found {
    //   //   // position: relative;
    //   left: 85%;
    // }
  }
  @media only screen and (max-width: 900px) {
    // .add-found {
    //   //   // position: relative;
    //   left: 80%;
    // }
  }
  @media only screen and (max-width: 600px) {
  }
}

.Accordion-wrapper {
  .accordion-header {
    // padding: 10px;
    cursor: pointer;
    position: relative;
  }

  .accordion-indicator {
    position: absolute;
    right: 0;
    top: 0;
  }

  .accordion-body {
    padding-left: 10px;
    padding-right: 10px;

    .part-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.notification-item-detail-modal-main-wrapper {
  p {
    margin: 0;
  }
  .icon-btn-wrapper-inline {
    display: inline-block;
  }
  .icon-btn-wrapper {
    height: 35px;
  }
  .icon-btn-wrapper-sm {
    height: 30px;
  }
  .close-btn {
    display: flex;
    justify-content: flex-end;
  }
  .detail-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 0;
  }
  .detail-col.left {
    width: 50%;
  }
  .detail-col.right {
    width: 45%;
  }
  @media only screen and (max-width: 1800px) {
    .icon-btn-wrapper {
      height: 35px;
    }
    .icon-btn-wrapper-sm {
      height: 30px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .icon-btn-wrapper {
      height: 30px;
    }
    .icon-btn-wrapper-sm {
      height: 25px;
    }
  }

  @media only screen and (max-width: 1400px) {
    .icon-btn-wrapper {
      height: 30px;
    }
    .icon-btn-wrapper-sm {
      height: 25px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .icon-btn-wrapper {
      height: 25px;
    }
    .icon-btn-wrapper-sm {
      height: 20px;
    }
  }

  @media only screen and (max-width: 900px) {
  }

  @media only screen and (max-width: 600px) {
    .icon-btn-wrapper {
      height: 25px;
    }
    .icon-btn-wrapper-sm {
      height: 20px;
    }
  }
}

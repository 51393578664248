.wrapper-security {
  padding: 0 2rem;

  .heading-wrapper {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 10;
  }

  .tenant-security-table {
    position: relative;
    z-index: 10;
  }
}

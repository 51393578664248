.advertisment-detail-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  // gap: 2rem;

  .header-advertisment {
    position: fixed;
    width: 100%;
    text-align: center;
    background-color: #151517;
    z-index: 1;

    h2 {
      padding: 15px;
    }
  }

  .ad-chart-details {
    margin-top: 1rem;
    width: 75%;
    border-radius: 10px;
    padding: 15px 30px;

    .bar-chart-wrapper {
      width: 100%;

      .chart-details {
        position: relative;

        h3 {
          text-align: start;
        }

        .actions {
          position: absolute;
          left: 85%;
          top: 12%;
          display: flex;
          flex-direction: row;
          gap: 10px;

          p {
            padding: 5px 8px;
            border-radius: 10px;
            border: 1px solid grey;
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 5px;
            cursor: pointer;

            img {
              width: 25px;
              height: 20px;
            }
          }
        }
      }

      .chart-bar-details {
        padding: 5px 15px;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 20px;

        .text-rotate {
          writing-mode: vertical-rl;
        }
      }
    }
  }

  .advertisement-component {
    border-radius: 10px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #272727;
    height: auto;
    width: 75%;

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    // .image-wrapper {
    //   // flex: 2;

    //   img {
    //     width: 100%;
    //     height: 100%;
    //     object-fit: fill;
    //     cursor: pointer;
    //   }
    // }

    .image-wrapper {
      // overflow: hidden;
      position: relative;
      width: 100%; /* Full width */
      padding-top: calc(100% / 5.18); /* Aspect ratio 5.18:1 */
      overflow: hidden;
      background-color: #f0f0f0; /* Placeholder background */

      img {
        // width: 100%;
        // height: 300px;
        // object-fit: fill; // Prevents distortion while ensuring full coverage
        // // object-position: 50% 50%;
        cursor: pointer;
        // aspect-ratio: 1/1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: fill; /* Ensures cropping instead of distortion */
      }
    }

    .document-wrapper {
      display: flex;
      flex-direction: column;
      // height: 100vh;
      .description {
        img {
          width: 100%;
          height: 450px;
          object-fit: cover;
        }
      }

      .doc-image-wrapper {
        width: 100%;
        height: 60%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      // PDF CONTAINER
      .pdf-container {
        border-radius: 10px;
        width: 100%;
        height: 450px;
        overflow-x: hidden;
        overflow-y: scroll;
      }

      /* Scrollbar style starts */
      /* width */
      ::-webkit-scrollbar {
        width: 10px;
        margin-right: 1rem;
      }
      /* Track */
      ::-webkit-scrollbar-track {
        background-color: #707070;
        scroll-padding: 1rem;
      }
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background-color: #3d3d3d;
        background-clip: padding-box;
      }
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #272727;
        cursor: pointer;
      }
      /* Scrollbar style ends */
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

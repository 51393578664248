.invoicing-wrapper {
  padding: 1.5rem;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    position: relative;
    z-index: inherit;
    // font-weight: 600;
    // text-align: center;

    // .link {
    //   text-align: right;
    //   // float: right;
    //   // border: 1px solid red;
    //   // width: max-content;
    // }
  }

  // RENT INVOICE Table
  .tenant-invoice-table {
    .action-button {
      height: 30px;
      // margin-left: 20px;
    }
  }

  // UTILITY INVOICE Table
  .properties-invoice-table {
    .main-desc {
      white-space: nowrap;
      width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .doc {
      img {
        width: 25px;
        height: 25px;
      }

      span {
        cursor: pointer;
      }
    }

    .action-button {
      height: 30px;
      // margin-left: 20px;
    }
  }

  // PARTIES INVOICE Table
  .parties-invoice-table {
    .action-button {
      height: 30px;
      // margin-left: 20px;
    }
  }

  // ADVERTISMENT INVOICE Table
  .advertisment-invoice-table {
    .action-button {
      height: 30px;
      // margin-left: 20px;
    }
  }
}

.tenant-move-out-request-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .tenant-move-out-request-header {
    text-align: center;
  }

  .report-wrapper {
    width: 50%;

    .detail-box {
      margin-top: 5px;
      border-radius: 10px;
      padding: 10px 20px;
      .moveout-unit-detail-box {
        
        margin: 15px 30px;
        

        .log-header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            margin: 10px 0;
          }
        }

        hr {
          width: 100%;
          display: block;
          height: 0;
          border: 1px solid rgba(211, 211, 211, 0.656);
          margin: 15px 0;
        }

        .unit-info {
          padding: 0;
        }

        .time-info {
          display: flex;
          flex-direction: column;
        }

        .form-btn-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 15px;
          padding: 10px;

          // .btn1 {
          //   background: none;
          //   color: #37bdb0;
          //   border: 2px solid #37bdb0;
          //   border-radius: 10px;
          // }
        }
      }
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

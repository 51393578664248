.custom-image-wrapper-main {
  position: relative;
  .width-scale {
    width: 100%;
  }
  .height-scale {
    height: 100%;
  }
  .lazy-loader {
    width: 100px;
  }
  .overflow-hidden {
    overflow: hidden;
  }

  .objectFit-fill {
    object-fit: fill;
  }
  .objectFit-contain {
    object-fit: contain;
  }
  .objectFit-cover {
    object-fit: cover;
  }
  .objectFit-scale-down {
    object-fit: scale-down;
  }
  .objectFit-none {
    object-fit: none;
  }
}
.custom-thumbnail-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  .react-thumbnail-generator {
    top: 0;
    left: 0;
    position: absolute;
    /* object-fit: cover; */
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .video-thumbnail-icon-wrapper {
    width: 50%;
    height: 0;
    max-height: 150px;
    max-width: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .video-thumbnail {
    background-color: #202020ba;
    border-radius: 50%;
    // border: 1px solid #37bdb0;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding-top: 100%;
    height: 0;
    // width: 50%;
    // height: 50%;
    // max-height: 150px;
    // max-width: 150px;
    // box-shadow: 0 0 0 1px #37bdb0;
    img {
      height: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    p {
      font-size: 14px;
      bottom: 10%;
      left: 0;
    }
    transition: 0.5s;
  }
}

.custom-thumbnail-wrapper:hover .video-thumbnail {
  transform: translate(-50%, -50%) scale(1.1);
  box-shadow: 0 0 10px 5px #37bdb0;
}
.aspectRatioResponsiveness {
  width: 100%;
  height: 0;
  .image {
    height: 100%;
    width: 100%;
  }
  .center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .top-right {
  }
  .top-left {
    top: 0;
    left: 0;
  }
  .bottom-right {
  }
  .bottom-left {
  }
}
.loading-text {
  margin: 0;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

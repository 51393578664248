.add-property-page-wrapper-main {
  padding-bottom: 100px;

  .progress-steps {
    width: 100%;
    .step-list {
      overflow: hidden;
    }
  }

  .addingPropertyStep-wrapper {
  }

  .addingFMCStep-wrapper {
  }

  .addingLicenseStep-wrapper {
  }

  .addingManagementModelStep-wrapper {
  }

  .addingOwnerStep-wrapper {
  }

  .addingPMCStep-wrapper {
  }

  .addingSMCStep-wrapper {
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
    .form-group {
      width: 100%;
    }
    .addingPropertyStep-wrapper {
    }

    .addingLicenseStep-wrapper {
    }
  }
}

.tenant-unit-details-page-wrapper {
  p {
    margin: 0;
  }
  .status-icon-wrapper {
    img {
      width: 10%;
    }
  }
  .location {
    width: 30px;
  }

  .detail-card {
    margin-bottom: 30px;
    .flex {
      display: flex;
      .custom-col {
        margin-right: 20px;
      }
    }
  }

  .application-pending-application {
  }

  .button-wrapper-flex {
    display: flex;
    justify-content: space-between;
    .custom-btn {
      width: 48%;
    }
  }
  /* ********************************************************************************************************************************************************** */

  @media only screen and (max-width: 1800px) {
    .location {
      width: 27px;
    }
  }

  /* ********************************************************************************************************************************************************** */

  @media only screen and (max-width: 1600px) {
    .location {
      width: 24px;
    }
  }

  /* ********************************************************************************************************************************************************** */

  @media only screen and (max-width: 1400px) {
    .location {
      width: 21px;
    }
  }

  /* ********************************************************************************************************************************************************** */

  @media only screen and (max-width: 1200px) {
  }

  /* ********************************************************************************************************************************************************** */

  @media only screen and (max-width: 900px) {
    .location {
      width: 19px;
    }
    .status-icon-wrapper {
      img {
        width: 70px;
      }
    }

    .detail-card {
      .flex {
        display: block;
        .custom-col {
          margin-right: 0;
        }
      }
      .owner,
      .floor,
      .size,
      .ammenties {
        margin-top: 5px !important;
      }
    }
  }

  /* ********************************************************************************************************************************************************** */

  @media only screen and (max-width: 600px) {
  }
}

.add-task-module-wrapper {
  padding-bottom: 100px;
  .form-wrapper {
    background-color: #262626;
    padding: 3%;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
  }
  .button-wrapper {
    margin-top: 30px;
    .custom-text-btn {
      margin-top: 20px;
    }
  }

  .form-group {
    width: 49%;
    margin-bottom: 20px;

    .add-image {
      margin-top: 20px;
    }

    .txtBtn {
      color: #37bdb0;
      margin: 10px;
      padding-right: 75px;
    }

    .action-button {
      display: inline-block;

      .remove {
        outline: none;
        color: red;
        background: transparent;
      }
    }
  }

  .daily-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .time-input {
      display: flex;
      flex-direction: column;
      flex: 2;
      background-color: #000000;
      border-radius: 10px;

      input {
        border: none;
        outline: none;
        padding: 0 10px 5px 15px;
        // font-size: 14px;
        background: transparent;
      }
    }
  }

  .tenant {
    border-radius: 10px;

    label {
      margin: 10px 0 7px 15px;
      color: lightgray;

      span {
        color: white;
      }
    }
  }
  .form-group-full {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .custom-radio-component-wrapper {
      margin-right: 50px;
    }
    .custom-form-control {
      width: 100%;
    }
  }

  .weekly-day {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #000000;
    padding-bottom: 10px;

    .week-check-box {
      padding: 0 15px;
      display: grid;
      grid-template-columns: auto auto auto auto auto;
      grid-gap: 10px;
    }
  }

  .doc-list-wrapper {
    width: 100%;
    .button {
      margin-right: 10px;
    }
    .doc-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .doc {
        .remove-btn-wrapper {
          margin-top: 10px;
        }
      }
    }
    .add-btn-wrapper {
      display: flex;
      justify-content: flex-end;
      .add-btn {
        max-width: 100px;
        width: 100%;
      }
    }
  }
  .no-attachments {
    margin: 20px 0 30px;
    width: 100%;
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
    .form-group {
      width: 100%;
    }
  }
}

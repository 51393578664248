.request-wrapper {
  padding: 0 2rem;

  .heading-wrapper {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // .register-wrapper {
    //   margin-bottom: 20px;
    //   .link-security {
    //     text-align: right;
    //   }
    // }

    // .heading {
    //   text-align: center;
    // }
  }
}

.success-modal-wrapper {
  .modal-header-custom {
    margin-bottom: 40px;
    .icon-wrapper {
      img {
        width: 25%;
      }
    }
    h2 {
      font-weight: 600;
    }
  }
  .modal-body {
    padding: 8% 10%;
  }
  .description {
    margin: 5% 0;
  }

  .btn-wrapper {
    .custom-btn {
      width: 100%;
    }
  }

  .form-group {
    margin-bottom: 20px;
  }

  .otherTheme {
    .react-tel-input .form-control {
      background: #f1ffff !important;
      color: #444444 !important;
    }
  }
  .close {
    width: 30px;
  }
  .close-btn-wrapper {
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 900px) {
    .description {
      margin: 20px 0;
    }
    .close {
      width: 20px;
    }
  }
}

.get-cheque-detail-modal-wrapper {
  p {
    margin: 0;
  }
  .modal-header-custom {
    padding: 5px 5px 5px;
  }
  .custom-modal-body {
    padding: 2% 2%;
  }
  .cheque-details-flex {
    padding: 3%;
    border-radius: 10px;
    background-color: #151517;
  }

  .otherTheme {
    background-color: #f1ffff;
    // padding: 30px;
    // border-radius: 10px;
    color: #444444 !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .option {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    h3 {
      margin: 0;
    }
  }
  .modal-footer-custom {
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
  .custm-row {
    display: flex;

    margin: 10px 0 10px;
    .left {
      width: 40%;
    }
  }
  .icon {
    height: 25px;
  }
  .cross-btn {
    display: flex;
    justify-content: flex-end;
  }
}

.rating-ui-wrapper {
  .star-icon {
    display: inline-block;
    margin: 0 5px;
    height: 30px;
    img {
      height: 100%;
    }
  }
}

.helpAndSupport-page-wrapper {
  margin-top: 1rem;
  .main-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .main_details {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 15px;

      .details {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }

    .main_contacts {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;

      .btn {
        padding: 0 85px;
        background-color: #37bdb0;
        border-radius: 10px;

        p {
          padding-top: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
        }
      }

      .contacts {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;

        p {
          padding: 5px 10px;
          background-color: #1c3735;
          border-radius: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 1800px) {
  }

  @media only screen and (max-width: 1600px) {
  }

  @media only screen and (max-width: 1400px) {
  }

  @media only screen and (max-width: 1200px) {
  }

  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

.community-image-stripe-component-wrapper {
  padding: 3% 3% 0;
  border-radius: 10px;
  .image-stripe {
    display: flex;
    flex-wrap: wrap;
  }
  .image-item {
    position: relative;
    width: 25%;
    height: 0;
    padding-bottom: 20%;
    margin-bottom: 5%;
    margin-right: 12.5%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    .inner-image {
      position: absolute;
      top: 0;
      left: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
    .view-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .hidden-input {
    display: none;
  }
  .label-add {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
    }
  }
  .limit {
    margin: 0 0 30px;
  }
  .action {
    display: block;
    position: absolute;
    border-radius: 100%;
    height: 0px;
    width: 40px;
    padding-bottom: 40px;
    cursor: pointer;
    top: -20px;
  }
  .tick-icon {
    left: -20px;
    background: #424243;
    &.default {
      background-color: #37bdb0;
    }
  }
  .delete-icon {
    right: -20px;
    background: #ff1e1e;
    img {
      width: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .tick-icon::after {
    content: "";
    display: block;
    position: absolute;
    transform: rotate(50deg) translate(-50%, -50%);
    left: 16%;
    top: 45%;
    height: 65%;
    width: 35%;
    border-bottom: 5px solid #fff;
    border-right: 5px solid #fff;
  }
  @media only screen and (max-width: 1800px) {
    .action {
      width: 35px;
      padding-bottom: 35px;
      top: -17.5px;
    }
    .delete-icon {
      right: -17.5px;
    }
    .tick-icon {
      left: -17.5px;
    }
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
    .action {
      width: 30px;
      padding-bottom: 30px;
      top: -15px;
    }
    .delete-icon {
      right: -15px;
    }
    .tick-icon {
      left: -15px;
    }
    .tick-icon::after {
      border-bottom: 4px solid #fff;
      border-right: 4px solid #fff;
    }
    .image-item {
      margin-bottom: 45px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .image-item {
      width: 40%;
      padding-bottom: 30%;
      &:nth-child(n) {
        margin-right: 20%;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .action {
      width: 25px;
      padding-bottom: 25px;
      top: -12.5px;
    }
    .delete-icon {
      right: -12.5px;
    }
    .tick-icon {
      left: -12.5px;
    }
    .tick-icon::after {
      border-bottom: 3px solid #fff;
      border-right: 3px solid #fff;
    }
  }
}

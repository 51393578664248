.custom-radio-component-wrapper {
  &.label_vertical {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 5px;
  }

  .radio-button {
    cursor: pointer;
    .circle {
      cursor: pointer;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 4px;
      border: 2px solid #939393;
      .inner-dot {
        border-radius: 50%;
        height: 100%;
        width: 100%;
      }
    }
    &.active {
      .circle {
        border: 2px solid #37bdb0;
        .inner-dot {
          background-color: #37bdb0;
        }
      }
    }
  }
  // .label {
  //   margin-top: 4px;
  //   margin-left: 15px;
  //   color: #939393;
  // }
  .form-field-input {
    display: none;
    &:checked ~ .circle {
      border: 2px solid #37bdb0;
      .inner-dot {
        background-color: #37bdb0;
      }
    }
  }

  @media only screen and (max-width: 1800px) {
    .radio-button .circle {
      width: 27px;
      height: 27px;
    }
    // .label {
    //   margin-top: 3.5px;
    //   margin-left: 9px;
    // }
  }
  @media only screen and (max-width: 1600px) {
    .radio-button .circle {
      width: 25px;
      height: 25px;
    }
    // .label {
    //   margin-top: 3px;
    //   margin-left: 8px;
    // }
  }
  @media only screen and (max-width: 1400px) {
    .radio-button .circle {
      width: 23px;
      height: 23px;
      padding: 3px;
    }
    // .label {
    //   margin-top: 2.5px;
    //   margin-left: 7px;
    // }
  }
  @media only screen and (max-width: 1200px) {
    .radio-button .circle {
    }
    .label {
    }
  }
  @media only screen and (max-width: 900px) {
    .radio-button .circle {
      width: 20px;
      height: 20px;
      padding: 4px;
    }
    // .label {
    //   margin-top: 2px;
    //   margin-left: 5px;
    // }
  }
  @media only screen and (max-width: 600px) {
    .radio-button .circle {
    }
    .label {
    }
  }
}

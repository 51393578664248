.assign-license-module-wrapper {
  .form-wrapper {
    background-color: #262626;
    padding: 3%;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    position: relative;
    z-index: 10;
  }
  .button-wrapper {
    margin-top: 30px;
    .custom-text-btn {
      margin-top: 20px;
    }
  }

  .form-group {
    width: 49%;
    margin-bottom: 20px;
  }
  .form-group-full {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .custom-radio-component-wrapper {
      margin-right: 50px;
    }
  }
  .warning-wrapper {
    .warning-wrapper-text {
      border-radius: 10px;
      margin-top: 30px;
      padding: 3%;
    }
    .warning-text {
      display: flex;
      align-items: center;
      p {
        margin: 0 0 0 20px;
      }
      img {
        width: 3%;
        min-width: 20px;
      }
    }
    .prorated-wrapper {
      display: flex;
      margin-top: 20px;
    }
    .prorated {
      p {
        margin: 0;
      }
    }
    .total-license-amt {
      margin-left: 20px;
      p {
        margin: 0;
      }
    }
  }
  .form-wrapper-top {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .form-wrapper-bottom {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
    .warning-wrapper {
      .total-license-amt {
        margin: 10px 0 0;
      }
      .prorated-wrapper {
        display: block;
      }
    }
  }
}

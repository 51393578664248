.custom-select-input-v2 {
  select {
    display: block;
    width: 100%;
    border: 0;
    background-color: #262626;
    &:focus {
      outline: none;
      border: 2px solid #37BDB0;
    }
  }
}

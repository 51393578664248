.staff-scheduling-wrapper {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;

  //   .header {
  //     position: fixed;
  //     width: 100%;
  //     text-align: center;
  //     background-color: #151517;
  //     z-index: 1;

  //     h2 {
  //       padding: 15px;
  //     }
  //   }

  .heading-details {
    display: flex;
    justify-content: flex-start;
    gap: 25px;

    .location-wrapper {
      display: flex;
      flex-direction: column;
      padding: 5px 5px 5px 45px;

      span {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .date-wrapper {
      display: flex;
      align-items: center;
      // justify-content: space-around;

      .date {
        padding: 15px 50px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 15px;

        .icon-wrapper {
          padding: 0 10px;
          cursor: pointer;
        }
      }
    }

    .date-wrapper-view {
      display: flex;
      align-items: center;

      .date {
        padding: 15px 50px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 15px;

        .drop-down {
          color: gray !important;
          border: none;
          background: inherit;
          outline: none;
          cursor: pointer;
        }
      }
    }
  }

  .schedule-wrapper {
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: flex-start;
    gap: 15px;

    .emp-details {
      width: 25%;
      height: 100%;
      padding: 10px 5px;

      .search-box {
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        .search {
          width: 100%;
        }

        .icon {
          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      .profile-details-wrapper {
        margin: 0 10px;
        display: flex;
        flex-direction: column;

        .profile {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 15px;
          margin: 10px 0;

          .detail {
            width: 90%;
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 20px;

            img {
              width: 35px;
              height: 35px;
              border-radius: 50%;
              object-fit: cover;
            }
          }

          .dropdown {
            position: relative;
            display: inline-block;
          }

          .dropdown-content {
            display: none;
            position: absolute;
            right: 0;
            background-color: #f1f1f1;
            min-width: 180px;
            border-radius: 10px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
          }

          .link-tag {
            padding: 5px 14px;
            border-radius: 10px;
            text-decoration: none;
            display: block;

            // .icon-drop {
            //   color: black;
            // }
          }

          //   .dropdown-content a:hover {
          //     background-color: #ddd;
          //   }

          .dropdown:hover .dropdown-content {
            display: block;
          }

          .plus {
            width: 10%;
            margin: 0 10px;
          }
        }
      }
    }

    .schedule-details {
      width: 75%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      overflow-x: scroll;

      .dates-wrapper {
        background: grey;
        display: grid;
        grid-template-columns: repeat(12, 150px);
        gap: 5px;

        div {
          width: 25%;
          border-right: 1px solid grey;
          padding: 5px 15px;
        }
      }

      .values-wrapper {
        border: 1px solid rgb(247, 212, 17);
      }
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

.card-list-wrapper-main {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  gap: 2%;
  background-color: #262626;
  padding: 1rem;
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
    display: block;
  }
}

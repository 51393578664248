.visitor-pass-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .visitor-pass-header {
    text-align: center;
    // padding: 10px;
  }

  .report-wrapper {
    // padding: 0.5rem;
    // width: 50%;

    .detail-box {
      margin-top: 20px;
      border-radius: 10px;
      padding: 10px 20px;
      background-color: #262626;
      .visitor-unit-detail-box {
        margin: 20px 35px;
        color: white;

        .log-header {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 20px;

          .img-wrapper {
            // padding: 5px;

            // background: #151517;

            img {
              width: 100px;
              height: 100px;
              border-radius: 10px;
              object-fit: cover;
            }
          }

          .detail {
            padding: 10px;

            .num {
              margin-bottom: 10px;
            }

            p {
              // color: #505050;
              margin-top: 10px;

              // span {
              //   margin-left: 10px;
              // }
            }
          }
        }

        .visitor-info {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;

          // div {
          //   p {
          //     // color: #505050;
          //   }
          // }

          .reason-info {
            p {
              // color: #505050;

              span {
                color: #37bdb0;
              }
            }
          }
        }

        .time-info {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 100px;

          // div {
          //   p {
          //     // color: #505050;
          //   }
          // }
        }
      }
    }
  }

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
    // .log-header {
    //   display: flex;
    //   flex-direction: column;
    //   gap: 20px;
    // }

    // .visitor-info {
    //   display: flex;
    //   flex-direction: column;
    // }
  }
}

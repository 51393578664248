.initial-agreement-form-wrapper {
  .page-heading {
    margin-bottom: 50px;
  }
  // Error Note
  .error-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 45px;
      height: 45px;
      object-fit: cover;
    }

    p {
      margin: 10px;
      padding: 10px;
      color: red;
    }
  }

  // Payment
  .payment-wrapper {
    padding: 10px 25px 20px 25px;
    border-radius: 10px;
    .pay {
      margin: 10px 25px;
    }
    .payment-detail {
      display: flex;
      flex-direction: column;

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        gap: 20rem;
        padding: 0 25px;

        .amount {
          color: #37bdb0;
        }
      }
    }
  }

  // Deposit
  .deposit-wrapper {
    margin-top: 15px;
    padding: 10px 25px;
    border-radius: 10px;

    .deposit-detail {
      display: flex;
      flex-direction: column;

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 25px;

        .amount {
          color: #37bdb0;
        }
      }
    }
  }

  // Instalment
  .instalment-wrapper {
    margin-top: 15px;
    padding: 10px 25px;
    border-radius: 10px;

    .instalment-detail {
      display: flex;
      flex-direction: column;

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 25px;

        .amount {
          color: #37bdb0;
        }
      }
    }
  }

  .unit-detail-installment-plan {
    border-radius: 10px;
    padding: 5%;
  }

  // Property Details
  .property-wrapper {
    margin-top: 15px;
    padding: 1% 2%;
    border-radius: 10px;
    background: #151517;

    .property-detail-heading {
      margin-bottom: 20px;
    }
    .custom_card {
      .top {
      }
    }
    .property-detail-body {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
    }
    .unit-detail {
      margin-top: 30px;
      .unit_detail_external {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
      }
      .unit_detail_ammenties {
        margin-top: 30px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;
      }
    }

    .property_name {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    .property_city {
    }

    .property_state {
    }

    .property_country {
    }

    .property_address1 {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    .property_address2 {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    .unit_detail_external {
    }

    .unit_detail_ammenties {
    }

    .unit_name {
    }

    .unit_type {
    }

    .unit_description {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    .note {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    .unit_floor {
    }

    .total_rent {
    }

    .unit_size_measurement {
    }

    .unit_size {
    }

    .kitchen {
    }

    .pantry {
    }

    .toilet {
    }

    .bathroom {
    }

    .livingroom {
    }

    .maidroom {
    }

    .balcony {
    }

    .showroom {
    }

    .terrace {
    }

    .laundry {
    }

    .offices {
    }

    .store {
    }
  }

  // Signature
  .signature-wrapper {
    margin-top: 15px;
    padding: 10px 5px;
    border-radius: 10px;

    .signature-detail {
      display: flex;
      flex-direction: row;
      justify-content: right;

      .sign {
        color: #37bdb0;
        padding-right: 7px;
      }

      .blank {
        width: 300px;
        height: 75px;
        border-radius: 10px;
        background-color: #fff;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          border-radius: 10px;
        }
      }

      input {
        display: none;
      }
    }
  }

  // Button
  .btn-wrapper {
    margin-top: 30px;
    button {
      margin-bottom: 20px;
    }

    .btn {
      color: red;
      padding: 20px 100px;
    }
  }
  .revision-message {
    margin: 20px 0 0;
    padding: 30px;
    border-radius: 10px;
    p {
      margin: 0;
    }
    .box {
      margin-bottom: 15px;
    }
    .message {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 30px;
    }
    .action-btn-wrapper {
    }
  }
  .template-trigger {
    border-radius: 7px;
    margin-top: 20px;
    padding: 10px 20px;
    .eye-icon {
      width: 30px;
    }
  }
  .installment-plan-heading {
    // margin: 40px 0 20px;
    // margin: 40px 0 20px;
    // padding: 0 5%;
    margin-bottom: 20px;
  }
  .installment-plan-detail-wrapper {
    border-radius: 10px;
    // padding: 5%;
    margin-top: 30px;
    background: #151517;
    padding: 20px 15px;
    .section-heading {
      margin-bottom: 20px;
    }
    .table-section-heading {
      margin-bottom: 20px;
    }
    .custom-row {
      margin-bottom: 20px;
      .custom-column {
        width: 48%;
        display: flex;
        justify-content: space-between;
        .label {
          width: 48%;
        }
        .value {
          width: 48%;
        }
      }
    }
    .custom-row-flex {
      display: flex;
      justify-content: space-between;
    }
    .custom-row-flex-2 .custom-column {
      width: 48%;
    }
    .custom-table-wrapper {
      padding: 0 !important;
      // background-color: #151517;

      .custom-table thead {
        background-color: #404040;
      }
      tbody {
        tr {
          border-bottom: 2px solid #404040;
        }
        tr:last-child {
          border: none;
        }
      }
    }
    .deposit-list {
      margin-top: 20px;
      padding-top: 20px;
    }
    .installment-list {
      margin-top: 20px;
      padding-top: 20px;
      // border-top: 2px solid #939393;
    }
  }
  .doc-view-control-wrapper {
    padding: 5% 6%;
    border-radius: 10px;
    margin-top: 50px;
    .agreement-heading {
      margin-bottom: 20px;
    }
    .agreement-description {
      margin-bottom: 30px;
    }
    .view-document-btn {
      width: 100%;
      max-width: 300px;
    }
  }
  .cancel_renewal {
    margin: 30px 0;
    width: 100%;
    text-align: center;
  }
  .status-wrapper {
    .icon-wrapper {
      margin: 0 auto;
      width: 12%;
      min-width: 70px;
      img {
        width: 100%;
      }
    }
    p {
      margin: 30px 0 0 0;
    }
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
  }
  @media only screen and (max-width: 600px) {
  }
}

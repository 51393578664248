.contractor-pass-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .contractor-pass-header {
    text-align: center;
  }

  .report-wrapper {
    .detail-box {
      margin-top: 20px;
      border-radius: 10px;
      padding: 15px;
      background-color: #262626;
      .contractor-unit-detail-box {
        margin: 15px 35px;
        color: white;

        .log-header {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 20px;

          .img-wrapper {
            img {
              width: 100px;
              height: 100px;
              border-radius: 10px;
              object-fit: cover;
            }
          }

          .detail {
            padding: 10px;

            .num {
              margin-bottom: 10px;
            }

            p {
              margin-top: 10px;

              span {
                margin-left: 10px;
              }
            }
          }
        }

        .visitor-info {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;

          .reason-info {
            p {
              span {
                color: #37bdb0;
              }
            }
          }
        }

        .time-info {
          display: flex;
          align-items: center;
          justify-content: space-between;

          div {
            width: 190px;

            p {
              .approve {
                color: #37bdb0;
              }

              .reject {
                color: red;
              }

              .request {
                color: green;
              }

              .pending {
                color: #e7da20;
              }
            }
          }
        }

        .request_by {
          p {
            display: flex;
            flex-direction: row;
            gap: 7rem;
          }
        }

        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          .reject-btn {
            background-color: red;
            color: white;
          }
        }
      }
    }
  }
}

.invoice-list-component-wrapper {
  .invoice-list {
    padding: 1%;
    border-radius: 10px;
    background-color: #000000;
  }
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    .invoice-list {
      padding: 10px;
    }
  }
  @media only screen and (max-width: 600px) {
  }
}

.invoice-item-component-wrapper {
  p {
    margin: 0;
  }
  cursor: pointer;
  border-radius: 5px;
  padding: 1% 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .status-date {
    margin-left: 10px;
    display: flex;
    align-items: center;
    .date {
      margin-left: 10px;
    }
  }
  .arr-right {
    height: 25px;
  }
  @media only screen and (max-width: 1800px) {
    .arr-right {
      height: 23px;
    }
  }
  @media only screen and (max-width: 1600px) {
    .arr-right {
      height: 21px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .arr-right {
      height: 18px;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 900px) {
    padding: 10px 20px;
  }
  @media only screen and (max-width: 600px) {
    display: block;
    padding: 10px 10px;
    .status-date {
      margin: 5px 0 0;
      .date {
        margin: 0;
      }
    }
    .status {
      order: 2;
      margin-left: 10px;
    }
    .arr-right {
      order: 3;
    }
  }
}
